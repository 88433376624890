import React, { ReactElement } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import styles from './SmallContainer.module.less';

interface Props extends CcxComponentProps {
    bottomChildren?: ReactElement;
    middleChildren?: ReactElement;
    topChildren?: ReactElement;
}

function SmallContainer({
    bottomChildren,
    middleChildren,
    topChildren,
    testId = 'SmallContainer',
}: Props): ReactElement {
    return (
        <div className={styles.SmallContainer} data-testid={testId}>
            <div
                className={styles.SmallContainerTop}
                data-testid={`${testId}Top`}
            >
                {topChildren}
            </div>
            <div
                className={styles.SmallContainerMiddle}
                data-testid={`${testId}Middle`}
            >
                {middleChildren}
            </div>
            <div
                className={styles.SmallContainerBottom}
                data-testid={`${testId}Bottom`}
            >
                {bottomChildren}
            </div>
        </div>
    );
}

export default SmallContainer;
