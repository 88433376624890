import { createContext } from 'react';

export type StateContextProps = {
    reducer: any;
    initialState: any;
    children?: any;
};

// We initialise them without default values, to make that happen, we
// apply the Partial helper type.
export const StateContext = createContext<Partial<StateContextProps> | any>({});
