import { ReactElement } from 'react';
import { Button, Tabs } from 'antd';
import { SettingOutlined } from '@ant-design/icons/lib/icons';
import AppEmpty from '../AppEmpty';
import { useParams } from 'react-router-dom';
import ButtonWithOverlayDialog from '../common/ButtonWithOverlayDialog';
import BackupsList from './BackupsList';
import BackupsScheduleList from './BackupsScheduleList';
import { useHistory } from 'react-router';
import BackupSettingsModal from './BackupsSettingsModal';
import { useAppSelector } from '../../redux/hooks';

export type UrlProps = {
    dataStoreUuid: string;
    projectUuid: string;
    tab: string;
};

function Backups(): ReactElement {
    const history = useHistory();
    const { dataStoreUuid, projectUuid, tab } = useParams<UrlProps>();
    const { dataStore: deployment } = useAppSelector(
        (state) => state.dataStore
    );

    return (
        <Tabs
            data-testid="BackupSettings"
            tabBarExtraContent={
                deployment && (
                    <ButtonWithOverlayDialog
                        button={
                            <Button
                                data-testid={`BackupSettings`}
                                icon={<SettingOutlined />}
                                disabled={!deployment?.operable}
                            >
                                Backup settings
                            </Button>
                        }
                        overlay={
                            <BackupSettingsModal deployment={deployment} />
                        }
                    />
                )
            }
            activeKey={tab || 'backups'}
            onChange={(tabKey: any) => {
                const tab = tabKey === 'backups' ? '' : `/${tabKey}`;
                history.push(
                    `/projects/${projectUuid}/data-stores/${dataStoreUuid}/backups${tab}`
                );
            }}
        >
            <Tabs.TabPane
                tab="All backups"
                key="backups"
                style={{ paddingTop: 20 }}
            >
                {(deployment && <BackupsList deployment={deployment} />) || (
                    <AppEmpty message="No datastore was retrieved" />
                )}
            </Tabs.TabPane>

            <Tabs.TabPane
                tab="Backup schedules"
                key="schedules"
                style={{ paddingTop: 20 }}
            >
                {(deployment && (
                    <BackupsScheduleList deployment={deployment} />
                )) || <AppEmpty message="No datastore was retrieved" />}
            </Tabs.TabPane>
        </Tabs>
    );
}

export default Backups;
