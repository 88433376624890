export type PrometheusPostgreSqlIdleSessionsInTransactionAbortedStatsBackendApiProps =
    {
        created: number;
        idle_in_transaction_aborted: number;
    };

export default class PrometheusPostgreSqlIdleSessionsInTransactionAbortedStats {
    readonly created: number;
    readonly idleInTransactionAborted: number;

    constructor(
        props: PrometheusPostgreSqlIdleSessionsInTransactionAbortedStatsBackendApiProps
    ) {
        this.created = props.created;
        this.idleInTransactionAborted = props.idle_in_transaction_aborted;
    }
}
