export type PrometheusPostgreSqlIdleSessionsStatsBackendApiProps = {
    created: number;
    idle: number;
};

export default class PrometheusPostgreSqlIdleSessionsStats {
    readonly created: number;
    readonly idle: number;

    constructor(props: PrometheusPostgreSqlIdleSessionsStatsBackendApiProps) {
        this.created = props.created;
        this.idle = props.idle;
    }
}
