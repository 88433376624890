import React, { ReactElement, useEffect, useState } from 'react';
import FriendlyTimestamp from '../../../types/FriendlyTimestamp';
import AppChart from '../../AppChart';
import CcxComponentProps from '../../../core/CcxComponent';
import ChartCustomTooltip from './ChartCustomTooltip';
import PrometheusMySqlHandlersStats from '../../../types/PrometheusMySqlHandlersStats';

interface Props extends CcxComponentProps {
    data?: PrometheusMySqlHandlersStats[];
    loading: boolean;
    displaySummary?: boolean;
    height?: number;
    width?: number;
}

function PrometheusMySqlHandlersChart({
    data,
    loading,
    displaySummary,
    testId = 'PrometheusMySqlHandlersChart',
    width,
    height = 250,
}: Props): ReactElement {
    const [chartData, setChartData] = useState<any>([]);
    const [summary, setSummary] = useState<any>([]);

    const setChartSummary = (data: any, series: any) => {
        const max: number[] = [];
        const min: number[] = [];
        const sum: number[] = [];
        const avg: number[] = [];

        series.forEach((s: any) => {
            max[s.value] = 0;
            min[s.value] = 0;
            sum[s.value] = 0;
            avg[s.value] = 0;
        });

        data.forEach((d: any) => {
            series.forEach((s: any) => {
                sum[s.value] += d[s.value];

                min[s.value] =
                    d[s.value] < min[s.value] ? d[s.value] : min[s.value];
                max[s.value] =
                    d[s.value] > max[s.value] ? d[s.value] : max[s.value];
            });
        });

        series.forEach((s: any) => {
            avg[s.value] = sum[s.value] / data.length;
        });

        const dataSummary = series.map((s: any) => {
            return {
                serie: s.value,
                label: s.label,
                avg: avg[s.value].toFixed(2),
                min: min[s.value].toFixed(2),
                max: max[s.value].toFixed(2),
                sum: sum[s.value].toFixed(2),
            };
        });

        setSummary(dataSummary);
    };

    useEffect(() => {
        if (data && data.length > 0) {
            const formattedData = data.map(
                (stat: PrometheusMySqlHandlersStats) => {
                    return [
                        {
                            yValue: stat.handlersReadRnd,
                            series: 'handlers_read_rnd',
                            xValue: new FriendlyTimestamp(
                                stat.created
                            ).getDate(),
                        },
                        {
                            yValue: stat.handlersReadRndNext,
                            series: 'handlers_read_rnd_next',
                            xValue: new FriendlyTimestamp(
                                stat.created
                            ).getDate(),
                        },
                        {
                            yValue: stat.handlersReadNext,
                            series: 'handlers_read_next',
                            xValue: new FriendlyTimestamp(
                                stat.created
                            ).getDate(),
                        },
                        {
                            yValue: stat.handlersReadKey,
                            series: 'handlers_read_key',
                            xValue: new FriendlyTimestamp(
                                stat.created
                            ).getDate(),
                        },
                        {
                            yValue: stat.handlersReadFirst,
                            series: 'handlers_read_first',
                            xValue: new FriendlyTimestamp(
                                stat.created
                            ).getDate(),
                        },
                        {
                            yValue: stat.handlersReadLast,
                            series: 'handlers_read_last',
                            xValue: new FriendlyTimestamp(
                                stat.created
                            ).getDate(),
                        },
                        {
                            yValue: stat.handlersDelete,
                            series: 'handlers_delete',
                            xValue: new FriendlyTimestamp(
                                stat.created
                            ).getDate(),
                        },
                        {
                            yValue: stat.handlersUpdate,
                            series: 'handlers_update',
                            xValue: new FriendlyTimestamp(
                                stat.created
                            ).getDate(),
                        },
                        {
                            yValue: stat.handlersWrite,
                            series: 'handlers_write',
                            xValue: new FriendlyTimestamp(
                                stat.created
                            ).getDate(),
                        },
                    ];
                }
            );
            setChartData(formattedData.flat());

            const summarySeries = [
                {
                    value: 'handlersReadRnd',
                    label: 'handlers_read_rnd',
                },
                {
                    value: 'handlersReadRndNext',
                    label: 'handlers_read_rnd_next',
                },
                {
                    value: 'handlersReadNext',
                    label: 'handlers_read_next',
                },
                {
                    value: 'handlersReadKey',
                    label: 'handlers_read_key',
                },
                {
                    value: 'handlersReadFirst',
                    label: 'handlers_read_first',
                },
                {
                    value: 'handlersReadLast',
                    label: 'handlers_read_last',
                },
                {
                    value: 'handlersDelete',
                    label: 'handlers_delete',
                },
                {
                    value: 'handlersUpdate',
                    label: 'handlers_update',
                },
                {
                    value: 'handlersWrite',
                    label: 'handlers_write',
                },
            ];
            setChartSummary(data, summarySeries);
        }
    }, [data]);

    const chartConfig = {
        title: {
            visible: true,
            text: 'Handler stats',
        },
        description: {
            visible: true,
            text: '',
        },
        height,
        width,
        autoFit: !width && !height,
        xField: 'xValue',
        yField: 'yValue',
        xAxis: {
            type: 'time',
            mask: 'HH:mm',
        },
        animation: false,
        seriesField: 'series',
        tooltip: {
            customContent: (title: any, data: any) => {
                const getTitle = () => {
                    if (!data || data.length === 0) return '';
                    return data[0]?.data?.xValue.toString();
                };

                const getContent = () => {
                    if (!data || data.length === 0) return [];
                    return data.map((d: any) => ({
                        color: d?.color,
                        label: d?.data?.series,
                        value: d?.data?.yValue,
                    }));
                };

                const tooltipData = {
                    title: getTitle(),
                    content: getContent(),
                };

                return (
                    <ChartCustomTooltip
                        title={tooltipData.title}
                        content={tooltipData.content}
                    />
                );
            },
        },
    };

    return (
        <AppChart
            type="line"
            data={chartData}
            config={chartConfig}
            summary={summary}
            loading={loading}
            displaySummary={displaySummary}
            testId={testId}
        />
    );
}

export default PrometheusMySqlHandlersChart;
