import React, { ReactElement, useState, useEffect } from 'react';
import { CloudSyncOutlined, InfoCircleOutlined } from '@ant-design/icons';
import ButtonModalForm from '../ButtonModalForm';
import useContentCidrs from '../../core/hooks/useContentCidrs';
import useContentProviders from '../../core/hooks/useContentProviders';
import styles from './CreateVpcPeerButton.module.less';
import VpcService, { CreatePeerParams } from '../../services/VpcService';
import CloudProvider from '../../types/CloudProvider';
import CloudProviderRegion from '../../types/CloudProviderRegion';
import useVpcs from '../../core/hooks/useVpcs';
import Vpc from '../../types/Vpc';
import { notification } from 'antd';
import CcxIconCheckCircleTwoTone from '../ccx/icons/CcxIconCheckCircleTwoTone';
import CcxIconCloseCircleTwoTone from '../ccx/icons/CcxIconCloseCircleTwoTone';

type CreateVpcPeerButtonProps = {
    cloud?: string;
    onSuccess: Function;
    region?: string;
    buttonType?: string;
    buttonText?: string;
    hideIcon?: boolean;
    buttonClassName?: string;
    selectedVpcUuid?: string;
};

function CreateVpcPeerButton({
    cloud,
    onSuccess,
    region,
    buttonType,
    buttonText,
    hideIcon,
    buttonClassName,
    selectedVpcUuid,
}: CreateVpcPeerButtonProps): ReactElement {
    const { cloudProviders, loading: loadingProviders } = useContentProviders();
    const { cidrs, loading: loadingCidrs } = useContentCidrs();
    const {
        vpcs,
        loading: loadingVpcs,
        refresh: refreshVpcs,
    } = useVpcs(cloud, region);
    const [initialFormValues, setInitialFormValues] = useState<any>({});

    const fieldsSetup = {
        step1: [
            {
                name: ['vpcUuid'],
                testId: 'CreateVpcPeerButtonVpcUuid',
                required: false,
                label: 'Source VPC',
                type: 'select',
                placeholder: 'Select a VPC to peer to your VPC',
                options: [],
                disabled: loadingVpcs,
            },
            {
                name: ['accepterOwnerId'],
                testId: 'CreateVpcPeerButtonAccepterOwnerId',
                required: true,
                label: 'Your AWS account ID',
                placeholder: 'Enter the owner account ID',
                type: 'input',
                tips: <>The AWS owner account ID of the peering VPC.</>,
            },
            {
                name: ['accepterRegion'],
                testId: 'CreateVpcPeerButtonAccepterRegion',
                required: true,
                label: 'Your cloud region',
                placeholder: 'Enter the peering VPC region',
                type: 'input',
                tips: <>The region of the peering VPC, i.e.: eu-west-2</>,
            },
            {
                name: ['accepterVpcId'],
                testId: 'CreateVpcPeerButtonAccepterVpcId',
                required: true,
                label: 'Your VPC ID',
                placeholder: 'Enter the peering VPC ID',
                type: 'input',
                tips: <>The VPC ID of the peering VPC.</>,
            },
            {
                name: ['accepterCidr'],
                testId: 'CreateVpcPeerButtonAcceptedCidr',
                required: true,
                label: 'Your IPv4 CIDR',
                placeholder: 'Enter the peering VPC IPv4 CIDR',
                type: 'input',
                tips: <>IPv4 CIDR of the peering VPC.</>,
            },
        ],
    };

    const [fields, setFields] = useState<any>(fieldsSetup);

    useEffect(() => {
        if (fields && cloudProviders && cloudProviders.length > 0) {
            const newStep1 = fields.step1.map((f: any) => {
                if (f.name && f.name[0] === 'cloud') {
                    f.options =
                        cloudProviders
                            ?.filter((c: CloudProvider) => {
                                // only show AWS for now
                                return c.isAws();
                            })
                            .map((c: CloudProvider) => {
                                return { label: c.name, value: c.code };
                            }) || [];
                }

                if (f.name && f.name[0] === 'region') {
                    f.options =
                        cloudProviders
                            ?.filter((c: CloudProvider) => {
                                // only show AWS for now
                                return c.isAws();
                            })[0]
                            .regions.map((r: CloudProviderRegion) => {
                                return {
                                    label: `${r.code} - ${r.name}`,
                                    value: r.code,
                                };
                            }) || [];
                }
                return f;
            });
            const newFields = fields;
            newFields.step1 = newStep1;
            setFields(newFields);
            const newValues = initialFormValues;
            newValues.cloud = cloudProviders[0].code;
            newValues.region = cloudProviders[0].regions[0].code;
            setInitialFormValues(newValues);
        }
    }, [cloudProviders, fields]);

    useEffect(() => {
        if (fields && cidrs && cidrs.length > 0) {
            const newStep1 = fields.step1.map((f: any) => {
                if (f.name && f.name[0] === 'cidrIpv4Block') {
                    f.options =
                        cidrs?.map((c: string) => {
                            return { label: c, value: c };
                        }) || [];
                }

                return f;
            });
            const newFields = fields;
            newFields.step1 = newStep1;
            setFields(newFields);
        }
    }, [cidrs, fields]);

    useEffect(() => {
        if (vpcs && vpcs.length > 0) {
            const newStep1 = fields.step1.map((f: any) => {
                if (f.name && f.name[0] === 'vpcUuid') {
                    f.options =
                        vpcs?.map((item: Vpc) => {
                            return {
                                label: `${item?.getName()} - ${item?.getCidrIpv4Block()}`,
                                value: item?.id,
                            };
                        }) || [];
                }

                return f;
            });
            const newFields = fields;
            newFields.step1 = newStep1;
            setFields(newFields);
        }
    }, [vpcs]);

    useEffect(() => {
        if (selectedVpcUuid) {
            const newFormValues = initialFormValues;
            newFormValues.vpcUuid = selectedVpcUuid;
            setInitialFormValues(newFormValues);

            const newStep1 = fields.step1.map((f: any) => {
                if (f.name && f.name[0] === 'vpcUuid') {
                    f.disabled = true;
                }
                return f;
            });
            const newFields = fields;
            newFields.step1 = newStep1;
            setFields(newFields);
        }
    }, [selectedVpcUuid]);

    const doApiRequest = async ({
        accepterCidr,
        accepterOwnerId,
        accepterRegion,
        accepterVpcId,
        vpcUuid,
    }: CreatePeerParams) => {
        try {
            await VpcService.createPeer({
                accepterCidr,
                accepterOwnerId,
                accepterRegion,
                accepterVpcId,
                cloud,
                region,
                vpcUuid,
            });

            notification.open({
                message: 'Create VPC peering',
                description: `New VPC peering successfully created!`,
                icon: <CcxIconCheckCircleTwoTone twoToneColor="#52c41a" />,
            });

            return true;
        } catch (e) {
            notification.open({
                message: 'Create VPC peering',
                description: `There was an error creating your VPC peering. ${e}`,
                icon: <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />,
            });

            console.error(e);

            throw e;
        }
    };

    return (
        <ButtonModalForm
            title="Create new peering connection"
            buttonText={buttonText || 'Create new peering connection'}
            buttonIcon={hideIcon ? <></> : <CloudSyncOutlined />}
            onSubmit={doApiRequest}
            onSuccess={onSuccess}
            fields={fields.step1}
            buttonType={buttonType}
            buttonClassName={buttonClassName}
            formValues={initialFormValues}
            submitText="Create"
            formLayout="vertical"
            useGrid={true}
        >
            <span>
                <p>Create new peering connection</p>
            </span>
        </ButtonModalForm>
    );
}

export default CreateVpcPeerButton;
