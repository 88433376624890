export type PrometheusProxySqlQueryCacheMemoryStatsBackendApiProps = {
    created: number;
    query_cache_memory_bytes: number;
};

export default class PrometheusProxySqlQueryCacheMemoryStats {
    readonly created: number;
    readonly queryCacheMemoryBytes: number;

    constructor(props: PrometheusProxySqlQueryCacheMemoryStatsBackendApiProps) {
        this.created = props.created;
        this.queryCacheMemoryBytes = props.query_cache_memory_bytes;
    }
}
