export type PrometheusHaProxyTotalInOutStatsBackendApiProps = {
    created: number;
    haproxy_frontend_bytes_in_total: number;
    haproxy_frontend_bytes_out_total: number;
    haproxy_backend_bytes_in_total: number;
    haproxy_backend_bytes_out_total: number;
};

export default class PrometheusHaProxyTotalInOutStats {
    readonly created: number;
    readonly haproxyFrontendBytesInTotal: number;
    readonly haproxyFrontendBytesOutTotal: number;
    readonly haproxyBackendBytesInTotal: number;
    readonly haproxyBackendBytesOutTotal: number;

    constructor(props: PrometheusHaProxyTotalInOutStatsBackendApiProps) {
        this.created = props.created;
        this.haproxyFrontendBytesInTotal =
            props.haproxy_frontend_bytes_in_total;
        this.haproxyFrontendBytesOutTotal =
            props.haproxy_frontend_bytes_out_total;
        this.haproxyBackendBytesInTotal = props.haproxy_backend_bytes_in_total;
        this.haproxyBackendBytesOutTotal =
            props.haproxy_backend_bytes_out_total;
    }
}
