import FirewallRulePort from './FirewallRulePort';
import FirewallRulePortPropClassProps from './FirewallRulePort';

export type FirewallRuleClassProps = {
    description: string;
    source: string;
    ports: FirewallRulePortPropClassProps[];
};

export interface FirewallRuleInterface {
    description: string;
    source: string;
    ports: FirewallRulePortPropClassProps[];
}

export default class FirewallRule implements FirewallRuleInterface {
    readonly description: string;
    readonly ports: FirewallRulePort[];
    readonly source: string;

    constructor(props: FirewallRuleClassProps) {
        this.description = props.description;
        this.ports = props.ports.map((p: any) => new FirewallRulePort(p));
        this.source = props.source;
    }
}
