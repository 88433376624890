import React, { ReactElement } from 'react';
import styles from './LazyLoader.module.less';
import { Spin, Skeleton } from 'antd';
import CcxComponentProps from '../core/CcxComponent';

interface Props extends CcxComponentProps {
    active?: boolean;
    avatarShape?: string;
    buttonShape?: string;
    rows?: number;
    size?: string;
    type?: string;
}

/**
 * Skeleton for React's lazy loader
 */
function LazyLoader({
    active = true,
    avatarShape = 'circle',
    buttonShape = 'default',
    rows = 4,
    size = 'default',
    type = 'spinner',
    testId = 'LazyLoader',
}: Props): ReactElement {
    switch (type) {
        case 'row':
            return (
                <div data-testid={testId}>
                    <Skeleton
                        active={active}
                        paragraph={{ rows }}
                        data-testid={testId}
                        className={styles.LazyLoaderMargin}
                        {...{ size }}
                    />
                </div>
            );
        case 'avatar':
            return (
                <div data-testid={testId}>
                    <Skeleton.Avatar
                        active={active}
                        size={size as any}
                        shape={avatarShape as any}
                        data-testid={testId}
                    />
                </div>
            );
        case 'button':
            return (
                <div data-testid={testId}>
                    <Skeleton.Button
                        active={active}
                        size={size as any}
                        shape={buttonShape as any}
                        data-testid={testId}
                    />
                </div>
            );
        case 'image':
            return (
                <div data-testid={testId}>
                    <Skeleton.Image
                        active={active}
                        data-testid={testId}
                        {...{ size }}
                    />
                </div>
            );
        case 'input':
            return (
                <div data-testid={testId}>
                    <Skeleton.Input
                        active={active}
                        size={size as any}
                        data-testid={testId}
                    />
                </div>
            );
        case 'spinner':
        default:
            return <Spin data-testid={testId} />;
    }
}

export default LazyLoader;
