import { Alert, Button, Col, Form, Input, Modal, Row, Spin } from 'antd';
import AppBorderedPanel from '../../ccx/common/AppBorderedPanel';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useState } from 'react';
import styles from './ChangePasswordModal.module.less';
import { PasswordError } from './AccountInfo';

interface ChangePasswordModalProps {
    readonly error: PasswordError;
    readonly testId?: string;
    readonly isLoading: boolean;
    readonly isVisible: boolean;
    readonly onCancel: Function;
    readonly onUpdatePassword: Function;
    readonly onChangeOldPassword: Function;
}

type PasswordValidation = {
    validate: boolean;
    label: string;
};

function ChangePasswordModal({
    error,
    onCancel,
    isLoading,
    isVisible,
    onUpdatePassword,
    onChangeOldPassword,
    testId = 'ChangePasswordModal',
}: ChangePasswordModalProps) {
    const [form] = Form.useForm();
    const [passwordValidations, setPasswordValidations] = useState<
        PasswordValidation[]
    >([]);
    const [showPasswordRules, setShowPasswordRules] = useState<boolean>(false);
    const [changeButton, setChangeButton] = useState<boolean>(true);

    const validatePasswordStrength = (password: string) => {
        const validationRules: PasswordValidation[] = [
            {
                validate: password.length >= 8,
                label: '8 Letters',
            },
            {
                validate: /[!@#$%^&*()\-_=+{};:,<.>]/.test(password),
                label: '1 special character',
            },
            {
                validate: /[A-Z]/.test(password),
                label: '1 uppercase letter',
            },
            {
                validate: /\d/.test(password),
                label: '1 number',
            },
            { validate: /[a-z]/.test(password), label: '1 lowercase letter' },
        ];

        disableChangeButton(validationRules);
        setPasswordValidations(validationRules);
    };

    const disableChangeButton = async (
        validationRules: PasswordValidation[] = passwordValidations
    ) => {
        if (validationRules?.length === 0 || !validationRules) {
            return true;
        }
        const isFailed = validationRules?.find(
            (rule: PasswordValidation) => !rule.validate
        );

        const { oldPassword, newPassword, confirmPassword } =
            await form.getFieldsValue();

        if (!isFailed && oldPassword && newPassword && confirmPassword) {
            setChangeButton(false);
            return;
        }

        setChangeButton(true);
    };

    const onSubmit = async () => {
        try {
            if (showPasswordRules) return;

            const formValid = await form.validateFields();

            onUpdatePassword(formValid);
        } catch (error) {}
    };

    return (
        <Modal
            title={<strong>{`Change password`}</strong>}
            open={isVisible}
            width={450}
            data-testid={testId}
            footer={[
                <Button key="change" disabled={changeButton} onClick={onSubmit}>
                    Change
                </Button>,
            ]}
            cancelText
            onCancel={() => onCancel()}
        >
            <Spin spinning={isLoading}>
                <Alert
                    showIcon
                    message="We recommend you to change your password every three months."
                />
                <Form
                    layout="vertical"
                    form={form}
                    className={styles.FormStyle}
                >
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item noStyle>
                                <Form.Item
                                    name="oldPassword"
                                    label="Current Password"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please enter current password',
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        autoComplete="off"
                                        data-testid={`${testId}OldPasswordInput`}
                                        placeholder="Type your current password"
                                        size="large"
                                        onChange={(e) => {
                                            form.setFieldValue(
                                                'oldPassword',
                                                e.target.value
                                            );
                                            disableChangeButton();
                                            onChangeOldPassword();
                                        }}
                                    />
                                    {error.visible && (
                                        <Col
                                            className={styles.ValidationFailure}
                                        >
                                            {error.message}
                                        </Col>
                                    )}
                                </Form.Item>
                            </Form.Item>
                            <div className={styles.FormDivider} />
                        </Col>
                        <Col span={24}>
                            <Form.Item noStyle>
                                <Form.Item
                                    name="newPassword"
                                    label="New Password"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please enter a new password',
                                        },
                                        ({ getFieldValue }: any) => ({
                                            validator(_: any, value: string) {
                                                if (
                                                    !value ||
                                                    getFieldValue(
                                                        'oldPassword'
                                                    ) !== value
                                                ) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(
                                                    new Error(
                                                        '“New password” have to be different than Old password”'
                                                    )
                                                );
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password
                                        autoComplete="off"
                                        data-testid={`${testId}NewPasswordInput`}
                                        placeholder="Type your new password"
                                        size="large"
                                        onFocus={() =>
                                            setShowPasswordRules(true)
                                        }
                                        onChange={(e) =>
                                            validatePasswordStrength(
                                                e.target.value
                                            )
                                        }
                                        onBlur={() => {
                                            const isFalse =
                                                passwordValidations?.find(
                                                    (
                                                        rule: PasswordValidation
                                                    ) => !rule.validate
                                                );
                                            if (!isFalse) {
                                                setShowPasswordRules(false);
                                            }
                                        }}
                                    />
                                </Form.Item>
                            </Form.Item>
                            {showPasswordRules &&
                                !!passwordValidations.length && (
                                    <AppBorderedPanel>
                                        <Row align={'middle'}>
                                            A valid password must contain at
                                            least
                                            {passwordValidations?.map(
                                                (
                                                    rule: PasswordValidation,
                                                    index: number
                                                ) => (
                                                    <Col
                                                        key={`${rule.label}-${index}`}
                                                        sm={12}
                                                        xs={24}
                                                        className={
                                                            rule.validate
                                                                ? styles.ValidationSuccess
                                                                : styles.ValidationFailure
                                                        }
                                                    >
                                                        <CheckCircleOutlined
                                                            className={
                                                                rule.validate
                                                                    ? styles.ValidationSuccess
                                                                    : styles.ValidationFailure
                                                            }
                                                        />{' '}
                                                        {rule.label}
                                                    </Col>
                                                )
                                            )}{' '}
                                        </Row>
                                    </AppBorderedPanel>
                                )}
                        </Col>
                        <Col span={24} style={{ marginTop: 16 }}>
                            <Form.Item noStyle>
                                <Form.Item
                                    dependencies={['newPassword']}
                                    name="confirmPassword"
                                    label="Repeat password"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please confirm your password',
                                        },
                                        ({ getFieldValue }: any) => ({
                                            validator(_: any, value: string) {
                                                if (
                                                    !value ||
                                                    getFieldValue(
                                                        'newPassword'
                                                    ) === value
                                                ) {
                                                    return Promise.resolve();
                                                } else {
                                                    return Promise.reject(
                                                        new Error(
                                                            '“New password” and “Repeat password” don’t match'
                                                        )
                                                    );
                                                }
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password
                                        autoComplete="off"
                                        data-testid={`${testId}ConfirmPasswordInput`}
                                        placeholder="Enter your password again"
                                        size="large"
                                        onChange={(e) => disableChangeButton()}
                                    />
                                </Form.Item>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    );
}

export default ChangePasswordModal;
