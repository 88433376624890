export type PrometheusNetworkUsageStatsBackendApiProps = {
    created: number;
    transmit_bytes: number;
    receive_bytes: number;
};

export default class PrometheusNetworkUsageStats {
    readonly created: number;
    readonly transmit: number;
    readonly receive: number;

    constructor(props: PrometheusNetworkUsageStatsBackendApiProps) {
        this.created = props.created;
        this.transmit = props.transmit_bytes;
        this.receive = props.receive_bytes;
    }
}
