import {
    Configuration,
    Vpc,
    VPCApi,
    VpcPeering,
    VPCPeeringApi,
} from './vpc/typescript-axios';
import { API_VPC_V2 } from '../core/CcxEnv';
import { SDK_AXIOS_DEFAULT_BASE_OPTIONS } from './config';

export const vpcApiInstance = new VPCApi(
    new Configuration({
        basePath: API_VPC_V2,
        baseOptions: SDK_AXIOS_DEFAULT_BASE_OPTIONS,
    })
);
export const vpcPeeringApiInstance = new VPCPeeringApi(
    new Configuration({
        basePath: API_VPC_V2,
        baseOptions: SDK_AXIOS_DEFAULT_BASE_OPTIONS,
    })
);

export interface VpcDTO extends Vpc {}

export interface VpcPeeringDTO extends VpcPeering {}
