import { Modal, Row } from 'antd';
import { Button } from 'antd';
import { Alert } from 'antd';
import { Col } from 'antd';
import CcxComponentProps from '../../core/CcxComponent';
import Credentials from '../../types/Credentials';
import AppCodeCard from '../AppCodeCard';
import styles from './AuthorizationModal.module.less';

interface Props extends CcxComponentProps {
    credentials?: Credentials;
    show: boolean;
    setShow: Function;
}

function AuthorizationModal({
    credentials,
    testId = 'AuthorizationModal',
    show = false,
    setShow,
}: Props) {
    const onClose = () => {
        setShow(false);
    };

    return (
        <Modal
            title={'Create credentials'}
            open={show}
            data-testid={`${testId}Modal`}
            width={600}
            onCancel={onClose}
            footer={
                <Row justify="end" gutter={[16, 16]}>
                    <Col span={24}>
                        <Alert
                            message={
                                <strong>
                                    {' '}
                                    Copy and save the client secret before
                                    closing
                                </strong>
                            }
                            description={
                                <span>
                                    Make sure you've copied and saved the client
                                    secret before you close this dialog. The
                                    client secret value can not be obtained
                                    later.
                                </span>
                            }
                            style={{ textAlign: 'start' }}
                            type="warning"
                            showIcon
                        />
                    </Col>
                    <Col span={true as any}>
                        <Button
                            data-testid={`${testId}CloseButton`}
                            onClick={onClose}
                        >
                            Close
                        </Button>
                    </Col>
                </Row>
            }
        >
            {credentials && (
                <>
                    <div data-testid={`${testId}ClientID`}>
                        <strong data-testid={`${testId}ClientIDTitle`}>
                            Client ID
                        </strong>
                        <AppCodeCard
                            children={credentials.clientId}
                            testId={`${testId}ClientIDCodeCard`}
                        />
                    </div>
                    <div
                        className={styles.AuthorizationModalSecret}
                        data-testid={`${testId}ClientSecret`}
                    >
                        <strong data-testid={`${testId}ClientSecretTitle`}>
                            Client secret
                        </strong>
                        <AppCodeCard
                            children={credentials.secret}
                            testId={`${testId}ClientSecretCodeCard`}
                        />
                    </div>
                </>
            )}
        </Modal>
    );
}

export default AuthorizationModal;
