export type PrometheusProxySqlLatencyHostgroupDistributionStatsBackendApiProps =
    {
        created: number;
        latency_us: number;
    };

export default class PrometheusProxySqlLatencyHostgroupDistributionStats {
    readonly created: number;
    readonly latencyUs: number;

    constructor(
        props: PrometheusProxySqlLatencyHostgroupDistributionStatsBackendApiProps
    ) {
        this.created = props.created;
        this.latencyUs = props.latency_us;
    }
}
