import { Tabs } from 'antd';
import React, { ReactElement, Suspense } from 'react';
import CcxComponentProps from '../../core/CcxComponent';
import LazyLoader from '../LazyLoader';
import styles from './Account.module.less';
import PaymentsInvoices from './paymentsInvoices/PaymentsInvoices';
import AccountInfo from './accountInfo/AccountInfo';
import { billingDisabled } from '../../core/CcxEnv';
import { Authorization } from './authorization/Authorization';

interface Props extends CcxComponentProps {}

function Account({ testId = 'Account' }: Props): ReactElement {
    return (
        <section className={styles.Account} data-testid={testId}>
            <h1 data-testid={`${testId}Title`}>
                <strong>Account</strong>
            </h1>

            <Tabs
                className={styles.AccountTabs}
                data-testid={`${testId}Tabs`}
                type="card"
                destroyInactiveTabPane={true}
            >
                <Tabs.TabPane
                    data-testid={`${testId}TabsAccountInfo`}
                    tab="Account information"
                    key="account-info"
                >
                    <Suspense fallback={<LazyLoader />}>
                        <AccountInfo />
                    </Suspense>
                </Tabs.TabPane>
                {!billingDisabled && (
                    <Tabs.TabPane
                        data-testid={`${testId}TabsPaymentsInvoices`}
                        tab="Payments & invoices"
                        key="payments"
                    >
                        <Suspense fallback={<LazyLoader type="row" />}>
                            <PaymentsInvoices />
                        </Suspense>
                    </Tabs.TabPane>
                )}
                <Tabs.TabPane
                    data-testid={`${testId}TabsAuthorizationInfo`}
                    tab="Authorization"
                    key="authorization"
                >
                    <Suspense fallback={<LazyLoader />}>
                        <Authorization />
                    </Suspense>
                </Tabs.TabPane>
            </Tabs>
        </section>
    );
}

export default Account;
