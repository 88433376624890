export type CredentialsClassProps = {
    user_id: string;
    client_id: string;
    description: string;
    roles: string;
    created_at: string;
    revoked_at: string;
    last_used_at: string;
    expires_at: string;
    secret: string;
    last_used_from: string;
    status: string;
};

export interface CredentialsInterface {
    userId: string;
    clientId: string;
    description: string;
    roles: string;
    createdAt: string;
    revokedAt: string;
    lastUsedAt: string;
    expiresAt: string;
    secret: string;
    isActive: Function;
    isExpired: Function;
    isDisabled: Function;
    lastUsedFrom: string;
    status: string;
}

export default class Credentials implements CredentialsInterface {
    readonly userId: string;
    readonly clientId: string;
    readonly description: string;
    readonly roles: string;
    readonly createdAt: string;
    readonly revokedAt: string;
    readonly lastUsedAt: string;
    readonly expiresAt: string;
    readonly secret: string;
    readonly lastUsedFrom: string;
    readonly status: string;

    constructor(props: CredentialsClassProps) {
        this.userId = props.user_id;
        this.clientId = props.client_id;
        this.description = props.description || '-';
        this.roles = props.roles;
        this.createdAt = props.created_at;
        this.revokedAt = props.revoked_at;
        this.lastUsedAt = props.last_used_at;
        this.expiresAt = props.expires_at;
        this.secret = props.secret;
        this.lastUsedFrom = props.last_used_from;
        this.status = props.status;
    }

    isActive(): boolean {
        return this.status === 'active' && !this.isExpired();
    }

    isExpired(): boolean {
        if (this.expiresAt) {
            const now = new Date();
            const expiry = new Date(this.expiresAt);

            return !(expiry > now);
        }
        return false;
    }

    isDisabled(): boolean {
        return this.status === 'disabled';
    }

    isRevoked(): boolean {
        return !!this.revokedAt;
    }

    canBeDisabled(): boolean {
        return this.isActive() && !this.isRevoked();
    }

    canBeEnabled(): boolean {
        return this.isDisabled() && !this.isExpired();
    }

    canBeRevoked(): boolean {
        return (this.isActive() || this.canBeEnabled()) && !this.isRevoked();
    }

    canBeRemoved(): boolean {
        return this.isRevoked() || this.isExpired();
    }

    getStatusText(): string {
        if (this.isRevoked()) return 'Revoked';
        if (this.isDisabled()) return 'Disabled';
        if (this.isExpired()) return 'Inactive';
        if (this.isActive()) return 'Active';
        return '';
    }
}
