export type PrometheusProxySqlQueriesHostgroupDistributionStatsBackendApiProps =
    {
        created: number;
        queries: number;
    };

export default class PrometheusProxySqlQueriesHostgroupDistributionStats {
    readonly created: number;
    readonly queries: number;

    constructor(
        props: PrometheusProxySqlQueriesHostgroupDistributionStatsBackendApiProps
    ) {
        this.created = props.created;
        this.queries = props.queries;
    }
}
