export type PrometheusHaProxyBackendMaxQueuedRequestsStatsBackendApiProps = {
    created: number;
    haproxy_backend_max_queue: number;
};

export default class PrometheusHaProxyBackendMaxQueuedRequestsStats {
    readonly created: number;
    readonly haproxyBackendMaxQueue: number;

    constructor(
        props: PrometheusHaProxyBackendMaxQueuedRequestsStatsBackendApiProps
    ) {
        this.created = props.created;
        this.haproxyBackendMaxQueue = props.haproxy_backend_max_queue;
    }
}
