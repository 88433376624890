import React, { ReactElement } from 'react';
import AppLogoBrand from './AppLogoBrand';

interface Props {
    height?: number;
    width?: number;
    useExtended?: boolean;
}

function AppLogoBrandMardiaDb({
    height = 28,
    width = 89,
    useExtended = true,
}: Props): ReactElement {
    return (
        <AppLogoBrand
            height={height}
            logo={
                useExtended
                    ? 'vendor-mariadb-brand-logo'
                    : 'vendor-mariadb-brand-logo-short'
            }
            size=""
            testId="AppLogoBrandMardiaDb"
            width={width}
        />
    );
}

export default AppLogoBrandMardiaDb;
