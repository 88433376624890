import React, { ReactElement } from 'react';
import styles from './LbDashboard.module.less';
import CloudInstance from '../../../types/CloudInstance';
import ProxySqlLbDashboard from './ProxySqlLbDashboard';
import AppEmpty from '../../AppEmpty';
import HaProxyLbDashboard from './HaProxyLbDashboard';

type LbDashboardProps = {
    uuid: string;
    host_uuid: string;
    port: number;
    from?: string;
    to?: string;
    interval: number;
    node?: CloudInstance;
    hidden?: boolean;
    displaySummary?: boolean;
    onLoading?: Function;
};

function LbDashboard({
    uuid,
    host_uuid,
    port,
    from,
    to,
    interval,
    node,
    hidden,
    displaySummary,
    onLoading,
}: LbDashboardProps): ReactElement {
    return (
        <section className={styles.LbDashboard}>
            {node?.isProxySql() ? (
                <ProxySqlLbDashboard
                    uuid={uuid}
                    host_uuid={host_uuid}
                    port={port}
                    from={from}
                    to={to}
                    interval={interval}
                    hidden={hidden}
                    displaySummary={displaySummary}
                    onLoading={onLoading}
                />
            ) : node?.isHaProxy() ? (
                <HaProxyLbDashboard
                    uuid={uuid}
                    host_uuid={host_uuid}
                    port={port}
                    from={from}
                    to={to}
                    interval={interval}
                    hidden={hidden}
                    displaySummary={displaySummary}
                    onLoading={onLoading}
                />
            ) : (
                <AppEmpty message="Load Balancer not supported yet." />
            )}
        </section>
    );
}

export default LbDashboard;
