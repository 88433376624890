import React from 'react';
import Icon from '@ant-design/icons';
import styles from './AppIcon.module.less';
import CcxComponentProps from '../core/CcxComponent';

export default AppIcon;

export const ICON_SIZE_XSMALL = 'xsmall';
export const ICON_SIZE_SMALL = 'small';
export const ICON_SIZE_MEDIUM = 'medium';
export const ICON_SIZE_LARGE = 'large';
export const ICON_SIZE_XLARGE = 'xlarge';
export const ICON_SIZE_2XLARGE = '2xlarge';

export const CDN_URL = 'https://st.s9s.io/nnj';

export interface AppIconProps extends CcxComponentProps {
    icon: string;
    size?: string;
    sizePx?: number;
}

function AppIcon({
    icon,
    size = ICON_SIZE_SMALL,
    sizePx,
    testId = 'AppIcon',
}: AppIconProps) {
    let sizeStr = sizePx ? `${sizePx}px` : '';

    if (!sizeStr) {
        switch (size) {
            case ICON_SIZE_XSMALL:
                sizeStr = '16px';
                break;
            case ICON_SIZE_MEDIUM:
                sizeStr = '24px';
                break;
            case ICON_SIZE_LARGE:
                sizeStr = '32px';
                break;
            case ICON_SIZE_XLARGE:
                sizeStr = '64px';
                break;
            case ICON_SIZE_2XLARGE:
                sizeStr = '128px';
                break;
            case ICON_SIZE_SMALL:
            default:
                sizeStr = '18px';
                break;
        }
    }

    return (
        <Icon
            className={styles.AppIcon}
            data-testid={testId}
            component={() =>
                typeof icon === 'string' ? (
                    <img
                        src={`${CDN_URL}/icon/${icon}.svg`}
                        alt={icon}
                        width={sizeStr}
                        height={sizeStr}
                    />
                ) : (
                    React.cloneElement(icon, {
                        width: sizeStr,
                        height: sizeStr,
                    })
                )
            }
        />
    );
}
