import React, { ReactElement } from 'react';
import Error500 from './Error500';
import { ErrorBoundary } from 'react-error-boundary';
import NotFoundError404 from './NotFoundError404';
import Error404 from './Error404';
import { useHistory } from 'react-router-dom';
import AppProviders from '../../AppProviders';
import AppLayoutTemplate from '../layout/AppLayoutTemplate';
import AppContentTemplate from '../layout/AppContentTemplate';

type Props = {
    children: ReactElement;
};

function AppErrorBoundary({ children }: Props) {
    const onErrorHandler = (error: Error, info: { componentStack: string }) => {
        if (!(error instanceof NotFoundError404)) {
            console.error(
                'ErrorBoundary caught an error:',
                error,
                info.componentStack
            );
        }
    };

    return (
        <ErrorBoundary
            FallbackComponent={AppErrorFallback}
            onError={onErrorHandler}
            onReset={() => {
                // reset the state of your app so the error doesn't happen again
            }}
        >
            {children}
        </ErrorBoundary>
    );
}

type AppErrorFallbackProps = {
    error: Error;
    resetErrorBoundary: Function;
};

function AppErrorFallback({
    error,
    resetErrorBoundary,
}: AppErrorFallbackProps) {
    const isLocalhost = Boolean(
        window.location.hostname === 'localhost' ||
            // [::1] is the IPv6 localhost address.
            window.location.hostname === '[::1]' ||
            // 127.0.0.0/8 are considered localhost for IPv4.
            window.location.hostname.match(
                /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
            )
    );

    const history = useHistory();

    const handleHomeClick = () => {
        history.push({
            pathname: '/',
        });
        resetErrorBoundary?.();
    };

    const redirectToHomePage = () => {
        handleHomeClick();
        return null; // Return null to prevent rendering any content for the error
    };

    if (!(error instanceof NotFoundError404) && !isLocalhost) {
        return redirectToHomePage();
    }

    if (error instanceof NotFoundError404) {
        return (
            <AppProviders>
                <AppLayoutTemplate>
                    <AppContentTemplate>
                        <Error404 onHomeClick={handleHomeClick} />
                    </AppContentTemplate>
                </AppLayoutTemplate>
            </AppProviders>
        );
    } else {
        return (
            <AppLayoutTemplate showHeader={false}>
                <AppContentTemplate>
                    <Error500 error={error} onHomeClick={handleHomeClick} />
                </AppContentTemplate>
            </AppLayoutTemplate>
        );
    }
}

export default AppErrorBoundary;
