import { API_AUTH } from '../core/CcxEnv';
import User from '../types/User';
import AuthService from './AuthService';

const AUTH_SERVICE_URL = API_AUTH;

export default class UsersService extends AuthService {
    protected static addr(): string {
        return AUTH_SERVICE_URL || '';
    }

    public static async check(): Promise<User> {
        return new User(await this.request('check'));
    }

    public static async logout() {
        await this.request('logout');
    }
}
