export type PrometheusRedisCommandsPerSecStatsBackendApiProps = {
    created: number;
    commands_per_sec: number;
};

export default class PrometheusRedisCommandsPerSecStats {
    readonly created: number;
    readonly commandsPerSec: number;

    constructor(props: PrometheusRedisCommandsPerSecStatsBackendApiProps) {
        this.created = props.created;
        this.commandsPerSec = props.commands_per_sec;
    }
}
