import React, { ReactElement, useState } from 'react';
import styles from './CopyToClipboardText.module.less';
import { CopyFilled, CopyOutlined } from '@ant-design/icons';
import { notification, Tooltip } from 'antd';
import Hoverable from './Hoverable';
import classNames from 'classnames';
import AppButton from './ccx/common/AppButton';
import CcxIconCheckCircleTwoTone from './ccx/icons/CcxIconCheckCircleTwoTone';

export type CopyToClipboardTextProps = {
    text?: string;
    preview?: string;
    hoverable?: boolean;
    tooltip?: string;
    nowrap?: boolean;
    buttonProps?: any;
    successMessage?: string;
    showPreview?: boolean;
};

function CopyToClipboardText({
    text,
    preview,
    hoverable = true,
    tooltip = 'Click to copy to clipboard.',
    nowrap,
    buttonProps = {},
    successMessage,
    showPreview = true,
}: CopyToClipboardTextProps): ReactElement {
    const [isHovered, setIsHovered] = useState<boolean>(false);

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    const handleClick = () => {
        copyMessage(text);
        notification.open({
            message: 'Clipboard Updated',
            description: successMessage || 'Copied to clipboard.',
            icon: <CcxIconCheckCircleTwoTone twoToneColor="#52c41a" />,
        });
    };

    const copyMessage = (val: string | undefined) => {
        if (!val) {
            return;
        }
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    };

    return hoverable ? (
        <div
            data-testid="hoverable-trigger"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <Hoverable icon={isHovered ? <CopyFilled /> : <CopyOutlined />}>
                <Tooltip
                    placement="bottom"
                    title={tooltip}
                    className={classNames(styles.CopyToClipboardText, {
                        [styles.CopyToClipboardTextNowrap]: nowrap,
                    })}
                    {...{ onClick: handleClick }}
                >
                    <span>{preview ? preview : text}</span>
                </Tooltip>
            </Hoverable>
        </div>
    ) : (
        <div
            data-testid="hoverable-trigger"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <Tooltip
                placement="bottom"
                data-testid="hoverable-trigger"
                title={tooltip}
                className={classNames(styles.CopyToClipboardText, {
                    [styles.CopyToClipboardTextNowrap]: nowrap,
                })}
                {...{ onClick: handleClick }}
            >
                <span>
                    {showPreview ? (preview ? preview : text) : ''}{' '}
                    <AppButton
                        type="link"
                        icon={isHovered ? <CopyFilled /> : <CopyOutlined />}
                        {...buttonProps}
                    />
                </span>
            </Tooltip>
        </div>
    );
}

export default CopyToClipboardText;
