import React from 'react';
import { Button, Space } from 'antd';
import classNames from 'classnames';
import styles from './AppButton.module.less';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

export default AppButton;
export type AppButtonProps = any & {
    textSize?: SizeType;
    testId?: string;
};

function AppButton({
    textSize = 'middle',
    children,
    icon,
    testId = 'AppButton',
    className,
    ...rest
}: AppButtonProps) {
    return (
        <Button
            className={classNames(styles.AppButton, className)}
            data-testid={testId}
            {...rest}
        >
            {children ? (
                <Space>
                    {icon}
                    <span
                        className={classNames(styles.AppButton, {
                            [styles[`AppButton_text_${textSize}`]]: true,
                        })}
                    >
                        {children}
                    </span>
                </Space>
            ) : (
                icon
            )}
        </Button>
    );
}
