export type CloudProviderGroupClassProps = {
    label: string;
    logo: string;
    name: string;
};

export interface CloudProviderGroupInterface {
    label: string;
    logo: string;
    name: string;
}

export default class CloudProviderGroup implements CloudProviderGroupInterface {
    readonly label: string;
    readonly logo: string;
    readonly name: string;

    constructor(props: CloudProviderGroupClassProps) {
        this.label = props.label;
        this.logo = props.logo;
        this.name = props.name;
    }

    getCloudProviderGroupLogo(): any {
        return this.logo;
    }

    getCloudProviderGroupName(): any {
        return this.name;
    }

    getCloudProviderGroupLabel(): any {
        return this.label;
    }
}
