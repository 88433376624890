import { Checkbox, Select } from 'antd';
import styles from './DatastoreFilterPopover.module.less';

export interface OptionsInterface {
    label: string;
    value: string;
}

interface DatastoreFilterPopoverProps {
    options: {
        datastoreTypes: OptionsInterface[];
        tagsList: OptionsInterface[];
    };
    onChangeTagsFilter: Function;
    onChangeDbTypesFilter: Function;
}

function DatastoreFilterPopover({
    options,
    onChangeTagsFilter,
    onChangeDbTypesFilter,
}: DatastoreFilterPopoverProps) {
    return (
        <div className={styles.FilterPopoverContent}>
            <h4>
                <strong>Database type</strong>
            </h4>

            <Checkbox.Group
                className={styles.CheckboxStyle}
                options={options.datastoreTypes}
                onChange={(value) => onChangeDbTypesFilter(value)}
            />
            <h4 className={styles.TagsHeadingStyle}>
                <strong>Tags</strong>
            </h4>
            <Select
                mode="tags"
                className={styles.FilterInput}
                placeholder="Enter Tags"
                tokenSeparators={[',']}
                maxTagCount={2}
                onChange={(value) => onChangeTagsFilter(value)}
                options={options.tagsList}
            />
        </div>
    );
}

export default DatastoreFilterPopover;
