import React, { ReactElement, useRef } from 'react';
import styles from './AppChartHistogram.module.less';
import { Histogram } from '@ant-design/charts';

export type AppChartHistogramProps = {
    config: any;
    data: any[];
};

function AppChartHistogram({
    config,
    data,
}: AppChartHistogramProps): ReactElement {
    const ref = useRef<any>();

    return (
        <div className={styles.AppChartHistogram}>
            <Histogram {...config} chartRef={ref} data={data} />
        </div>
    );
}

export default AppChartHistogram;
