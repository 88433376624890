export type PrometheusMssqlHandlerTransactionsStatsBackendApiProps = {
    created: number;
    background: number;
    dormant: number;
    runnable: number;
    sleeping: number;
    done: number;
    running: number;
};

export default class PrometheusMssqlHandlerTransactionsStats {
    readonly created: number;
    readonly background: number;
    readonly dormant: number;
    readonly runnable: number;
    readonly sleeping: number;
    readonly done: number;
    readonly running: number;

    constructor(props: PrometheusMssqlHandlerTransactionsStatsBackendApiProps) {
        this.created = props.created;
        this.background = props.background;
        this.dormant = props.dormant;
        this.runnable = props.runnable;
        this.sleeping = props.sleeping;
        this.done = props.done;
        this.running = props.running;
    }
}
