export type CmonDataStoreStatus = {
    cluster_status: string;
    cluster_status_text: string;
};

export interface DataStoreStatusInterface {
    cmonStatus: string;
    status: string;
    statusText: string;
}

export const DATA_STORE_STATUS_DEGRADED = 'DEGRADED';
export const DATA_STORE_STATUS_UNREACHABLE = 'UNREACHABLE';
export const DATA_STORE_STATUS_MGMD_NO_CONTACT = 'MGMD_NO_CONTACT';
export const DATA_STORE_STATUS_UNKNOWN = 'UNKNOWN';
export const DATA_STORE_STATUS_NOT_STARTED = 'NOT_STARTED';
export const DATA_STORE_STATUS_MAINTENANCE = 'MAINTENANCE';
export const DATA_STORE_STATUS_STARTED = 'STARTED';
export const DATA_STORE_STATUS_CREATED = 'CREATED';
export const DATA_STORE_STATUS_DEPLOY_FAILED = 'DEPLOY_FAILED';
export const DATA_STORE_STATUS_FAILURE = 'FAILURE';
export const DATA_STORE_STATUS_SETUP_FAILED = 'SETUP_FAILED';
export const DATA_STORE_STATUS_PENDING = 'PENDING';
export const DATA_STORE_STATUS_DELETING = 'DELETING';
export const DATA_STORE_STATUS_DELETED = 'DELETED';
export const DATA_STORE_STATUS_SHUTTING_DOWN = 'SHUTTING_DOWN';
export const DATA_STORE_STATUS_RECOVERING = 'RECOVERING';
export const DATA_STORE_STATUS_STOPPED = 'STOPPED';
export const DATA_STORE_STATUS_STARTING = 'STARTING';
export const DATA_STORE_STATUS_CREATING_CLUSTER_INFRA =
    'CREATING_CLUSTER_INFRA';
export const DATA_STORE_STATUS_CREATING_CLUSTER = 'CREATING_CLUSTER';
export const DATA_STORE_STATUS_CREATING_CLUSTER_LB = 'CREATING_CLUSTER_LB';
export const DATA_STORE_STATUS_CREATING_CLUSTER_FINAL =
    'CREATING_CLUSTER_FINAL';
export const DATA_STORE_STATUS_RESTORING_BACKUP = 'RESTORING_BACKUP';

export default class DataStoreStatus implements DataStoreStatusInterface {
    readonly cmonStatus: string;
    readonly status: string;
    readonly statusText: string;

    constructor(props: CmonDataStoreStatus) {
        this.cmonStatus = props.cluster_status;
        this.status = this.getStatus(props.cluster_status);
        this.statusText = props.cluster_status_text;
    }

    isStatusOk(): boolean {
        return (
            this.cmonStatus === DATA_STORE_STATUS_STARTED ||
            this.cmonStatus === DATA_STORE_STATUS_CREATED
        );
    }

    isStatusWarning(): boolean {
        return (
            this.cmonStatus === DATA_STORE_STATUS_DEGRADED ||
            this.cmonStatus === DATA_STORE_STATUS_UNREACHABLE ||
            this.cmonStatus === DATA_STORE_STATUS_MGMD_NO_CONTACT ||
            this.cmonStatus === DATA_STORE_STATUS_UNKNOWN ||
            this.cmonStatus === DATA_STORE_STATUS_NOT_STARTED ||
            this.cmonStatus === DATA_STORE_STATUS_MAINTENANCE
        );
    }

    isStatusError(): boolean {
        return (
            this.cmonStatus === DATA_STORE_STATUS_DEPLOY_FAILED ||
            this.cmonStatus === DATA_STORE_STATUS_FAILURE ||
            this.cmonStatus === DATA_STORE_STATUS_SETUP_FAILED
        );
    }

    isDeploying(): boolean {
        return (
            this.cmonStatus === DATA_STORE_STATUS_CREATING_CLUSTER ||
            this.cmonStatus === DATA_STORE_STATUS_PENDING ||
            this.cmonStatus === DATA_STORE_STATUS_CREATING_CLUSTER_INFRA ||
            this.cmonStatus === DATA_STORE_STATUS_CREATING_CLUSTER_LB ||
            this.cmonStatus === DATA_STORE_STATUS_CREATING_CLUSTER_FINAL ||
            this.cmonStatus === DATA_STORE_STATUS_RESTORING_BACKUP
        );
    }

    isDeleting(): boolean {
        return this.cmonStatus === DATA_STORE_STATUS_DELETING;
    }

    isUnreachable(): boolean {
        return this.cmonStatus === DATA_STORE_STATUS_UNREACHABLE;
    }

    isUnderMaintenance(): boolean {
        return this.cmonStatus === DATA_STORE_STATUS_MAINTENANCE;
    }

    isStatusStartedOrDegraded(): boolean {
        return (
            this.cmonStatus === DATA_STORE_STATUS_STARTED ||
            this.cmonStatus === DATA_STORE_STATUS_DEGRADED
        );
    }

    private getStatus(status: string) {
        switch (status) {
            // CCX Statuses:
            case DATA_STORE_STATUS_CREATED:
                return 'Created';
            case DATA_STORE_STATUS_CREATING_CLUSTER:
                return 'Creating';
            case DATA_STORE_STATUS_DELETED:
                return 'Deleted';
            case DATA_STORE_STATUS_DELETING:
                return 'Deleting';
            case DATA_STORE_STATUS_DEPLOY_FAILED:
                return 'Failed';
            case DATA_STORE_STATUS_SETUP_FAILED:
                return 'Failed';
            case DATA_STORE_STATUS_MAINTENANCE:
                return 'Maintenance';
            case DATA_STORE_STATUS_UNREACHABLE:
                return 'Unreachable';
            case DATA_STORE_STATUS_PENDING:
                return 'Pending';
            case DATA_STORE_STATUS_CREATING_CLUSTER_INFRA:
                return 'Creating cluster infrastructure';
            case DATA_STORE_STATUS_CREATING_CLUSTER_LB:
                return 'Deploying load balancers';
            case DATA_STORE_STATUS_CREATING_CLUSTER_FINAL:
                return 'Finalizing deployment';
            case DATA_STORE_STATUS_RESTORING_BACKUP:
                return 'Restoring backup';

            // CMON Statuses:
            case DATA_STORE_STATUS_MGMD_NO_CONTACT:
                return 'Warning';
            case DATA_STORE_STATUS_STARTED:
                return 'Started';
            case DATA_STORE_STATUS_NOT_STARTED:
                return 'Not running';
            case DATA_STORE_STATUS_DEGRADED:
                return 'Warning';
            case DATA_STORE_STATUS_FAILURE:
                return 'Failure';
            case DATA_STORE_STATUS_SHUTTING_DOWN:
                return 'Shutting down';
            case DATA_STORE_STATUS_RECOVERING:
                return 'Recovering';
            case DATA_STORE_STATUS_STOPPED:
                return 'Stopped';
            case DATA_STORE_STATUS_STARTING:
                return 'Starting';
            case DATA_STORE_STATUS_UNKNOWN:
            default:
                return 'Unknown';
        }
    }
}
