import { useEffect, useState } from 'react';
import BillingService from '../../services/BillingService';
import Invoice from '../../types/Invoice';

export default function useInvoices() {
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [invoices, setInvoices] = useState<Invoice[] | undefined>(undefined);

    const load = async () => {
        try {
            const invoices = await BillingService.getInvoices();
            setInvoices(invoices);
            setError(null);
        } catch (e: any) {
            setError(e);
        }
    };

    const refresh = () => {
        setUpdating(true);
        load();
        setUpdating(false);
    };

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            await load();
            setLoading(false);
        };

        init();
    }, []);

    return { invoices, refresh, loading, updating, error };
}
