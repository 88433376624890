import { useEffect, useState } from 'react';
import PrometheusStatService from '../../services/PrometheusStatService';
import PrometheusMssqlHandlerTransactionsStats from '../../types/PrometheusMssqlHandlerTransactionsStats';

export default function usePrometheusMssqlHandlerTransactionsStats(
    uuid: string,
    host_uuid?: string,
    from?: string,
    to?: string
) {
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [stats, setStats] = useState<
        PrometheusMssqlHandlerTransactionsStats[] | undefined
    >(undefined);

    const load = async () => {
        if (uuid && host_uuid) {
            try {
                const details = await PrometheusStatService.getMssqlProcesses(
                    uuid,
                    host_uuid,
                    from,
                    to
                );
                setStats(
                    details.map(
                        (d: any) =>
                            new PrometheusMssqlHandlerTransactionsStats(d)
                    )
                );
                setError(null);
            } catch (e: any) {
                setError(e);
            }
        }
    };

    const refresh = () => {
        setUpdating(true);
        load();
        setUpdating(false);
    };

    // get deployments for the given user
    useEffect(() => {
        const init = async () => {
            setLoading(true);
            await load();
            setLoading(false);
        };

        init();
    }, [uuid, host_uuid, from, to]);

    return { stats, refresh, loading, updating, error };
}
