import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ServiceService from '../services/ServiceService';
import Job from '../types/Job';

interface Jobs {
    [key: string]: Job[];
}
interface InitiallyLoaded {
    [key: string]: boolean;
}

interface JobSliceType {
    jobs: Jobs;
    loading: boolean;
    initiallyLoaded: InitiallyLoaded;
    error: string | null;
    intervals: any;
}

const initialState = {
    jobs: {},
    loading: false,
    initiallyLoaded: {},
    error: null,
    intervals: {},
} as JobSliceType;

export const fetchJobs = createAsyncThunk(
    'jobs/fetchJobs',
    async (
        {
            dataStoreUuid,
            limit,
            offset,
        }: {
            dataStoreUuid: string;
            limit: number;
            offset: number;
        },
        { rejectWithValue }
    ) => {
        try {
            const response = await ServiceService.getAllJobs(
                dataStoreUuid,
                limit,
                offset
            );

            return {
                datastoreUuid: dataStoreUuid,
                jobs: response.jobs,
            };
        } catch (error) {
            if (error instanceof Error) return rejectWithValue(error.message);

            return rejectWithValue('Error fetching jobs');
        }
    }
);

export const refreshJobs = createAsyncThunk(
    'jobs/refreshJobs',
    async (
        {
            dataStoreUuid,
            limit,
            offset,
        }: {
            dataStoreUuid: string;
            limit: number;
            offset: number;
        },
        { rejectWithValue, dispatch }
    ) => {
        try {
            const response = await ServiceService.getAllJobs(
                dataStoreUuid,
                limit,
                offset
            );
            return {
                datastoreUuid: dataStoreUuid,
                jobs: response.jobs,
            };
        } catch (error) {
            dispatch(clearIntervals({ dataStoreUuid }));

            if (error instanceof Error) return rejectWithValue(error.message);

            return rejectWithValue('Error fetching jobs');
        }
    }
);

const jobsSlice = createSlice({
    name: 'jobs',
    initialState,
    reducers: {
        setIntervals: (state, action) => {
            state.intervals[action.payload.dataStoreUuid] =
                action.payload.intervalId;
        },
        clearIntervals: (state, action) => {
            clearInterval(state.intervals[action.payload.dataStoreUuid]);
            delete state.intervals[action.payload.dataStoreUuid];
            delete state.jobs[action.payload.dataStoreUuid];
            state.initiallyLoaded = {};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchJobs.pending, (state, action) => {
                state.initiallyLoaded[action.meta.arg.dataStoreUuid] = true;
                state.loading = true;
            })
            .addCase(fetchJobs.fulfilled, (state, action) => {
                state.jobs[action.payload.datastoreUuid] = action.payload.jobs;
                state.error = null;
                state.initiallyLoaded[action.payload.datastoreUuid] = true;
                state.loading = false;
            })
            .addCase(fetchJobs.rejected, (state, action) => {
                state.initiallyLoaded[action.meta.arg.dataStoreUuid] = true;
                state.error = action.payload as string;
                state.loading = false;
            });

        builder
            .addCase(refreshJobs.fulfilled, (state, action) => {
                state.jobs[action.payload.datastoreUuid] = action.payload.jobs;
                state.initiallyLoaded[action.payload.datastoreUuid] = true;
            })
            .addCase(refreshJobs.rejected, (state, action) => {
                state.initiallyLoaded[action.meta.arg.dataStoreUuid] = true;
                state.error = action.payload as string;
            });
    },
});

export const { setIntervals, clearIntervals } = jobsSlice.actions;
export default jobsSlice.reducer;
