import React, { useCallback, useEffect, useRef, useState } from 'react';
import BackupSettings from '../../types/BackupSettings';
import {
    backupSettingsApiInterface,
    BackupSettingsDTO,
    BackupSettingsPatchDTO,
} from '../../sdk/BackupsApi';
import { notification } from 'antd';
import CcxIconCloseCircleTwoTone from '../../components/ccx/icons/CcxIconCloseCircleTwoTone';

export default function useBackupSettings(uuid: string | undefined) {
    const settingsDTO = useRef<BackupSettingsDTO>({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [backupSettings, setBackupSettings] = useState<
        BackupSettings | undefined
    >(undefined);

    const refresh = useCallback(async () => {
        if (uuid) {
            setLoading(true);

            try {
                const { data } =
                    await backupSettingsApiInterface.settingsDatastoreUuidGet(
                        uuid
                    );
                settingsDTO.current = data;
                const details = new BackupSettings(data);
                setBackupSettings(details);
                setLoading(false);
                setError(null);
            } catch (e: any) {
                setLoading(false);
                setError(e);
            }

            setLoading(false);
        }
    }, [uuid]);

    const update: (settings: BackupSettingsPatchDTO) => void = useCallback(
        async (settings: BackupSettingsPatchDTO) => {
            try {
                if (!uuid) {
                    throw new Error('No datastore uuid provided');
                }
                setLoading(true);

                const { data } =
                    await backupSettingsApiInterface.settingsDatastoreUuidPatch(
                        uuid || '',
                        settings
                    );
                settingsDTO.current = data;
                const details = new BackupSettings(data);
                setBackupSettings(details);
            } catch (e: any) {
                notification.open({
                    message: 'Update backup settings failed',
                    description: e.response?.data?.err || e.message,
                    icon: <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />,
                });
                throw e;
            } finally {
                setLoading(false);
            }
        },
        [uuid]
    );

    useEffect(() => {
        (async () => {
            await refresh();
        })();
    }, [uuid]);

    return { backupSettings, refresh, update, loading, error };
}
