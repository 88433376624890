import React, { ReactElement, useState } from 'react';
import styles from './IncrementalBackups.module.less';
import AppEmpty from '../AppEmpty';
import useBackupsIncremental from '../../core/hooks/useBackupsIncremental';
import AppTable from '../AppTable';
import AppLoading from '../AppLoading';
import FriendlyDateTime from '../../types/FriendlyDateTime';
import ExpandableText from '../ExpandableText';
import prettyBytes from 'pretty-bytes';
import { Collapse, notification, Spin, Modal, Tooltip } from 'antd';
import { UndoOutlined } from '@ant-design/icons';
import BackupService from '../../services/BackupService';
import AppConfirmDialog from '../AppConfirmDialog';
import AppLabeledIconStatus from '../ccx/common/AppLabeledIconStatus';
import CcxIconCheckCircle from '../ccx/icons/CcxIconCheckCircle';
import CcxIconExclamationCircle from '../ccx/icons/CcxIconExclamationCircle';
import CcxIconInfoCircleTwoTone from '../ccx/icons/CcxIconInfoCircleTwoTone';
import CcxIconCheckCircleTwoTone from '../ccx/icons/CcxIconCheckCircleTwoTone';
import CcxIconCloseCircleTwoTone from '../ccx/icons/CcxIconCloseCircleTwoTone';
import BackupStatus from './BackupStatus';
import BackupsModal from './BackupsModal';
import numbro from 'numbro';

type Props = {
    dataStoreUuid: string;
    parentId: number;
    restoring: boolean | undefined;
};

function IncrementalBackups({
    dataStoreUuid,
    parentId,
    restoring,
}: Props): ReactElement {
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(10);
    const { backups, loading, page, pageSize, total } = useBackupsIncremental(
        dataStoreUuid,
        parentId,
        currentPage,
        currentPageSize
    );
    const { Panel } = Collapse;

    const handleTableChange = async (pagination: any) => {
        setCurrentPage(pagination.current);
    };

    const [openRestore, setOpenRestore] = useState<boolean>(false);
    const [restoreObj, setRestoreObj] = useState<any>();

    const backupsColumns = [
        {
            title: 'Id',
            dataIndex: 'backupId',
            key: 'backupId',
        },
        {
            title: 'Method',
            dataIndex: 'backupMethod',
            key: 'backupMethod',
        },
        {
            title: 'Type',
            dataIndex: 'backupType',
            key: 'backupType',
        },
        {
            title: 'Status',
            key: 'status',
            render: (text: string, record: any) => {
                return (
                    <BackupStatus backup={record} data-testid="BackupStatus" />
                );
            },
        },
        {
            title: 'Started',
            dataIndex: 'startedAt',
            key: 'startedAt',
            width: 230,
            render: (text: string, record: any) => {
                const a = new FriendlyDateTime({
                    date: record.startedAt,
                });
                return (
                    <ExpandableText
                        collapsedText={a?.getFullDate()}
                        expandedText={a?.getFriendlyDate()}
                    />
                );
            },
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
            width: 140,
            render: (text: string, record: any) => {
                const from = new Date(record.startedAt);
                const to = new Date(record.endedAt);
                const diff = to.getTime() - from.getTime();
                const secs = diff / 1000;
                let duration;
                if (secs > 59) {
                    duration = `${(secs / 60).toFixed()} mins`;
                } else {
                    duration = `${secs.toFixed()} secs`;
                }

                return (
                    <ExpandableText
                        collapsedText={duration}
                        expandedText={new FriendlyDateTime({
                            date: record.endedAt,
                        }).getFullDate()}
                    />
                );
            },
        },
        {
            title: 'Size',
            dataIndex: 'size',
            key: 'size',
            width: 100,
            render: (text: string, record: any) => {
                return numbro(parseInt(record.size, 10)).format({
                    output: 'byte',
                    base: 'binary',
                    spaceSeparated: true,
                    mantissa: 2,
                });
            },
        },
        {
            title: 'Actions',
            key: 'actions',
            width: 85,
            render: (text: string, record: any) => {
                const onConfirmRestore = () => {
                    setOpenRestore(true);
                    setRestoreObj(record);
                };

                return (
                    <div className={styles.IncrementalBackupsActionsColumn}>
                        {restoring ? (
                            <Spin />
                        ) : (
                            <AppConfirmDialog
                                onOk={onConfirmRestore}
                                actionIcon={<a>Restore</a>}
                                title={<strong>Restore a backup</strong>}
                                content="You are going to restore your database using the selected backup."
                            />
                        )}
                    </div>
                );
            },
        },
    ];

    return (
        <div>
            <BackupsModal
                visible={openRestore}
                currentBackup={restoreObj}
                dataStore={dataStoreUuid}
                onclose={() => setOpenRestore(false)}
            />

            {loading ? (
                <AppLoading />
            ) : backups?.length > 0 ? (
                <Collapse
                    defaultActiveKey={['incremental']}
                    className={styles.IncrementalBackups}
                >
                    <Panel
                        header={`${total} Incremental Backups`}
                        key="incremental"
                    >
                        <AppTable
                            columns={backupsColumns}
                            data={backups}
                            rowKey="backupId"
                            pagination={{
                                pageSize,
                                current: page,
                                total,
                                hideOnSinglePage: true,
                            }}
                            onChange={handleTableChange}
                            className={styles.IncrementalBackupsExpandableTable}
                        />
                    </Panel>
                </Collapse>
            ) : (
                <AppEmpty message="There are no Backups to display yet." />
            )}
        </div>
    );
}

export default IncrementalBackups;
