export type CmonProxySqlStatBackendApiProps = {
    ACTIVE_TRANSACTIONS: number;
    BACKEND_QUERY_TIME_NSEC: number;
    CLIENT_CONNECTIONS_ABORTED: number;
    CLIENT_CONNECTIONS_CONNECTED: number;
    CLIENT_CONNECTIONS_CREATED: number;
    COM_AUTOCOMMIT: number;
    COM_AUTOCOMMIT_FILTERED: number;
    COM_COMMIT: number;
    COM_COMMIT_FILTERED: number;
    COM_ROLLBACK: number;
    COM_ROLLBACK_FILTERED: number;
    CONNPOOL_MEMORY_BYTES: number;
    MYSQL_BACKEND_BUFFERS_BYTES: number;
    MYSQL_FRONTEND_BUFFERS_BYTES: number;
    MYSQL_MONITOR_WORKERS: number;
    MYSQL_SESSION_INTERNAL_BYTES: number;
    MYSQL_THREAD_WORKERS: number;
    QUERIES_BACKENDS_BYTES_RECV: number;
    QUERIES_BACKENDS_BYTES_SENT: number;
    QUERY_CACHE_BYTES_IN: number;
    QUERY_CACHE_BYTES_OUT: number;
    QUERY_CACHE_COUNT_GET: number;
    QUERY_CACHE_COUNT_GET_OK: number;
    QUERY_CACHE_COUNT_SET: number;
    QUERY_CACHE_ENTRIES: number;
    QUERY_CACHE_MEMORY_BYTES: number;
    QUERY_CACHE_PURGED: number;
    QUERY_PROCESSOR_TIME_NSEC: number;
    QUESTIONS: number;
    SERVERS_TABLE_VERSION: number;
    SERVER_CONNECTIONS_ABORTED: number;
    SERVER_CONNECTIONS_CONNECTED: number;
    SERVER_CONNECTIONS_CREATED: number;
    SLOW_QUERIES: number;
    SQLITE3_MEMORY_BYTES: number;
    created: number;
    interval: number;
    sampleends: number;
    samplekey: string;
    serverid: number;
};

export default class CmonProxySqlStat {
    readonly activeTransactions: number;
    readonly backendQueryTimeNsec: number;
    readonly clientConnectionsAborted: number;
    readonly clientConnectionsConnected: number;
    readonly clientConnectionsCreated: number;
    readonly comAutoCommit: number;
    readonly comAutoCommitFiltered: number;
    readonly comCommit: number;
    readonly comCommitFiltered: number;
    readonly comRollback: number;
    readonly comRollbackFiltered: number;
    readonly connPoolMemoryBytes: number;
    readonly mysqlBackendBuffersBytes: number;
    readonly mysqlFrontendBuffersBytes: number;
    readonly mysqlMonitorWorkers: number;
    readonly mysqlSessionInternalBytes: number;
    readonly mysqlThreadWorkers: number;
    readonly queriesBackendsBytesRecv: number;
    readonly queriesBackendsBytesSent: number;
    readonly queryCacheBytesIn: number;
    readonly queryCacheBytesOut: number;
    readonly queryCacheCountGet: number;
    readonly queryCacheCountGetOk: number;
    readonly queryCacheCountSet: number;
    readonly queryCacheEntries: number;
    readonly queryCacheMemoryBytes: number;
    readonly queryCachePurged: number;
    readonly queryProcessorTimeNsec: number;
    readonly questions: number;
    readonly serversTableVersion: number;
    readonly serverConnectionsAborted: number;
    readonly serverConnectionsConnected: number;
    readonly serverConnectionsCreated: number;
    readonly slowQueries: number;
    readonly sqlite3MemoryBytes: number;

    readonly created: number;
    readonly interval: number;
    readonly sampleEnds: number;
    readonly sampleKey: string;
    readonly serverId: number;

    constructor(props: CmonProxySqlStatBackendApiProps) {
        this.activeTransactions = props.ACTIVE_TRANSACTIONS;
        this.backendQueryTimeNsec = props.BACKEND_QUERY_TIME_NSEC;
        this.clientConnectionsAborted = props.CLIENT_CONNECTIONS_ABORTED;
        this.clientConnectionsConnected = props.CLIENT_CONNECTIONS_CONNECTED;
        this.clientConnectionsCreated = props.CLIENT_CONNECTIONS_CREATED;
        this.comAutoCommit = props.COM_AUTOCOMMIT;
        this.comAutoCommitFiltered = props.COM_AUTOCOMMIT_FILTERED;
        this.comCommit = props.COM_COMMIT;
        this.comCommitFiltered = props.COM_COMMIT_FILTERED;
        this.comRollback = props.COM_ROLLBACK;
        this.comRollbackFiltered = props.COM_ROLLBACK_FILTERED;
        this.connPoolMemoryBytes = props.CONNPOOL_MEMORY_BYTES;
        this.mysqlBackendBuffersBytes = props.MYSQL_BACKEND_BUFFERS_BYTES;
        this.mysqlFrontendBuffersBytes = props.MYSQL_FRONTEND_BUFFERS_BYTES;
        this.mysqlMonitorWorkers = props.MYSQL_MONITOR_WORKERS;
        this.mysqlSessionInternalBytes = props.MYSQL_SESSION_INTERNAL_BYTES;
        this.mysqlThreadWorkers = props.MYSQL_THREAD_WORKERS;
        this.queriesBackendsBytesRecv = props.QUERIES_BACKENDS_BYTES_RECV;
        this.queriesBackendsBytesSent = props.QUERIES_BACKENDS_BYTES_SENT;
        this.queryCacheBytesIn = props.QUERY_CACHE_BYTES_IN;
        this.queryCacheBytesOut = props.QUERY_CACHE_BYTES_OUT;
        this.queryCacheCountGet = props.QUERY_CACHE_COUNT_GET;
        this.queryCacheCountGetOk = props.QUERY_CACHE_COUNT_GET_OK;
        this.queryCacheCountSet = props.QUERY_CACHE_COUNT_SET;
        this.queryCacheEntries = props.QUERY_CACHE_ENTRIES;
        this.queryCacheMemoryBytes = props.QUERY_CACHE_MEMORY_BYTES;
        this.queryCachePurged = props.QUERY_CACHE_PURGED;
        this.queryProcessorTimeNsec = props.QUERY_PROCESSOR_TIME_NSEC;
        this.questions = props.QUESTIONS;
        this.serversTableVersion = props.SERVERS_TABLE_VERSION;
        this.serverConnectionsAborted = props.SERVER_CONNECTIONS_ABORTED;
        this.serverConnectionsConnected = props.SERVER_CONNECTIONS_CONNECTED;
        this.serverConnectionsCreated = props.SERVER_CONNECTIONS_CREATED;
        this.slowQueries = props.SLOW_QUERIES;
        this.sqlite3MemoryBytes = props.SQLITE3_MEMORY_BYTES;

        this.created = props.created;
        this.interval = props.interval;
        this.sampleEnds = props.sampleends;
        this.sampleKey = props.samplekey;
        this.serverId = props.serverid;
    }
}
