import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import styles from './DataStoreMaintenanceTooltip.module.less';

function DataStoreMaintenanceTooltip() {
    return (
        <Tooltip
            className={styles.MaintenanceTooltip}
            overlayClassName={styles.OverlayMaintenanceTooltip}
            placement="top"
            arrowPointAtCenter={true}
            overlayInnerStyle={{
                borderRadius: 8,
                padding: '12px 16px',
            }}
            title={
                <div className={styles.MaintenanceTooltipContainer}>
                    <strong>
                        The update will be performed using a roll-forward
                        upgrade algorithm with the following steps:
                    </strong>
                    <ol className={styles.MaintenanceTooltipPoints}>
                        <li>
                            {' '}
                            The oldest replica (or primary if no replica exist)
                            will be selected first;
                        </li>
                        <li>
                            A new node will be added with the same specification
                            as the oldest node and join the datastore;
                        </li>
                        <li>The oldest node will be removed;</li>
                        <li>
                            If it is a primary-replica configuration then the
                            primary will be updated last. A new node will be
                            added, the new node will be promoted to become the
                            new primary, and the old primary will be removed.
                        </li>
                    </ol>
                    <strong>Please note:</strong>
                    <ul className={styles.MaintenanceTooltipPoints}>
                        <li>
                            Steps from 1 to 3 continues until all replicas (or
                            primaries in case of a multi-primary setup) are
                            updated.
                        </li>
                        <li>
                            The FQDNs will be constant but the underlying IP
                            addresses may change;
                        </li>
                        <li>
                            There will be short service interruption during step
                            5, to promote the new primary.
                        </li>
                    </ul>
                </div>
            }
        >
            <InfoCircleOutlined className={styles.TooltipInfoIcon} />
        </Tooltip>
    );
}

export default DataStoreMaintenanceTooltip;
