export type AddressClassProps = {
    city?: string;
    country?: string;
    line_1?: string;
    line_2?: string;
    postal_code?: string;
    state?: string;
};

export interface AddressInterface {
    city?: string;
    country?: string;
    line1?: string;
    line2?: string;
    postalCode?: string;
    state?: string;
}

export default class Address implements AddressInterface {
    readonly city?: string;
    readonly country?: string;
    readonly line1?: string;
    readonly line2?: string;
    readonly postalCode?: string;
    readonly state?: string;

    constructor(props: AddressClassProps) {
        this.city = props.city;
        this.country = props.country;
        this.line1 = props.line_1;
        this.line2 = props.line_2;
        this.postalCode = props.postal_code;
        this.state = props.state;
    }
}
