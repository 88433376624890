import React, { ReactElement } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';

interface Props extends CcxComponentProps {
    children: ReactElement;
}

const AppSupportLink: React.FC<Props> = ({
    children,
    testId = 'AppBorderedPanel',
}) => {
    return (
        <a
            data-testid={`${testId}`}
            href="https://ccx.severalnines.com/support"
            target="_blank"
        >
            {children}
        </a>
    );
};

export default AppSupportLink;
