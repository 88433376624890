import React, { ReactElement } from 'react';
import { Tooltip } from 'antd';
import CcxComponentProps from '../../core/CcxComponent';
import DotLabelStatus from '../ccx/common/DotLabelStatus';
import Backup from '../../types/Backup';

interface Props extends CcxComponentProps {
    backup?: Backup;
    updating?: boolean;
}

function BackupStatus({
    backup,
    testId = 'BackupStatus',
    updating = false,
}: Props): ReactElement {
    let StatusComponent = null;
    if (backup?.isStatusOk()) {
        StatusComponent = (
            <DotLabelStatus type="ok" label={backup?.status} glow={updating} />
        );
    } else if (backup?.isStatusError()) {
        StatusComponent = (
            <DotLabelStatus
                type="error"
                label={backup?.status}
                glow={updating}
            />
        );
    } else if (backup?.isStatusWarning()) {
        StatusComponent = (
            <DotLabelStatus
                type="warning"
                label={backup?.status}
                glow={updating}
            />
        );
    }

    if (backup?.isStatusError() || backup?.isStatusWarning()) {
        return (
            <Tooltip
                placement="bottom"
                title="Our Support Team has been notified of an incident in regards to this issue. No further action is required."
            >
                {StatusComponent}{' '}
            </Tooltip>
        );
    }
    return (
        <Tooltip placement="bottom" title={`Backup is ${backup?.status}`}>
            {StatusComponent}{' '}
        </Tooltip>
    );
}

export default BackupStatus;
