import { Col, Radio, Row } from 'antd';
import CcxComponentProps from '../../../../core/CcxComponent';
import DeploymentsItem from '../../../../types/DeploymentsItem';
import styles from './DataStoreOverviewAccessToServices.module.less';
import ServiceAccessConnectionInformation from '../services/ServiceAccessConnectionInformation';
import LazyLoader from '../../../LazyLoader';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import { sortNodesByRole } from '../../../../core/helpers';
import Service from '../../../../types/Service';
import { useState } from 'react';

interface Props extends CcxComponentProps {
    dataStore: DeploymentsItem | undefined;
    services?: any;
}

function DataStoreOverviewAccessToServices({
    dataStore,
    services,
    testId = 'DataStoreOverviewAccessToServices',
}: Props) {
    const getRoleNameWithInfo = (s: Service) => {
        if (s.isRoleReplica()) {
            return (
                <div
                    data-testid={`${testId}Replica`}
                    className={styles.DataStoreOverviewAccessToServicesRoleName}
                >
                    <span>Replica</span>
                    <InfoIcon
                        data-testid={`${testId}ReplicaIcon`}
                        info={
                            <span>The replica points to a read-only node.</span>
                        }
                    />
                </div>
            );
        } else {
            return (
                <div
                    data-testid={`${testId}Primary`}
                    className={styles.DataStoreOverviewAccessToServicesRoleName}
                >
                    <span>Primary</span>
                    <InfoIcon
                        data-testid={`${testId}PrimaryIcon`}
                        info={
                            <span>The primary points to a writeable node.</span>
                        }
                    />
                </div>
            );
        }
    };

    const uniqueRolesMap: Record<string, boolean> = {}; // Object to keep track of unique roles
    const sortedDbServices = services && sortNodesByRole(services?.dbServices);

    const accessibleServices = (sortedDbServices || []).filter(
        (service: Service) => {
            const role = service.getRole();

            if (!uniqueRolesMap[role]) {
                if (
                    (service.isRolePrimary() && dataStore?.getPrimaryUrl()) ||
                    (service.isRoleReplica() && dataStore?.getReplicaUrl())
                ) {
                    uniqueRolesMap[role] = true;
                    return true;
                }
            }
            return false;
        }
    );

    const isMariaDBorMySQL = dataStore?.isMariaDb() || dataStore?.isPercona();

    const [connectionMethod, setConnectionMethod] = useState(
        isMariaDBorMySQL ? 'cli' : 'dsn'
    );

    const tabsOptions = [
        { label: 'CLI', value: 'cli' },
        { label: 'DSN', value: 'dsn' },
    ];

    if (accessibleServices.length === 0) return null;

    return (
        <div className={styles.DataStoreOverviewAccessToServices}>
            <header>Connection information</header>
            {isMariaDBorMySQL && (
                <Row data-testid={`${testId}MariaDBorMySQL`} gutter={[16, 16]}>
                    <Col>
                        <Radio.Group
                            size="small"
                            value={connectionMethod}
                            onChange={(e) =>
                                setConnectionMethod(e.target.value)
                            }
                            optionType="button"
                            className={
                                styles.DataStoreOverviewAccessToServicesRadioOptions
                            }
                        >
                            {tabsOptions?.map(({ label, value: val }: any) => (
                                <Radio.Button key={val} value={val}>
                                    <span>{label}</span>
                                </Radio.Button>
                            ))}
                        </Radio.Group>
                    </Col>
                </Row>
            )}

            {dataStore && accessibleServices ? (
                <Row data-testid={testId} gutter={[16, 16]}>
                    {accessibleServices?.map((s: Service) => {
                        if (dataStore.isMSSQL() && s.isRoleReplica())
                            return null;
                        return (
                            <>
                                <Col
                                    sm={3}
                                    xs={24}
                                    className={
                                        styles.DataStoreOverviewAccessToServicesRole
                                    }
                                >
                                    {getRoleNameWithInfo(s)}
                                </Col>
                                <Col
                                    sm={21}
                                    xs={24}
                                    className={
                                        styles.DataStoreOverviewAccessToServicesConInfo
                                    }
                                >
                                    <ServiceAccessConnectionInformation
                                        connectionMethod={connectionMethod}
                                        dataStore={dataStore}
                                        service={s}
                                    />
                                </Col>
                            </>
                        );
                    })}
                </Row>
            ) : (
                <LazyLoader type="row" testId={`${testId}Loader`} rows={2} />
            )}
        </div>
    );
}
export default DataStoreOverviewAccessToServices;
