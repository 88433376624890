import { Button, Row, Col, Alert, Space } from 'antd';
import { ReactElement, useState } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import { AUTH_APP_URL, AUTH_UI_APP_DISABLED } from '../../../core/CcxEnv';
import AppBorderedPanel from '../../ccx/common/AppBorderedPanel';
import AppSupportLink from '../../ccx/common/AppSupportLink';
import styles from './AccountInfo.module.less';
import { useAppSelector } from '../../../redux/hooks';
import LazyLoader from '../../LazyLoader';
import ChangePasswordModal from './ChangePasswordModal';
import AuthService from '../../../services/AuthService';

interface Props extends CcxComponentProps {}

export type ChangePassword = {
    oldPassword: string;
    newPassword: string;
};

export type PasswordError = {
    visible: boolean;
    message: string;
};

export interface ChangePasswordFormInterface extends ChangePassword {
    confirmPassword: string;
}

function AccountInfo({ testId = 'AccountInfo' }: Props): ReactElement {
    const { user, subscription, billingUserData, billingAddress, isLoading } =
        useAppSelector((state) => state.user);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [error, setError] = useState<PasswordError>({
        visible: false,
        message: 'Your password is incorrect. Please, try again',
    });
    const [showPasswordLoader, setShowPasswordLoader] =
        useState<boolean>(false);

    const closeModal = () => {
        setIsVisible(false);
    };

    const onChangeOldPassword = () => {
        setError({ ...error, visible: false });
    };

    const onUpdatePassword = async ({
        oldPassword,
        newPassword,
    }: ChangePasswordFormInterface) => {
        setShowPasswordLoader(true);
        const data: ChangePassword = {
            oldPassword,
            newPassword,
        };
        const isPasswordUpdated = await AuthService.updatePassword(data);
        setShowPasswordLoader(false);
        if (!isPasswordUpdated) {
            setError({ ...error, visible: true });
            return;
        }
        setError({ ...error, visible: false });
        setIsVisible(false);
    };

    return isLoading ? (
        <LazyLoader type="row" />
    ) : (
        <section data-testid={`${testId}`}>
            {user && subscription && (
                <Space
                    direction="vertical"
                    size="large"
                    className={styles.AccountInfoSpace}
                >
                    {!AUTH_UI_APP_DISABLED && !user?.emailConfirmed && (
                        <Alert
                            message={<strong>Verify your email address</strong>}
                            description={
                                <>
                                    We need you to verify your email. We’ve sent
                                    a verification email to
                                    {` `}
                                    <strong>{user?.login}</strong>. Please,
                                    click on the link in the email so you can
                                    verify your email. If you don’t see an email
                                    from us, check your Junk folder or try to{' '}
                                    <a
                                        target="_blank"
                                        href={`${AUTH_APP_URL}/verify-account?initial=1&from=ccx&send=1`}
                                        rel="noopener noreferrer"
                                        data-testid="AccountInfoVerifyEmailLink"
                                    >
                                        resend the verification email
                                    </a>{' '}
                                    again.
                                </>
                            }
                            type="warning"
                            showIcon
                        />
                    )}

                    <Alert
                        message={
                            <>
                                To change your personal information, please
                                contact our{' '}
                                <AppSupportLink>
                                    <>support team</>
                                </AppSupportLink>
                            </>
                        }
                        type="info"
                        showIcon
                    />

                    <div>
                        <h1 data-testid={`${testId}PersonalInformationTitle`}>
                            <strong>Personal information</strong>
                        </h1>

                        <AppBorderedPanel>
                            <Row>
                                <Col sm={10} xs={24}>
                                    <Space direction="vertical" size="middle">
                                        <div>
                                            <strong>First Name</strong>:{' '}
                                            {billingUserData?.firstName}
                                        </div>
                                        <div>
                                            <strong>Last Name</strong>:{' '}
                                            {billingUserData?.lastName}
                                        </div>
                                        <div>
                                            <strong>Email</strong>:{' '}
                                            {user?.login}
                                        </div>
                                        <div>
                                            <strong>Billing address 1</strong>:{' '}
                                            {billingAddress?.line1}
                                        </div>
                                        <div>
                                            <strong>Billing address 2</strong>:{' '}
                                            {billingAddress?.line2}
                                        </div>
                                    </Space>
                                </Col>

                                <Col sm={14} xs={24}>
                                    <Space direction="vertical" size="middle">
                                        <div>
                                            <strong>City</strong>:{' '}
                                            {billingAddress?.city}
                                        </div>
                                        <div>
                                            <strong>State</strong>:{' '}
                                            {billingAddress?.state}
                                        </div>
                                        <div>
                                            <strong>Postal code</strong>:{' '}
                                            {billingAddress?.postalCode}
                                        </div>
                                        <div>
                                            <strong>Country</strong>:{' '}
                                            {billingAddress?.country}
                                        </div>
                                    </Space>
                                </Col>
                            </Row>
                        </AppBorderedPanel>
                    </div>

                    <div>
                        <h1 data-testid={`${testId}CompanyInformationTitle`}>
                            <strong>Company information</strong>
                        </h1>

                        <AppBorderedPanel>
                            <Row>
                                <Col sm={10} xs={24}>
                                    <Space direction="vertical" size="middle">
                                        <div>
                                            <strong>Company name</strong>:{' '}
                                            {billingUserData?.companyName}
                                        </div>
                                    </Space>
                                </Col>

                                <Col sm={14} xs={24}>
                                    <Space direction="vertical" size="middle">
                                        <div>
                                            <strong>EU VAT number</strong>:{' '}
                                            {billingUserData?.euVatId}
                                        </div>
                                    </Space>
                                </Col>
                            </Row>
                        </AppBorderedPanel>
                    </div>

                    <div>
                        <h1 data-testid={`${testId}AccountManagementTitle`}>
                            <strong>Account management</strong>
                        </h1>

                        <AppBorderedPanel>
                            <Row justify={'space-between'} align={'middle'}>
                                <Col>
                                    <Space direction="vertical" size="middle">
                                        <div>
                                            <strong>Change password</strong>
                                            <br />
                                            Make your account more secure by
                                            changing your password at least once
                                            every three months.{' '}
                                        </div>
                                    </Space>
                                </Col>

                                <Col sm={4} xs={24}>
                                    <Space direction="vertical" size="middle">
                                        <Button
                                            onClick={() => setIsVisible(true)}
                                        >
                                            Change Password
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </AppBorderedPanel>
                    </div>
                </Space>
            )}
            <ChangePasswordModal
                error={error}
                isVisible={isVisible}
                onCancel={closeModal}
                isLoading={showPasswordLoader}
                onUpdatePassword={onUpdatePassword}
                onChangeOldPassword={onChangeOldPassword}
            />
        </section>
    );
}

export default AccountInfo;
