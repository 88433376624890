import { useEffect, useState } from 'react';
import QueryMonitorService from '../../services/QueryMonitorService';
import TopQueries from '../../types/TopQueries';

export default function useTopQueries(uuid: string | undefined) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [topQueries, setTopQueries] = useState<TopQueries>();

    const load = async () => {
        if (uuid) {
            setLoading(true);

            try {
                const details = await QueryMonitorService.getTopQueries(uuid);
                setTopQueries(details);
                setLoading(false);
                setError(null);
            } catch (e: any) {
                setLoading(false);
                setError(e);
            }

            setLoading(false);
        }
    };

    const refresh = () => {
        load();
    };

    // get deployments for the given user
    useEffect(() => {
        refresh();
    }, [uuid]);

    return { topQueries, refresh, loading, error };
}
