import ApiService from './ApiService';
import { API_V2_DEPLOYMENT } from '../core/CcxEnv';

// constants
const API_URL = API_V2_DEPLOYMENT;

export default class DownloadingAuthCertificatesService extends ApiService {
    /**
     * @param {string} certificateType
     */
    static async getDatastoreCertificate(
        certificateType: string
    ): Promise<any> {
        const response = await fetch(
            `${API_URL}/data-stores/certificates/${certificateType}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/x-x509-ca-cert',
                },
            }
        );

        if (!response.ok) {
            throw new Error();
        }

        return response;
    }
}
