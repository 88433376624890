export type PrometheusHaProxyCurrentSessionsByBackendStatsBackendApiProps = {
    created: number;
    haproxy_server_current_sessions: number;
};

export default class PrometheusHaProxyCurrentSessionsByBackendStats {
    readonly created: number;
    readonly haproxyServerCurrentSessions: number;

    constructor(
        props: PrometheusHaProxyCurrentSessionsByBackendStatsBackendApiProps
    ) {
        this.created = props.created;
        this.haproxyServerCurrentSessions =
            props.haproxy_server_current_sessions;
    }
}
