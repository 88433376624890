import React, { ReactElement } from 'react';
import { Tooltip } from 'antd';
import CcxComponentProps from '../../core/CcxComponent';
import DotLabelStatus from '../ccx/common/DotLabelStatus';
import Restore from '../../types/Restore';

interface Props extends CcxComponentProps {
    restore?: Restore;
    updating?: boolean;
}

function RestoreStatus({
    restore,
    testId = 'RestoreStatus',
    updating = false,
}: Props): ReactElement {
    let StatusComponent = null;
    if (restore?.isStatusOk()) {
        StatusComponent = (
            <DotLabelStatus
                type="info"
                label={restore?.getStatusText()}
                glow={updating}
            />
        );
    } else if (restore?.isStatusError()) {
        StatusComponent = (
            <DotLabelStatus
                type="error"
                label={restore?.getStatusText()}
                glow={updating}
            />
        );
    } else if (restore?.isStatusCompleted()) {
        StatusComponent = (
            <DotLabelStatus
                type="ok"
                label={restore?.getStatusText()}
                glow={updating}
            />
        );
    }

    if (restore?.isStatusError()) {
        return (
            <Tooltip
                placement="bottom"
                title="Our Support Team has been notified of an incident in regards to this issue. No further action is required."
            >
                {StatusComponent}{' '}
            </Tooltip>
        );
    }
    return (
        <Tooltip placement="bottom" title={`${restore?.statusText}`}>
            {StatusComponent}{' '}
        </Tooltip>
    );
}

export default RestoreStatus;
