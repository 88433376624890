import { ReactElement } from 'react';
import styles from './AppChart.module.less';
import AppChartArea from './AppChartArea';
import AppChartLiquid from './AppChartLiquid';
import AppChartLine from './AppChartLine';

import AppChartSummary from './AppChartSummary';
import AppLoading from './AppLoading';
import CcxComponentProps from '../core/CcxComponent';
import AppChartHistogram from './AppChartHistogram';
import AppChartTinyArea from './AppChartTinyArea';
import AppEmpty from './AppEmpty';
import AppChartColumn from './AppChartColumn';

interface Props extends CcxComponentProps {
    config: any;
    data?: any[];
    type: string;
    summary?: any;
    loading?: boolean;
    displaySummary?: boolean;
    bordered?: boolean;
}

function AppChart({
    data,
    type,
    config,
    summary,
    loading = true,
    displaySummary = false,
    bordered = true,
    testId = 'AppChart',
}: Props): ReactElement {
    const getChartComponent = (type: string, config: any) => {
        switch (type) {
            case 'area':
                return <AppChartArea config={config} data={data || []} />;
            case 'tinyArea':
                return <AppChartTinyArea config={config} data={data || []} />;
            case 'liquid':
                return <AppChartLiquid config={config} />;
            case 'line':
                return <AppChartLine config={config} data={data || []} />;
            case 'histogram':
                return <AppChartHistogram config={config} data={data || []} />;
            case 'column':
                return <AppChartColumn config={config} data={data || []} />;
            default:
                return null;
        }
    };

    let chart = null;

    if (loading) {
        chart = <AppLoading testId={`${testId}LoadingSpinner`} />;
    } else {
        if (
            data?.length === 0 ||
            (type === 'liquid' && config?.percent === 0)
        ) {
            const message = (
                <span className={styles.AppChartEmptyMessage}>
                    There is no data to display
                </span>
            );

            chart = (
                <div className={styles.AppChartEmpty}>
                    <h4 className={styles.AppChartEmptyHeader}>
                        {config?.title?.text}
                    </h4>
                    <AppEmpty message={message} defaultImage={true} />
                </div>
            );
        } else {
            if (config?.title?.text === 'Load average') {
                data = data?.map((val: any) => {
                    return { ...val, yValue: val.yValue };
                });
            } else {
                // Should we even have rounding here?
                data = data?.map((val: any) => {
                    return { ...val, yValue: Math.round(val.yValue) };
                });
            }

            chart = (
                <>
                    <h4>{config?.title?.text}</h4>

                    {getChartComponent(type, config)}

                    {!displaySummary ? null : summary && summary.length > 0 ? (
                        <AppChartSummary data={summary} />
                    ) : null}
                </>
            );
        }
    }

    return (
        <div
            className={bordered ? styles.AppChart : styles.AppChartNoBorder}
            data-testid={testId}
        >
            {chart}
        </div>
    );
}

export default AppChart;
