import { useCallback, useState } from 'react';

type UseThrowErrorProps = {};

export default function useThrowError({}: UseThrowErrorProps = {}) {
    const [error, setError] = useState<Error>();
    if (error) {
        throw error;
    }
    const throwError = useCallback((error: Error) => {
        setError(error);
    }, []);

    return {
        throwError,
    };
}
