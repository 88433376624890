import React, { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './AppLogo.module.less';
import { AppLogoUrl } from '../../core/CcxEnv';
import User from '../../types/User';

export type AppLogoProps = {
    user?: User | undefined;
};

function AppLogo({ user }: AppLogoProps): ReactElement {
    return (
        <div className={styles.AppLogo}>
            <NavLink
                to={user?.isAdmin ? '/admin' : '/projects/default/data-stores'}
                data-testid="AppLogoLink"
            >
                <img src={AppLogoUrl} alt="Logo" data-testid="AppLogoImage" />
            </NavLink>
        </div>
    );
}

export default AppLogo;
