export type TermsAcceptedClassProps = {
    TermsAndConditionsV1: boolean;
    PrivacyPolicyV1: boolean;
};

export default class TermsAccepted {
    public readonly TermsAndConditionsV1: boolean;
    public readonly PrivacyPolicyV1: boolean;

    constructor(props: TermsAcceptedClassProps) {
        this.TermsAndConditionsV1 = props.TermsAndConditionsV1;
        this.PrivacyPolicyV1 = props.PrivacyPolicyV1;
    }
}
