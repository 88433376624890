import dayjs from 'dayjs';
import Backup from './Backup';

export type RestoreClassProps = {
    cmon_backup_id: number;
    progress: number;
    status: number;
    status_text: string;
    created_at: any;
    started_at: any;
    updated_at: any;
    ended_at: any;
    backup: any;
};

export interface RestoreInterface {
    backupId: number;
    progress: number;
    status: number;
    statusText: string;
    createdAt: string;
    startedAt: string;
    updatedAt: string;
    endedAt: string;
    backupType: string;
    backupSize: any;
}

export const RESTORE_STATUS_DEFINED = 0;
export const RESTORE_STATUS_DEQUEUED = 1;
export const RESTORE_STATUS_RUNNING = 2;
export const RESTORE_STATUS_RUNNING2 = 3;
export const RESTORE_STATUS_RUNNING3 = 4;
export const RESTORE_STATUS_RUNNING_EXT = 5;
export const RESTORE_STATUS_ABORTED = 6;
export const RESTORE_STATUS_FINISHED = 7;
export const RESTORE_STATUS_FAILED = 8;

export default class Restore implements RestoreInterface {
    readonly backupId: number;
    readonly progress: number;
    readonly status: number;
    readonly statusText: string;
    readonly createdAt: string;
    readonly startedAt: string;
    readonly updatedAt: string;
    readonly endedAt: string;
    readonly backupType: string;
    readonly backupSize: any;

    constructor(props: RestoreClassProps) {
        this.backupId = props.cmon_backup_id;
        this.progress = props.progress || 0;
        this.status = props.status || 0;
        this.statusText = props.status_text;
        this.createdAt = props.created_at ? props.created_at.seconds : '';
        this.startedAt = props.started_at ? props.started_at.seconds : '';
        this.updatedAt = props.updated_at ? props.updated_at.seconds : '';
        this.endedAt = props.ended_at ? props.ended_at.seconds : '';
        this.backupType = props?.backup?.backup_type;
        this.backupSize = props?.backup?.size;
    }

    isStatusOk(): boolean {
        return (
            this.status === RESTORE_STATUS_DEFINED ||
            this.status === RESTORE_STATUS_RUNNING ||
            this.status === RESTORE_STATUS_RUNNING2 ||
            this.status === RESTORE_STATUS_RUNNING3 ||
            this.status === RESTORE_STATUS_RUNNING_EXT
        );
    }

    isStatusError(): boolean {
        return (
            this.status === RESTORE_STATUS_ABORTED ||
            this.status === RESTORE_STATUS_FAILED ||
            this.status === RESTORE_STATUS_DEQUEUED
        );
    }

    isStatusCompleted(): boolean {
        return this.status === RESTORE_STATUS_FINISHED;
    }

    getStatusText(): string {
        switch (this.status) {
            case RESTORE_STATUS_DEFINED:
                return 'Defined';
            case RESTORE_STATUS_DEQUEUED:
                return 'Dequeued';
            case RESTORE_STATUS_RUNNING:
                return 'Running';
            case RESTORE_STATUS_RUNNING2:
                return 'Running';
            case RESTORE_STATUS_RUNNING3:
                return 'Running';
            case RESTORE_STATUS_RUNNING_EXT:
                return 'Running';
            case RESTORE_STATUS_ABORTED:
                return 'Aborted';
            case RESTORE_STATUS_FINISHED:
                return 'Finished';
            case RESTORE_STATUS_FAILED:
                return 'Failed';

            default:
                return 'Unknown';
        }
    }

    isClosed(): boolean {
        const endDate = dayjs.unix(parseInt(this.endedAt, 10));

        const minutesAgo = dayjs().diff(endDate, 'm');

        if (this.isStatusCompleted() && this.endedAt) {
            return minutesAgo >= 5;
        } else if (this.isStatusError() && this.endedAt) {
            return minutesAgo >= 5;
        }

        return false;
    }
}
