export type PrometheusProxySqlDataReceivedHostgroupDistributionStatsBackendApiProps =
    {
        created: number;
        data_recv: number;
    };

export default class PrometheusProxySqlDataReceivedHostgroupDistributionStats {
    readonly created: number;
    readonly dataRecv: number;

    constructor(
        props: PrometheusProxySqlDataReceivedHostgroupDistributionStatsBackendApiProps
    ) {
        this.created = props.created;
        this.dataRecv = props.data_recv;
    }
}
