import BackupsFormModal, { BackupsFormModalProps } from './BackupsFormModal';
import React from 'react';
import { useForm } from 'antd/lib/form/Form';
import DeploymentsItem from '../../types/DeploymentsItem';
import BackupSchedule from '../../types/BackupSchedule';
import BackupsScheduleEditIncrementalForm from './BackupsScheduleEditIncrementalForm';
import BackupsScheduleEditFullForm from './BackupsScheduleEditFullForm';

type BackupsScheduleEditFullDrawerProps = Omit<
    BackupsFormModalProps,
    'form'
> & {
    deployment: DeploymentsItem;
    schedule: BackupSchedule;
    onSuccess?: () => void;
};

export default function BackupsScheduleEditDrawer({
    deployment,
    schedule,
    onSuccess,
    ...rest
}: BackupsScheduleEditFullDrawerProps) {
    const [form] = useForm();

    return (
        <BackupsFormModal
            form={form}
            title={
                schedule.backupType === 'incremental'
                    ? 'Edit incremental backup schedule'
                    : 'Edit full backup schedule'
            }
            {...rest}
        >
            {schedule.backupType === 'incremental' ? (
                <BackupsScheduleEditIncrementalForm
                    deployment={deployment}
                    form={form}
                    onSuccess={onSuccess}
                />
            ) : (
                <BackupsScheduleEditFullForm
                    deployment={deployment}
                    form={form}
                    onSuccess={onSuccess}
                />
            )}
        </BackupsFormModal>
    );
}
