import { ReactElement, useEffect, useState } from 'react';
import { Form, Input, Select, Switch, Radio } from 'antd';
import styles from './AppForm.module.less';
import { Validator } from 'ip-num/Validator';
import { IPv4 } from 'ip-num/IPNumber';
import { IPv4CidrRange } from 'ip-num/IPRange';
import { InputNumber } from 'antd';
import AppFormSlider from './AppFormSlider';
import AppFormFlagSelect from './AppFormFlagSelect';
import CcxComponentProps from '../../../core/CcxComponent';
import CcxIconInfoCircleOutlined from '../icons/CcxIconInfoCircleOutlined';
import { Tooltip } from 'antd';

interface Props extends CcxComponentProps {
    fields?: any[];
    form?: any;
    onPressEnter?: Function;
    errorFields?: any[];
    formClassName?: string;
    useGrid?: boolean;
    overideValidationFunction?: Function;
}

function AppForm({
    fields,
    onPressEnter,
    errorFields,
    testId = 'AppForm',
    formClassName,
    useGrid = false,
    overideValidationFunction = () => {},
}: Props): ReactElement {
    const { Option } = Select;

    const [formList, setFormList] = useState(fields);

    useEffect(() => {
        const sortedArray = sortByGroup(fields);
        setFormList([...sortedArray]);
    }, [errorFields, fields]);

    const sortByGroup = (fields?: any[]) => {
        const groupedItems: any = {};
        fields &&
            fields.forEach((field) => {
                const groupName = field.groupBy;
                if (!groupedItems[groupName]) {
                    groupedItems[groupName] = [];
                }
                groupedItems[groupName].push(field);
            });

        for (const groupName in groupedItems) {
            if (groupedItems.hasOwnProperty(groupName)) {
                groupedItems[groupName].sort((a: any, b: any) => {
                    return a.groupBy - b.groupBy;
                });
            }
        }
        const sortedArray = Object.values(groupedItems).flat();
        return sortedArray;
    };

    const getFormComponent = (field: any) => {
        if (!field) {
            return null;
        }

        switch (field.type) {
            case 'flagSelect':
                return (
                    <AppFormFlagSelect
                        field={field}
                        onPressEnter={onPressEnter}
                        testId={field.testId}
                    />
                );
            case 'select':
                return (
                    <Select
                        showSearch
                        placeholder={field.placeholder}
                        optionFilterProp="children"
                        className={styles.AppFormInput}
                        disabled={field.disabled}
                        filterOption={(input: any, option: any) =>
                            option?.props?.children?.props?.children[1]
                                .toLowerCase()
                                .indexOf(input?.toLowerCase()) >= 0
                        }
                        onChange={field.onChange}
                        data-testid={field.testId}
                    >
                        {field.options?.map((o: any) => {
                            return (
                                <Option value={o.value} key={o.value}>
                                    {o.content || o.label}
                                </Option>
                            );
                        })}
                    </Select>
                );
            case 'multiple':
                return (
                    <Select
                        showSearch
                        mode="multiple"
                        placeholder={field.placeholder}
                        optionFilterProp="children"
                        className={styles.AppFormInput}
                        disabled={field.disabled}
                        filterOption={(input: any, option: any) =>
                            option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                        data-testid={field.testId}
                    >
                        {field.options.map((o: any) => {
                            return (
                                <Option value={o.value} key={o.value}>
                                    {o.label}
                                </Option>
                            );
                        })}
                    </Select>
                );
            case 'textarea':
                return (
                    <Input.TextArea
                        className={styles.AppFormInput}
                        placeholder={field.placeholder || field.label}
                        disabled={field.disabled}
                        allowClear
                        onPressEnter={onPressEnter as any}
                        data-testid={field.testId}
                    />
                );
            case 'password':
                return (
                    <Input.Password
                        className={styles.AppFormInput}
                        placeholder={field.placeholder || field.label}
                        disabled={field.disabled}
                        allowClear
                        onPressEnter={onPressEnter as any}
                        data-testid={field.testId}
                    />
                );
            case 'ip':
            case 'cidr':
                return (
                    <Input
                        className={styles.AppFormInput}
                        placeholder={field.placeholder || field.label}
                        disabled={field.disabled}
                        allowClear
                        onPressEnter={onPressEnter as any}
                        data-testid={field.testId}
                        onBlur={field.onBlur}
                    />
                );
            case 'radioGroup':
                return (
                    <Radio.Group
                        className={
                            field.layout === 'vertical'
                                ? styles.AppFormRadioGroupVertical
                                : styles.AppFormRadioGroupHorizontal
                        }
                        disabled={field.disabled}
                        onChange={field.onChange}
                        data-testid={field.testId}
                    >
                        {field.options?.map((o: any) => {
                            return (
                                <Radio.Button
                                    className={
                                        field.layout === 'vertical'
                                            ? styles.AppFormRadioGroupItemVertical
                                            : styles.AppFormRadioGroupItemHorizontal
                                    }
                                    disabled={field.disabled}
                                    value={o.value}
                                    onChange={field.onChange}
                                    key={o.value}
                                    {...{
                                        placeholder:
                                            field.placeholder || field.label,
                                        allowClear: true,
                                        onPressEnter,
                                    }}
                                >
                                    {o.children}
                                </Radio.Button>
                            );
                        })}
                    </Radio.Group>
                );
            case 'hidden':
                return (
                    <input
                        type="hidden"
                        className={styles.AppFormInput}
                        placeholder={field.placeholder || field.label}
                        disabled={field.disabled}
                        data-testid={field.testId}
                    />
                );
            case 'rawElement':
                return field.rawElement;
            case 'switch':
                return (
                    <Switch
                        className={styles.AppFormSwitch}
                        onChange={field.onChange}
                        data-testid={field.testId}
                    />
                );
            case 'slider':
                return (
                    <AppFormSlider field={field} onChange={field.onChange} />
                );

            case 'number':
                return (
                    <InputNumber
                        className={
                            field.align === 'left'
                                ? styles.AppFormInputNumberLeft
                                : styles.AppFormInputNumber
                        }
                        placeholder={field.placeholder || field.label}
                        disabled={field.disabled}
                        onPressEnter={onPressEnter as any}
                        min={field.minValue}
                        max={field.maxValue}
                        addonAfter={field.options ? field.options : undefined}
                        maxLength={field.maxLength}
                        formatter={field.formatter}
                        parser={field.parser}
                        step={field.step}
                        data-testid={field.testId}
                        onChange={field.onChange}
                    />
                );
            default:
                return (
                    <Input
                        className={styles.AppFormInput}
                        placeholder={field.placeholder || field.label}
                        disabled={field.disabled}
                        allowClear
                        onChange={field.onChange}
                        onPressEnter={onPressEnter as any}
                        maxLength={field.maxLength}
                        data-testid={field.testId}
                    />
                );
        }
    };

    return (
        <div
            data-testid={testId}
            className={`${
                useGrid ? formClassName + ' ' + styles.AppRow : formClassName
            }`}
        >
            {formList?.map((field: any, index) => {
                const getFieldErrors = () => {
                    return errorFields
                        ? errorFields?.filter((f) => {
                              // f.name and field.name are like: ["source"]
                              return f.name[0] === field.name[0];
                          })
                        : [];
                };

                const hasErrors = () => {
                    return getFieldErrors().length > 0;
                };

                const helpText =
                    (hasErrors() &&
                        getFieldErrors()
                            .map((f: any) => {
                                return f.errors.join(' ');
                            })
                            .join()) ||
                    field.helpText;

                const validateStatus = !hasErrors() ? 'success' : 'error';

                const checkReservedIp = (rule: any, value: any) => {
                    if (!value) {
                        return Promise.resolve();
                    }

                    const cidrComponents = value.split('/');
                    const ip = cidrComponents[0];
                    if (!ip) {
                        return Promise.resolve();
                    }
                    const [validIpv4String] = Validator.isValidIPv4String(ip);
                    if (!validIpv4String) {
                        return Promise.resolve();
                    }
                    const ipV4 = new IPv4(ip);

                    const reservedRange = [
                        '0.0.0.0/8',
                        '127.0.0.0/8',
                        '169.254.0.0/16',
                        '198.51.100.0/24',
                        '203.0.113.0/24',
                        '224.0.0.0/4',
                        '240.0.0.0/4',
                        '255.255.255.255/32',
                    ];

                    let valid = true;
                    reservedRange.forEach((r: string) => {
                        const ipv4Range = IPv4CidrRange.fromCidr(r);
                        if (
                            ipV4.isGreaterThanOrEquals(ipv4Range.getFirst()) &&
                            ipV4.isLessThanOrEquals(ipv4Range.getLast())
                        ) {
                            valid = false;
                        }
                    });

                    if (!valid) {
                        return Promise.reject('Cannot use a reserved IP range');
                    }

                    return Promise.resolve();
                };

                const checkCidr = (rule: any, value: any) => {
                    if (!value) {
                        return Promise.resolve();
                    }

                    const [result, message] =
                        Validator.isValidIPv4CidrNotation(value);

                    if (result) {
                        return Promise.resolve();
                    }

                    return Promise.reject(message);
                };

                const checkIp = (rule: any, value: any) => {
                    if (!value) {
                        return Promise.resolve();
                    }

                    const [result, message] =
                        Validator.isValidIPv4String(value);

                    if (result) {
                        return Promise.resolve();
                    }

                    return Promise.reject(message);
                };

                const checkTimeDuration = (rule: any, value: any) => {
                    const isValidDuration: Boolean =
                        overideValidationFunction();
                    if (!isValidDuration)
                        return Promise.reject(
                            'Please enter a duration that corresponds to whole hours.'
                        );

                    return Promise.resolve();
                };

                const checkPatterns = (rule: any, value: any) => {
                    if (!value || !field.validators) {
                        return Promise.resolve();
                    }

                    let valid = true;
                    let message = '';

                    field.validators.forEach((v: any) => {
                        const regex = RegExp(v.pattern);
                        if (!regex.test(value)) {
                            valid = false;
                            message = v.message;
                        }
                    });

                    if (!valid) {
                        return Promise.reject(message);
                    }

                    return Promise.resolve();
                };

                const validationRules: any[] = field.rules || [];

                if (typeof field.label === 'string') {
                    validationRules.push({
                        required: field.required,
                        message: `${field.label} is required.`,
                    });
                } else if (field.validationName) {
                    validationRules.push({
                        required: field.required,
                        message: `${field.validationName} is required.`,
                    });
                }

                if (field.maxLength) {
                    validationRules.push({
                        max: field.maxLength,
                        message: `Up to ${field.maxLength} characters in length.`,
                    });
                }

                if (field.minLength) {
                    validationRules.push({
                        min: field.minLength,
                        message: `At least ${field.minLength} characters in length.`,
                    });
                }

                if (field.validators) {
                    validationRules.push({ validator: checkPatterns });
                }

                if (field.type === 'cidr') {
                    validationRules.push({ validator: checkCidr });
                    validationRules.push({ validator: checkReservedIp });
                }

                if (field.type === 'ip') {
                    validationRules.push({ validator: checkIp });
                }

                if (
                    field.type === 'number' &&
                    field.name[0] === 'expirationTime'
                ) {
                    validationRules.push({
                        validator: checkTimeDuration,
                    });
                }

                return (
                    <Form.Item
                        label={
                            field.type === 'radioGroup' &&
                            field.layout !== 'vertical' ? null : (
                                <>
                                    {field.label}
                                    {field.tips ? (
                                        <Tooltip title={field.tips}>
                                            <div
                                                className={
                                                    styles.AppFormTipIcon
                                                }
                                            >
                                                <CcxIconInfoCircleOutlined />
                                            </div>
                                        </Tooltip>
                                    ) : null}
                                </>
                            )
                        }
                        validateStatus={validateStatus as any}
                        key={field.name}
                        required={field.required}
                        help={helpText}
                        className={field.groupBy ? styles.GroupForm : ''}
                        extra={field.extra}
                    >
                        <Form.Item
                            name={field.name}
                            rules={validationRules}
                            noStyle
                            shouldUpdate={(
                                prevValues: any,
                                currentValues: any
                            ) => prevValues !== currentValues}
                            initialValue={
                                field.defaultValue ? field.defaultValue : null
                            }
                        >
                            {getFormComponent(field)}
                        </Form.Item>
                    </Form.Item>
                );
            })}
        </div>
    );
}

export default AppForm;
