export type SlowQueriesBackendApiProps = {
    created: number;
    slow_queries: number;
};

export default class SlowQueries {
    readonly created: number;
    readonly slowQueries: number;

    constructor(props: SlowQueriesBackendApiProps) {
        this.created = props.created;
        this.slowQueries = props.slow_queries;
    }
}
