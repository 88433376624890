import styles from './StretchedClick.module.less';

export type StretchedClickProps = {
    handleClick?: (...args: any[]) => void;
};

const StretchedClick = ({ handleClick }: StretchedClickProps) => {
    return <div className={styles.StretchedClick} onClick={handleClick}></div>;
};

export default StretchedClick;
