export type PrometheusHaProxySessionsStatsBackendApiProps = {
    created: number;
    haproxy_frontend_current_sessions: number;
    haproxy_frontend_current_session_rate: number;
    haproxy_backend_current_sessions: number;
    haproxy_backend_current_session_rate: number;
};

export default class PrometheusHaProxySessionsStats {
    readonly created: number;
    readonly haproxyFrontendCurrentSessions: number;
    readonly haproxyFrontendCurrentSessionRate: number;
    readonly haproxyBackendCurrentSessions: number;
    readonly haproxyBackendCurrentSessionRate: number;

    constructor(props: PrometheusHaProxySessionsStatsBackendApiProps) {
        this.created = props.created;
        this.haproxyFrontendCurrentSessions =
            props.haproxy_frontend_current_sessions;
        this.haproxyFrontendCurrentSessionRate =
            props.haproxy_frontend_current_session_rate;
        this.haproxyBackendCurrentSessions =
            props.haproxy_backend_current_sessions;
        this.haproxyBackendCurrentSessionRate =
            props.haproxy_backend_current_session_rate;
    }
}
