export type QueryProcessClassProps = {
    user: string;
    instance: string;
    client: string;
    query: string;
    state: string;
    query_time: number;
};

export interface QueryProcessInterface {
    user: string;
    instance: string;
    client: string;
    query: string;
    state: string;
    queryTime: number;
    uniqueKey: string; // this is used for the Table component, a unique key for each value
}

export default class QueryProcess implements QueryProcessInterface {
    readonly user: string;
    readonly instance: string;
    readonly client: string;
    readonly query: string;
    readonly state: string;
    readonly queryTime: number;
    readonly uniqueKey: string;

    constructor(props: QueryProcessClassProps) {
        this.instance = props.instance;
        this.user = props.user;
        this.client = props.client;
        this.query = props.query;
        this.state = props.state;
        this.queryTime = props.query_time;
        this.uniqueKey = `${props.user}-${props.query_time}`;
    }
}
