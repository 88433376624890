import { useEffect, useState } from 'react';
import VpcService from '../../services/VpcService';
import Vpc from '../../types/Vpc';

export default function useVpc(
    cloud?: string,
    region?: string,
    vpcUuid?: string
) {
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [vpc, setVpc] = useState<Vpc | undefined>(undefined);

    const load = async () => {
        if (cloud && region && vpcUuid) {
            try {
                const details = await VpcService.getVpc({
                    cloud,
                    region,
                    vpcUuid,
                });
                setVpc(details);
                setError(null);
            } catch (e: any) {
                setError(e);
            }
        }
    };

    const refresh = () => {
        setUpdating(true);
        load();
        setUpdating(false);
    };

    // get deployments for the given user
    useEffect(() => {
        const init = async () => {
            setLoading(true);
            await load();
            setLoading(false);
        };

        init();
    }, [cloud, region, vpcUuid]);

    return { vpc, refresh, loading, updating, error };
}
