export type DbDatabaseClassProps = {
    // acl: string;
    // class_name: string;
    // cluster_id: number;
    // data_directory: string;
    // database_id: number;
    database_name: string;
    database_size: number;
    // dirty: boolean;
    number_of_tables: number;
    // owner_group_id: number;
    // owner_group_name: string;
    // owner_user_id: number;
    // owner_user_name: string;
    // version: number;
    status: string;
};

export interface DbDatabaseInterface {
    // acl: string;
    // className: string;
    // clusterId: number;
    // dataDirectory: string;
    // databaseId: number;
    databaseName: string;
    databaseSize: number;
    // dirty: boolean;
    numberOfTables: number;
    // ownerGroupId: number;
    // ownerGroupName: string;
    // ownerUserId: number;
    // ownerUserName: string;
    // version: number;
    uniqueKey: string; // this is used for the Table component, a unique key for each value
    status: string;
}

export const DEFAULT_DATABASE_NAME = 'ccxdb';
export const DELETING_STATUS = 'deleted';

export default class DbDatabase implements DbDatabaseInterface {
    // readonly acl: string;
    // readonly className: string;
    // readonly clusterId: number;
    // readonly dataDirectory: string;
    // readonly databaseId: number;
    readonly databaseName: string;
    readonly databaseSize: number;
    // readonly dirty: boolean;
    readonly numberOfTables: number;
    // readonly ownerGroupId: number;
    // readonly ownerGroupName: string;
    // readonly ownerUserId: number;
    // readonly ownerUserName: string;
    // readonly version: number;
    readonly uniqueKey: string;
    readonly status: string;

    constructor(props: DbDatabaseClassProps) {
        // this.acl = props.acl;
        // this.className = props.class_name;
        // this.clusterId = props.cluster_id;
        // this.dataDirectory = props.data_directory;
        // this.databaseId = props.database_id;
        this.databaseName = props.database_name;
        this.databaseSize = props.database_size;
        // this.dirty = props.dirty;
        this.numberOfTables = props.number_of_tables;
        // this.ownerGroupId = props.owner_group_id;
        // this.ownerGroupName = props.owner_group_name;
        // this.ownerUserId = props.owner_user_id;
        // this.ownerUserName = props.owner_user_name;
        // this.version = props.version;
        this.uniqueKey = `${props.database_name}-${props.database_size}`;
        this.status = props.status;
    }

    isDefaultDatabase(): boolean {
        return this.databaseName === DEFAULT_DATABASE_NAME;
    }

    isDeleting(): boolean {
        return this.status === DELETING_STATUS;
    }
}
