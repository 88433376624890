import BillingAddress, { BillingAddressClassProps } from './BillingAddress';
import BillingUserData, { BillingUserDataClassProps } from './BillingUserData';

export type SubscriptionBillingUserClassProps = {
    created_at: string;
    stripe_customer_id: string;
    stripe_subscription_id: string;
    updated_at: string;
    user_data: BillingUserDataClassProps;
    address_data: BillingAddressClassProps;
    user_id: string;
    uuid: string;
};

export interface SubscriptionBillingUserInterface {
    createdAt: string;
    stripeCustomerId: string;
    stripeSubscriptionId: string;
    updatedAt: string;
    userData?: BillingUserData;
    addressData?: BillingAddress;
    userId: string;
    uuid: string;
}

export default class SubscriptionBillingUser
    implements SubscriptionBillingUserInterface
{
    readonly createdAt: string;
    readonly stripeCustomerId: string;
    readonly stripeSubscriptionId: string;
    readonly updatedAt: string;
    readonly userData?: BillingUserData;
    readonly addressData?: BillingAddress;
    readonly userId: string;
    readonly uuid: string;

    constructor(props: SubscriptionBillingUserClassProps) {
        this.createdAt = props.created_at;
        this.stripeCustomerId = props.stripe_customer_id;
        this.stripeSubscriptionId = props.stripe_subscription_id;
        this.updatedAt = props.updated_at;
        this.userData = props.user_data
            ? new BillingUserData(props.user_data)
            : undefined;
        this.addressData = props.address_data
            ? new BillingAddress(props.address_data)
            : undefined;
        this.userId = props.user_id;
        this.uuid = props.uuid;
    }

    getBillingAddress(): BillingAddress | undefined {
        return this.addressData;
    }

    getUserData(): BillingUserData | undefined {
        return this.userData;
    }
}
