export type PrometheusRedisMemoryUsedStatsBackendApiProps = {
    created: number;
    memory_used: number;
};

export default class PrometheusRedisMemoryUsedStats {
    readonly created: number;
    readonly memoryUsed: number;

    constructor(props: PrometheusRedisMemoryUsedStatsBackendApiProps) {
        this.created = props.created;
        this.memoryUsed = props.memory_used;
    }
}
