import {
    Row,
    Col,
    Alert,
    Switch,
    Button,
    Typography,
    DatePicker,
    Form,
    notification,
    Modal,
    TimePicker,
} from 'antd';
import numbro from 'numbro';
import React, { useEffect, useState } from 'react';
import CcxComponentProps from '../../core/CcxComponent';
import Backup from '../../types/Backup';
import BackupColumns from './BackupColumns';

import styles from './BackupsModal.module.less';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import moment from 'moment';
import CcxIconInfoCircleTwoTone from '../ccx/icons/CcxIconInfoCircleTwoTone';
import BackupService from '../../services/BackupService';
import CcxIconCloseCircleTwoTone from '../ccx/icons/CcxIconCloseCircleTwoTone';

dayjs.extend(utc);

type onCloseCallback = (submitted: boolean) => void;

interface Props extends CcxComponentProps {
    visible?: boolean;
    currentBackup?: Backup;
    dataStore?: any;
    onclose: onCloseCallback;
}

function BackupsModal({
    testId = 'BackupsModal',
    visible = false,
    dataStore,
    currentBackup,
    onclose,
}: Props) {
    const [point, setPoint] = useState<boolean>(false);
    const [formattedDate, setFormattedDate] = useState<string>();
    const [pointDate, setPointDate] = useState<any>();
    const [pointTime, setPointTime] = useState<any>();
    const [pointInTime, setPointInTime] = useState<any>();
    const [pickerValue, setPickerValue] = useState<any>();
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
    const [disabledHourRange, setDisabledHourRange] = useState<any>([]);
    const [disabledMinutesRange, setDisabledMinutesRange] = useState<any>([]);

    const format = 'DD/MM/YYYY HH:mm UTC';

    const [form] = Form.useForm();

    const { Text } = Typography;

    const formatSize = (size: any) => {
        return numbro(size).format({
            output: 'byte',
            base: 'binary',
            spaceSeparated: true,
            mantissa: 2,
        });
    };

    const handleSaveClick = () => {
        if (currentBackup) {
            return new Promise(async (resolve, reject) => {
                try {
                    const result = await BackupService.restoreBackup(
                        dataStore,
                        currentBackup?.backupId,
                        point ? pointInTime?.toISOString() : null
                    );

                    notification.open({
                        message: 'Restore Backup',
                        description: 'The backup will be restored soon.',
                        icon: <CcxIconInfoCircleTwoTone />,
                    });

                    Modal.destroyAll();

                    onclose && onclose(true);

                    resolve(null);
                } catch (e) {
                    notification.open({
                        message: 'Restore Backup',
                        description: `There was an error restoring the backup. ${e}`,
                        icon: (
                            <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />
                        ),
                    });

                    console.error(e);

                    reject();
                }
            }).catch(() => console.log('Oops errors!'));
        }
    };

    useEffect(() => {
        if (currentBackup && currentBackup?.startedAt) {
            setFormattedDate(
                dayjs.utc(currentBackup?.startedAt).format(format)
            );
            if (point) {
                setPickerValue(moment.utc(currentBackup?.endedAt));
            }
        }
    }, [currentBackup, point, pointTime]);

    useEffect(() => {
        if (point) {
            if (pointTime && pointDate) {
                setPointInTime(
                    moment.utc(
                        `${pointDate.format('YYYY-MM-DD')} ${pointTime.format(
                            'HH:mm:ss'
                        )}`
                    )
                );
                setButtonDisabled(false);
            } else {
                setButtonDisabled(true);
            }
        } else {
            setButtonDisabled(false);
        }
    }, [point, pointDate, pointTime]);

    const disabledArray = (end: number) => {
        return Array(end - 0)
            .fill(null)
            .map((_, idx) => 0 + idx);
    };

    // Generates an array of disabled hours based on current and end hours.
    const disabledHours = (currentHour: number, endHour: number) => {
        return Array.from({ length: 24 }, (v, i) =>
            i > currentHour || i < endHour ? i : -1
        );
    };

    const onSetPointDate = (date: any, dateString: string) => {
        setPointDate(date);
        const currentDate = moment();

        const end = parseInt(
            moment.utc(currentBackup?.endedAt).format('HH'),
            10
        );
        if (
            date?.format('DD/MM/YYYY') === currentDate.format('DD/MM/YYYY') &&
            date?.format('DD/MM/YYYY') ===
                moment.utc(currentBackup?.endedAt).format('DD/MM/YYYY')
        ) {
            // Disbale hours before backup and after current time
            const currentUtcTime = moment.utc();
            const currentHour = currentUtcTime.hour();
            setDisabledHourRange(() => disabledHours(currentHour, end));
        } else {
            // Disable previous hours from backup
            setDisabledHourRange(disabledArray(end));
        }
    };

    useEffect(() => {
        if (!visible) {
            setPointDate(null);
            form.setFieldsValue({
                pointDate: null,
            });
        }
    }, [form, visible]);

    useEffect(() => {
        if (pointDate) {
            const currentDate = moment();
            const isAfter = moment.utc(pointDate).isAfter(currentDate);
            if (isAfter) {
                setDisabledHourRange([]);
                setDisabledMinutesRange([]);
            }
            if (
                pointDate?.format('DD/MM/YYYY') ===
                moment.utc(currentBackup?.endedAt).format('DD/MM/YYYY')
            ) {
                const end = parseInt(
                    moment.utc(currentBackup?.endedAt).format('mm'),
                    10
                );

                return setDisabledMinutesRange(disabledArray(end + 1));
            }
        }
    }, [currentBackup?.endedAt, pointDate]);

    const onSetPointTime = (time: any, dateString: string) => {
        form.setFieldsValue({ pointTime: time ? moment.utc(time) : null });
        setPointTime(moment.utc(time));
    };

    const onTimeSelection = (time: any) => {
        const selectedHour = parseInt(moment(time).format('HH'), 10);
        const endHour = parseInt(
            moment.utc(currentBackup?.endedAt).format('HH'),
            10
        );
        const endMinute = parseInt(
            moment.utc(currentBackup?.endedAt).format('mm'),
            10
        );
        const currentUtcTime = moment.utc();
        const currentHour = currentUtcTime.hour();
        const currentMinute = currentUtcTime.minute();

        if (selectedHour > endHour && selectedHour !== currentHour) {
            return setDisabledMinutesRange([]);
        } else if (selectedHour === currentHour) {
            const disabledMinutes: number[] = Array.from(
                { length: 60 },
                (v, i) => (i > currentMinute ? i : -1)
            ); // Generates an array of disabled minutes based on the current minute.

            setDisabledMinutesRange(disabledMinutes);
            setDisabledHourRange(() => disabledHours(currentHour, endHour));
            return;
        } else {
            return setDisabledMinutesRange(disabledArray(endMinute + 1));
        }
    };

    const disabledDate = (current: any) => {
        const endedAt = moment.utc(currentBackup?.endedAt);
        const currentDate = moment.utc();

        return (
            current &&
            !(
                current.isSameOrAfter(endedAt, 'day') &&
                current.isSameOrBefore(currentDate, 'day')
            ) // Disable all dates except from endedAt to currentDate
        );
    };

    const formattedTime = moment.utc(pointTime).format('HH:mm:ss');

    return (
        <Modal
            title={'Restore a backup'}
            open={visible}
            data-testid={`${testId}Modal`}
            width={600}
            onCancel={() => onclose(false)}
            footer={
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Alert
                            message="You are going to restore a backup"
                            description={
                                <span style={{ textAlign: 'start' }}>
                                    You are about to restore a backup created on{' '}
                                    <strong>{formattedDate}</strong>. This
                                    process will completely overwrite your
                                    current data and all changes since your last
                                    backup will be lost.
                                </span>
                            }
                            style={{ textAlign: 'start' }}
                            type="warning"
                            showIcon
                        />
                    </Col>
                    <Col span={16}>
                        {point && (
                            <>
                                <Text type="danger">*</Text>Required
                            </>
                        )}
                    </Col>
                    <Col>
                        <Button
                            data-testid={`${testId}CancelButton`}
                            onClick={() => onclose(false)}
                        >
                            Cancel
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            onClick={handleSaveClick}
                            data-testid={`${testId}SubmitButton`}
                            type="primary"
                            disabled={buttonDisabled}
                        >
                            Restore
                        </Button>
                    </Col>
                </Row>
            }
        >
            <h4 data-testid={`${testId}TitleInfo`}>
                <strong>Backup info</strong>
            </h4>

            <Row gutter={24}>
                <Col>
                    <BackupColumns
                        title="Backup ID"
                        subTitle={currentBackup?.backupId}
                    />
                </Col>
                <Col>
                    <BackupColumns
                        title="Type"
                        subTitle={currentBackup?.backupType}
                    />
                </Col>
                <Col>
                    <BackupColumns
                        title="Size"
                        last={true}
                        subTitle={formatSize(currentBackup?.size)}
                    />
                </Col>
            </Row>

            <h4
                data-testid={`${testId}TitleSettings`}
                className={styles.BackupsModalSettings}
            >
                <strong>Restore settings</strong>
            </h4>

            <Form form={form} layout="vertical">
                <Row gutter={[24, 8]}>
                    <Col className={styles.BackupsModalSwitchText}>
                        Use point in time recovery
                    </Col>
                    <Col>
                        <Form.Item noStyle>
                            <Form.Item name="point">
                                <Switch onChange={setPoint} />
                            </Form.Item>
                        </Form.Item>
                    </Col>
                </Row>
                {point && (
                    <Row gutter={[24, 16]}>
                        <Col span={24}>
                            Choose a specific point in time you want to go back
                            to.
                        </Col>
                        <Col span={8}>
                            <Form.Item noStyle>
                                <Form.Item
                                    name="pointDate"
                                    label="Date"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select a date',
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        format={`DD/MM/YYYY`}
                                        className={
                                            styles.BackupsModalDatePicker
                                        }
                                        onChange={onSetPointDate}
                                        disabledDate={disabledDate}
                                    />
                                </Form.Item>
                            </Form.Item>
                        </Col>
                        {pointDate && (
                            <Col span={6}>
                                <Form.Item noStyle>
                                    <Form.Item
                                        name="pointTime"
                                        label="Time (UTC)"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select a time',
                                            },
                                        ]}
                                    >
                                        <TimePicker
                                            format={`HH:mm:ss`}
                                            className={
                                                styles.BackupsModalDatePicker
                                            }
                                            onChange={onSetPointTime}
                                            disabledHours={() =>
                                                disabledHourRange
                                            }
                                            disabledMinutes={() =>
                                                disabledMinutesRange
                                            }
                                            onSelect={(t) => onTimeSelection(t)}
                                            value={moment(
                                                formattedTime,
                                                'HH:mm:ss'
                                            )}
                                        />
                                    </Form.Item>
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                )}
            </Form>
        </Modal>
    );
}

export default BackupsModal;
