import { useEffect, useState } from 'react';
import StatService from '../../services/StatService';
import CmonDiskStat from '../../types/CmonDiskStat';

export default function useDiskStatsCompare(
    uuid: string,
    nodes?: any[],
    from?: string,
    to?: string
) {
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [stats, setStats] = useState<any[] | undefined>(undefined);

    const load = async () => {
        if (uuid && nodes && nodes.length > 0) {
            try {
                const details = await Promise.all(
                    nodes.map((n: string) => {
                        const [host, port] = n.split(':');
                        return StatService.getDiskStats(
                            uuid,
                            host,
                            parseInt(port, 10),
                            from,
                            to
                        );
                    })
                );
                setStats(
                    details.map((d: any, i: number) => {
                        return d.map((dd: any) => new CmonDiskStat(dd));
                    })
                );
                setError(null);
            } catch (e: any) {
                setError(e);
            }
        }
    };

    const refresh = () => {
        setUpdating(true);
        load();
        setUpdating(false);
    };

    // get deployments for the given user
    useEffect(() => {
        const init = async () => {
            setLoading(true);
            await load();
            setLoading(false);
        };

        init();
    }, [uuid, nodes, from, to]);

    return { stats, refresh, loading, updating, error };
}
