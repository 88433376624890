import React, { ReactElement, useEffect, useState } from 'react';
import AppEmpty from '../../AppEmpty';
import styles from './HostCompareDashboard.module.less';
import { Col, Row } from 'antd';
import useSqlStatsCompare from '../../../core/hooks/useSqlStatsCompare';
import PostgreSqlConnectionsChart from '../../ccx/charts/PostgreSqlConnectionsChart';
import PostgreSqlCommitsChart from '../../ccx/charts/PostgreSqlCommitsChart';
import PostgreSqlRollbacksChart from '../../ccx/charts/PostgreSqlRollbacksChart';
import PostgreSqlRowsDeletedChart from '../../ccx/charts/PostgreSqlRowsDeletedChart';
import PostgreSqlRowsFetchedChart from '../../ccx/charts/PostgreSqlRowsFetchedChart';
import PostgreSqlRowsInsertedChart from '../../ccx/charts/PostgreSqlRowsInsertedChart';
import PostgreSqlRowsUpdatedChart from '../../ccx/charts/PostgreSqlRowsUpdatedChart';

interface Props {
    uuid: string;
    from?: string;
    to?: string;
    interval: number;
    hidden?: boolean;
    displaySummary?: boolean;
    selectedNodes: any[];
}

function PostgreSqlDbCompareDashboard({
    uuid,
    from,
    to,
    interval,
    hidden,
    displaySummary,
    selectedNodes,
}: Props): ReactElement {
    const {
        stats: sqlStats,
        refresh: sqlStatsRefresh,
        loading: sqlStatsLoading,
    } = useSqlStatsCompare(uuid, selectedNodes, from, to);
    const [sqlInterval, setSqlInterval] = useState<number | undefined>(
        undefined
    );
    const [refreshInterval, setRefreshInterval] = useState(interval);

    useEffect(() => {
        let interval: any;
        if (sqlStats && refreshInterval > 0) {
            interval = setInterval(() => {
                sqlStatsRefresh();
            }, refreshInterval);
            setSqlInterval(interval);
        }
        return () => clearInterval(interval);
    }, [sqlStats, refreshInterval]);

    useEffect(() => {
        if (interval === 0) {
            // when interval is 0, we must pause the refresh (clear existing intervals)
            clearInterval(sqlInterval);
            setSqlInterval(0);
        }
        setRefreshInterval(interval);
    }, [interval]);

    useEffect(() => {
        if (hidden) {
            // clear interval to stop updating data if the tab is not active (charts are not visible)
            clearInterval(sqlInterval);
            setSqlInterval(0);
        } else if (sqlInterval === 0) {
            // force refresh if the interval is 0, otherwise, the interval will refresh data soon
            // interval is undefined on the first load
            // interval will be 0 when paused or tab is hidden
            sqlStatsRefresh();
        }
    }, [hidden]);

    return (
        <section className={styles.PostgreSqlDbCompareDashboard}>
            {selectedNodes.length > 0 ? (
                <Row
                    gutter={[
                        { xs: 8, sm: 16, md: 24, lg: 32, xl: 40, xxl: 48 },
                        { xs: 8, sm: 16, md: 24, lg: 32, xl: 40, xxl: 48 },
                    ]}
                >
                    {selectedNodes.map((n: any, idx: number) => (
                        <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} key={n}>
                            <h1>{n}</h1>

                            <Row
                                gutter={[
                                    {
                                        xs: 8,
                                        sm: 16,
                                        md: 24,
                                        lg: 32,
                                        xl: 40,
                                        xxl: 48,
                                    },
                                    {
                                        xs: 8,
                                        sm: 16,
                                        md: 24,
                                        lg: 32,
                                        xl: 40,
                                        xxl: 48,
                                    },
                                ]}
                                className={
                                    styles.PostgreSqlDbCompareDashboardRow
                                }
                            >
                                <Col
                                    className={
                                        styles.PostgreSqlDbCompareDashboardCol
                                    }
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                >
                                    <PostgreSqlConnectionsChart
                                        data={sqlStats ? sqlStats[idx] : []}
                                        loading={sqlStatsLoading}
                                        displaySummary={displaySummary}
                                    />
                                </Col>
                                <Col
                                    className={
                                        styles.PostgreSqlDbCompareDashboardCol
                                    }
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                >
                                    <PostgreSqlCommitsChart
                                        data={sqlStats ? sqlStats[idx] : []}
                                        loading={sqlStatsLoading}
                                        displaySummary={displaySummary}
                                    />
                                </Col>
                                <Col
                                    className={
                                        styles.PostgreSqlDbCompareDashboardCol
                                    }
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                >
                                    <PostgreSqlRollbacksChart
                                        data={sqlStats ? sqlStats[idx] : []}
                                        loading={sqlStatsLoading}
                                        displaySummary={displaySummary}
                                    />
                                </Col>
                                <Col
                                    className={
                                        styles.PostgreSqlDbCompareDashboardCol
                                    }
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                >
                                    <PostgreSqlRowsDeletedChart
                                        data={sqlStats ? sqlStats[idx] : []}
                                        loading={sqlStatsLoading}
                                        displaySummary={displaySummary}
                                    />
                                </Col>
                                <Col
                                    className={
                                        styles.PostgreSqlDbCompareDashboardCol
                                    }
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                >
                                    <PostgreSqlRowsFetchedChart
                                        data={sqlStats ? sqlStats[idx] : []}
                                        loading={sqlStatsLoading}
                                        displaySummary={displaySummary}
                                    />
                                </Col>
                                <Col
                                    className={
                                        styles.PostgreSqlDbCompareDashboardCol
                                    }
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                >
                                    <PostgreSqlRowsInsertedChart
                                        data={sqlStats ? sqlStats[idx] : []}
                                        loading={sqlStatsLoading}
                                        displaySummary={displaySummary}
                                    />
                                </Col>
                                <Col
                                    className={
                                        styles.PostgreSqlDbCompareDashboardCol
                                    }
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                >
                                    <PostgreSqlRowsUpdatedChart
                                        data={sqlStats ? sqlStats[idx] : []}
                                        loading={sqlStatsLoading}
                                        displaySummary={displaySummary}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    ))}
                </Row>
            ) : (
                <AppEmpty message="No Database Nodes selected yet." />
            )}
        </section>
    );
}

export default PostgreSqlDbCompareDashboard;
