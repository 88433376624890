import reducer, { initialState } from './core/providers/reducer';
import React, { useEffect } from 'react';
import {
    AppFaviconUrl,
    billingDisabled,
    GLOBAL_REDIRECT_URL,
    WRONG_GLOBAL_REDIRECT_URL,
} from './core/CcxEnv';
import { StateProvider } from './core/providers/StateProvider';
import { VpcsProvider } from './core/providers/VpcsProvider';
import { ChartsProvider } from './core/providers/ChartsProvider';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { checkUserSubscription, userCheck } from './slices/user.slice';

type AppProvidersProps = {
    children: React.ReactNode;
};
export default function AppProviders({ children }: AppProvidersProps) {
    // setting favicons
    let icons: any = [];
    if (document) {
        icons = document.querySelectorAll(
            'link[rel=icon],[rel=apple-touch-icon]'
        );
    }

    if (icons.length) {
        icons.forEach(function (i: any) {
            i.href = AppFaviconUrl;
        });
    }

    // redirect for if users on lintasarta try to access dbaas.cloudeka.id instead of cmd.cloudeka.id
    if (WRONG_GLOBAL_REDIRECT_URL && GLOBAL_REDIRECT_URL) {
        const wrongUrl =
            window.location !== window.parent.location
                ? document.referrer
                : document.location.href;

        if (wrongUrl.startsWith(WRONG_GLOBAL_REDIRECT_URL)) {
            window.parent.location.href = GLOBAL_REDIRECT_URL;
        }
    }

    const dispatch = useAppDispatch();

    const { user } = useAppSelector((state) => state.user);

    useEffect(() => {
        dispatch(userCheck());
        if (!billingDisabled) {
            dispatch(checkUserSubscription());
        }
    }, [dispatch]);

    useEffect(() => {
        const AUTO_REFRESH_INTERVAL = 60000;
        const interval = setInterval(async () => {
            if (!billingDisabled) {
                dispatch(checkUserSubscription());
            }
        }, AUTO_REFRESH_INTERVAL);

        return () => clearInterval(interval);
    }, [dispatch]);

    return user ? (
        <StateProvider initialState={initialState} reducer={reducer}>
            <VpcsProvider>
                <ChartsProvider>{children}</ChartsProvider>
            </VpcsProvider>
        </StateProvider>
    ) : (
        <></>
    );
}
