import { useEffect, useState } from 'react';
import IpifyService from '../../services/IpifyService';

export default function useIPAddress() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [ipAddress, setIPAddress] = useState<any | null>(null);

    const fetchIpAddress = async () => {
        try {
            const { ip } = await IpifyService.getIPAddress();
            setIPAddress(ip);
        } catch (error: any) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setLoading(true);
        fetchIpAddress();
    }, []);

    return { ipAddress, loading, error };
}
