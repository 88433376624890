import React, { ReactElement, useEffect, useState } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import CmonMemoryStat from '../../../types/CmonMemoryStat';
import AppChart from '../../AppChart';

interface Props extends CcxComponentProps {
    data?: CmonMemoryStat[];
    loading: boolean;
    displaySummary?: boolean;
    height?: number;
    width?: number;
}

function RamUtilizationChart({
    data,
    loading,
    displaySummary,
    testId = 'RamUtilizationChart',
    width,
    height = 250,
}: Props): ReactElement {
    const [chartData, setChartData] = useState(0);
    const [summary, setSummary] = useState([]);

    useEffect(() => {
        if (data) {
            const reducer = (accumulator: number, current: CmonMemoryStat) => {
                return accumulator + current.memoryUtilization;
            };
            const avg = data.reduce(reducer, 0) / data.length;
            setChartData(avg);
        }
    }, [data]);

    const chartConfig = {
        title: {
            visible: true,
            text: 'RAM utilization',
        },
        description: {
            visible: true,
            text: '',
        },
        min: 0,
        max: 1,
        percent: chartData,
        height,
        width,
        autoFit: !width && !height,
        animation: false,
        statistic: {
            formatter: (value: number) => (100 * value).toFixed(1) + '%',
        },
    };

    return (
        <AppChart
            type="liquid"
            config={chartConfig}
            summary={summary}
            loading={loading}
            displaySummary={displaySummary}
            testId={testId}
        />
    );
}

export default RamUtilizationChart;
