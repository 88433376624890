import { useEffect, useState } from 'react';
import Deployment from '../../types/Deployment';
import QueryMonitorService from '../../services/QueryMonitorService';

export default function useQueryProcesses(uuid: string | undefined) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [queryProcesses, setQueryProcesses] = useState<
        Deployment | undefined | any
    >(undefined);

    const load = async () => {
        if (uuid) {
            setLoading(true);

            try {
                const details = await QueryMonitorService.getQueryProcesses(
                    uuid
                );
                setQueryProcesses(details);
                setLoading(false);
                setError(null);
            } catch (e: any) {
                setLoading(false);
                setError(e);
            }

            setLoading(false);
        }
    };

    const refresh = () => {
        load();
    };

    // get deployments for the given user
    useEffect(() => {
        refresh();
    }, [uuid]);

    return { queryProcesses, refresh, loading, error };
}
