import React, { ReactElement } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import styles from './ChangePaymentMethod.module.less';
import { CreditCardOutlined, CustomerServiceOutlined } from '@ant-design/icons';
import PaymentMethodModal from '../PaymentMethodModal';
import AppSupportLink from '../../ccx/common/AppSupportLink';

interface Props extends CcxComponentProps {}

function ChangePaymentMethod({
    testId = 'ChangePaymentMethod',
}: Props): ReactElement {
    return (
        <div className={styles.ChangePaymentMethod} data-testid={testId}>
            <div data-testid={`${testId}Top`}>
                <PaymentMethodModal
                    icon={<CreditCardOutlined />}
                    label="Change payment method"
                />
            </div>
            <div data-testid={`${testId}Middle`}>
                <AppSupportLink>
                    <>
                        <CustomerServiceOutlined />
                        {` `}Contact support
                    </>
                </AppSupportLink>
            </div>
        </div>
    );
}

export default ChangePaymentMethod;
