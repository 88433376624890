import React from 'react';
import DeploymentsItem from '../../../../types/DeploymentsItem';
import Service from '../../../../types/Service';
import useDatastoreServiceDsn from './useDatastoreServiceDsn';
import CopyToClipboardText from '../../../CopyToClipboardText';

export default ServiceDSNCopyButton;
export type ServiceDSNCopyButtonProps = {
    dataStore?: DeploymentsItem;
    service?: Service;
    type?: string;
    buttonProps?: any;
};

function ServiceDSNCopyButton({
    dataStore,
    service,
    type = 'db',
    buttonProps,
    ...rest
}: ServiceDSNCopyButtonProps) {
    const { dsn, username, database, hostPort, dbType } =
        useDatastoreServiceDsn({ dataStore, service, type });

    const tooltip = `${dbType}://${username}@${hostPort}/${database}`;

    return (
        <CopyToClipboardText
            text={dsn}
            tooltip={tooltip}
            buttonProps={{
                children: 'DSN',
                textSize: 'small',
                ...buttonProps,
            }}
            hoverable={false}
            nowrap={true}
            showPreview={false}
            successMessage={'DSN copied to clipboard'}
        />
    );
}
