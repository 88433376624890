import DeploymentsItem from '../../../../types/DeploymentsItem';
import Service from '../../../../types/Service';
import { useMemo } from 'react';
import DsnService from '../../../../services/DsnService';

export type UseDatastoreServiceDsnProps = {
    dataStore?: DeploymentsItem;
    service?: Service;
    type?: string;
};
export default function useDatastoreServiceDsn({
    dataStore,
    service,
    type,
}: UseDatastoreServiceDsnProps) {
    return useMemo(() => {
        let dbType = '';
        const username =
            dataStore?.getDbAccount().getUsername() || '{your_username}';
        const password =
            dataStore?.getDbAccount().getPassword() || '{your_password}';
        const database =
            dataStore?.getDbAccount().getDatabase() || '{your_database}';
        if (dataStore?.isMariaDb() || dataStore?.isPercona()) {
            dbType = 'mysql';
        } else if (dataStore?.isPostgreSql()) {
            dbType = 'postgres';
        } else if (dataStore?.isRedis()) {
            dbType = 'rediss';
        } else if (dataStore?.isMSSQL()) {
            dbType = 'mssql';
        }
        const hostPort =
            (type === 'db' ? service?.getFQDN() : service?.getFQDN(true)) ||
            '{database_host:port}';

        const dsn = DsnService.getDsn({
            service: dbType,
            username,
            password,
            database,
            hostPort,
            port: `${service?.getPort()}`,
        });
        return {
            dsn,
            username,
            password,
            database,
            dbType,
            hostPort,
        };
    }, [dataStore, service, type]);
}
