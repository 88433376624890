import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { ReactElement } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';

import AppForm from '../../ccx/common/AppForm';
import styles from './BillingForm.module.less';

interface Props extends CcxComponentProps {
    fields: any[];
    form: FormInstance;
    values?: any;
    errorFields: any[];
    children?: any;
}

export default function BillingForm({
    testId = 'BillingForm',
    fields,
    form,
    values,
    errorFields,
    children,
}: Props): ReactElement {
    return (
        <Form
            form={form}
            data-testid={testId}
            layout={'vertical'}
            initialValues={values ? { ...values } : null}
        >
            <AppForm fields={fields} errorFields={errorFields} useGrid={true} />
            <div className={styles.BillingFormChildren}>{children}</div>
        </Form>
    );
}
