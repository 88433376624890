import React, { ReactElement } from 'react';
import AppCdnIcon from '../common/AppCdnIcon';

interface Props {
    height: number;
    width: number;
}

function CcxIcon1NodeDeployment({ height, width }: Props): ReactElement {
    return (
        <AppCdnIcon
            height={height}
            icon="ccx-1-node-deployment"
            size=""
            testId="AppDeploymentNodesIcon1"
            width={width}
        />
    );
}

export default CcxIcon1NodeDeployment;
