import React, { ReactElement, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import CcxComponentProps from '../../../../core/CcxComponent';
import DeploymentsItem from '../../../../types/DeploymentsItem';
import CcxLogo from '../../../ccx/common/CcxLogo';
import CcxIcon1NodeDeployment from '../../../ccx/icons/CcxIcon1NodeDeployment';
import LazyLoader from '../../../LazyLoader';
import DataStoreStatus from '../DataStoreStatus';
import styles from './DataStoreOverviewGeneralInformation.module.less';
import SmallContainer from '../../../ccx/common/SmallContainer';
import AppFormTags from '../../../ccx/common/AppFormTags';
import CopyToClipboardText from '../../../CopyToClipboardText';

interface Props extends CcxComponentProps {
    dataStore?: DeploymentsItem;
}

function DataStoreOverviewGeneralInformation({
    dataStore,
    testId = 'DataStoreOverviewGeneralInformation',
}: Props): ReactElement {
    const [cards, setCards] = useState<any>([]);

    useEffect(() => {
        if (dataStore) {
            setCards([
                {
                    label: (
                        <div
                            className={
                                styles.DataStoreOverviewGeneralInformationLabel
                            }
                        >
                            Name and ID
                        </div>
                    ),
                    testId: `${testId}CardName`,
                    content: (
                        <div
                            className={
                                styles.DataStoreOverviewGeneralInformationName
                            }
                        >
                            {dataStore.getName()}

                            <div>
                                <CopyToClipboardText
                                    text={dataStore.getUUID()}
                                    preview={
                                        dataStore.getUUID().length > 20
                                            ? `${dataStore
                                                  .getUUID()
                                                  .substring(0, 20)}...`
                                            : dataStore.getUUID()
                                    }
                                    tooltip={dataStore.getUUID()}
                                    hoverable={false}
                                />
                            </div>
                        </div>
                    ),
                    span: 12,
                },
                {
                    label: (
                        <div
                            className={
                                styles.DataStoreOverviewGeneralInformationLabel
                            }
                        >
                            Status
                        </div>
                    ),
                    testId: `${testId}CardStatus`,
                    content: (
                        <DataStoreStatus
                            dataStore={dataStore}
                            testId={`${testId}Status`}
                            updating={false}
                        />
                    ),
                    span: 12,
                    style: 'DataStoreOverviewGeneralInformationEvenColumns',
                },
                {
                    label: (
                        <div
                            className={
                                styles.DataStoreOverviewGeneralInformationLabel
                            }
                        >
                            Version
                        </div>
                    ),
                    testId: `${testId}CardVersion`,
                    content: (
                        <SmallContainer
                            topChildren={
                                <>
                                    <CcxLogo
                                        logo={dataStore.getDbIconName()}
                                        size="xsmall"
                                    />
                                    {dataStore?.getDbVendorName()}{' '}
                                    {dataStore?.db.dbVersion}
                                </>
                            }
                        />
                    ),
                    span: 12,
                },
                {
                    label: (
                        <div
                            className={
                                styles.DataStoreOverviewGeneralInformationLabel
                            }
                        >
                            Topology
                        </div>
                    ),
                    testId: `${testId}CardTopology`,
                    content: (
                        <SmallContainer
                            topChildren={
                                <>
                                    {dataStore.getNumberOfNodes() && (
                                        <CcxIcon1NodeDeployment
                                            height={16}
                                            width={16}
                                        />
                                    )}
                                    <div>
                                        {dataStore.getNumberOfNodes()}{' '}
                                        {dataStore.getNumberOfNodes() === 1
                                            ? 'node'
                                            : 'nodes'}{' '}
                                        {dataStore.clusterType.toLowerCase()}
                                    </div>
                                </>
                            }
                        />
                    ),
                    span: 12,
                    style: 'DataStoreOverviewGeneralInformationEvenColumns',
                },
                {
                    label: (
                        <div
                            className={
                                styles.DataStoreOverviewGeneralInformationLabel
                            }
                        >
                            Configuration
                        </div>
                    ),
                    testId: `${testId}CardTopology`,
                    content: (
                        <SmallContainer
                            topChildren={
                                <div>{dataStore.getClusterTypeName()}</div>
                            }
                        />
                    ),
                    span: 12,
                },
                {
                    label: (
                        <div
                            className={
                                styles.DataStoreOverviewGeneralInformationLabel
                            }
                        >
                            Tags
                        </div>
                    ),
                    testId: `${testId}CardTags`,
                    content: (
                        <div>
                            <AppFormTags length={6} tags={dataStore.tags} />
                        </div>
                    ),
                    span: 12,
                    style: 'DataStoreOverviewGeneralInformationEvenColumns',
                },
            ]);
        }
    }, [dataStore]);

    return (
        <div
            className={styles.DataStoreOverviewGeneralInformation}
            data-testid={testId}
        >
            <div>
                {dataStore && cards.length > 0 ? (
                    <>
                        <header>General information</header>
                        <main>
                            <Row gutter={[16, 24]}>
                                {cards.map((c: any, i: number) => {
                                    return (
                                        <Col
                                            span={c.span}
                                            key={`cards${i}`}
                                            className={styles[c.style]}
                                        >
                                            <SmallContainer
                                                testId={c.testId}
                                                topChildren={c.label}
                                                middleChildren={c.content}
                                            />
                                        </Col>
                                    );
                                })}
                            </Row>
                        </main>
                    </>
                ) : (
                    <LazyLoader
                        type="row"
                        testId={`${testId}Loader`}
                        rows={9}
                    />
                )}
            </div>
        </div>
    );
}

export default DataStoreOverviewGeneralInformation;
