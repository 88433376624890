export type DataStoreEmailNotificationsSettingsClassProps = {
    enabled: boolean;
    emails: string[] | null;
};

export interface DataStoreEmailNotificationsSettingsInterface {
    getEmailsList: Function;
    isEmailNotificationEnabled: Function;
}

export default class DataStoreEmailNotificationsSettings
    implements DataStoreEmailNotificationsSettingsInterface
{
    readonly enabled: boolean;
    readonly emails: string[] | null;

    constructor(props: DataStoreEmailNotificationsSettingsClassProps) {
        this.enabled = props.enabled;
        this.emails = props.emails;
    }

    getEmailsList(): string[] {
        return this.emails || [];
    }

    isEmailNotificationEnabled(): boolean {
        return this.enabled;
    }
}
