import ApiService from './ApiService';
import QueryProcesses from '../types/QueryProcesses';
import TopQueries from '../types/TopQueries';
import { API_QMON } from '../core/CcxEnv';

// constants
const API_URL = API_QMON;

export default class QueryMonitorService extends ApiService {
    /**
     * Get list of query processes.
     * @param {string} uuid
     */
    static async getQueryProcesses(uuid: string): Promise<QueryProcesses> {
        const response = await this.request('GET', `qmon/${uuid}/processes`);
        return new QueryProcesses(response);
    }

    /**
     * Get list of top queries.
     * @param {string} uuid
     */
    static async getTopQueries(uuid: string): Promise<TopQueries> {
        const response = await this.request('GET', `qmon/${uuid}/topqueries`);
        return new TopQueries(response);
    }

    protected static getApiUrl() {
        return API_URL;
    }
}
