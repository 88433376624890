export type PrometheusDiskSpaceUsageStatsBackendApiProps = {
    created: number;
    used: number;
    available: number;
};

export default class PrometheusDiskSpaceUsageStats {
    readonly created: number;
    readonly used: number;
    readonly available: number;

    constructor(props: PrometheusDiskSpaceUsageStatsBackendApiProps) {
        this.created = props.created;
        this.used = props.used;
        this.available = props.available;
    }
}
