export type PrometheusDiskIOUtilStatsBackendApiProps = {
    created: number;
    io_time: number;
};

export default class PrometheusDiskIOUtilStats {
    readonly created: number;
    readonly ioTime: number;

    constructor(props: PrometheusDiskIOUtilStatsBackendApiProps) {
        this.created = props.created;
        this.ioTime = props.io_time;
    }
}
