import React, { ReactElement, useEffect, useState } from 'react';
import AppEmpty from '../../AppEmpty';
import styles from './MySqlDbCompareDashboard.module.less';
import { Col, Row } from 'antd';
import MysqlConnectionsChart from '../../ccx/charts/MysqlConnectionsChart';
import MysqlComDeleteChart from '../../ccx/charts/MysqlComDeleteChart';
import MysqlComReplaceChart from '../../ccx/charts/MysqlComReplaceChart';
import MysqlComSelectChart from '../../ccx/charts/MysqlComSelectChart';
import MysqlComUpdateChart from '../../ccx/charts/MysqlComUpdateChart';
import MysqlComInsertChart from '../../ccx/charts/MysqlComInsertChart';
import useSqlStatsCompare from '../../../core/hooks/useSqlStatsCompare';

interface Props {
    uuid: string;
    from?: string;
    to?: string;
    interval: number;
    hidden?: boolean;
    displaySummary?: boolean;
    selectedNodes: any[];
}

function MySqlDbCompareDashboard({
    uuid,
    from,
    to,
    interval,
    hidden,
    displaySummary,
    selectedNodes,
}: Props): ReactElement {
    const {
        stats: sqlStats,
        refresh: sqlStatsRefresh,
        loading: sqlStatsLoading,
    } = useSqlStatsCompare(uuid, selectedNodes, from, to);
    const [sqlInterval, setSqlInterval] = useState<number | undefined>(
        undefined
    );
    const [refreshInterval, setRefreshInterval] = useState(interval);

    useEffect(() => {
        let interval: any;
        if (sqlStats && refreshInterval > 0) {
            interval = setInterval(() => {
                sqlStatsRefresh();
            }, refreshInterval);
            setSqlInterval(interval);
        }
        return () => clearInterval(interval);
    }, [sqlStats, refreshInterval]);

    useEffect(() => {
        if (interval === 0) {
            // when interval is 0, we must pause the refresh (clear existing intervals)
            clearInterval(sqlInterval);
            setSqlInterval(0);
        }
        setRefreshInterval(interval);
    }, [interval]);

    useEffect(() => {
        if (hidden) {
            // clear interval to stop updating data if the tab is not active (charts are not visible)
            clearInterval(sqlInterval);
            setSqlInterval(0);
        } else if (sqlInterval === 0) {
            // force refresh if the interval is 0, otherwise, the interval will refresh data soon
            // interval is undefined on the first load
            // interval will be 0 when paused or tab is hidden
            sqlStatsRefresh();
        }
    }, [hidden]);

    return (
        <section className={styles.MysqlDbCompareDashboard}>
            {selectedNodes.length > 0 ? (
                <Row
                    gutter={[
                        { xs: 8, sm: 16, md: 24, lg: 32, xl: 40, xxl: 48 },
                        { xs: 8, sm: 16, md: 24, lg: 32, xl: 40, xxl: 48 },
                    ]}
                >
                    {selectedNodes.map((n: any, idx: number) => (
                        <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} key={n}>
                            <h1>{n}</h1>

                            <Row
                                gutter={[
                                    {
                                        xs: 8,
                                        sm: 16,
                                        md: 24,
                                        lg: 32,
                                        xl: 40,
                                        xxl: 48,
                                    },
                                    {
                                        xs: 8,
                                        sm: 16,
                                        md: 24,
                                        lg: 32,
                                        xl: 40,
                                        xxl: 48,
                                    },
                                ]}
                                className={styles.MysqlDbCompareDashboardRow}
                            >
                                <Col
                                    className={
                                        styles.MysqlDbCompareDashboardCol
                                    }
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                >
                                    <MysqlConnectionsChart
                                        data={sqlStats ? sqlStats[idx] : []}
                                        loading={sqlStatsLoading}
                                        displaySummary={displaySummary}
                                    />
                                </Col>
                                <Col
                                    className={
                                        styles.MysqlDbCompareDashboardCol
                                    }
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                >
                                    <MysqlComDeleteChart
                                        data={sqlStats ? sqlStats[idx] : []}
                                        loading={sqlStatsLoading}
                                        displaySummary={displaySummary}
                                    />
                                </Col>
                                <Col
                                    className={
                                        styles.MysqlDbCompareDashboardCol
                                    }
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                >
                                    <MysqlComReplaceChart
                                        data={sqlStats ? sqlStats[idx] : []}
                                        loading={sqlStatsLoading}
                                        displaySummary={displaySummary}
                                    />
                                </Col>
                                <Col
                                    className={
                                        styles.MysqlDbCompareDashboardCol
                                    }
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                >
                                    <MysqlComSelectChart
                                        data={sqlStats ? sqlStats[idx] : []}
                                        loading={sqlStatsLoading}
                                        displaySummary={displaySummary}
                                    />
                                </Col>
                                <Col
                                    className={
                                        styles.MysqlDbCompareDashboardCol
                                    }
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                >
                                    <MysqlComUpdateChart
                                        data={sqlStats ? sqlStats[idx] : []}
                                        loading={sqlStatsLoading}
                                        displaySummary={displaySummary}
                                    />
                                </Col>
                                <Col
                                    className={
                                        styles.MysqlDbCompareDashboardCol
                                    }
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                >
                                    <MysqlComInsertChart
                                        data={sqlStats ? sqlStats[idx] : []}
                                        loading={sqlStatsLoading}
                                        displaySummary={displaySummary}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    ))}
                </Row>
            ) : (
                <AppEmpty message="No Database Nodes selected yet." />
            )}
        </section>
    );
}

export default MySqlDbCompareDashboard;
