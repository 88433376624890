import AppConfirmDialog from '../../../AppConfirmDialog';
import ProvisionService from '../../../../services/ProvisionService';
import Service from '../../../../types/Service';
import { CrownOutlined } from '@ant-design/icons';
import useDataStoreJobs from '../../../../core/hooks/useDataStoreJobs';

export default PromoteReplicaButton;

export type PromoteReplicaButtonProps = {
    service: Service;
};

function PromoteReplicaButton({ service }: PromoteReplicaButtonProps) {
    const { refresh: refreshJobs } = useDataStoreJobs({
        dataStoreUuid: service.getClusterUuid(),
    });

    const handleOk = async () => {
        try {
            await ProvisionService.promoteReplica(
                service.getClusterUuid(),
                service.getServiceUuid()
            );

            refreshJobs();
        } catch (e: any) {}
    };
    return (
        <AppConfirmDialog
            label="Promote Replica"
            stretchedClick={true}
            onOk={handleOk}
            title="WARNING!"
            content="During this operation, the current primary is drained, which means new writes are blocked and outstanding writes are completed before the replica is promoted to the new primary. The current primary becomes a replica of the new primary."
            actionIcon={<CrownOutlined />}
        />
    );
}
