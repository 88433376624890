import React, { ReactElement, useEffect, useState } from 'react';
import styles from './DbCompareDashboard.module.less';
import MySqlDbCompareDashboard from './MySqlDbCompareDashboard';
import PostgreSqlDbCompareDashboard from './PostgreSqlDbCompareDashboard';
import Deployment from '../../../types/Deployment';

type DbCompareDashboardProps = {
    uuid: string;
    from?: string;
    to?: string;
    interval: number;
    deployment?: Deployment;
    hidden?: boolean;
    displaySummary?: boolean;
    selectedNodes: any[];
};

function DbCompareDashboard({
    uuid,
    from,
    to,
    interval,
    deployment,
    hidden,
    displaySummary,
    selectedNodes,
}: DbCompareDashboardProps): ReactElement {
    return (
        <section className={styles.DbCompareDashboard}>
            {deployment?.isClusterPostgreSql() ? (
                <PostgreSqlDbCompareDashboard
                    uuid={uuid}
                    from={from}
                    to={to}
                    interval={interval}
                    hidden={hidden}
                    displaySummary={displaySummary}
                    selectedNodes={selectedNodes}
                />
            ) : (
                <MySqlDbCompareDashboard
                    uuid={uuid}
                    from={from}
                    to={to}
                    interval={interval}
                    hidden={hidden}
                    displaySummary={displaySummary}
                    selectedNodes={selectedNodes}
                />
            )}
        </section>
    );
}

export default DbCompareDashboard;
