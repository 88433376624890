import ApiService, { RequestProps } from './ApiService';
import { API_DB_CONFIGURATION } from '../core/CcxEnv';
import DbParameters from '../types/DbParameters';

// constants
const API_URL = API_DB_CONFIGURATION;
export default class DatabaseConfigurationService extends ApiService {
    /**
     * Get list of Database parameters.
     */
    static async getDatabaseParameters(
        dataStoreUuid?: string
    ): Promise<DbParameters> {
        const response = await this.request('GET', `${dataStoreUuid}`);
        return new DbParameters(response);
    }

    static async updateDatabaseParameters(
        dataStoreUuid: string,
        requestData: RequestProps
    ) {
        const reqData = { data: requestData };
        await this.request('PUT', `${dataStoreUuid}`, reqData);
    }

    protected static getApiUrl() {
        return API_URL;
    }
}
