import React, { ReactElement } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import CcxIconCheckCircle from '../icons/CcxIconCheckCircle';
import AppLabeledIconStatus from './AppLabeledIconStatus';
import styles from './IconLabelStatusOk.module.less';

interface Props extends CcxComponentProps {
    label: string;
    showIcon?: boolean;
}

function IconLabelStatusOk({
    label,
    showIcon,
    testId = 'IconLabelStatusOk',
}: Props): ReactElement {
    return (
        <AppLabeledIconStatus
            label={label}
            icon={showIcon === true ? <CcxIconCheckCircle /> : undefined}
            className={styles.IconLabelStatusOk}
            testId={testId}
        />
    );
}

export default IconLabelStatusOk;
