import { useEffect, useState } from 'react';
import DatabaseConfigurationService from '../../services/DatabaseConfigurationService';
import DbParameters from '../../types/DbParameters';

export default function useDbParameters(dataStoreUuid?: string) {
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);
    const [dbParameters, setDbParameters] = useState<DbParameters | undefined>(
        undefined
    );

    const load = async () => {
        if (dataStoreUuid) {
            setLoading(true);

            try {
                const result =
                    await DatabaseConfigurationService.getDatabaseParameters(
                        dataStoreUuid
                    );
                setDbParameters(result);
                setLoading(false);
                setError(null);
            } catch (e: any) {
                setLoading(false);
                setError(e);
            }

            setLoading(false);
        }
    };

    const refresh = () => {
        const update = async () => {
            setUpdating(true);
            await load();
            setUpdating(false);
        };
        update();
    };

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            await load();
            setLoading(false);
        };

        if (!dbParameters) {
            init();
        } else {
            refresh();
        }
    }, [dataStoreUuid]);

    return { dbParameters, refresh, loading, error, updating };
}
