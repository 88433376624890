export type PrometheusPostgreSqlCheckpointSyncTimeStatsBackendApiProps = {
    created: number;
    pg_stat_bgwriter_checkpoint_sync_time: number;
};

export default class PrometheusPostgreSqlCheckpointSyncTimeStats {
    readonly created: number;
    readonly pgStatBgwriterCheckpointSyncTime: number;

    constructor(
        props: PrometheusPostgreSqlCheckpointSyncTimeStatsBackendApiProps
    ) {
        this.created = props.created;
        this.pgStatBgwriterCheckpointSyncTime =
            props.pg_stat_bgwriter_checkpoint_sync_time;
    }
}
