import React, { ReactElement, useEffect, useState } from 'react';
import styles from './PostgreSqlDbDashboard.module.less';
import { Col, Row, Spin } from 'antd';
import usePrometheusPostgreSqlActiveSessionsStats from '../../../core/hooks/usePrometheusPostgreSqlActiveSessionsStats';
import usePrometheusPostgreSqlCheckpointsRequestedAndTimedStats from '../../../core/hooks/usePrometheusPostgreSqlCheckpointsRequestedAndTimedStats';
import usePrometheusPostgreSqlCheckpointSyncTimeStats from '../../../core/hooks/usePrometheusPostgreSqlCheckpointSyncTimeStats';
import usePrometheusPostgreSqlCheckpointWriteTimeStats from '../../../core/hooks/usePrometheusPostgreSqlCheckpointWriteTimeStats';
import usePrometheusPostgreSqlDeleteStats from '../../../core/hooks/usePrometheusPostgreSqlDeleteStats';
import usePrometheusPostgreSqlDiskIOUtilizationStats from '../../../core/hooks/usePrometheusPostgreSqlDiskIOUtilizationStats';
import usePrometheusPostgreSqlDiskLatencyStats from '../../../core/hooks/usePrometheusPostgreSqlDiskLatencyStats';
import usePrometheusPostgreSqlDiskUsageStats from '../../../core/hooks/usePrometheusPostgreSqlDiskUsageStats';
import usePrometheusPostgreSqlIdleSessionsInTransactionAbortedStats from '../../../core/hooks/usePrometheusPostgreSqlIdleSessionsInTransactionAbortedStats';
import usePrometheusPostgreSqlIdleSessionsInTransactionStats from '../../../core/hooks/usePrometheusPostgreSqlIdleSessionsInTransactionStats';
import usePrometheusPostgreSqlIdleSessionsStats from '../../../core/hooks/usePrometheusPostgreSqlIdleSessionsStats';
import usePrometheusPostgreSqlInsertStats from '../../../core/hooks/usePrometheusPostgreSqlInsertStats';
import usePrometheusPostgreSqlLockTablesStats from '../../../core/hooks/usePrometheusPostgreSqlLockTablesStats';
import usePrometheusPostgreSqlSelectFetchedStats from '../../../core/hooks/usePrometheusPostgreSqlSelectFetchedStats';
import usePrometheusPostgreSqlSelectReturnedStats from '../../../core/hooks/usePrometheusPostgreSqlSelectReturnedStats';
import usePrometheusPostgreSqlUpdateStats from '../../../core/hooks/usePrometheusPostgreSqlUpdateStats';
import PrometheusPostgreSqlSelectFetchedChart from '../../ccx/charts/PrometheusPostgreSqlSelectFetchedChart';
import PrometheusPostgreSqlInsertChart from '../../ccx/charts/PrometheusPostgreSqlInsertChart';
import PrometheusPostgreSqlUpdateChart from '../../ccx/charts/PrometheusPostgreSqlUpdateChart';
import PrometheusPostgreSqlDeleteChart from '../../ccx/charts/PrometheusPostgreSqlDeleteChart';
import PrometheusPostgreSqlSelectReturnedChart from '../../ccx/charts/PrometheusPostgreSqlSelectReturnedChart';
import PrometheusPostgreSqlActiveSessionsChart from '../../ccx/charts/PrometheusPostgreSqlActiveSessionsChart';
import PrometheusPostgreSqlIdleSessionsChart from '../../ccx/charts/PrometheusPostgreSqlIdleSessionsChart';
import PrometheusPostgreSqlDiskLatencyChart from '../../ccx/charts/PrometheusPostgreSqlDiskLatencyChart';
import PrometheusPostgreSqlDiskUsageChart from '../../ccx/charts/PrometheusPostgreSqlDiskUsageChart';
import PrometheusPostgreSqlDiskIOUtilizationChart from '../../ccx/charts/PrometheusPostgreSqlDiskIOUtilizationChart';
import PrometheusPostgreSqlCheckpointWriteTimeChart from '../../ccx/charts/PrometheusPostgreSqlCheckpointWriteTimeChart';
import PrometheusPostgreSqlCheckpointsRequestedAndTimedChart from '../../ccx/charts/PrometheusPostgreSqlCheckpointsRequestedAndTimedChart';
import PrometheusPostgreSqlCheckpointSyncTimeChart from '../../ccx/charts/PrometheusPostgreSqlCheckpointSyncTimeChart';
import PrometheusPostgreSqlLockTablesChart from '../../ccx/charts/PrometheusPostgreSqlLockTablesChart';
import PrometheusPostgreSqlIdleSessionsInTransactionChart from '../../ccx/charts/PrometheusPostgreSqlIdleSessionsInTransactionChart';
import PrometheusPostgreSqlIdleSessionsInTransactionAbortedChart from '../../ccx/charts/PrometheusPostgreSqlIdleSessionsInTransactionAbortedChart';

type PostgreSqlDbDashboardProps = {
    uuid: string;
    host_uuid: string;
    port: number;
    from?: string;
    to?: string;
    interval: number;
    hidden?: boolean;
    displaySummary?: boolean;
    onLoading?: Function;
};

function PostgreSqlDbDashboard({
    uuid,
    host_uuid,
    port,
    from,
    to,
    interval,
    hidden,
    displaySummary,
    onLoading,
}: PostgreSqlDbDashboardProps): ReactElement {
    const {
        stats: activeSessionsStats,
        refresh: activeSessionsStatsRefresh,
        loading: activeSessionsStatsLoading,
    } = usePrometheusPostgreSqlActiveSessionsStats(uuid, host_uuid, from, to);

    const {
        stats: checkpointsRequestedAndTimedStats,
        refresh: checkpointsRequestedAndTimedStatsRefresh,
        loading: checkpointsRequestedAndTimedStatsLoading,
    } = usePrometheusPostgreSqlCheckpointsRequestedAndTimedStats(
        uuid,
        host_uuid,
        from,
        to
    );

    const {
        stats: checkpointSyncTimeStats,
        refresh: checkpointSyncTimeStatsRefresh,
        loading: checkpointSyncTimeStatsLoading,
    } = usePrometheusPostgreSqlCheckpointSyncTimeStats(
        uuid,
        host_uuid,
        from,
        to
    );

    const {
        stats: checkpointWriteTimeStats,
        refresh: checkpointWriteTimeStatsRefresh,
        loading: checkpointWriteTimeStatsLoading,
    } = usePrometheusPostgreSqlCheckpointWriteTimeStats(
        uuid,
        host_uuid,
        from,
        to
    );

    const {
        stats: deleteStats,
        refresh: deleteStatsRefresh,
        loading: deleteStatsLoading,
    } = usePrometheusPostgreSqlDeleteStats(uuid, host_uuid, from, to);

    const {
        stats: diskIOUtilizationStats,
        refresh: diskIOUtilizationStatsRefresh,
        loading: diskIOUtilizationStatsLoading,
    } = usePrometheusPostgreSqlDiskIOUtilizationStats(
        uuid,
        host_uuid,
        from,
        to
    );

    const {
        stats: diskLatencyStats,
        refresh: diskLatencyStatsRefresh,
        loading: diskLatencyStatsLoading,
    } = usePrometheusPostgreSqlDiskLatencyStats(uuid, host_uuid, from, to);

    const {
        stats: diskUsageStats,
        refresh: diskUsageStatsRefresh,
        loading: diskUsageStatsLoading,
    } = usePrometheusPostgreSqlDiskUsageStats(uuid, host_uuid, from, to);

    const {
        stats: idleSessionsInTransactionAbortedStats,
        refresh: idleSessionsInTransactionAbortedStatsRefresh,
        loading: idleSessionsInTransactionAbortedStatsLoading,
    } = usePrometheusPostgreSqlIdleSessionsInTransactionAbortedStats(
        uuid,
        host_uuid,
        from,
        to
    );

    const {
        stats: idleSessionsInTransactionStats,
        refresh: idleSessionsInTransactionStatsRefresh,
        loading: idleSessionsInTransactionStatsLoading,
    } = usePrometheusPostgreSqlIdleSessionsInTransactionStats(
        uuid,
        host_uuid,
        from,
        to
    );

    const {
        stats: idleSessionsStats,
        refresh: idleSessionsStatsRefresh,
        loading: idleSessionsStatsLoading,
    } = usePrometheusPostgreSqlIdleSessionsStats(uuid, host_uuid, from, to);

    const {
        stats: insertStats,
        refresh: insertStatsRefresh,
        loading: insertStatsLoading,
    } = usePrometheusPostgreSqlInsertStats(uuid, host_uuid, from, to);

    const {
        stats: lockTablesStats,
        refresh: lockTablesStatsRefresh,
        loading: lockTablesStatsLoading,
    } = usePrometheusPostgreSqlLockTablesStats(uuid, host_uuid, from, to);

    const {
        stats: selectFetchedStats,
        refresh: selectFetchedStatsRefresh,
        loading: selectFetchedStatsLoading,
    } = usePrometheusPostgreSqlSelectFetchedStats(uuid, host_uuid, from, to);

    const {
        stats: selectReturnedStats,
        refresh: selectReturnedStatsRefresh,
        loading: selectReturnedStatsLoading,
    } = usePrometheusPostgreSqlSelectReturnedStats(uuid, host_uuid, from, to);

    const {
        stats: updateStats,
        refresh: updateStatsRefresh,
        loading: updateStatsLoading,
    } = usePrometheusPostgreSqlUpdateStats(uuid, host_uuid, from, to);

    const [activeSessionsStatsInterval, setActiveSessionsStatsInterval] =
        useState<number | undefined>(undefined);
    const [
        checkpointsRequestedAndTimedStatsInterval,
        setCheckpointsRequestedAndTimedStatsInterval,
    ] = useState<number | undefined>(undefined);
    const [
        checkpointSyncTimeStatsInterval,
        setCheckpointSyncTimeStatsInterval,
    ] = useState<number | undefined>(undefined);
    const [
        checkpointWriteTimeStatsInterval,
        setCheckpointWriteTimeStatsInterval,
    ] = useState<number | undefined>(undefined);
    const [deleteStatsInterval, setDeleteStatsInterval] = useState<
        number | undefined
    >(undefined);
    const [diskIOUtilizationStatsInterval, setDiskIOUtilizationStatsInterval] =
        useState<number | undefined>(undefined);
    const [diskLatencyStatsInterval, setDiskLatencyStatsInterval] = useState<
        number | undefined
    >(undefined);
    const [diskUsageStatsInterval, setDiskUsageStatsInterval] = useState<
        number | undefined
    >(undefined);
    const [
        idleSessionsInTransactionAbortedStatsInterval,
        setIdleSessionsInTransactionAbortedStatsInterval,
    ] = useState<number | undefined>(undefined);
    const [
        idleSessionsInTransactionStatsInterval,
        setIdleSessionsInTransactionStatsInterval,
    ] = useState<number | undefined>(undefined);
    const [idleSessionsStatsInterval, setIdleSessionsStatsInterval] = useState<
        number | undefined
    >(undefined);
    const [insertStatsInterval, setInsertStatsInterval] = useState<
        number | undefined
    >(undefined);
    const [lockTablesStatsInterval, setLockTablesStatsInterval] = useState<
        number | undefined
    >(undefined);
    const [selectFetchedStatsInterval, setSelectFetchedStatsInterval] =
        useState<number | undefined>(undefined);
    const [selectReturnedStatsInterval, setSelectReturnedStatsInterval] =
        useState<number | undefined>(undefined);
    const [updateStatsInterval, setUpdateStatsInterval] = useState<
        number | undefined
    >(undefined);

    const [refreshInterval, setRefreshInterval] = useState(interval);

    useEffect(() => {
        let interval: any;
        if (activeSessionsStats && refreshInterval > 0) {
            interval = setInterval(() => {
                activeSessionsStatsRefresh();
            }, refreshInterval);
            setActiveSessionsStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [activeSessionsStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (checkpointsRequestedAndTimedStats && refreshInterval > 0) {
            interval = setInterval(() => {
                checkpointsRequestedAndTimedStatsRefresh();
            }, refreshInterval);
            setCheckpointsRequestedAndTimedStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [checkpointsRequestedAndTimedStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (checkpointSyncTimeStats && refreshInterval > 0) {
            interval = setInterval(() => {
                checkpointSyncTimeStatsRefresh();
            }, refreshInterval);
            setCheckpointSyncTimeStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [checkpointSyncTimeStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (checkpointWriteTimeStats && refreshInterval > 0) {
            interval = setInterval(() => {
                checkpointWriteTimeStatsRefresh();
            }, refreshInterval);
            setCheckpointWriteTimeStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [checkpointWriteTimeStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (deleteStats && refreshInterval > 0) {
            interval = setInterval(() => {
                deleteStatsRefresh();
            }, refreshInterval);
            setDeleteStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [deleteStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (diskIOUtilizationStats && refreshInterval > 0) {
            interval = setInterval(() => {
                diskIOUtilizationStatsRefresh();
            }, refreshInterval);
            setDiskIOUtilizationStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [diskIOUtilizationStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (diskLatencyStats && refreshInterval > 0) {
            interval = setInterval(() => {
                diskLatencyStatsRefresh();
            }, refreshInterval);
            setDiskLatencyStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [diskLatencyStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (diskUsageStats && refreshInterval > 0) {
            interval = setInterval(() => {
                diskUsageStatsRefresh();
            }, refreshInterval);
            setDiskUsageStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [diskUsageStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (idleSessionsInTransactionAbortedStats && refreshInterval > 0) {
            interval = setInterval(() => {
                idleSessionsInTransactionAbortedStatsRefresh();
            }, refreshInterval);
            setIdleSessionsInTransactionAbortedStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [idleSessionsInTransactionAbortedStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (idleSessionsInTransactionStats && refreshInterval > 0) {
            interval = setInterval(() => {
                idleSessionsInTransactionStatsRefresh();
            }, refreshInterval);
            setIdleSessionsInTransactionStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [idleSessionsInTransactionStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (idleSessionsStats && refreshInterval > 0) {
            interval = setInterval(() => {
                idleSessionsStatsRefresh();
            }, refreshInterval);
            setIdleSessionsStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [idleSessionsStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (insertStats && refreshInterval > 0) {
            interval = setInterval(() => {
                insertStatsRefresh();
            }, refreshInterval);
            setInsertStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [insertStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (lockTablesStats && refreshInterval > 0) {
            interval = setInterval(() => {
                lockTablesStatsRefresh();
            }, refreshInterval);
            setLockTablesStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [lockTablesStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (selectFetchedStats && refreshInterval > 0) {
            interval = setInterval(() => {
                selectFetchedStatsRefresh();
            }, refreshInterval);
            setSelectFetchedStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [selectFetchedStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (selectReturnedStats && refreshInterval > 0) {
            interval = setInterval(() => {
                selectReturnedStatsRefresh();
            }, refreshInterval);
            setSelectReturnedStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [selectReturnedStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (updateStats && refreshInterval > 0) {
            interval = setInterval(() => {
                updateStatsRefresh();
            }, refreshInterval);
            setUpdateStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [updateStats, refreshInterval]);

    useEffect(() => {
        if (interval === 0) {
            // when interval is 0, we must pause the refresh (clear existing intervals)
            clearInterval(activeSessionsStatsInterval);
            clearInterval(checkpointsRequestedAndTimedStatsInterval);
            clearInterval(checkpointSyncTimeStatsInterval);
            clearInterval(checkpointWriteTimeStatsInterval);
            clearInterval(deleteStatsInterval);
            clearInterval(diskIOUtilizationStatsInterval);
            clearInterval(diskLatencyStatsInterval);
            clearInterval(diskUsageStatsInterval);
            clearInterval(idleSessionsInTransactionAbortedStatsInterval);
            clearInterval(idleSessionsInTransactionStatsInterval);
            clearInterval(idleSessionsStatsInterval);
            clearInterval(insertStatsInterval);
            clearInterval(lockTablesStatsInterval);
            clearInterval(selectFetchedStatsInterval);
            clearInterval(selectReturnedStatsInterval);
            clearInterval(updateStatsInterval);
            setActiveSessionsStatsInterval(0);
            setCheckpointsRequestedAndTimedStatsInterval(0);
            setCheckpointSyncTimeStatsInterval(0);
            setCheckpointWriteTimeStatsInterval(0);
            setDeleteStatsInterval(0);
            setDiskIOUtilizationStatsInterval(0);
            setDiskLatencyStatsInterval(0);
            setDiskUsageStatsInterval(0);
            setIdleSessionsInTransactionAbortedStatsInterval(0);
            setIdleSessionsInTransactionStatsInterval(0);
            setIdleSessionsStatsInterval(0);
            setInsertStatsInterval(0);
            setLockTablesStatsInterval(0);
            setSelectFetchedStatsInterval(0);
            setSelectReturnedStatsInterval(0);
            setUpdateStatsInterval(0);
        }
        setRefreshInterval(interval);
    }, [interval]);

    useEffect(() => {
        if (hidden) {
            // clear interval to stop updating data if the tab is not active (charts are not visible)
            clearInterval(activeSessionsStatsInterval);
            clearInterval(checkpointsRequestedAndTimedStatsInterval);
            clearInterval(checkpointSyncTimeStatsInterval);
            clearInterval(checkpointWriteTimeStatsInterval);
            clearInterval(deleteStatsInterval);
            clearInterval(diskIOUtilizationStatsInterval);
            clearInterval(diskLatencyStatsInterval);
            clearInterval(diskUsageStatsInterval);
            clearInterval(idleSessionsInTransactionAbortedStatsInterval);
            clearInterval(idleSessionsInTransactionStatsInterval);
            clearInterval(idleSessionsStatsInterval);
            clearInterval(insertStatsInterval);
            clearInterval(lockTablesStatsInterval);
            clearInterval(selectFetchedStatsInterval);
            clearInterval(selectReturnedStatsInterval);
            clearInterval(updateStatsInterval);
            setActiveSessionsStatsInterval(0);
            setCheckpointsRequestedAndTimedStatsInterval(0);
            setCheckpointSyncTimeStatsInterval(0);
            setCheckpointWriteTimeStatsInterval(0);
            setDeleteStatsInterval(0);
            setDiskIOUtilizationStatsInterval(0);
            setDiskLatencyStatsInterval(0);
            setDiskUsageStatsInterval(0);
            setIdleSessionsInTransactionAbortedStatsInterval(0);
            setIdleSessionsInTransactionStatsInterval(0);
            setIdleSessionsStatsInterval(0);
            setInsertStatsInterval(0);
            setLockTablesStatsInterval(0);
            setSelectFetchedStatsInterval(0);
            setSelectReturnedStatsInterval(0);
            setUpdateStatsInterval(0);
        } else if (
            activeSessionsStatsInterval === 0 ||
            checkpointsRequestedAndTimedStatsInterval === 0 ||
            checkpointSyncTimeStatsInterval === 0 ||
            checkpointWriteTimeStatsInterval === 0 ||
            deleteStatsInterval === 0 ||
            diskIOUtilizationStatsInterval === 0 ||
            diskLatencyStatsInterval === 0 ||
            diskUsageStatsInterval === 0 ||
            idleSessionsInTransactionAbortedStatsInterval === 0 ||
            idleSessionsInTransactionStatsInterval === 0 ||
            idleSessionsStatsInterval === 0 ||
            insertStatsInterval === 0 ||
            lockTablesStatsInterval === 0 ||
            selectFetchedStatsInterval === 0 ||
            selectReturnedStatsInterval === 0 ||
            updateStatsInterval === 0
        ) {
            // force refresh if the interval is 0, otherwise, the interval will refresh data soon
            // interval is undefined on the first load
            // interval will be 0 when paused or tab is hidden
            activeSessionsStatsRefresh();
            checkpointsRequestedAndTimedStatsRefresh();
            checkpointSyncTimeStatsRefresh();
            checkpointWriteTimeStatsRefresh();
            deleteStatsRefresh();
            diskIOUtilizationStatsRefresh();
            diskLatencyStatsRefresh();
            diskUsageStatsRefresh();
            idleSessionsInTransactionAbortedStatsRefresh();
            idleSessionsInTransactionStatsRefresh();
            idleSessionsStatsRefresh();
            insertStatsRefresh();
            lockTablesStatsRefresh();
            selectFetchedStatsRefresh();
            selectReturnedStatsRefresh();
            updateStatsRefresh();
        }
    }, [hidden]);

    useEffect(() => {
        if (
            activeSessionsStatsLoading ||
            checkpointsRequestedAndTimedStatsLoading ||
            checkpointSyncTimeStatsLoading ||
            checkpointWriteTimeStatsLoading ||
            deleteStatsLoading ||
            diskIOUtilizationStatsLoading ||
            diskLatencyStatsLoading ||
            diskUsageStatsLoading ||
            idleSessionsInTransactionAbortedStatsLoading ||
            idleSessionsInTransactionStatsLoading ||
            idleSessionsStatsLoading ||
            insertStatsLoading ||
            lockTablesStatsLoading ||
            selectFetchedStatsLoading ||
            selectReturnedStatsLoading ||
            updateStatsLoading
        ) {
            onLoading && onLoading(true);
        } else {
            onLoading && onLoading(false);
        }
    }, [
        activeSessionsStatsLoading,
        checkpointsRequestedAndTimedStatsLoading,
        checkpointSyncTimeStatsLoading,
        checkpointWriteTimeStatsLoading,
        deleteStatsLoading,
        diskIOUtilizationStatsLoading,
        diskLatencyStatsLoading,
        diskUsageStatsLoading,
        idleSessionsInTransactionAbortedStatsLoading,
        idleSessionsInTransactionStatsLoading,
        idleSessionsStatsLoading,
        insertStatsLoading,
        lockTablesStatsLoading,
        selectFetchedStatsLoading,
        selectReturnedStatsLoading,
        updateStatsLoading,
    ]);

    return (
        <section className={styles.PostgreSqlDbDashboard}>
            <Row
                gutter={[
                    32,
                    { xs: 8, sm: 16, md: 24, lg: 32, xl: 40, xxl: 48 },
                ]}
                className={styles.PostgreSqlDbDashboardRow}
            >
                <Col
                    className={styles.PostgreSqlDbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusPostgreSqlSelectFetchedChart
                        data={selectFetchedStats}
                        loading={selectFetchedStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.PostgreSqlDbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusPostgreSqlInsertChart
                        data={insertStats}
                        loading={insertStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.PostgreSqlDbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusPostgreSqlUpdateChart
                        data={updateStats}
                        loading={updateStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.PostgreSqlDbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusPostgreSqlDeleteChart
                        data={deleteStats}
                        loading={deleteStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.PostgreSqlDbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusPostgreSqlSelectReturnedChart
                        data={selectReturnedStats}
                        loading={selectReturnedStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.PostgreSqlDbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusPostgreSqlActiveSessionsChart
                        data={activeSessionsStats}
                        loading={activeSessionsStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.PostgreSqlDbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusPostgreSqlIdleSessionsChart
                        data={idleSessionsStats}
                        loading={idleSessionsStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.PostgreSqlDbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusPostgreSqlIdleSessionsInTransactionChart
                        data={idleSessionsInTransactionStats}
                        loading={idleSessionsInTransactionStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.PostgreSqlDbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusPostgreSqlIdleSessionsInTransactionAbortedChart
                        data={idleSessionsInTransactionAbortedStats}
                        loading={idleSessionsInTransactionAbortedStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.PostgreSqlDbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusPostgreSqlLockTablesChart
                        data={lockTablesStats}
                        loading={lockTablesStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.PostgreSqlDbDashboardCol}
                    xs={24}
                    sm={24}
                    md={16}
                    lg={16}
                    xl={16}
                    xxl={16}
                >
                    <PrometheusPostgreSqlCheckpointsRequestedAndTimedChart
                        data={checkpointsRequestedAndTimedStats}
                        loading={checkpointsRequestedAndTimedStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.PostgreSqlDbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusPostgreSqlCheckpointSyncTimeChart
                        data={checkpointSyncTimeStats}
                        loading={checkpointSyncTimeStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.PostgreSqlDbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusPostgreSqlCheckpointWriteTimeChart
                        data={checkpointWriteTimeStats}
                        loading={checkpointWriteTimeStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.PostgreSqlDbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusPostgreSqlDiskIOUtilizationChart
                        data={diskIOUtilizationStats}
                        loading={diskIOUtilizationStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.PostgreSqlDbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusPostgreSqlDiskUsageChart
                        data={diskUsageStats}
                        loading={diskUsageStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.PostgreSqlDbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusPostgreSqlDiskLatencyChart
                        data={diskLatencyStats}
                        loading={diskLatencyStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
            </Row>
        </section>
    );
}

export default PostgreSqlDbDashboard;
