import { Parameter, ParameterProps } from './DbParameter';

export type DbParametersClassProps = {
    parameters: ParameterProps;
};

export interface DbParametersInterface {
    parameters: ParameterProps;
}

export default class DbParameters implements DbParametersInterface {
    readonly parameters: any;

    constructor(props: DbParametersClassProps) {
        this.parameters = Object.entries(props.parameters).map(
            ([name, prop]) => new Parameter(name, prop)
        );
    }
}
