export type CmonNetStatBackendApiProps = {
    created: number;
    interface: string;
    interval: number;
    rxBytes: number;
    rxDrops: number;
    rxErrors: number;
    rxFrames: number;
    rxMulticasts: number;
    rxPackets: number;
    rxSpeed: number;
    sampleends: number;
    samplekey: string;
    serverid: number;
    txBytes: number;
    txDrops: number;
    txErrors: number;
    txPackets: number;
    txSpeed: number;
};

export default class CmonNetStat {
    readonly created: number;
    readonly interface: string;
    readonly interval: number;
    readonly rxBytes: number;
    readonly rxDrops: number;
    readonly rxErrors: number;
    readonly rxFrames: number;
    readonly rxMulticasts: number;
    readonly rxPackets: number;
    readonly rxSpeed: number;
    readonly sampleEnds: number;
    readonly sampleKey: string;
    readonly serverId: number;
    readonly txBytes: number;
    readonly txDrops: number;
    readonly txErrors: number;
    readonly txPackets: number;
    readonly txSpeed: number;

    constructor(props: CmonNetStatBackendApiProps) {
        this.created = props.created;
        this.interface = props.interface;
        this.interval = props.interval;
        this.rxBytes = props.rxBytes;
        this.rxDrops = props.rxDrops;
        this.rxErrors = props.rxErrors;
        this.rxFrames = props.rxFrames;
        this.rxMulticasts = props.rxMulticasts;
        this.rxPackets = props.rxPackets;
        this.rxSpeed = props.rxSpeed;
        this.sampleEnds = props.sampleends;
        this.sampleKey = props.samplekey;
        this.serverId = props.serverid;
        this.txBytes = props.txBytes;
        this.txDrops = props.txDrops;
        this.txErrors = props.txErrors;
        this.txPackets = props.txPackets;
        this.txSpeed = props.txSpeed;
    }
}
