import React, { ReactElement } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import AppLabeledIconStatus from './AppLabeledIconStatus';
import styles from './DotLabelStatus.module.less';

export interface DotLabelStatusProps extends CcxComponentProps {
    glow?: boolean;
    label?: string;
    type?: string;
}

function DotLabelStatus({
    glow,
    testId = 'DotLabelStatus',
    label = '',
    type,
}: DotLabelStatusProps): ReactElement {
    let dotCss: string = '';
    let labelCss: string = '';
    switch (type) {
        case 'ok':
            dotCss = glow
                ? styles.DotLabelStatusDotGreenGlow
                : styles.DotLabelStatusDotGreen;
            labelCss = styles.DotLabelStatusLabelGreen;
            break;
        case 'error':
            dotCss = glow
                ? styles.DotLabelStatusDotRedGlow
                : styles.DotLabelStatusDotRed;
            labelCss = styles.DotLabelStatusLabelRed;
            break;
        case 'warning':
            dotCss = glow
                ? styles.DotLabelStatusDotOrangeGlow
                : styles.DotLabelStatusDotOrange;
            labelCss = styles.DotLabelStatusLabelOrange;
            break;
        case 'info':
            dotCss = glow
                ? styles.DotLabelStatusDotBlueGlow
                : styles.DotLabelStatusDotBlue;
            labelCss = styles.DotLabelStatusLabelBlue;
            break;
        case 'disabled':
            dotCss = glow
                ? styles.DotLabelStatusDotGrayGlow
                : styles.DotLabelStatusDotGray;
            labelCss = styles.DotLabelStatusLabelGray;
            break;
        case 'normal':
        default:
            dotCss = glow
                ? styles.DotLabelStatusDotGrayGlow
                : styles.DotLabelStatusDotGray;
            labelCss = styles.DotLabelStatusLabelGray;
    }

    return (
        <div className={styles.DotLabelStatus} data-testid={testId}>
            <div className={dotCss} data-testid={`${testId}Dot`}></div>
            <div className={labelCss} data-testid={`${testId}Label`}>
                {label}
            </div>
        </div>
    );
}

export default DotLabelStatus;
