import { Space, Tooltip } from 'antd';
import { ReactElement } from 'react';
import InstanceSize from '../../../types/InstanceSize';
import { CcxIconCpu, CcxIconDisk, CcxIconRam } from '../../ccx/icons/Icons';
import styles from './InstanceCard.module.less';

export type Props = {
    instance: InstanceSize;
};

export default function InstanceCard({ instance }: Props): ReactElement {
    return (
        <div className={styles.InstanceCardCentered}>
            {instance.name && <strong>{instance.name}</strong>}
            <Space>
                <Space size={4}>
                    <Tooltip title="CPU">
                        <CcxIconCpu className={styles.SpecIcon} />
                    </Tooltip>
                    {`${instance.cpu}`}
                </Space>
                <Space size={4}>
                    <Tooltip title="RAM">
                        <CcxIconRam className={styles.SpecIcon} />
                    </Tooltip>
                    {`${instance.ram} GB`}
                </Space>
            </Space>
            {instance.diskSize && (
                <Space size={4}>
                    <Tooltip title="Disk">
                        <CcxIconDisk className={styles.SpecIcon} />
                    </Tooltip>
                    {`${instance.diskSize} GB`}
                </Space>
            )}
        </div>
    );
}
