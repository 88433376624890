export type PrometheusMySqlTableLockingStatsBackendApiProps = {
    created: number;
    table_locks_immediate: number;
    table_locks_waited: number;
};

export default class PrometheusMySqlTableLockingStats {
    readonly created: number;
    readonly tableLocksImmediate: number;
    readonly tableLocksWaited: number;

    constructor(props: PrometheusMySqlTableLockingStatsBackendApiProps) {
        this.created = props.created;
        this.tableLocksImmediate = props.table_locks_immediate;
        this.tableLocksWaited = props.table_locks_waited;
    }
}
