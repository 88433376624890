export type InstanceSizeClassProps = {
    code: string;
    type: string;
    cpu: number;
    ram: number;
    name: string;
    verified_level: number;
    price: number;
    disk_size: number;
};

export interface InstanceSizeInterface {
    code: string;
    type: string;
    cpu: number;
    ram: number;
    name: string;
    verifiedLevel: number;
    price: number;
    diskSize: number;
}

export default class InstanceSize implements InstanceSizeInterface {
    readonly code: string;
    readonly type: string;
    readonly cpu: number;
    readonly ram: number;
    readonly name: string;
    readonly verifiedLevel: number;
    readonly price: number;
    readonly diskSize: number;

    constructor(props: InstanceSizeClassProps) {
        this.code = props.code;
        this.type = props.type;
        this.cpu = props.cpu;
        this.ram = props.ram;
        this.name = props.name;
        this.verifiedLevel = props.verified_level;
        this.price = props.price ? props.price : 0;
        this.diskSize = props.disk_size;
    }

    getMonthlyPrice(): number {
        return (this.price / 100) * 730;
    }
}
