import { DatabaseType } from '../../types/Db';
import { maxLength } from './formValidators';

export function getDatabaseUsernameValidators(type: DatabaseType) {
    switch (type) {
        case DatabaseType.MYSQL:
            return [maxLength(16)];
        case DatabaseType.POSTGRES:
            return [
                maxLength(63),
                {
                    message:
                        'Only letters, underscore (_) and numbers are allowed. The first character must be a letter or an underscore (_). No whitespaces allowed.',
                    pattern: /^[a-zA-Z_]([A-Za-z0-9_]*$)/,
                },
            ];
        case DatabaseType.MSSQL:
            return [
                maxLength(128),
                {
                    message:
                        'Only letters, numbers, underscores, and special characters such as @#$%^&+=.,-,[,] are allowed. The first character must be a letter or an underscore (_). No whitespaces allowed.',
                    pattern: /^[a-zA-Z_]([a-zA-Z0-9_@#$%^&+=\.\-\[\]]*$)/,
                },
            ];
    }
    return [];
}

export function getDatabaseNameValidators(type: DatabaseType) {
    switch (type) {
        case DatabaseType.MYSQL:
            return [
                maxLength(64),
                {
                    message:
                        'Cannot contain / \\ or . characters. Cannot contain characters that are not permitted in file names.',
                    pattern: /^[^\\/?%*:|\"<>.]*$/,
                },
            ];
        case DatabaseType.POSTGRES:
            return [
                maxLength(63),
                {
                    message:
                        'Name must begin with a letter (a-z) or an underscore (_) and may contain letters, digits, or underscores',
                    pattern: /^[a-zA-Z]\w*$/,
                },
            ];
        case DatabaseType.MSSQL:
            return [
                maxLength(128),
                {
                    message:
                        'A database name can contain only letters, numbers, and underscores',
                    pattern: /^[a-zA-Z0-9_]+$/,
                },
            ];
    }

    return [];
}
