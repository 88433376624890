import { Col, Row } from 'antd';
import AddFirewallRule from './AddFirewallRule';
import DeploymentsItem from '../../types/DeploymentsItem';
import styles from './FirewallHeader.module.less';

type FirewallHeaderProps = {
    refresh: Function;
    ipAddress: string;
    dataStoreUuid: string;
    currentDeployment: DeploymentsItem | null | undefined;
};

function FirewallHeader({
    ipAddress,
    dataStoreUuid,
    refresh,
    currentDeployment,
}: FirewallHeaderProps) {
    return (
        <Row className={styles.FirewallHeader}>
            <Col>
                <strong>Your IP: {ipAddress}</strong>
            </Col>
            <Col>
                <AddFirewallRule
                    ipAddress={ipAddress}
                    currentDeployment={currentDeployment}
                    onSuccess={refresh}
                    uuid={dataStoreUuid}
                />
            </Col>
        </Row>
    );
}

export default FirewallHeader;
