import React, { ReactElement, useEffect, useState } from 'react';
import styles from './MysqlDbDashboard.module.less';
import { Col, Row } from 'antd';
import usePrometheusMySqlQueriesStats from '../../../core/hooks/usePrometheusMySqlQueriesStats';
import PrometheusMssqlQueriesChart from '../../ccx/charts/PrometheusMssqlQueriesChart';
import PrometheusMssqlHandlerTransactionsChart from '../../ccx/charts/PrometheusMssqlHandlerTransactionsChart';
import usePrometheusMssqlHandlerTransactionsStats from '../../../core/hooks/usePrometheusMssqlProcessesStats';
import usePrometheusMssqlQPSStats from '../../../core/hooks/usePrometheusMssqlQPSStats';

type MysqlDbDashboardProps = {
    uuid: string;
    host_uuid: string;
    port: number;
    from?: string;
    to?: string;
    interval: number;
    hidden?: boolean;
    displaySummary?: boolean;
    onLoading?: Function;
};

function MysqlDbDashboard({
    uuid,
    host_uuid,
    port,
    from,
    to,
    interval,
    hidden,
    displaySummary,
    onLoading,
}: MysqlDbDashboardProps): ReactElement {
    const {
        stats: queriesStats,
        refresh: queriesStatsRefresh,
        loading: queriesStatsLoading,
    } = usePrometheusMssqlQPSStats(uuid, host_uuid, from, to);

    const {
        stats: handlerTransactionsStats,
        refresh: handlerTransactionsStatsRefresh,
        loading: handlerTransactionsStatsLoading,
    } = usePrometheusMssqlHandlerTransactionsStats(uuid, host_uuid, from, to);

    const [queriesStatsInterval, setQueriesStatsInterval] = useState<
        number | undefined
    >(undefined);

    const [
        handlerTransactionsStatsInterval,
        setHandlerTransactionsStatsInterval,
    ] = useState<number | undefined>(undefined);

    const [refreshInterval, setRefreshInterval] = useState(interval);

    useEffect(() => {
        let interval: any;
        if (queriesStats && refreshInterval > 0) {
            interval = setInterval(() => {
                queriesStatsRefresh();
            }, refreshInterval);
            setQueriesStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [queriesStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (handlerTransactionsStats && refreshInterval > 0) {
            interval = setInterval(() => {
                handlerTransactionsStatsRefresh();
            }, refreshInterval);
            setHandlerTransactionsStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [handlerTransactionsStats, refreshInterval]);

    useEffect(() => {
        if (interval === 0) {
            // when interval is 0, we must pause the refresh (clear existing intervals)
            clearInterval(queriesStatsInterval);
            clearInterval(handlerTransactionsStatsInterval);
            setQueriesStatsInterval(0);
            setHandlerTransactionsStatsInterval(0);
        }
        setRefreshInterval(interval);
    }, [interval]);

    useEffect(() => {
        if (hidden) {
            // clear interval to stop updating data if the tab is not active (charts are not visible)
            clearInterval(queriesStatsInterval);
            clearInterval(handlerTransactionsStatsInterval);
            setQueriesStatsInterval(0);
            setHandlerTransactionsStatsInterval(0);
        } else if (
            queriesStatsInterval === 0 ||
            handlerTransactionsStatsInterval === 0
        ) {
            // force refresh if the interval is 0, otherwise, the interval will refresh data soon
            // interval is undefined on the first load
            // interval will be 0 when paused or tab is hidden
            queriesStatsRefresh();
            handlerTransactionsStatsRefresh();
        }
    }, [hidden]);

    useEffect(() => {
        if (queriesStatsLoading || handlerTransactionsStatsLoading) {
            onLoading && onLoading(true);
        } else {
            onLoading && onLoading(false);
        }
    }, [queriesStatsLoading, handlerTransactionsStatsLoading]);

    return (
        <section className={styles.MysqlDbDashboard}>
            <Row
                gutter={[
                    32,
                    { xs: 8, sm: 16, md: 24, lg: 32, xl: 40, xxl: 48 },
                ]}
                className={styles.MysqlDbDashboardRow}
            >
                <Col
                    className={styles.MysqlDbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusMssqlQueriesChart
                        data={queriesStats}
                        loading={queriesStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.MysqlDbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusMssqlHandlerTransactionsChart
                        data={handlerTransactionsStats}
                        loading={handlerTransactionsStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
            </Row>
        </section>
    );
}

export default MysqlDbDashboard;
