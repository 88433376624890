export type PrometheusProxySqlClientConnectionsStatsBackendApiProps = {
    created: number;
    client_connections_aborted: number;
    client_connections_connected: number;
    client_connections_created: number;
    client_connections_non_idle: number;
};

export default class PrometheusProxySqlClientConnectionsStats {
    readonly created: number;
    readonly clientConnectionsAborted: number;
    readonly clientConnectionsConnected: number;
    readonly clientConnectionsCreated: number;
    readonly clientConnectionsNonIdle: number;

    constructor(
        props: PrometheusProxySqlClientConnectionsStatsBackendApiProps
    ) {
        this.created = props.created;
        this.clientConnectionsAborted = props.client_connections_aborted;
        this.clientConnectionsConnected = props.client_connections_connected;
        this.clientConnectionsCreated = props.client_connections_created;
        this.clientConnectionsNonIdle = props.client_connections_non_idle;
    }
}
