import styles from './DatastoreScalingStepHeader.module.less';
import { ReactNode } from 'react';

interface DatastoreScalingStepHeaderProps {
    readonly title: string;
    readonly subtitle?: string;
    readonly children: ReactNode;
    readonly testId?: string;
}

export default function DatastoreScalingStepHeader({
    title,
    subtitle,
    children,
    testId = 'DatastoreScalingStepHeader',
}: DatastoreScalingStepHeaderProps) {
    return (
        <div data-testid={testId} className={styles.DatastoreScalingStepHeader}>
            <p>
                <strong>{title}</strong>
                <p className={styles.DatastoreScalingStepHeaderInfo}>
                    {subtitle}
                </p>
            </p>
            {children}
        </div>
    );
}
