import React from 'react';
import { BellOutlined } from '@ant-design/icons';
import CcxComponentProps from '../../../core/CcxComponent';

interface Props extends CcxComponentProps {}

const CcxIconBellOutlined = ({ testId }: Props) => {
    return <BellOutlined data-testid={testId || 'CcxIconBellOutlined'} />;
};

export default CcxIconBellOutlined;
