export type PrometheusProxySqlQueryCacheInOutBytesStatsBackendApiProps = {
    created: number;
    query_cache_bytes_in: number;
    query_cache_bytes_out: number;
};

export default class PrometheusProxySqlQueryCacheInOutBytesStats {
    readonly created: number;
    readonly queryCacheBytesIn: number;
    readonly queryCacheBytesOut: number;

    constructor(
        props: PrometheusProxySqlQueryCacheInOutBytesStatsBackendApiProps
    ) {
        this.created = props.created;
        this.queryCacheBytesIn = props.query_cache_bytes_in;
        this.queryCacheBytesOut = props.query_cache_bytes_out;
    }
}
