export type CmonDiskStatBackendApiProps = {
    blocksize: number;
    created: number;
    device: string;
    free: number;
    interval: number;
    mountpoint: string;
    reads: number;
    readspersec: number;
    sampleends: number;
    samplekey: string;
    sectorsread: number;
    sectorswritten: number;
    serverid: number;
    total: number;
    utilization: number;
    writes: number;
    writespersec: number;
};

export default class CmonDiskStat {
    readonly blocksize: number;
    readonly created: number;
    readonly device: string;
    readonly free: number;
    readonly interval: number;
    readonly mountpoint: string;
    readonly reads: number;
    readonly readsPerSec: number;
    readonly sampleEnds: number;
    readonly sampleKey: string;
    readonly sectorsRead: number;
    readonly sectorsWritten: number;
    readonly serverId: number;
    readonly total: number;
    readonly utilization: number;
    readonly writes: number;
    readonly writesPerSec: number;

    constructor(props: CmonDiskStatBackendApiProps) {
        this.blocksize = props.blocksize;
        this.created = props.created;
        this.device = props.device;
        this.free = props.free;
        this.interval = props.interval;
        this.mountpoint = props.mountpoint;
        this.reads = props.reads;
        this.readsPerSec = props.readspersec;
        this.sampleEnds = props.sampleends;
        this.sampleKey = props.samplekey;
        this.sectorsRead = props.sectorsread;
        this.sectorsWritten = props.sectorswritten;
        this.serverId = props.serverid;
        this.total = props.total;
        this.utilization = props.utilization;
        this.writes = props.writes;
        this.writesPerSec = props.writespersec;
    }
}
