import DatastoreScalingStepHeader from '../DatastoreScalingStepHeader';
import { Col, Form, InputNumber, Row } from 'antd';
import AppRadioGroupGrid, {
    AppRadioGridButton,
} from '../../../ccx/common/Form/AppRadioGroupGrid';
import Space from '../../../common/antd/Space';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import styles from './DatastoreVolumeCard.module.less';
import {
    DatastoreConfigurations,
    SelectOptions,
} from './DataStoreNodeConfiguration';
import InstanceVolumeType from '../../../../types/InstanceVolumeType';

interface DatastoreVolumeCardProps {
    readonly testId?: string;
    readonly selectOptions: SelectOptions;
    readonly configuration: DatastoreConfigurations | undefined;
    readonly onChangeConfigurations: Function;
}

const IO1: string = 'io1';

function DatastoreVolumeCard({
    selectOptions,
    configuration,
    onChangeConfigurations,
    testId = 'DatastoreVolumeCard',
}: DatastoreVolumeCardProps) {
    return (
        <DatastoreScalingStepHeader title="Volume type" subtitle="">
            <Row gutter={[16, 16]} data-testid={testId}>
                <Col span={24}>
                    <AppRadioGroupGrid
                        data-testid={`${testId}StorageTypeRadio`}
                        value={configuration?.storageType}
                        defaultValue={configuration?.storageType}
                        onChange={(e) =>
                            onChangeConfigurations({
                                ...configuration,
                                storageType: e.target.value,
                            })
                        }
                        items={selectOptions?.volumeTypes.map(
                            (t: InstanceVolumeType) => {
                                return (
                                    <AppRadioGridButton
                                        key={t.code}
                                        data-testid={`${testId}StorageTypeRadio${t.code}`}
                                        value={t.code}
                                    >
                                        {' '}
                                        <div
                                            className={
                                                styles.InstanceVolumeTypeFormCentered
                                            }
                                        >
                                            <Space>
                                                {t.name}
                                                {(t.info && (
                                                    <InfoIcon info={t.info} />
                                                )) ||
                                                    undefined}
                                            </Space>
                                        </div>
                                    </AppRadioGridButton>
                                );
                            }
                        )}
                    />
                    {configuration?.storageType === IO1 && (
                        <div>
                            <p>
                                <strong>IOPS</strong>
                            </p>
                            <Form.Item
                                name="volumeIops"
                                label=""
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select IOPS',
                                    },
                                ]}
                            >
                                <InputNumber
                                    className={
                                        styles.InstanceVolumeTypeFormInputNumber
                                    }
                                    defaultValue={configuration.iops}
                                    min={1000}
                                    onChange={(value) => {
                                        onChangeConfigurations({
                                            ...configuration,
                                            iops: value,
                                        });
                                    }}
                                    size="large"
                                    data-testid={`${testId}VolumeIopsInput`}
                                />
                            </Form.Item>
                        </div>
                    )}
                </Col>
            </Row>
        </DatastoreScalingStepHeader>
    );
}

export default DatastoreVolumeCard;
