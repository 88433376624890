import React from 'react';
import styles from './AppFormEmailTag.module.less';
import { Tag } from 'antd';
import CcxComponentProps from '../../../core/CcxComponent';

interface Props extends CcxComponentProps {
    label: string;
    value: string;
    closable: boolean;
    onClose: (e: React.MouseEvent<HTMLElement>) => void;
}

const AppFormEmailTag = ({ label, value, closable, onClose }: Props) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(value);

    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };
    return (
        <Tag
            className={
                isValidEmail
                    ? styles.DatastoreEmailNotificationTags
                    : styles.DatastoreEmailNotificationTagsError
            }
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
        >
            {label}
        </Tag>
    );
};

export default AppFormEmailTag;
