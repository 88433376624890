import React, { ReactElement, useRef } from 'react';
import styles from './AppChartLiquid.module.less';
import { Liquid } from '@ant-design/charts';
import CcxComponentProps from '../core/CcxComponent';

interface Props extends CcxComponentProps {
    config: any;
}

function AppChartLiquid({
    config,
    testId = 'AppChartLiquid',
}: Props): ReactElement {
    const ref = useRef<any>();

    return (
        <div className={styles.AppChartLiquid} data-testid={testId}>
            <Liquid {...config} chartRef={ref} />
        </div>
    );
}

export default AppChartLiquid;
