export type CountryClassProps = {
    code: string;
    name: string;
};

export interface CountryInterface {
    code: string;
    name: string;
}

export default class Country implements CountryInterface {
    readonly code: string;
    readonly name: string;

    constructor(props: CountryClassProps) {
        this.code = props.code;
        this.name = props.name;
    }
}
