import React, { ReactElement, useState } from 'react';
import { Row, Col, Slider, InputNumber } from 'antd';
import styles from './AppForm.module.less';
import CcxComponentProps from '../../../core/CcxComponent';

interface Props extends CcxComponentProps {
    field: any; // @TODO: define a AppFormSliderField interface
    onChange?: Function;
}

function AppFormSlider({
    field,
    onChange,
    testId = 'AppFormSlider',
}: Props): ReactElement {
    const [sliderInputValue, setSliderInputValue] = useState(
        field.defaultValue
    );

    const onSliderChange = (value: number) => {
        setSliderInputValue(value);
        onChange && onChange(value);
    };

    const onSliderInputChange = (value: number | null) => {
        setSliderInputValue(value);
        onChange && onChange(value);
    };

    const baseTestId = testId || field.testId;

    return field.showInputNumber ? (
        <div data-testid={baseTestId}>
            <Row>
                <Col span={17}>
                    <Slider
                        className={styles.AppFormSlider}
                        min={field.min}
                        max={field.max}
                        marks={field.marks}
                        defaultValue={sliderInputValue}
                        value={sliderInputValue}
                        onChange={onSliderChange}
                        step={field.sliderStep}
                        data-testid={`${baseTestId}Slider`}
                    />
                </Col>
                <Col span={1}></Col>
                <Col span={6}>
                    <InputNumber
                        className={styles.AppFormInputNumber}
                        min={field.min}
                        max={field.max}
                        value={sliderInputValue}
                        defaultValue={sliderInputValue}
                        onChange={onSliderInputChange}
                        formatter={field.formatter}
                        parser={field.parser}
                        step={field.inputStep}
                        data-testid={`${baseTestId}InputNumber`}
                    />
                </Col>
            </Row>
        </div>
    ) : (
        <div data-testid={testId || field.testId || 'AppFormSlider'}>
            <Slider
                className={styles.AppFormSlider}
                min={field.min}
                max={field.max}
                marks={field.marks}
                step={field.sliderStep}
                data-testid={`${baseTestId}Slider`}
            />
        </div>
    );
}

export default AppFormSlider;
