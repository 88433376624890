import Vpc, { VpcClassProps, VpcInterface } from './Vpc';
import VpcPeeringData, {
    VpcPeeringDataClassProps,
    VpcPeeringDataInterface,
} from './VpcPeeringData';
import { VpcPeeringDTO } from '../sdk/VpcApi';
// import {ApiVpcPeeringInterface} from "../services/VpcService";

export enum VpcPeeringState {
    ACTIVE = 'ACTIVE',
    FAILED = 'FAILED',
    EXPIRED = 'EXPIRED',
    PROVISIONING = 'PROVISIONING',
    DELETING = 'DELETING',
    REJECTED = 'REJECTED',
    PENDING = 'PENDING-ACCEPTANCE',
    DELETED = 'DELETED',
}

type VpcPerringClassProps = VpcPeeringDTO & {
    vpc_id: string;
};

export interface VpcPeeringInterface {
    id?: string;
    targetCloudRegion?: string;
    targetCloudUserId?: string;
    targetCloudVpcId?: string;
    targetCidr?: string;
    state?: VpcPeeringState;
    stateMessage?: string;

    getCidrIpv4Block: Function;
    isStatusOk: Function;
    isStatusError: Function;
    isStatusWarning: Function;
    getStatus: Function;
    getStatusMessage: Function;
    isStatusDeleted: Function;
    isStatusDeleting: Function;
    isStatusPending: Function;
}

export default class VpcPeering implements VpcPeeringInterface {
    readonly id?: string;
    readonly targetCloudRegion?: string;
    readonly targetCloudUserId?: string;
    readonly targetCloudVpcId?: string;
    readonly targetCidr?: string;
    readonly state?: VpcPeeringState;
    readonly stateMessage?: string;
    readonly vpcId: string;

    constructor(props: VpcPerringClassProps) {
        this.id = props.id;
        this.targetCloudRegion = props.target_cloud_region;
        this.targetCloudUserId = props.target_cloud_user_id;
        this.targetCloudVpcId = props.target_cloud_vpc_id;
        this.targetCidr = props.target_cidr;
        this.state = props.state?.toUpperCase() as VpcPeeringState;
        this.stateMessage = props.state_message;
        this.vpcId = props.vpc_id;
    }

    getStatus() {
        return this.state;
    }

    getCidrIpv4Block() {
        return this.targetCidr;
    }

    isStatusOk() {
        return this.state === VpcPeeringState.ACTIVE;
    }

    isStatusError() {
        return [
            VpcPeeringState.FAILED,
            VpcPeeringState.EXPIRED,
            VpcPeeringState.REJECTED,
        ].includes(this.state as VpcPeeringState);
    }

    isStatusWarning() {
        return [
            VpcPeeringState.DELETED,
            VpcPeeringState.DELETING,
            VpcPeeringState.PROVISIONING,
        ].includes(this.state as VpcPeeringState);
    }

    isStatusDeleted() {
        return this.state === VpcPeeringState.DELETED;
    }

    isStatusDeleting() {
        return this.state === VpcPeeringState.DELETING;
    }

    isStatusPending() {
        return this.state === VpcPeeringState.PENDING;
    }
    getStatusMessage() {
        return this.stateMessage;
    }

    getExpirationTime() {
        throw new Error('Method not implemented.');
    }
}
