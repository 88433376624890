import { Modal, TableColumnsType, notification } from 'antd';
import AppTable from '../AppTable';
import CcxIconInfoCircleTwoTone from '../ccx/icons/CcxIconInfoCircleTwoTone';
import FirewallService from '../../services/FirewallService';
import CcxIconCheckCircleTwoTone from '../ccx/icons/CcxIconCheckCircleTwoTone';
import CcxIconCloseCircleTwoTone from '../ccx/icons/CcxIconCloseCircleTwoTone';
import AppConfirmDialog from '../AppConfirmDialog';
import CcxIconDeleteOutlined from '../ccx/icons/CcxIconDeleteOutlined';
import FirewallRulePort from '../../types/FirewallRulePort';
import FirewallRule from '../../types/FirewallRule';
import styles from './FirewallsPortsTable.module.less';

interface PortsProps {
    rule: FirewallRule;
    ports: FirewallRulePort[];
    dataStoreUuid: string;
    refresh: Function;
}

function FirewallPortsTable({
    rule,
    ports,
    dataStoreUuid,
    refresh,
}: PortsProps) {
    const columns: TableColumnsType = [
        { title: 'Port number', dataIndex: 'portNo', key: 'portNo' },
        { title: 'Port name', dataIndex: 'port', key: 'port' },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text: string, record: any) => {
                const onConfirmDelete = () => {
                    return new Promise(async (resolve, reject) => {
                        notification.open({
                            message: 'Remove trusted port',
                            description: 'The port will be removed soon.',
                            icon: <CcxIconInfoCircleTwoTone />,
                        });

                        Modal.destroyAll();

                        try {
                            await FirewallService.deleteRule({
                                rule: {
                                    ...rule,
                                    port: record.port,
                                },
                                uuid: dataStoreUuid,
                            });

                            refresh();

                            notification.open({
                                message: 'Remove trusted port',
                                description: 'Port successfully removed',
                                icon: (
                                    <CcxIconCheckCircleTwoTone twoToneColor="#52c41a" />
                                ),
                            });

                            resolve(null);
                        } catch (e) {
                            notification.open({
                                message: 'Remove trusted port',
                                description: `There was an error removing the port. ${e}`,
                                icon: (
                                    <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />
                                ),
                            });

                            console.error(e);

                            reject();
                        }
                    }).catch(() => console.log('Oops errors!'));
                };

                return (
                    <div className={styles.PortOptions}>
                        <AppConfirmDialog
                            critical={true}
                            onOk={onConfirmDelete}
                            content="The port will be removed."
                            actionIcon={<CcxIconDeleteOutlined />}
                        />
                    </div>
                );
            },
        },
    ];

    return (
        <AppTable
            className={styles.PortsTable}
            columns={columns}
            data={ports}
            rowKey="portNo"
            pagination={false}
        />
    );
}

export default FirewallPortsTable;
