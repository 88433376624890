export type PrometheusProxySqlQuestionsStatsBackendApiProps = {
    created: number;
    questions: number;
};

export default class PrometheusProxySqlQuestionsStats {
    readonly created: number;
    readonly questions: number;

    constructor(props: PrometheusProxySqlQuestionsStatsBackendApiProps) {
        this.created = props.created;
        this.questions = props.questions;
    }
}
