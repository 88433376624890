export type CmonHaProxyStatBackendApiProps = {
    bin: number;
    bout: number;
    comp_byp: number;
    comp_in: number;
    comp_out: number;
    comp_rsp: number;
    conn_rate: number;
    conn_rate_max: number;
    conn_tot: number;
    created: number;
    dcon: number;
    dreq: number;
    dresp: number;
    dses: number;
    ereq: number;
    hrsp_1xx: number;
    hrsp_2xx: number;
    hrsp_3xx: number;
    hrsp_4xx: number;
    hrsp_5xx: number;
    hrsp_other: number;
    iid: number;
    intercepted: number;
    interval: number;
    mode: string;
    pid: number;
    pxname: string;
    rate: number;
    rate_lim: number;
    rate_max: number;
    req_rate: number;
    req_rate_max: number;
    req_tot: number;
    sampleends: number;
    samplekey: string;
    scur: number;
    serverid: number;
    sid: number;
    slim: number;
    smax: number;
    status: string;
    stot: number;
    svname: string;
    type: number;
};

export default class CmonHaProxyStat {
    readonly bIn: number;
    readonly bOut: number;
    readonly compByp: number;
    readonly compIn: number;
    readonly compOut: number;
    readonly compRsp: number;
    readonly connRate: number;
    readonly connRateMax: number;
    readonly connTot: number;
    readonly created: number;
    readonly dCon: number;
    readonly dReq: number;
    readonly dResp: number;
    readonly dSes: number;
    readonly eReq: number;
    readonly hrsp1xx: number;
    readonly hrsp2xx: number;
    readonly hrsp3xx: number;
    readonly hrsp4xx: number;
    readonly hrsp5xx: number;
    readonly hrspOther: number;
    readonly iId: number;
    readonly intercepted: number;
    readonly interval: number;
    readonly mode: string;
    readonly pid: number;
    readonly pxName: string;
    readonly rate: number;
    readonly rateLim: number;
    readonly rateMax: number;
    readonly reqRate: number;
    readonly reqRateMax: number;
    readonly reqTot: number;
    readonly sampleEnds: number;
    readonly sampleKey: string;
    readonly scur: number;
    readonly serverId: number;
    readonly sId: number;
    readonly sLim: number;
    readonly sMax: number;
    readonly status: string;
    readonly sTot: number;
    readonly svName: string;
    readonly type: number;

    constructor(props: CmonHaProxyStatBackendApiProps) {
        this.bIn = props.bin;
        this.bOut = props.bout;
        this.compByp = props.comp_byp;
        this.compIn = props.comp_in;
        this.compOut = props.comp_out;
        this.compRsp = props.comp_rsp;
        this.connRate = props.conn_rate;
        this.connRateMax = props.conn_rate_max;
        this.connTot = props.conn_tot;
        this.created = props.created;
        this.dCon = props.dcon;
        this.dReq = props.dreq;
        this.dResp = props.dresp;
        this.dSes = props.dses;
        this.eReq = props.ereq;
        this.hrsp1xx = props.hrsp_1xx;
        this.hrsp2xx = props.hrsp_2xx;
        this.hrsp3xx = props.hrsp_3xx;
        this.hrsp4xx = props.hrsp_4xx;
        this.hrsp5xx = props.hrsp_5xx;
        this.hrspOther = props.hrsp_other;
        this.iId = props.iid;
        this.intercepted = props.intercepted;
        this.interval = props.interval;
        this.mode = props.mode;
        this.pid = props.pid;
        this.pxName = props.pxname;
        this.rate = props.rate;
        this.rateLim = props.rate_lim;
        this.rateMax = props.rate_max;
        this.reqRate = props.req_rate;
        this.reqRateMax = props.req_rate_max;
        this.reqTot = props.req_tot;
        this.sampleEnds = props.sampleends;
        this.sampleKey = props.samplekey;
        this.scur = props.scur;
        this.serverId = props.serverid;
        this.sId = props.sid;
        this.sLim = props.slim;
        this.sMax = props.smax;
        this.status = props.status;
        this.sTot = props.stot;
        this.svName = props.svname;
        this.type = props.type;
    }

    isFrontend() {
        return this.svName === 'FRONTEND';
    }

    isBackend() {
        return this.svName === 'BACKEND';
    }
}
