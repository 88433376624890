export type PrometheusLoadAvgStatsBackendApiProps = {
    created: number;
    loadavg1: number;
    loadavg5: number;
    loadavg15: number;
};

export default class PrometheusLoadAvgStats {
    readonly created: number;
    readonly loadAvg1: number;
    readonly loadAvg5: number;
    readonly loadAvg15: number;

    constructor(props: PrometheusLoadAvgStatsBackendApiProps) {
        this.created = props.created;
        this.loadAvg1 = props.loadavg1;
        this.loadAvg5 = props.loadavg5;
        this.loadAvg15 = props.loadavg15;
    }
}
