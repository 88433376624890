import { useEffect, useState } from 'react';
import ContentService, {
    InstanceSizeProps,
} from '../../services/ContentService';

export default function useContentInstanceSizes(cloudCode?: string) {
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [instanceSizes, setInstanceSizes] = useState<
        InstanceSizeProps[] | undefined
    >(undefined);

    const load = async () => {
        if (cloudCode) {
            try {
                const resultList = await ContentService.getInstanceSize(
                    cloudCode
                );
                setInstanceSizes(resultList);
                setLoading(false);
                setError(null);
            } catch (e: any) {
                setLoading(false);
                setError(e);
            }
        }
    };

    const refresh = () => {
        setUpdating(true);
        load();
        setUpdating(false);
    };

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            await load();
            setLoading(false);
        };

        init();
    }, [cloudCode]);

    return { instanceSizes, refresh, loading, updating, error };
}
