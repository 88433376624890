export type PrometheusProxySqlActiveTransactionsStatsBackendApiProps = {
    created: number;
    active_transactions: number;
};

export default class PrometheusProxySqlActiveTransactionsStats {
    readonly created: number;
    readonly activeTransactions: number;

    constructor(
        props: PrometheusProxySqlActiveTransactionsStatsBackendApiProps
    ) {
        this.created = props.created;
        this.activeTransactions = props.active_transactions;
    }
}
