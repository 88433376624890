import CcxComponentProps from '../../../core/CcxComponent';
import React, { useEffect, useState } from 'react';
import AppFormTag from './AppFormTag';
import { Tooltip } from 'antd';

interface Props extends CcxComponentProps {
    tags: any;
    length?: number;
}

function AppFormTags({ tags, length, testId = 'AppFormTags' }: Props) {
    const [subTags, setSubTags] = useState<any>([]);

    useEffect(() => {
        if (length && tags) {
            setSubTags(tags.slice(0, length));
        }
    }, [length, tags]);

    return (
        <div data-testid={`${testId}`}>
            <Tooltip title={tags.join(', ')} data-testid={`${testId}Tooltip`}>
                {subTags?.map((t: string, i: number) => {
                    return (
                        <AppFormTag
                            key={`Tag${i}`}
                            size="Small"
                            label={t}
                            closable={false}
                            toolTip={false}
                        />
                    );
                })}
            </Tooltip>
        </div>
    );
}

export default AppFormTags;
