import SubscriptionBillingUser from './SubscriptionBillingUser';

export type SubscriptionClassProps = {
    billing_user: any;
    err?: string;
    valid: boolean;
};

export interface SubscriptionInterface {
    billingUser?: SubscriptionBillingUser;
    valid: boolean;
}

export default class Subscription implements SubscriptionInterface {
    readonly billingUser?: SubscriptionBillingUser;
    readonly valid: boolean;

    constructor(props: SubscriptionClassProps) {
        this.billingUser =
            props.billing_user !== null
                ? new SubscriptionBillingUser(props.billing_user)
                : undefined;
        this.valid = props.valid;
    }

    isValid() {
        return this.valid;
    }
}
