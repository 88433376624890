import AdditionalFields, {
    AdditionalFieldsClassProps,
} from './AdditionalFields';
import FriendlyDateTime from './FriendlyDateTime';
import TermsAccepted, { TermsAcceptedClassProps } from './TermsAccepted';

export type UserClassProps = {
    accountId: string;
    id: string;
    login: string;
    isAdmin?: boolean;
    firstName: string;
    first_name: string;
    lastName: string;
    last_name: string;
    status: number;
    verifiedLevel: number;
    maxDataStores: number;
    emailConfirmed: boolean;
    pendingRemoval: boolean;
    allowNewsletters: boolean;
    termsAccepted: TermsAccepted;
    disabledText: string;
    additionalFields: AdditionalFieldsClassProps;
    created_at: string;
};

export default class User {
    public readonly accountId: string;
    public readonly id: string;
    public readonly login: string;
    public readonly isAdmin?: boolean;
    public readonly firstName: string;
    public readonly lastName: string;
    public readonly status: number;
    public readonly verifiedLevel: number;
    public readonly maxDataStores: number;
    public readonly emailConfirmed: boolean;
    public readonly pendingRemoval: boolean;
    public readonly allowNewsletters: boolean;
    public readonly termsAccepted?: TermsAcceptedClassProps;
    public readonly disabledText: string;
    public readonly additionalFields: AdditionalFields;
    public readonly createdAt: string;
    public readonly createdAtFull: string;

    constructor(props: UserClassProps) {
        // @Todo: user the real account id (this is not currently available in auth service
        this.accountId = '1';
        this.id = props.id;
        this.login = props.login;
        this.isAdmin = props.isAdmin;
        this.firstName = props.firstName || props.first_name;
        this.lastName = props.lastName || props.last_name;
        this.status = props.status;
        this.verifiedLevel = props.verifiedLevel ? 1 : 0;
        this.maxDataStores = props.maxDataStores || 0;
        this.emailConfirmed = props.emailConfirmed;
        this.pendingRemoval = props.pendingRemoval;
        this.allowNewsletters = props.allowNewsletters;
        this.termsAccepted = props.termsAccepted
            ? new TermsAccepted(props.termsAccepted)
            : undefined;
        this.disabledText =
            props.disabledText ||
            'Please contact sales to increase your account limits.';
        this.additionalFields = new AdditionalFields(props.additionalFields);
        this.createdAt = new FriendlyDateTime({
            date: props.created_at,
        }).getFriendlyDate();
        this.createdAtFull = new FriendlyDateTime({
            date: props.created_at,
        }).getFullDate();
    }

    isNewUser(): boolean {
        return this.additionalFields.newUser;
    }
}
