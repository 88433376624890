export type CouponClassProps = {
    amount_off: number;
    coupon: string;
    duration: string;
    duration_in_months: number;
    name: string;
    percent_off: number;
    valid: boolean;
};

export interface CouponInterface {
    amountOff: number;
    coupon: string;
    duration: string;
    durationInMonths: number;
    name: string;
    percentOff: number;
    valid: boolean;
    isValid: Function;
}

export default class Coupon implements CouponInterface {
    readonly amountOff: number;
    readonly coupon: string;
    readonly duration: string;
    readonly durationInMonths: number;
    readonly name: string;
    readonly percentOff: number;
    readonly valid: boolean;

    constructor(props: CouponClassProps) {
        this.amountOff = props.amount_off;
        this.coupon = props.coupon;
        this.duration = props.duration;
        this.durationInMonths = props.duration_in_months;
        this.name = props.name;
        this.percentOff = props.percent_off;
        this.valid = props.valid;
    }

    isValid() {
        return this.valid === true;
    }
}
