import UnauthorizedApiError from '../errors/UnauthorizedApiError';
import JsonError from '../errors/JsonError';
import {
    API_AUTH,
    AUTH_APP_URL,
    AUTH_REDIRECT_URL,
    AUTH_UI_APP_DISABLED,
    CHANGE_PASSWORD,
} from '../core/CcxEnv';
import { ChangePassword } from '../components/account/accountInfo/AccountInfo';

export type RequestProps = {
    data?: object;
    redirect?: boolean;
};

export default class AuthService {
    protected static addr(): string {
        throw new Error(`addr() is not implemented`);
    }

    protected static async request(
        method: string,
        props?: RequestProps
    ): Promise<any> {
        props = Object.assign({ redirect: true }, props || {});
        let body: string | undefined;
        if (props.data) {
            body = JSON.stringify(props.data);
        }
        const res = await fetch(`${this.addr()}/${method}`, {
            method: 'POST',
            credentials: 'include',
            body,
        });
        let resData;
        if (![201, 204].includes(res.status)) {
            resData = await res.json();
        }
        if (res.status === 401) {
            if (AUTH_REDIRECT_URL) {
                window.location.href = AUTH_REDIRECT_URL;
            } else {
                if (props.redirect === true) {
                    if (AUTH_UI_APP_DISABLED) {
                        window.location.href = AUTH_APP_URL;
                    } else {
                        window.location.href = `${AUTH_APP_URL}/?redirectTo=${encodeURIComponent(
                            window.location.href
                        )}`;
                    }
                }
            }
            throw new UnauthorizedApiError(resData);
        }
        if (res.status > 300) {
            throw new JsonError(resData);
        }
        return resData;
    }

    static async updatePassword(data: ChangePassword): Promise<boolean> {
        try {
            await fetch(`${API_AUTH}/${CHANGE_PASSWORD}`, {
                method: 'POST',
                body: JSON.stringify(data),
            });
            return true;
        } catch (e) {
            return false;
        }
    }
}
