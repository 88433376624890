import React, { ReactElement } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import styles from './VpcCidr.module.less';
import Vpc from '../../../types/Vpc';

interface Props extends CcxComponentProps {
    vpc: Vpc;
}

function VpcCidr({ vpc, testId = 'VpcCidr' }: Props): ReactElement {
    return (
        <div className={styles.VpcCidr} data-testid={testId}>
            <div data-testid={`${testId}ipBlock`}>{vpc.getCidrIpv4Block()}</div>
        </div>
    );
}

export default VpcCidr;
