import { useEffect, useState } from 'react';
import StatService from '../../services/StatService';
import CmonSqlStat from '../../types/CmonSqlStat';
import moment from 'moment';

interface Props {
    dataStoreUuid?: string;
    isPostgreSql?: boolean;
}

export default function useQueriesPerSecondStats({
    dataStoreUuid,
    isPostgreSql,
}: Props) {
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [stats, setStats] = useState<CmonSqlStat[] | undefined>(undefined);

    const load = async () => {
        const from = moment().subtract(15, 'minutes').toISOString();
        const to = moment().toISOString();

        if (dataStoreUuid) {
            try {
                const details = isPostgreSql
                    ? await StatService.getAggregatedPostgreSqlStats(
                          dataStoreUuid,
                          from,
                          to
                      )
                    : await StatService.getAggregatedMySqlStats(
                          dataStoreUuid,
                          from,
                          to
                      );
                const data = details[Object.keys(details)[0]];
                setStats(data.map((s: any) => new CmonSqlStat(s)));
                setError(null);
            } catch (e: any) {
                setError(e);
            }
        }
    };

    const refresh = () => {
        setUpdating(true);
        load();
        setUpdating(false);
    };

    // get deployments for the given user
    useEffect(() => {
        const init = async () => {
            setLoading(true);
            await load();
            setLoading(false);
        };

        init();
    }, [dataStoreUuid]);

    return { stats, refresh, loading, updating, error };
}
