import React, { ReactElement } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import styles from './PrettyNumber.module.less';

interface Props extends CcxComponentProps {
    precision?: number;
    prefix?: any;
    sufix?: any;
    value: number;
}

function PrettyNumber({
    className,
    precision,
    prefix,
    sufix,
    value,
    testId = 'PrettyNumber',
}: Props): ReactElement {
    const formattedValue = value.toFixed(precision);
    return (
        <div className={styles.PrettyNumber || className} data-testid={testId}>
            {prefix}
            {formattedValue}
            {sufix}
        </div>
    );
}

export default PrettyNumber;
