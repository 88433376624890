export const CMON_STATUS_OK = 'OK';
export const CMON_STATUS_DEGRADED = 'DEGRADED';
export const CMON_STATUS_UNKNOWN = 'UNKNOWN';
export const CMON_STATUS_DOWN = 'DOWN';
export const CMON_STATUS_UP = 'UP';

export interface CmonStatusInterface {
    status: string;
}

export default class CmonStatus implements CmonStatusInterface {
    readonly status: string;

    constructor(props: CmonStatusInterface) {
        this.status = props.status;
    }

    public isFunctional() {
        return (
            this.status !== CMON_STATUS_DEGRADED &&
            this.status !== CMON_STATUS_DOWN
        );
    }
}
