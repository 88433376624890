import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import CmonStatus from '../types/CmonStatus';
import CmonService from '../services/CmonService';

interface CmonStatusInterface {
    status: CmonStatus | undefined;
    loading: boolean | undefined;
    error: string | null;
}

const initialState = {
    status: undefined,
    loading: false,
    error: null,
} as CmonStatusInterface;

export const fetchCmonStatus = createAsyncThunk('fetchCmonStatus', async () => {
    try {
        const response = await CmonService.getCmonStatus();

        return response;
    } catch (error) {
        if (error instanceof Error) return console.log(error.message);

        return 'Error fetching jobs';
    }
});

const cmonSlice = createSlice({
    name: 'cmonStatus',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCmonStatus.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchCmonStatus.fulfilled, (state, action: any) => {
                state.status = action.payload;
                state.error = null;
                state.loading = false;
            })
            .addCase(fetchCmonStatus.rejected, (state, action) => {
                state.error = action.payload as string;
                state.loading = false;
            });
    },
});

export default cmonSlice.reducer;
