export type PrometheusHaProxyCurrentSessionRateBackendsStatsBackendApiProps = {
    created: number;
    haproxy_server_current_session_rate: number;
};

export default class PrometheusHaProxyCurrentSessionRateBackendsStats {
    readonly created: number;
    readonly haproxyServerCurrentSessionRate: number;

    constructor(
        props: PrometheusHaProxyCurrentSessionRateBackendsStatsBackendApiProps
    ) {
        this.created = props.created;
        this.haproxyServerCurrentSessionRate =
            props.haproxy_server_current_session_rate;
    }
}
