export type PrometheusHaProxyBackendServersTotalBytesOutStatsBackendApiProps = {
    created: number;
    haproxy_server_bytes_out_total: number;
};

export default class PrometheusHaProxyBackendServersTotalBytesOutStats {
    readonly created: number;
    readonly haproxyServerBytesOutTotal: number;

    constructor(
        props: PrometheusHaProxyBackendServersTotalBytesOutStatsBackendApiProps
    ) {
        this.created = props.created;
        this.haproxyServerBytesOutTotal = props.haproxy_server_bytes_out_total;
    }
}
