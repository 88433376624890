export type VpcAwsSubnetClassProps = {
    availability_zone: string;
    availability_zone_id: string;
    available_ip_address_count: number;
    cidr_ipv4_block: string;
    default_for_az: boolean;
    state: string;
    subnet_id: string;
    vpc_id: string;
};

export interface VpcAwsSubnetInterface {
    availabilityZone: string;
    availabilityZoneId: string;
    availableIpAddressCount: number;
    cidrIpv4Block: string;
    defaultForAz: boolean;
    state: string;
    subnetId: string;
    vpcId: string;
}

export default class VpcAwsSubnet implements VpcAwsSubnetInterface {
    readonly availabilityZone: string;
    readonly availabilityZoneId: string;
    readonly availableIpAddressCount: number;
    readonly cidrIpv4Block: string;
    readonly defaultForAz: boolean;
    readonly state: string;
    readonly subnetId: string;
    readonly vpcId: string;

    constructor(props: VpcAwsSubnetClassProps) {
        this.availableIpAddressCount = props.available_ip_address_count;
        this.availabilityZone = props.availability_zone;
        this.availabilityZoneId = props.availability_zone_id;
        this.cidrIpv4Block = props.cidr_ipv4_block;
        this.defaultForAz = props.default_for_az;
        this.state = props.state;
        this.subnetId = props.subnet_id;
        this.vpcId = props.vpc_id;
    }
}
