import PaymentMethod, {
    PaymentMethodClassProps,
    PaymentMethodInterface,
} from './PaymentMethod';

export type InvoiceClassProps = {
    amount: number;
    date: string;
    id: string;
    payment_method: any;
    pdf: string;
    status: string;
    currency_prefix: string;
    currency_suffix: string;
};

export interface InvoiceInterface {
    amount: number;
    date: string;
    invoiceNumber: string;
    paymentMethod: PaymentMethodInterface;
    pdf: string;
    status: string;
    getStatus: Function;
    getPaymentMethodString: Function;
    isStatusOk: Function;
}

const STATUS_OK = 'PAID';

export default class Invoice implements InvoiceInterface {
    readonly amount: number;
    readonly date: string;
    readonly invoiceNumber: string;
    readonly paymentMethod: PaymentMethodInterface;
    readonly pdf: string;
    readonly status: string;
    readonly currencyPrefix: string;
    readonly currencySuffix: string;

    constructor(props: InvoiceClassProps) {
        this.amount = props.amount;
        this.date = props.date;
        this.invoiceNumber = props.id;
        this.paymentMethod = props.payment_method;
        this.pdf = props.pdf;
        this.status = props.status;
        this.currencyPrefix = props.currency_prefix || '€';
        this.currencySuffix = props.currency_suffix || 'EUR';
    }

    getStatus() {
        return this.status[0].toUpperCase() + this.status.slice(1);
    }

    getPaymentMethodString() {
        return this.paymentMethod?.card
            ? `${this.paymentMethod?.card?.brand} *${this.paymentMethod?.card?.last4}`
            : 'N/A';
    }

    isStatusOk() {
        return this.status.toUpperCase() === STATUS_OK;
    }
}
