export type PrometheusHaProxyCurrentSessionsBackendServersStatsBackendApiProps =
    {
        created: number;
        haproxy_server_current_sessions: number;
    };

export default class PrometheusHaProxyCurrentSessionsBackendServersStats {
    readonly created: number;
    readonly haproxyServerCurrentSessions: number;

    constructor(
        props: PrometheusHaProxyCurrentSessionsBackendServersStatsBackendApiProps
    ) {
        this.created = props.created;
        this.haproxyServerCurrentSessions =
            props.haproxy_server_current_sessions;
    }
}
