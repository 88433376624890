import { useEffect, useState } from 'react';
import ContentService from '../../services/ContentService';

export default function useContentCidrs() {
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [cidrs, setCidrs] = useState<string[] | undefined>(undefined);

    const load = async () => {
        if (cidrs) {
            setLoading(false);
            // this is some sort of caching for the content api. Data form the content api will not change
            // so we only fetch once. After that, we return the previous values (cache)
            return;
        }

        try {
            const resultList = await ContentService.getCidrs();
            setCidrs(resultList);
            setLoading(false);
            setError(null);
        } catch (e: any) {
            setLoading(false);
            setError(e);
        }
    };

    const refresh = () => {
        setUpdating(true);
        load();
        setUpdating(false);
    };

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            await load();
            setLoading(false);
        };

        init();
    }, []);

    return { cidrs, refresh, loading, updating, error };
}
