import { useEffect, useState } from 'react';
import PrometheusStatService from '../../services/PrometheusStatService';
import PrometheusPostgreSqlActiveSessionsStats from '../../types/PrometheusPostgreSqlActiveSessionsStats';

export default function usePrometheusPostgreSqlActiveSessionsStats(
    uuid: string,
    host_uuid: string,
    from?: string,
    to?: string
) {
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [stats, setStats] = useState<
        PrometheusPostgreSqlActiveSessionsStats[] | undefined
    >(undefined);

    const load = async () => {
        if (uuid) {
            try {
                let details =
                    await PrometheusStatService.getPostgreSqlActiveSessions(
                        uuid,
                        host_uuid,
                        from,
                        to
                    );
                setStats(
                    details.map(
                        (d: any) =>
                            new PrometheusPostgreSqlActiveSessionsStats(d)
                    )
                );
                setError(null);
            } catch (e: any) {
                setError(e);
            }
        }
    };

    const refresh = () => {
        setUpdating(true);
        load();
        setUpdating(false);
    };

    // get deployments for the given user
    useEffect(() => {
        const init = async () => {
            setLoading(true);
            await load();
            setLoading(false);
        };

        init();
    }, [uuid, host_uuid, from, to]);

    return { stats, refresh, loading, updating, error };
}
