import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import AppLoader from './AppLoader';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { EXTERNAL_CSS_URL } from './core/CcxEnv';

if (EXTERNAL_CSS_URL) {
    // Create a link element
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = EXTERNAL_CSS_URL;

    // Append the link element to the head of the document synchronously (blocking)
    document.head.appendChild(link);
}

ReactDOM.render(
    // <React.StrictMode>
    <AppLoader>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </AppLoader>,
    // </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
