import { useEffect, useState } from 'react';
import AppEmpty from '../AppEmpty';
import { useParams } from 'react-router-dom';
import DatabaseGrowthService from '../../services/DatabaseGrowthService';
import {
    DateRangeInterface,
    DbGrowth,
    DbGrowthChartInterface,
    GrowthDbs,
} from '../../types/DbGrowth';
import moment from 'moment';
import DatabaseGrowthChart from './DatabaseGrowthChart';
import DatabaseGrowthHeading from './DatabaseGrowthHeading';
import DatabaseGrowthTable from './DatabaseGrowthTable';
import DeploymentsItem from '../../types/DeploymentsItem';

interface UrlProps {
    dataStoreUuid: string;
    projectUuid: string;
    tab: string;
}

type DatabaseGrowthProps = {
    currentDeployment: DeploymentsItem;
};

export type DateRangeProps = {
    Range: DateRangeInterface;
    fetch: boolean;
};

const ChartTypeFree: string = 'Free';
const ChartTypeOther: string = 'Other';
const ChartTypeIndexSize: string = 'Index Size';
const ChartTypeDataSize: string = 'Data Size';

const dateFormat = 'YYYY-MM-DD';

const DatabaseGrowth = ({ currentDeployment }: DatabaseGrowthProps) => {
    const { dataStoreUuid } = useParams<UrlProps>();

    const [databaseGrowthChartData, setDatabaseGrowthChartData] = useState<
        DbGrowthChartInterface[]
    >([]);

    const [databaseTable, setDatabaseTable] = useState<GrowthDbs[]>();

    const [storeCreatedDate, setStoreCreatedDate] = useState<any | undefined>(
        undefined
    );

    const [dateRange, setDateRange] = useState<DateRangeProps>({
        Range: {
            fromDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
            toDate: moment().format('YYYY-MM-DD'),
        },
        fetch: true,
    });
    const [activeDate, setActiveDate] = useState<moment.Moment>(moment());
    const [dbGrowthData, setDbGrowthData] = useState<DbGrowth[]>();

    const [growthLoading, setGrowthLoading] = useState<boolean>(false);

    const fetchData = async (dateRange: DateRangeInterface) => {
        setGrowthLoading(true);
        try {
            const response: any =
                await DatabaseGrowthService.getDatabaseGrowths(
                    dataStoreUuid,
                    dateRange
                );

            const sortedData: DbGrowth[] = ascendingSort(response.dbGrowthData);
            setDbGrowthData(sortedData);
            if (!storeCreatedDate) {
                getCreatedDate(sortedData);
            }

            processChartData(sortedData);
            processDBData(sortedData);
        } catch (error) {
            console.error(error);
        }

        setGrowthLoading(false);
    };

    useEffect(() => {
        if (dateRange.fetch) {
            fetchData(dateRange.Range);
        }
    }, [dateRange]);

    const onChangeDateRange = (newDate: DateRangeInterface) => {
        if (moment(newDate.fromDate).isAfter(newDate.toDate)) {
            return;
        }
        setDateRange({ Range: newDate, fetch: true });
    };

    const ascendingSort = (databaseGrowth: DbGrowth[]): DbGrowth[] => {
        return databaseGrowth.sort(
            (a: DbGrowth, b: DbGrowth) =>
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime()
        );
    };

    const processChartData = (databaseGrowth: DbGrowth[]) => {
        const chart: any[] = [];

        databaseGrowth?.forEach((db: DbGrowth) => {
            const createdDate = moment(db.createdAt.split('T')[0]);

            function getRelateValue(value: number): number {
                if (value === 0) {
                    return 0;
                }

                return (value / db.totalDirSize) * 100 > 1
                    ? value
                    : (1 / 100) * db.totalDirSize;
            }

            if (
                createdDate.isBetween(
                    dateRange.Range.fromDate,
                    dateRange.Range.toDate,
                    'day',
                    '[]'
                )
            ) {
                const other =
                    db.totalDirSize -
                    db.dataSize -
                    db.freeDataSize -
                    db.indexSize;

                chart.push(
                    {
                        value: db.freeDataSize,
                        realValue: db.freeDataSize,
                        type: ChartTypeFree,
                        date: moment.utc(db.createdAt).format('YYYY-MM-DD'),
                    },
                    {
                        value: getRelateValue(db.dataSize),
                        realValue: db.dataSize,
                        type: ChartTypeDataSize,
                        date: moment.utc(db.createdAt).format('YYYY-MM-DD'),
                    },
                    {
                        value: getRelateValue(db.indexSize),
                        realValue: db.indexSize,
                        type: ChartTypeIndexSize,
                        date: moment.utc(db.createdAt).format('YYYY-MM-DD'),
                    },
                    {
                        value: getRelateValue(other),
                        realValue: other,
                        type: ChartTypeOther,
                        date: moment.utc(db.createdAt).format('YYYY-MM-DD'),
                    }
                );
            }
        });

        chart.sort((a, b) => {
            return new Date(a.date).getTime() - new Date(b.date).getTime();
        });

        setDatabaseGrowthChartData(chart);
    };

    const processDBData = (
        databaseGrowth: DbGrowth[],
        date: moment.Moment = moment()
    ) => {
        const findMatchedDate = databaseGrowth.find((database: DbGrowth) =>
            moment(database.createdAt).isSame(date, 'day')
        );

        if (findMatchedDate) {
            setDatabaseTable(findMatchedDate.dbs);
        }
    };

    function getCreatedDate(dbGrowthList: DbGrowth[]) {
        if (dbGrowthList.length === 0) {
            const dateToday = moment().format(dateFormat).toString();

            setStoreCreatedDate(dateToday);

            setDateRange({
                Range: {
                    fromDate: dateToday,
                    toDate: moment().format(dateFormat).toString(),
                },
                fetch: false,
            });

            return null;
        }

        let createdDate = null;

        const createdStore = moment(dbGrowthList[0].createdAt)
            .utcOffset(0)
            .format(dateFormat)
            .toString();

        if (dbGrowthList.length > 14) {
            createdDate = moment()
                .subtract(Math.min(dbGrowthList.length, 14), 'days')
                .format(dateFormat)
                .toString();
        }

        setStoreCreatedDate(createdStore.toString());

        setDateRange({
            Range: {
                fromDate: createdDate ? createdDate : createdStore,
                toDate: moment().format(dateFormat).toString(),
            },
            fetch: false,
        });
    }

    const disabledDate = (current: moment.Moment): boolean => {
        const earliestDate = moment(storeCreatedDate).startOf('day');

        return !current.isBetween(earliestDate, moment(), 'day', '[]');
    };

    const onClickChartColumn = (data: any) => {
        const { date } = data?.data;
        setActiveDate(moment(date));
        if (dbGrowthData) {
            processDBData(dbGrowthData, date);
        }
    };

    return (
        <>
            {(currentDeployment && (
                <>
                    <DatabaseGrowthHeading
                        dateRange={dateRange}
                        dateFormat={dateFormat}
                        disabledDate={disabledDate}
                        onChangeDateRange={onChangeDateRange}
                    />

                    <DatabaseGrowthChart
                        activeDate={activeDate}
                        loading={growthLoading}
                        chartData={databaseGrowthChartData}
                        onClickChartColumn={onClickChartColumn}
                    />
                </>
            )) || <AppEmpty message="No datastore was retrieved" />}

            <DatabaseGrowthTable
                activeDate={activeDate}
                dataSource={databaseTable}
            />
        </>
    );
};

export default DatabaseGrowth;
