export type PrometheusHaProxyBackendTotalNumberOfConnectionErrorsStatsBackendApiProps =
    {
        created: number;
        haproxy_backend_connection_errors_total: number;
    };

export default class PrometheusHaProxyBackendTotalNumberOfConnectionErrorsStats {
    readonly created: number;
    readonly haproxyBackendConnectionErrorsTotal: number;

    constructor(
        props: PrometheusHaProxyBackendTotalNumberOfConnectionErrorsStatsBackendApiProps
    ) {
        this.created = props.created;
        this.haproxyBackendConnectionErrorsTotal =
            props.haproxy_backend_connection_errors_total;
    }
}
