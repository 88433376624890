import React, { ReactElement } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import CcxLogo from '../../ccx/common/CcxLogo';
import styles from './PaymentMethod.module.less';

interface Props extends CcxComponentProps {
    paymentMethod: any;
}

function PaymentMethod({
    testId = 'PaymentMethod',
    paymentMethod,
}: Props): ReactElement {
    return (
        <div className={styles.PaymentMethod} data-testid={testId}>
            <div data-testid={`${testId}Top`}>Payment method</div>
            {paymentMethod.brand && paymentMethod.last4 ? (
                <div data-testid={`${testId}Middle`}>
                    <CcxLogo
                        className={styles.PaymentMethodLogo}
                        logo={`ccx/${paymentMethod.brand}`}
                        size="medium"
                    />
                    <div data-testid={`${testId}CardLabel`}>
                        <strong>{`${paymentMethod.brand} *${paymentMethod.last4}`}</strong>
                    </div>
                </div>
            ) : (
                'N/A'
            )}
        </div>
    );
}

export default PaymentMethod;
