export type PrometheusPostgreSqlDeleteStatsBackendApiProps = {
    created: number;
    instance: string;
    pg_stat_database_tup_deleted: number;
};

export default class PrometheusPostgreSqlDeleteStats {
    readonly created: number;
    readonly instance: string;
    readonly pgStatDatabaseTupDeleted: number;

    constructor(props: PrometheusPostgreSqlDeleteStatsBackendApiProps) {
        this.created = props.created;
        this.instance = props.instance;
        this.pgStatDatabaseTupDeleted = props.pg_stat_database_tup_deleted;
    }
}
