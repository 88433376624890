import React, { useCallback, useEffect, useState } from 'react';
import BackupService from '../../services/BackupService';
import BackupSettings from '../../types/BackupSettings';
import { notification } from 'antd';
import CcxIconCloseCircleTwoTone from '../../components/ccx/icons/CcxIconCloseCircleTwoTone';

export default function useBackupRetention(uuid: string | undefined) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [retention, setRetention] = useState<BackupSettings | undefined>(
        undefined
    );

    const refresh = useCallback(async () => {
        if (uuid) {
            setLoading(true);

            try {
                const retention = await BackupService.getBackupRetention(uuid);
                setRetention(retention);
                setLoading(false);
                setError(null);
            } catch (e: any) {
                setLoading(false);
                setError(e);
            }

            setLoading(false);
        }
    }, [uuid]);

    const update = useCallback(
        async (retention: number) => {
            setLoading(true);
            try {
                if (!uuid) {
                    throw new Error('No datastore uuid provided');
                }
                await BackupService.updateBackupRetention(
                    uuid || '',
                    retention
                );
                setLoading(false);
            } catch (e: any) {
                notification.open({
                    message: 'Update retention period failed',
                    description: e.response?.data?.err || e.message,
                    icon: <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />,
                });
                throw e;
            } finally {
                setLoading(false);
            }
        },
        [uuid]
    );

    useEffect(() => {
        (async () => {
            await refresh();
        })();
    }, [uuid]);

    return { retention, refresh, update, loading, error };
}
