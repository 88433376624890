import { ReactComponent as CpuIcon } from '../../../assets/icons/cpu.svg';
import { ReactComponent as DiskIcon } from '../../../assets/icons/disk.svg';
import { ReactComponent as RamIcon } from '../../../assets/icons/ram.svg';

export type IconProps = {
    className?: string;
    height?: number;
    width?: number;
};

export function CcxIconCpu(props: IconProps) {
    return <CpuIcon {...props} />;
}

export function CcxIconDisk(props: IconProps) {
    return <DiskIcon {...props} />;
}

export function CcxIconRam(props: IconProps) {
    return <RamIcon {...props} />;
}
