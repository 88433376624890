export type CmonCpuStatBackendApiProps = {
    busy: number;
    cpuid: number;
    cpumhz: number;
    cpumodelname: string;
    cpuphysicalid: number;
    cputemp: number;
    created: number;
    guest: number;
    idle: number;
    interval: number;
    iowait: number;
    irq: number;
    loadavg1: number;
    loadavg15: number;
    loadavg5: number;
    sampleends: number;
    samplekey: string;
    serverid: number;
    steal: number;
    sys: number;
    uptime: number;
    user: number;
};

export default class CmonCpuStat {
    readonly busy: number;
    readonly cpuId: number;
    readonly cpuMhz: number;
    readonly cpuModelName: string;
    readonly cpuPhysicalId: number;
    readonly cpuTemp: number;
    readonly created: number;
    readonly guest: number;
    readonly idle: number;
    readonly interval: number;
    readonly ioWait: number;
    readonly irq: number;
    readonly loadAvg1: number;
    readonly loadAvg15: number;
    readonly loadAvg5: number;
    readonly sampleEnds: number;
    readonly sampleKey: string;
    readonly serverId: number;
    readonly steal: number;
    readonly sys: number;
    readonly uptime: number;
    readonly user: number;

    constructor(props: CmonCpuStatBackendApiProps) {
        this.busy = props.busy;
        this.cpuId = props.cpuid;
        this.cpuMhz = props.cpumhz;
        this.cpuModelName = props.cpumodelname;
        this.cpuPhysicalId = props.cpuphysicalid;
        this.cpuTemp = props.cputemp;
        this.created = props.created;
        this.guest = props.guest;
        this.idle = props.idle;
        this.interval = props.interval;
        this.ioWait = props.iowait;
        this.irq = props.irq;
        this.loadAvg1 = props.loadavg1;
        this.loadAvg15 = props.loadavg15;
        this.loadAvg5 = props.loadavg5;
        this.sampleEnds = props.sampleends;
        this.sampleKey = props.samplekey;
        this.serverId = props.serverid;
        this.steal = props.steal;
        this.sys = props.sys;
        this.uptime = props.uptime;
        this.user = props.user;
    }
}
