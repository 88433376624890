import { API_V2_DEPLOYMENT } from '../core/CcxEnv';
import { DateRangeInterface } from '../types/DbGrowth';
import DbGrowthData from '../types/DbGrowthData';
import ApiService from './ApiService';

export default class DatabaseGrowthService extends ApiService {
    /**
     * Get list of available database privileges.
     * @param {string} uuid
     * @param dateRange
     */
    static async getDatabaseGrowths(
        uuid: string,
        dateRange: DateRangeInterface
    ): Promise<DbGrowthData> {
        const response = await this.request(
            'GET',
            `data-stores/${uuid}/growth?fromDate=${dateRange.fromDate}&toDate=${dateRange.toDate}`
        );

        return new DbGrowthData(response);
    }

    protected static getApiUrl() {
        return API_V2_DEPLOYMENT;
    }
}
