export type PrometheusMssqlQPSStatsBackendApiProps = {
    created: number;
    QUERIES: number;
};

export default class PrometheusMssqlQPSStats {
    readonly created: number;
    readonly transactions: number;

    constructor(props: PrometheusMssqlQPSStatsBackendApiProps) {
        this.created = props.created;
        this.transactions = props.QUERIES;
    }
}
