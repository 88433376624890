import DbDatabase from './DbDatabase';

// export type DbDatabasesClassProps = {
//   clusters: any[];
//   debug_messages: string[];
//   reply_received: string;
//   request_created: string;
//   request_id: number;
//   request_processed: string;
//   request_status: string;
//   request_user_id: number;
// };

export interface DbDatabasesInterface {
    databases: DbDatabase[];
    // debugMessages: string[];
    // replyReceived: string;
    // requestCreated: string;
    // requestId: number;
    // requestProcessed: string;
    // requestStatus: string;
    // requestUserId: number;
}

export default class DbDatabases implements DbDatabasesInterface {
    readonly databases: DbDatabase[];
    // readonly debugMessages: string[];
    // readonly replyReceived: string;
    // readonly requestCreated: string;
    // readonly requestId: number;
    // readonly requestProcessed: string;
    // readonly requestStatus: string;
    // readonly requestUserId: number;

    constructor(props?: any[]) {
        this.databases =
            props?.map((d: any) => {
                return new DbDatabase(d);
            }) || [];
        // this.clusters = props.clusters;
        // this.debugMessages = props.debug_messages;
        // this.replyReceived = props.reply_received;
        // this.requestCreated = props.request_created;
        // this.requestId = props.request_id;
        // this.requestProcessed = props.request_processed;
        // this.requestStatus = props.request_status;
        // this.requestUserId = props.request_user_id;
    }
}
