import { CheckCircleOutlined } from '@ant-design/icons';
import React from 'react';
import CcxComponentProps from '../../../core/CcxComponent';

interface Props extends CcxComponentProps {}

const CcxIconCheckCircle = ({ testId = 'CcxIconCheckCircle' }: Props) => (
    <CheckCircleOutlined data-testid={testId} />
);

export default CcxIconCheckCircle;
