import React from 'react';
import styles from './AppLoading.module.less';
import { Spin } from 'antd';
import CcxComponentProps from '../core/CcxComponent';

interface Props extends CcxComponentProps {}

function AppLoading({ testId = 'AppLoading' }: Props) {
    return (
        <div className={styles.AppLoadingContainer} data-testid={testId}>
            <Spin size="large" />
        </div>
    );
}

export default AppLoading;
