import React, { ReactElement, ReactNode } from 'react';
import CcxComponentProps from '../../core/CcxComponent';
import styles from './FloatingContent.module.less';

interface Props extends CcxComponentProps {
    children: ReactNode;
    placement?: string;
}

function FloatingContent({
    children,
    placement = 'BottomLeft',
    testId = 'FloatingContent',
}: Props): ReactElement {
    return (
        <div
            className={`${styles.FloatingContent} ${styles.FloatingContentPlacementBottomLeft}`}
            data-testid={testId}
        >
            {children}
        </div>
    );
}

export default FloatingContent;
