export type PrometheusHaProxyBackendServersUpDownStatsBackendApiProps = {
    created: number;
    haproxy_server_down: number;
    haproxy_server_up: number;
};

export default class PrometheusHaProxyBackendServersUpDownStats {
    readonly created: number;
    readonly haproxyServerDown: number;
    readonly haproxyServerUp: number;

    constructor(
        props: PrometheusHaProxyBackendServersUpDownStatsBackendApiProps
    ) {
        this.created = props.created;
        this.haproxyServerDown = props.haproxy_server_down;
        this.haproxyServerUp = props.haproxy_server_up;
    }
}
