import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import CcxComponentProps from '../../../core/CcxComponent';

interface Props extends CcxComponentProps {}

const CcxIconDownOutlined = ({ testId }: Props) => {
    return <DownOutlined data-testid={testId || 'CcxIconDownOutlined'} />;
};

export default CcxIconDownOutlined;
