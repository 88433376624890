import DbNode from '../types/DbNode';
import DeploymentsItem from '../types/DeploymentsItem';
import Job, {
    STATUS_ERROR,
    TYPE_ADD,
    TYPE_PROMOTE_REPLICA,
    TYPE_REMOVE,
    TYPE_UPGRADE_SERVERS,
    TYPE_VOLUME_SIZE,
    TYPE_WORK,
    TYPE_MODIFYDBCONFIG,
    TYPE_REPAIR_NODE,
    STATUS_FINISHED,
    TYPE_RESTORE_BACKUP,
    JOB_TYPE_CHANGE_VOLUME_TYPE,
    JOB_TYPE_FAILOVER,
} from '../types/Job';

export class BackendModel {
    constructor(props: Record<string, any>) {
        Object.assign(this, camelCaseObject(props));
    }

    toJSON(): Record<string, any> {
        return snakeCaseObject(this);
    }
}

/**
 * @name camelCase
 * @desc Converts snake_case to camelCase
 * @param {string} inputString
 * @returns {string}
 * @exports
 */
export const camelCase = (inputString: string): string =>
    inputString.split('_').map(camelCaseConvert).join('');

/**
 * @name snakeCase
 * @desc Converts camelCase to snake_case
 * @param {string} inputString
 * @returns {string}
 * @exports
 */
export const snakeCase = (inputString: string): string =>
    inputString.split(snakeCaseRegexp).join('_').toLowerCase();

/**
 * @name camelCaseObject
 * @desc Converts objects keys from snake_case to camelCase and returns new object.
 * @param {Record<string,any>} inputObject
 * @returns {Record<string,any>}
 * @exports
 */
export const camelCaseObject = (
    inputObject: Record<string, any>
): Record<string, any> => objectKeysCase(inputObject, camelCase);

export const snakeCaseObject = (
    inputObject: Record<string, any>
): Record<string, any> => objectKeysCase(inputObject, snakeCase);

const objectKeysCase = (
    inputObject: Record<string, any>,
    convertor: Function
): Record<string, any> =>
    Object.keys(inputObject).reduce((res: Record<string, any>, k: string) => {
        res[convertor(k)] = inputObject[k];
        return res;
    }, {} as Record<string, any>);

const snakeCaseRegexp = /(?=[A-Z])/;

const camelCaseConvert = (s: string, i: number): string =>
    `${i > 0 ? ucFirst(s) : lcFirst(s)}${s.substring(1)}`;

const ucFirst = (s: string): string => s.charAt(0).toUpperCase();

const lcFirst = (s: string): string => s.charAt(0).toLowerCase();

export const getHostnameOrIP = (fqdn: string) => {
    if (fqdn?.includes(':')) {
        const ip = fqdn?.split(':');
        return ip[0];
    } else {
        const splittedFqdn = fqdn?.split('.');
        return splittedFqdn && `${splittedFqdn[0]}`;
    }
};

export const normalizeHostPort = (host: string, port: string) => {
    if (host?.includes(':')) {
        return host;
    } else {
        return `${host}:${port}`;
    }
};

const primaryRole = 'master';
export const sortNodesByRole = (nodes: any[]) => {
    // Separate nodes with the primary role from others
    const primaryNodes = nodes.filter((node: any) =>
        node.role.includes(primaryRole)
    );
    const otherNodes = nodes.filter(
        (node: any) => !node.role.includes(primaryRole)
    );

    // Sort the primary nodes to be at the top
    primaryNodes.sort((a: any, b: any) => a.role.localeCompare(b.role));

    // Combine the sorted primary nodes with the other nodes
    return [...primaryNodes, ...otherNodes];
};

export const getNonErroredJobs = (jobs: Job[]) => {
    return jobs
        ?.filter((job: Job) => {
            return (
                job.status !== STATUS_ERROR && job.status !== STATUS_FINISHED
            );
        })
        ?.filter((job: Job) => {
            return [
                TYPE_ADD,
                TYPE_REMOVE,
                TYPE_VOLUME_SIZE,
                TYPE_PROMOTE_REPLICA,
                TYPE_UPGRADE_SERVERS,
                TYPE_WORK,
                TYPE_MODIFYDBCONFIG,
                TYPE_REPAIR_NODE,
                TYPE_RESTORE_BACKUP,
                JOB_TYPE_CHANGE_VOLUME_TYPE,
                JOB_TYPE_FAILOVER,
            ].includes(job.type);
        });
};

export const isRestoringDatastore = (jobs: Job[]) => {
    const restoringJobs = getNonErroredJobs(jobs);
    if (restoringJobs) {
        const isRestoring = restoringJobs.find(
            (job: Job) => job.type === TYPE_RESTORE_BACKUP
        );
        return !!isRestoring;
    }
    return false;
};

export const singularOrPlural = (count: number, name: string): string => {
    return count === 1 ? name : `${name}s`;
};

export const objectToQueryParams = (obj: any): string => {
    const params = new URLSearchParams();

    Object.entries(obj).forEach(([key, value]: [string, any]) => {
        if (Array.isArray(value)) {
            value.forEach((item) => {
                params.append(key, item);
            });
        } else {
            params.set(key, value.toString());
        }
    });

    return params.toString();
};

export const excludeFakeAddresses = (email: string[] | undefined) => {
    if (!email) return [];

    return email.filter(
        (email) =>
            !email?.includes('@ext.id') && !email?.includes('@lintasarta.extid')
    );
};
