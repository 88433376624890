import React, { ReactElement } from 'react';
import styles from './AppEmpty.module.less';
import { Empty } from 'antd';
import CcxComponentProps from '../core/CcxComponent';
import AppCdnIcon from './ccx/common/AppCdnIcon';

interface Props extends CcxComponentProps {
    message?: string | ReactElement;
    children?: any;
    defaultImage?: boolean;
}

function AppEmpty({
    message,
    children,
    testId = 'AppEmpty',
    defaultImage = false,
}: Props) {
    return (
        <Empty
            data-testid={testId}
            description={
                <span
                    className={styles.AppEmptyMessage}
                    data-testid={`${testId}Description`}
                >
                    {message ? message : 'No data to display.'}
                </span>
            }
            image={
                defaultImage ? (
                    Empty.PRESENTED_IMAGE_DEFAULT
                ) : (
                    <AppCdnIcon
                        icon="ccx-empty"
                        size=""
                        testId={`${testId}Image`}
                        height={60}
                        width={120}
                    />
                )
            }
        >
            {children}
        </Empty>
    );
}

export default AppEmpty;
