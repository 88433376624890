import React, { ReactElement } from 'react';
import CcxComponentProps from '../../../../core/CcxComponent';
import DeploymentsItem from '../../../../types/DeploymentsItem';
import Service from '../../../../types/Service';
import CopyToClipboardText from '../../../CopyToClipboardText';
import styles from './ServiceInformation.module.less';
import useDatastoreServiceDsn from './useDatastoreServiceDsn';
import ServiceStatus from './ServiceStatus';
import { getHostnameOrIP } from '../../../../core/helpers';

interface Props extends CcxComponentProps {
    dataStore?: DeploymentsItem;
    service?: Service;
    type?: string;
    deploying?: boolean;
    copyButton?: boolean;
    copyDsn?: boolean;
    datetimeInfo?: boolean;
    instanceInfo?: boolean;
}

function ServiceInformation({
    dataStore,
    service,
    type = 'db',
    testId = 'ServiceInformation',
    deploying = false,
    copyButton = true,
    copyDsn = false,
    datetimeInfo = true,
    instanceInfo = true,
}: Props): ReactElement {
    const { dsn } = useDatastoreServiceDsn({ dataStore, service, type });

    const fqdn = type === 'db' ? service?.getFQDN() : service?.getHostname();

    const ipSection = service && (
        <>
            {deploying ? (
                <div className={styles.ServiceInformationMiddle}>
                    ---.---.---.---
                </div>
            ) : (
                <div className={styles.ServiceInformationMiddle}>
                    {fqdn &&
                        (copyButton ? (
                            <CopyToClipboardText
                                text={fqdn}
                                preview={getHostnameOrIP(fqdn)}
                                tooltip={fqdn}
                                hoverable={false}
                                nowrap={true}
                            />
                        ) : (
                            getHostnameOrIP(fqdn)
                        ))}
                </div>
            )}
        </>
    );

    const copyDsnSection = copyDsn && dsn && (
        <CopyToClipboardText
            text={dsn}
            tooltip={dsn}
            buttonProps={{
                children: <span>Copy DSN</span>,
                size: 'small',
                className: styles.ServiceInformationDsnBottom,
            }}
            hoverable={false}
            nowrap={true}
            showPreview={false}
            successMessage={'DSN copied to clipboard'}
        />
    );

    const serviceStatus = (
        <div className={styles.ServiceInformationServiceStatus}>
            <ServiceStatus
                service={service}
                testId={`${testId}${service?.getServiceUuid()}ServiceStatus`}
                updating={false}
            />
        </div>
    );

    return (
        <div className={styles.ServiceInformation} data-testid={testId}>
            <div className={styles.ServiceInformationStatusDot}>
                {ipSection}
                {serviceStatus}
            </div>
            {copyDsnSection}
        </div>
    );
}

export default ServiceInformation;
