import React, { ReactElement } from 'react';
import CcxComponentProps from '../../../../core/CcxComponent';
import DeploymentsItem from '../../../../types/DeploymentsItem';
import LazyLoader from '../../../LazyLoader';
import styles from './DataStoreOverviewQueriesPerSecond.module.less';
import NoChartAvailable from '../../../ccx/common/NoChartAvailable';
import DataStoreChart from '../DataStoreChart';

interface Props extends CcxComponentProps {
    dataStore?: DeploymentsItem;
}

function DataStoreOverviewQueriesPerSecond({
    dataStore,
    testId = 'DataStoreOverviewQueriesPerSecond',
}: Props): ReactElement {
    return (
        <div
            className={styles.DataStoreOverviewQueriesPerSecond}
            data-testid={testId}
        >
            <div>
                {dataStore ? (
                    <>
                        <header>Queries per second</header>
                        <main>
                            {dataStore?.isDeploying() ||
                            dataStore?.isDeleting() ||
                            dataStore?.isStatusError() ||
                            dataStore?.isStatusWarning() ? (
                                <NoChartAvailable
                                    testId={`${testId}${dataStore?.dataStoreUuid}NoChartAvailable`}
                                />
                            ) : (
                                <DataStoreChart
                                    dataStore={dataStore}
                                    tiny={false}
                                />
                            )}
                        </main>
                    </>
                ) : (
                    <LazyLoader type="row" testId={`${testId}Loader`} />
                )}
            </div>
        </div>
    );
}

export default DataStoreOverviewQueriesPerSecond;
