import ApiService from './ApiService';
import Deployment from '../types/Deployment';
import CmonMemoryStat from '../types/CmonMemoryStat';
import CmonCpuStat from '../types/CmonCpuStat';
import CmonDiskStat from '../types/CmonDiskStat';
import CmonNetStat from '../types/CmonNetStat';
import CmonSqlStat from '../types/CmonSqlStat';
import CmonProxySqlStat from '../types/CmonProxySqlStat';
import { API_STAT } from '../core/CcxEnv';

// constants
const API_URL = API_STAT;

export default class StatService extends ApiService {
    /**
     * Get memory stats.
     */
    static async getMemoryStats(
        uuid: string,
        host: string,
        port: number,
        from?: string,
        to?: string
    ): Promise<CmonMemoryStat[]> {
        return await this.request(
            'GET',
            `stat/${uuid}/memorystat${this.getQueryString(
                host,
                port,
                from,
                to
            )}`
        );
    }

    /**
     * Get network stats.
     */
    static async getNetStats(
        uuid: string,
        host: string,
        port: number,
        from?: string,
        to?: string
    ): Promise<CmonNetStat[]> {
        return await this.request(
            'GET',
            `stat/${uuid}/netstat${this.getQueryString(host, port, from, to)}`
        );
    }

    /**
     * Get disk stats.
     */
    static async getDiskStats(
        uuid: string,
        host: string,
        port: number,
        from?: string,
        to?: string
    ): Promise<CmonDiskStat[]> {
        return await this.request(
            'GET',
            `stat/${uuid}/diskstat${this.getQueryString(host, port, from, to)}`
        );
    }

    /**
     * Get CPU stats.
     */
    static async getCpuStats(
        uuid: string,
        host: string,
        port: number,
        from?: string,
        to?: string
    ): Promise<CmonCpuStat[]> {
        return await this.request(
            'GET',
            `stat/${uuid}/cpustat${this.getQueryString(host, port, from, to)}`
        );
    }

    /**
     * Get SQL stats.
     */
    static async getSqlStats(
        uuid: string,
        host: string,
        port: number,
        from?: string,
        to?: string
    ): Promise<CmonSqlStat[]> {
        return await this.request(
            'GET',
            `stat/${uuid}/sqlstat${this.getQueryString(host, port, from, to)}`
        );
    }

    /**
     * Get DB stats.
     */
    static async getDbStats(uuid: string): Promise<Deployment[]> {
        return await this.request('GET', `stat/${uuid}/dbstat`);
    }

    /**
     * Get TCP stats.
     */
    static async getTcpStats(uuid: string): Promise<Deployment[]> {
        return await this.request('GET', `stat/${uuid}/tcpStat`);
    }

    /**
     * Get NDB stats.
     */
    static async getNdbStats(uuid: string): Promise<Deployment[]> {
        return await this.request('GET', `stat/${uuid}/ndbstat`);
    }

    /**
     * Get ProxySLQ stats.
     */
    static async getProxySqlStats(
        uuid: string,
        host: string,
        port: number,
        from?: string,
        to?: string
    ): Promise<CmonProxySqlStat[]> {
        return await this.request(
            'GET',
            `stat/${uuid}/proxysqlstat${this.getQueryString(
                host,
                port,
                from,
                to
            )}`
        );
    }

    /**
     * Get Mongo stats.
     */
    static async getMongoStats(uuid: string): Promise<Deployment[]> {
        return await this.request('GET', `stat/${uuid}/mongoStat`);
    }

    /**
     * Get HAProxy stats.
     */
    static async getHaProxyStats(
        uuid: string,
        host: string,
        port: number,
        from?: string,
        to?: string
    ): Promise<CmonProxySqlStat[]> {
        return await this.request(
            'GET',
            `stat/${uuid}/haproxystat${this.getQueryString(
                host,
                port,
                from,
                to
            )}`
        );
    }

    /**
     * Get aggregated SQL Stats to display in the Data Store list
     * @param dataStoreUuid
     * @returns
     */
    static async getAggregatedMySqlStats(
        dataStoreUuid: string,
        from?: string,
        to?: string
    ): Promise<any> {
        let queryStr = `?include=QUERIES,created&calculate_per_sec=true`;
        if (from) {
            queryStr = `${queryStr}&from=${encodeURIComponent(from)}`;
        }
        if (to) {
            queryStr = `${queryStr}&to=${encodeURIComponent(to)}`;
        }

        return await this.request(
            'GET',
            `stat/${dataStoreUuid}/sqlstat/aggregate${queryStr}`
        );
    }

    /**
     * Get aggregated SQL Stats to display in the Data Store list
     * @param dataStoreUuid
     * @returns
     */

    static async getAggregatedPostgreSqlStats(
        dataStoreUuid: string,
        from?: string,
        to?: string
    ): Promise<any> {
        let queryStr = `?include=commits,rollbacks,created&calculate_per_sec=true`;
        if (from) {
            queryStr = `${queryStr}&from=${encodeURIComponent(from)}`;
        }
        if (to) {
            queryStr = `${queryStr}&to=${encodeURIComponent(to)}`;
        }

        return await this.request(
            'GET',
            `stat/${dataStoreUuid}/sqlstat/aggregate${queryStr}`
        );
    }

    protected static getQueryString(
        host: string,
        port: number,
        from?: string,
        to?: string
    ): string {
        let queryStr = `?host_port=${host}:${port}`;
        if (from) {
            queryStr = `${queryStr}&from=${encodeURIComponent(from)}`;
        }
        if (to) {
            queryStr = `${queryStr}&to=${encodeURIComponent(to)}`;
        }
        return queryStr;
    }

    protected static getApiUrl() {
        return API_URL;
    }
}
