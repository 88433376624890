export interface ParameterProps {
    value: string;
    description: string;
    type: string;
    validation_options: string;
    default_value: string;
}

export enum Type {
    TEXT = 'text',
    NUMBER = 'number',
    BOOLEAN = 'boolean',
    SELECT = 'select',
}

function asInt(n: any): number {
    try {
        return parseInt(n, 10);
    } catch (e) {
        return 0;
    }
}

function parseValidationOptions(validationOptions: any) {
    const result: any = {};

    // Check for 'oneof' in validationOptions
    const oneofMatch = validationOptions.match(/oneof=([A-Z,]+)/);
    if (oneofMatch) {
        result.oneof = oneofMatch[1].split(',');
    }

    // Check for 'min' and 'max' in validationOptions
    const minMatch = validationOptions.match(/min=([0-9]+)/);
    const maxMatch = validationOptions.match(/max=([0-9]+)/);
    if (minMatch || maxMatch) {
        result.min = minMatch ? minMatch[1] : undefined;
        result.max = maxMatch ? maxMatch[1] : undefined;
    }

    return result;
}

export class Parameter {
    readonly name: string;
    readonly value: string;
    readonly description: string;
    readonly type: Type;
    readonly defaultValue: string;
    readonly max: number;
    readonly min: number;
    readonly options: string[];

    constructor(name: string, props: ParameterProps) {
        this.name = name;
        this.value = props.value;
        this.description = props.description;
        this.defaultValue = props.default_value;

        switch (props.type) {
            case 'text':
                this.type = Type.TEXT;
                break;
            case 'number':
                this.type = Type.NUMBER;
                break;
            case 'boolean':
                this.type = Type.BOOLEAN;
                break;
            case 'select':
                this.type = Type.SELECT;
                break;
            default:
                this.type = Type.TEXT;
                break;
        }

        const validationOpts = parseValidationOptions(props.validation_options);

        this.max = asInt(validationOpts.max ?? 0);
        this.min = asInt(validationOpts.min ?? 0);
        this.options = validationOpts.oneof ?? [];
    }

    public validate(value: string): boolean {
        return true;
    }
}
