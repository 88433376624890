export type PrometheusPostgreSqlDiskIOUtilizationStatsBackendApiProps = {
    created: number;
    disk_io_time_seconds_total: number;
};

export default class PrometheusPostgreSqlDiskIOUtilizationStats {
    readonly created: number;
    readonly diskIOTimeSecondsTotal: number;

    constructor(
        props: PrometheusPostgreSqlDiskIOUtilizationStatsBackendApiProps
    ) {
        this.created = props.created;
        this.diskIOTimeSecondsTotal = props.disk_io_time_seconds_total;
    }
}
