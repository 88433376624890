import React, { ReactElement, useEffect, useState } from 'react';
import AppEmpty from '../../AppEmpty';
import styles from './ProxySqlLbCompareDashboard.module.less';
import { Col, Row } from 'antd';
import useProxySqlStatsCompare from '../../../core/hooks/useProxySqlStatsCompare';
import ProxySqlQuestionsChart from '../../ccx/charts/ProxySqlQuestionsChart';
import ProxySqlSlowQueriesChart from '../../ccx/charts/ProxySqlSlowQueriesChart';
import ProxySqlActiveTransactionsChart from '../../ccx/charts/ProxySqlActiveTransactionsChart';
import ProxySqlQueryCacheMemoryBytesChart from '../../ccx/charts/ProxySqlQueryCacheMemoryBytesChart';
import ProxySqlClientConnectionsChart from '../../ccx/charts/ProxySqlClientConnectionsChart';
import ProxySqlServerConnectionsChart from '../../ccx/charts/ProxySqlServerConnectionsChart';

interface Props {
    uuid: string;
    from?: string;
    to?: string;
    interval: number;
    hidden?: boolean;
    displaySummary?: boolean;
    selectedNodes: any[];
}

function ProxySqlLbCompareDashboard({
    uuid,
    from,
    to,
    interval,
    hidden,
    displaySummary,
    selectedNodes,
}: Props): ReactElement {
    const {
        stats: proxySqlStats,
        refresh: proxySqlStatsRefresh,
        loading: proxySqlStatsLoading,
    } = useProxySqlStatsCompare(uuid, selectedNodes, from, to);
    const [sqlInterval, setSqlInterval] = useState<number | undefined>(
        undefined
    );
    const [refreshInterval, setRefreshInterval] = useState(interval);

    useEffect(() => {
        let interval: any;
        if (proxySqlStats && refreshInterval > 0) {
            interval = setInterval(() => {
                proxySqlStatsRefresh();
            }, refreshInterval);
            setSqlInterval(interval);
        }
        return () => clearInterval(interval);
    }, [proxySqlStats, refreshInterval]);

    useEffect(() => {
        if (interval === 0) {
            // when interval is 0, we must pause the refresh (clear existing intervals)
            clearInterval(sqlInterval);
            setSqlInterval(0);
        }
        setRefreshInterval(interval);
    }, [interval]);

    useEffect(() => {
        if (hidden) {
            // clear interval to stop updating data if the tab is not active (charts are not visible)
            clearInterval(sqlInterval);
            setSqlInterval(0);
        } else if (sqlInterval === 0) {
            // force refresh if the interval is 0, otherwise, the interval will refresh data soon
            // interval is undefined on the first load
            // interval will be 0 when paused or tab is hidden
            proxySqlStatsRefresh();
        }
    }, [hidden]);

    return (
        <section className={styles.ProxySqlLbCompareDashboard}>
            {selectedNodes.length > 0 ? (
                <Row
                    gutter={[
                        { xs: 8, sm: 16, md: 24, lg: 32, xl: 40, xxl: 48 },
                        { xs: 8, sm: 16, md: 24, lg: 32, xl: 40, xxl: 48 },
                    ]}
                >
                    {selectedNodes.map((n: any, idx: number) => (
                        <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} key={n}>
                            <h1>{n}</h1>

                            <Row
                                gutter={[
                                    {
                                        xs: 8,
                                        sm: 16,
                                        md: 24,
                                        lg: 32,
                                        xl: 40,
                                        xxl: 48,
                                    },
                                    {
                                        xs: 8,
                                        sm: 16,
                                        md: 24,
                                        lg: 32,
                                        xl: 40,
                                        xxl: 48,
                                    },
                                ]}
                                className={styles.ProxySqlLbCompareDashboardRow}
                            >
                                <Col
                                    className={
                                        styles.ProxySqlLbCompareDashboardCol
                                    }
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                >
                                    <ProxySqlQuestionsChart
                                        data={
                                            proxySqlStats
                                                ? proxySqlStats[idx]
                                                : []
                                        }
                                        loading={proxySqlStatsLoading}
                                        displaySummary={displaySummary}
                                    />
                                </Col>
                                <Col
                                    className={
                                        styles.ProxySqlLbCompareDashboardCol
                                    }
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                >
                                    <ProxySqlClientConnectionsChart
                                        data={
                                            proxySqlStats
                                                ? proxySqlStats[idx]
                                                : []
                                        }
                                        loading={proxySqlStatsLoading}
                                        displaySummary={displaySummary}
                                    />
                                </Col>
                                <Col
                                    className={
                                        styles.ProxySqlLbCompareDashboardCol
                                    }
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                >
                                    <ProxySqlSlowQueriesChart
                                        data={
                                            proxySqlStats
                                                ? proxySqlStats[idx]
                                                : []
                                        }
                                        loading={proxySqlStatsLoading}
                                        displaySummary={displaySummary}
                                    />
                                </Col>
                                <Col
                                    className={
                                        styles.ProxySqlLbCompareDashboardCol
                                    }
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                >
                                    <ProxySqlServerConnectionsChart
                                        data={
                                            proxySqlStats
                                                ? proxySqlStats[idx]
                                                : []
                                        }
                                        loading={proxySqlStatsLoading}
                                        displaySummary={displaySummary}
                                    />
                                </Col>
                                <Col
                                    className={
                                        styles.ProxySqlLbCompareDashboardCol
                                    }
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                >
                                    <ProxySqlActiveTransactionsChart
                                        data={
                                            proxySqlStats
                                                ? proxySqlStats[idx]
                                                : []
                                        }
                                        loading={proxySqlStatsLoading}
                                        displaySummary={displaySummary}
                                    />
                                </Col>
                                <Col
                                    className={
                                        styles.ProxySqlLbCompareDashboardCol
                                    }
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                >
                                    <ProxySqlQueryCacheMemoryBytesChart
                                        data={
                                            proxySqlStats
                                                ? proxySqlStats[idx]
                                                : []
                                        }
                                        loading={proxySqlStatsLoading}
                                        displaySummary={displaySummary}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    ))}
                </Row>
            ) : (
                <AppEmpty message="No Load Balancer Nodes selected yet." />
            )}
        </section>
    );
}

export default ProxySqlLbCompareDashboard;
