import { Input, Tooltip } from 'antd';
import { Typography } from 'antd';
import { Select } from 'antd';
import { Radio } from 'antd';
import { Row } from 'antd';
import { Col } from 'antd';
import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React, { ReactElement, useEffect } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import DatabaseVendor from '../../../types/DatabaseVendor';
import DatabaseVendorType from '../../../types/DatabaseVendorType';
import DeploymentOptions from '../../../types/DeploymentOptions';
import AppFormTag from '../../ccx/common/AppFormTag';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import styles from './WizardFormConfigurationStep1.module.less';
import AppFormEmailTag from '../../ccx/common/AppFormEmailTag';
import classNames from 'classnames';

interface Props extends CcxComponentProps {
    selectedTech?: DatabaseVendor;
    handleDeselect: Function;
    handleTagChange: Function;
    handleVendorTypeChange: Function;
    deploymentOptions?: DeploymentOptions;
    databaseVendorType: string;
    handleNumberOfNodesChange: Function;
    saveEmailNotifications: Function;
    notificationEmails: string[] | undefined;
    form?: FormInstance;
    setValidNotificationEmails: Function;
}

export function WizardFormConfigurationStep1({
    testId = 'WizardFormConfigurationStep1',
    handleTagChange,
    handleDeselect,
    handleVendorTypeChange,
    selectedTech,
    deploymentOptions,
    databaseVendorType,
    handleNumberOfNodesChange,
    saveEmailNotifications,
    notificationEmails,
    setValidNotificationEmails,
    form,
}: Props): ReactElement {
    const { Text } = Typography;
    const isMicrosoft = selectedTech?.code === 'microsoft';

    useEffect(() => {
        const vendorTypes = selectedTech?.getVendorTypes();

        if (
            vendorTypes &&
            selectedTech &&
            ['postgres', 'redis', 'microsoft'].includes(selectedTech?.code)
        ) {
            handleVendorTypeChange({
                target: { value: vendorTypes[0].code },
            });

            if (form) {
                form.setFieldsValue({
                    databaseVendorType: vendorTypes[0].code,
                });
            }
        } else if (
            vendorTypes &&
            selectedTech &&
            ['mariadb', 'percona'].includes(selectedTech?.code)
        ) {
            handleVendorTypeChange({
                target: { value: vendorTypes[0]?.code },
            });

            if (form) {
                form.setFieldsValue({
                    databaseVendorType: vendorTypes[0]?.code,
                });
            }
        }
    }, [selectedTech]);

    const ConfigurationOptionSubInfo = [
        {
            code: 'galera',
            items: [
                'Synchronous replication',
                'Uses Galera technology',
                <b>Requires a Primary Key in each table</b>,
                'Can read from every node',
            ],
        },
        {
            code: 'replication',
            items: [
                'Asynchronous replication',
                'Uses MySQL Replication',
                'Multi-purpose',
                'Recommended for most use cases',
            ],
        },
        {
            code: 'mssql_single',
            items: ['Standalone server', 'This setup cannot be scaled'],
        },
        {
            code: 'mssql_ao_async',
            items: ['Uses Async commit mode', 'Standard license'],
        },
        {
            code: 'redis',
            items: [
                'Primary / replica using Redis Sentinel',
                'Can read from every node ',
            ],
        },
        {
            code: 'postgres_streaming',
            items: [
                'Primary / replica using Streaming replication',
                'Uses sync_commit mode ',
                'Can read from every node ',
            ],
        },
    ];

    return (
        <div data-testid={testId}>
            <p>
                <strong>Name your datastore</strong>
                <InfoIcon
                    info={
                        <span>
                            The name of the datastore can be changed later.
                        </span>
                    }
                />
            </p>
            <Form.Item
                name="deploymentName"
                label="Name"
                rules={[
                    {
                        required: true,
                        message: 'New datastores require a name',
                    },
                ]}
            >
                <Input
                    autoComplete="off"
                    data-testid={`${testId}DeploymentNameInput`}
                    placeholder="Enter a name for your choice"
                    maxLength={200}
                />
            </Form.Item>

            <p>
                <strong>
                    Add tags to search or group your databases clusters{' '}
                    <Text type="secondary">(optional)</Text>
                </strong>
            </p>
            <Form.Item
                name="tags"
                label="Tags"
                extra="You may add up to 12 tags"
            >
                <Select
                    data-testid={`${testId}TagsSelect`}
                    mode="tags"
                    onDeselect={handleDeselect as any}
                    onChange={handleTagChange as any}
                    tagRender={(props: any) => <AppFormTag {...props} />}
                    placeholder="Enter tags separated by comma, i.e.: production, europe, client ABC"
                    tokenSeparators={[',']}
                ></Select>
            </Form.Item>

            {selectedTech && (
                <Form.Item
                    name="databaseVendorType"
                    label={<strong>Configuration</strong>}
                    rules={[
                        {
                            required: true,
                            message: 'Select configuration',
                        },
                    ]}
                >
                    <Radio.Group
                        data-testid={`${testId}TypesOfNodesRadio`}
                        onChange={handleVendorTypeChange as any}
                    >
                        <Row
                            gutter={[16, 16]}
                            className={
                                !isMicrosoft
                                    ? styles.WizardFormConfigurationStep1ConfigRow
                                    : ''
                            }
                        >
                            {selectedTech
                                ?.getSortedVendorTypes()
                                .map(
                                    (
                                        databaseType: DatabaseVendorType,
                                        n: number
                                    ) => {
                                        return (
                                            <Col
                                                key={n}
                                                className={classNames(
                                                    styles.WizardFormConfigurationStep1ConfigCol,
                                                    {
                                                        [styles.WizardFormConfigurationColSelected]:
                                                            form?.getFieldValue(
                                                                'databaseVendorType'
                                                            ) ===
                                                            databaseType.code,
                                                    }
                                                )}
                                            >
                                                <Radio
                                                    data-testid={`${testId}TypesOfNodesRadio${n}`}
                                                    value={databaseType.code}
                                                    key={n}
                                                    className={
                                                        styles.WizardFormConfigurationStep1ConfigCard
                                                    }
                                                >
                                                    {selectedTech.getVendorTypeName(
                                                        databaseType.code
                                                    )}
                                                    <ul
                                                        className={
                                                            styles.WizardFormConfigurationStep1ConfigOpt
                                                        }
                                                    >
                                                        {ConfigurationOptionSubInfo?.map(
                                                            (item) => (
                                                                <React.Fragment
                                                                    key={
                                                                        item.code
                                                                    }
                                                                >
                                                                    {item.code ===
                                                                        databaseType.code &&
                                                                        item.items.map(
                                                                            (
                                                                                i
                                                                            ) => (
                                                                                <li
                                                                                    key={`${i}`}
                                                                                >
                                                                                    {
                                                                                        i
                                                                                    }
                                                                                </li>
                                                                            )
                                                                        )}
                                                                </React.Fragment>
                                                            )
                                                        )}
                                                    </ul>
                                                    {databaseType.getRecommendedConfiguration() &&
                                                        selectedTech.types
                                                            .length > 1 && (
                                                            <span
                                                                className={
                                                                    styles.WizardFormConfigurationStep1MarkerGreen
                                                                }
                                                            >
                                                                Recommended
                                                            </span>
                                                        )}
                                                </Radio>
                                            </Col>
                                        );
                                    }
                                )}
                        </Row>
                    </Radio.Group>
                </Form.Item>
            )}

            {selectedTech && (
                <>
                    {databaseVendorType && (
                        <Form.Item
                            name="numberOfNodes"
                            label={
                                <>
                                    <strong>Number of nodes</strong>
                                    <InfoIcon
                                        info={
                                            <span>
                                                The number of nodes can be
                                                scaled later. One node is
                                                strictly only for
                                                test/development purpose. More
                                                than one node is recommended for
                                                HA.
                                            </span>
                                        }
                                    />
                                </>
                            }
                            rules={[
                                {
                                    required: true,
                                    message: 'Select number of nodes',
                                },
                            ]}
                        >
                            <Radio.Group
                                data-testid={`${testId}NumberOfNodesRadio`}
                                onChange={handleNumberOfNodesChange as any}
                            >
                                {deploymentOptions
                                    ?.getDatabaseVendors()
                                    .filter(
                                        (v: DatabaseVendor) =>
                                            v.code === selectedTech?.code
                                    )[0]
                                    .numberOfNodes.map((n: number) => {
                                        return selectedTech
                                            ?.getVendorTypeByCode(
                                                databaseVendorType
                                            )
                                            ?.getSizeHintsName(n) ? (
                                            <Radio
                                                data-testid={`${testId}NumberOfNodesRadio${n}`}
                                                value={n}
                                                key={n}
                                            >
                                                <Tooltip
                                                    placement="top"
                                                    title={selectedTech
                                                        ?.getVendorTypeByCode(
                                                            databaseVendorType
                                                        )
                                                        ?.getSizeHintsInfoByNumber(
                                                            n
                                                        )}
                                                >
                                                    {databaseVendorType
                                                        ? selectedTech
                                                              ?.getVendorTypeByCode(
                                                                  databaseVendorType
                                                              )
                                                              ?.getSizeHintsName(
                                                                  n
                                                              )
                                                        : n}
                                                </Tooltip>
                                            </Radio>
                                        ) : null;
                                    })}
                            </Radio.Group>
                        </Form.Item>
                    )}
                </>
            )}
            <div className={styles.WizardFormConfigurationEmailNotification}>
                <strong>Email Notifications</strong>{' '}
            </div>
            <Form.Item
                label={'Notification Recipients'}
                name="notificationRecipients"
                rules={[
                    {
                        required: false,
                    },
                    () => ({
                        validator(_, value) {
                            if (value && value.length > 0) {
                                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                const isValidEmail = value.every(
                                    (email: string) => emailRegex.test(email)
                                );
                                if (!isValidEmail) {
                                    setValidNotificationEmails(true);
                                    return Promise.reject(
                                        new Error(
                                            'All entries must be valid email addresses'
                                        )
                                    );
                                }
                            }
                            setValidNotificationEmails(false);
                            return Promise.resolve();
                        },
                    }),
                ]}
                wrapperCol={{ span: 24 }}
                extra={
                    'Use semicolon (;) to separate email addresses, remove all addresses to disable'
                }
            >
                <Select
                    mode="tags"
                    open={false}
                    placeholder="Enter recipient's email address"
                    showSearch={false}
                    tagRender={(props: any) => <AppFormEmailTag {...props} />}
                    value={notificationEmails}
                    tokenSeparators={[';']}
                    maxTagCount={5}
                    onChange={(value: string[]) =>
                        saveEmailNotifications(value)
                    }
                />
            </Form.Item>
        </div>
    );
}
