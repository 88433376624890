import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import DataStoreServiceSingle from '../services/DatastoreServiceSingle';
import DeploymentsItem from '../types/DeploymentsItem';

interface DatastoreType {
    dataStore: DeploymentsItem | undefined;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
}
const initialState = {
    dataStore: undefined,
    isError: false,
    isSuccess: false,
    isLoading: false,
} as DatastoreType;

export type GetDataStoreParams = {
    dataStoreUuid: string;
};

export const getCurrentDataStore = createAsyncThunk(
    'datastore/getDatastore',
    async ({ dataStoreUuid }: GetDataStoreParams, { getState }: any) => {
        try {
            const { deploymentOptions } = getState()?.deploymentOptions;
            const response = await DataStoreServiceSingle.getDataStore(
                dataStoreUuid,
                deploymentOptions
            );
            return response;
        } catch (e) {
            throw e;
        }
    }
);

export const datastoreSlice = createSlice({
    name: 'datastore',
    initialState,
    reducers: {
        getCurrentDataStore: (state, action) => {
            state.isLoading = false;
            state.dataStore = action.payload;
            state.isSuccess = true;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCurrentDataStore.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getCurrentDataStore.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.dataStore = action.payload;
            })
            .addCase(getCurrentDataStore.rejected, (state, action) => {
                console.log('called');
                state.isLoading = false;
                state.isError = true;
            });
    },
});

export default datastoreSlice.reducer;
