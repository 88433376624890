export type CmonMemoryStatBackendApiProps = {
    created: number;
    interval: number;
    memoryutilization: number;
    pgpgin: number;
    pgpgout: number;
    pswpin: number;
    pswpout: number;
    rambuffers: number;
    ramcached: number;
    ramfree: number;
    ramfreemin: number;
    ramtotal: number;
    sampleends: number;
    samplekey: string;
    serverid: number;
    swapfree: number;
    swaptotal: number;
    swaputilization: number;
};

export default class CmonMemoryStat {
    readonly created: number;
    readonly interval: number;
    readonly memoryUtilization: number;
    readonly pgpgIn: number;
    readonly pgpgOut: number;
    readonly pswpIn: number;
    readonly pswpOut: number;
    readonly ramBuffers: number;
    readonly ramCached: number;
    readonly ramFree: number;
    readonly ramFreeMin: number;
    readonly ramTotal: number;
    readonly sampleEnds: number;
    readonly sampleKey: string;
    readonly serverId: number;
    readonly swapFree: number;
    readonly swapTotal: number;
    readonly swapUtilization: number;

    constructor(props: CmonMemoryStatBackendApiProps) {
        this.created = props.created;
        this.interval = props.interval;
        this.memoryUtilization = props.memoryutilization;
        this.pgpgIn = props.pgpgin;
        this.pgpgOut = props.pgpgout;
        this.pswpIn = props.pswpin;
        this.pswpOut = props.pswpout;
        this.ramBuffers = props.rambuffers;
        this.ramCached = props.ramcached;
        this.ramFree = props.ramfree;
        this.ramFreeMin = props.ramfreemin;
        this.ramTotal = props.ramtotal;
        this.sampleEnds = props.sampleends;
        this.sampleKey = props.samplekey;
        this.serverId = props.serverid;
        this.swapFree = props.swapfree;
        this.swapTotal = props.swaptotal;
        this.swapUtilization = props.swaputilization;
    }
}
