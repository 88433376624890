export type PrometheusPostgreSqlCheckpointWriteTimeStatsBackendApiProps = {
    created: number;
    pg_stat_bgwriter_checkpoint_write_time: number;
};

export default class PrometheusPostgreSqlCheckpointWriteTimeStats {
    readonly created: number;
    readonly pgStatBgwriterCheckpointWriteTime: number;

    constructor(
        props: PrometheusPostgreSqlCheckpointWriteTimeStatsBackendApiProps
    ) {
        this.created = props.created;
        this.pgStatBgwriterCheckpointWriteTime =
            props.pg_stat_bgwriter_checkpoint_write_time;
    }
}
