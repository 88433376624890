export type FirewallRulePortPropClassProps = {
    port: string;
    port_no: number;
};

export interface FirewallRulePortInterface {
    port: string;
    portNo: number;
}

export default class FirewallRulePort implements FirewallRulePortInterface {
    readonly port: string;
    readonly portNo: number;

    constructor(props: FirewallRulePortPropClassProps) {
        this.port = props.port;
        this.portNo = props.port_no;
    }
}
