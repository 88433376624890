export type BillingAddressClassProps = {
    city: string;
    country: string;
    line_1: string;
    line_2: string;
    postal_code: string;
    state: string;
};

export default class BillingAddress {
    public readonly city: string;
    public readonly country: string;
    public readonly line1: string;
    public readonly line2: string;
    public readonly postalCode: string;
    public readonly state: string;

    constructor(props: BillingAddressClassProps) {
        this.city = props.city || '';
        this.country = props.country || '';
        this.line1 = props.line_1 || '';
        this.line2 = props.line_2 || '';
        this.postalCode = props.postal_code || '';
        this.state = props.state || '';
    }
}
