import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import moment from 'moment';
dayjs.extend(utc);

export type TopQueryClassProps = {
    user: string;
    instance: string;
    database: string;
    digest_text: string;
    sum_time: number;
    count_star: number;
    last_seen: string;
    max_time: number;
    min_time: number;
    avg_time: number;
    examined_rows: number;
    affected_rows: number;
    sent_rows: number;
    disk: number;
    ram: number;
};

export interface TopQueryInterface {
    user: string;
    instance: string;
    database: string;
    digestText: string;
    sumTime: number;
    sumTimeFormatted: string;
    countStar: number;
    uniqueKey: string; // this is used for the Table component, a unique key for each value
    lastSeen: string;
    maxTime: number;
    minTime: number;
    avgTime: number;
    examinedRows: number;
    affectedRows: number;
    sentRows: number;
    disk: number;
    ram: number;
}

export default class TopQuery implements TopQueryInterface {
    readonly user: string;
    readonly instance: string;
    readonly database: string;
    readonly digestText: string;
    readonly sumTime: number;
    readonly sumTimeFormatted: string;
    readonly countStar: number;
    readonly uniqueKey: string;
    readonly lastSeen: string;
    readonly maxTime: number;
    readonly minTime: number;
    readonly avgTime: number;
    readonly examinedRows: number;
    readonly affectedRows: number;
    readonly sentRows: number;
    readonly disk: number;
    readonly ram: number;

    constructor(props: TopQueryClassProps) {
        this.instance = props.instance;
        this.user = props.user;
        this.database = props.database;
        this.digestText = props.digest_text;
        this.sumTime = props.sum_time;
        this.sumTimeFormatted = dayjs.utc(props.sum_time).format('HH:mm:ss');
        this.countStar = props.count_star;
        this.uniqueKey = `${props.sum_time}-${props.digest_text}`;
        this.lastSeen = moment
            .utc(props?.last_seen)
            .format('YYYY-MM-DD HH:mm:ss');
        this.maxTime = props.max_time || 0;
        this.minTime = props.min_time || 0;
        this.avgTime = props.avg_time || 0;
        this.examinedRows = props.examined_rows || 0;
        this.affectedRows = props.affected_rows || 0;
        this.sentRows = props.sent_rows || 0;
        this.disk = props.disk || 0;
        this.ram = props.ram || 0;
    }
}
