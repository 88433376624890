import ApiService from './ApiService';
import { API_FEEDBACK } from '../core/CcxEnv';

// constants
const API_URL = API_FEEDBACK;

type SendFeedbackParams = {
    dataStoreUuid?: string;
    feedbackMessage: string;
    feedbackProps?: string[];
    feedbackType: string;
    vpcUuid?: string;
};

export default class FeedbackService extends ApiService {
    /**
     * Send feedback message.
     * @param {string} feedbackMessage
     * @param {string} feedbackType
     * @param {string} dataStoreUuid
     * @param {string} feedbackProps
     * @param {string} vpcUuid
     */
    static async sendFeedback({
        dataStoreUuid,
        feedbackMessage,
        feedbackProps,
        feedbackType,
        vpcUuid,
    }: SendFeedbackParams): Promise<any> {
        const requestData = {
            data: {
                feedback_message: feedbackMessage,
                feedback_props: feedbackProps,
                feedback_type: feedbackType,
                vpc_uuid: vpcUuid,
                cluster_uuid: dataStoreUuid,
            },
        };
        return await this.request('POST', `feedback`, requestData);
    }

    protected static getApiUrl() {
        return API_URL;
    }
}
