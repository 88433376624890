import { useEffect, useState } from 'react';
import ContentService from '../../services/ContentService';
import CloudProvider from '../../types/CloudProvider';

export default function useContentProviders() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [cloudProviders, setCloudProviders] = useState<
        CloudProvider[] | undefined
    >(undefined);

    const load = async () => {
        if (cloudProviders) {
            // this is some sort of caching for the content api. Data form the content api will not change
            // so we only fetch once. After that, we return the previous values (cache)
            return;
        }

        try {
            const providerList = await ContentService.getProviders();
            setCloudProviders(providerList);
            setLoading(false);
            setError(null);
        } catch (e: any) {
            setLoading(false);
            setError(e);
        }
    };

    const refresh = () => {
        load();
    };

    useEffect(() => {
        refresh();
    }, []);

    return { cloudProviders, refresh, loading, error };
}
