import Icon from '@ant-design/icons/lib/components/Icon';
import CloudProvider from '../../../types/CloudProvider';
import CloudProviderLogo from './CloudProviderLogo';

export const getCloudProviderLogo = (p: CloudProvider, extended?: boolean) => {
    if (p) {
        const url = p?.getCloudProviderLogo();

        return <Icon component={() => <CloudProviderLogo url={url} />} />;
    }
    return null;
};
