import { ReactElement, useRef } from 'react';
import { Column } from '@ant-design/charts';

export type AppChartColumnProps = {
    config: any;
    data: any[];
};

function AppChartColumn({ config, data }: AppChartColumnProps): ReactElement {
    const ref = useRef<any>();

    return <Column {...config} chartRef={ref} data={data} />;
}

export default AppChartColumn;
