export type PrometheusMySqlHandlerTransactionsStatsBackendApiProps = {
    created: number;
    handlers_rollback: number;
    handlers_savepoint: number;
    handlers_commit: number;
    handlers_savepoint_rollback: number;
    handlers_prepare: number;
};

export default class PrometheusMySqlHandlerTransactionsStats {
    readonly created: number;
    readonly handlersRollback: number;
    readonly handlersSavepoint: number;
    readonly handlersCommit: number;
    readonly handlersSavepointRollback: number;
    readonly handlersPrepare: number;

    constructor(props: PrometheusMySqlHandlerTransactionsStatsBackendApiProps) {
        this.created = props.created;
        this.handlersRollback = props.handlers_rollback;
        this.handlersSavepoint = props.handlers_savepoint;
        this.handlersCommit = props.handlers_commit;
        this.handlersSavepointRollback = props.handlers_savepoint_rollback;
        this.handlersPrepare = props.handlers_prepare;
    }
}
