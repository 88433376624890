import { API_BACKUP } from '../core/CcxEnv';
import {
    Backup,
    BackupApi,
    Configuration,
    Restore,
    RestoreApi,
    Schedule,
    Settings,
    SettingsApi,
    SettingsSet,
} from './backups/typescript-axios';
import { SDK_AXIOS_DEFAULT_BASE_OPTIONS } from './config';

const configuration = new Configuration({
    basePath: API_BACKUP,
    baseOptions: SDK_AXIOS_DEFAULT_BASE_OPTIONS,
});
export const backupSettingsApiInterface = new SettingsApi(configuration);
export const backupRestoreApiInterface = new RestoreApi(configuration);
export const backupApiInterface = new BackupApi(configuration);

export interface BackupDTO extends Backup {}

export interface BackupScheduleDTO extends Schedule {}

export interface BackupSettingsDTO extends Settings {}

export interface BackupSettingsPatchDTO extends SettingsSet {}

export interface BackupRestoreDTO extends Restore {}
