import {
    EyeInvisibleOutlined,
    EyeOutlined,
    InfoCircleOutlined,
} from '@ant-design/icons';
import { Radio } from 'antd';
import { Row } from 'antd';
import { Form } from 'antd';
import { Tooltip } from 'antd';
import { Select } from 'antd';
import { Input } from 'antd';
import { Col } from 'antd';
import { Alert } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { ReactElement, useEffect } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import CloudProvider from '../../../types/CloudProvider';
import DeploymentOptions from '../../../types/DeploymentOptions';
import Vpc from '../../../types/Vpc';
import CardContentAvailabilityZoneMulti from '../../ccx/common/CardContentAvailabilityZoneMulti';
import styles from './WizardFormConfigurationStep4.module.less';

interface Props extends CcxComponentProps {
    deploymentOptions?: DeploymentOptions;
    cloudProvider?: CloudProvider;
    handleNetworkChange: Function;
    network: any;
    handleNetworkTypeChange: Function;
    networkType: any;
    numberOfNodes: number;
    handleVpcChange: Function;
    vpcs?: Vpc[];
    availabilityZones: any;
    zones: any;
    handleAvailabilityZoneChange: Function;
    form: FormInstance;
    StepsExtra: Function;
}

export function WizardFormConfigurationStep4({
    testId = 'WizardFormConfigurationStep4',
    deploymentOptions,
    cloudProvider,
    handleNetworkChange,
    network,
    handleNetworkTypeChange,
    networkType,
    numberOfNodes,
    handleVpcChange,
    vpcs,
    availabilityZones,
    zones,
    handleAvailabilityZoneChange,
    form,
    StepsExtra,
}: Props): ReactElement {
    const onAvailabilityZoneChange = (e: any) => {
        handleAvailabilityZoneChange &&
            handleAvailabilityZoneChange(0, e.target.value);
    };

    const handleMultiAvailabilityZoneChange = (
        instance: number,
        value: any
    ) => {
        handleAvailabilityZoneChange &&
            handleAvailabilityZoneChange(instance, value);
    };

    useEffect(() => {
        if (deploymentOptions && cloudProvider) {
            const allNetworks = deploymentOptions
                ?.getNetworks(cloudProvider)
                ?.filter((value: any) => value.enabled);

            if (allNetworks?.length === 1) {
                const networkDefault = allNetworks[0];

                form.setFieldsValue({
                    networkCode: networkDefault?.code,
                });

                handleNetworkChange({
                    target: {
                        value: networkDefault,
                    },
                });
            }
        }
    }, [deploymentOptions, cloudProvider]);

    return cloudProvider ? (
        <div data-testid={testId}>
            <p>
                <strong>Network type</strong>
            </p>
            <Alert
                message={
                    <>
                        Network type <strong>cannot be changed</strong> after
                        deployment.
                    </>
                }
                type="warning"
                showIcon
            />

            <Form.Item
                name="networkCode"
                label=""
                rules={[
                    {
                        required: true,
                        message: 'Select your network',
                    },
                ]}
            >
                <Radio.Group
                    data-testid={`${testId}NetworkSettingsRadio`}
                    className={styles.WizardFormConfigurationStep4Group}
                    onChange={handleNetworkChange as any}
                >
                    <Row gutter={16}>
                        {deploymentOptions
                            ?.getNetworks(cloudProvider)
                            ?.map((n: any) => {
                                return (
                                    <Col
                                        key={`${testId}${n.code}NetworkCol`}
                                        span={12}
                                    >
                                        <Radio.Button
                                            data-testid={`${testId}NetworkRadio${n.code}`}
                                            disabled={!n.enabled}
                                            value={n.code}
                                            key={n.code}
                                            className={
                                                styles.WizardFormConfigurationStep4RadioItem
                                            }
                                        >
                                            <div
                                                className={
                                                    styles.WizardFormConfigurationStep4Item
                                                }
                                            >
                                                <small>
                                                    <Tooltip
                                                        placement="bottom"
                                                        title={
                                                            n.enabled
                                                                ? n.info
                                                                : null
                                                        }
                                                    >
                                                        <InfoCircleOutlined />
                                                    </Tooltip>
                                                </small>
                                                {n.name === 'Public' ? (
                                                    <EyeOutlined />
                                                ) : (
                                                    <EyeInvisibleOutlined />
                                                )}
                                                <span>{n.name}</span>
                                            </div>
                                        </Radio.Button>
                                    </Col>
                                );
                            })}
                    </Row>
                </Radio.Group>
            </Form.Item>

            {network?.code === 'private' && (
                <>
                    {network.ha_available[numberOfNodes] && (
                        <>
                            <p>
                                <strong>
                                    Availability zone{' '}
                                    <Tooltip
                                        placement="bottom"
                                        title={deploymentOptions?.getNetworkTypeInfo(
                                            cloudProvider
                                        )}
                                    >
                                        <InfoCircleOutlined />
                                    </Tooltip>
                                </strong>
                            </p>
                            <Form.Item
                                name="networkType"
                                label=""
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select Network Type',
                                    },
                                ]}
                            >
                                <Radio.Group
                                    className={
                                        styles.WizardFormConfigurationStep4Group
                                    }
                                    data-testid={`${testId}NetworkTypeRadio`}
                                    onChange={handleNetworkTypeChange as any}
                                >
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Radio.Button
                                                className={
                                                    styles.WizardFormConfigurationStep4RadioTypeItem
                                                }
                                                data-testid={`${testId}NetworkRadio${'regular'}`}
                                                value={'regular'}
                                            >
                                                <div
                                                    className={
                                                        styles.WizardFormConfigurationStep4NetworkTypeItem
                                                    }
                                                >
                                                    <span> Single AZ</span>
                                                </div>
                                            </Radio.Button>
                                        </Col>
                                        <Col span={12}>
                                            <Radio.Button
                                                className={
                                                    styles.WizardFormConfigurationStep4RadioTypeItem
                                                }
                                                data-testid={`${testId}NetworkRadio${'ha'}`}
                                                disabled={
                                                    !network.ha_available[
                                                        numberOfNodes
                                                    ]
                                                }
                                                value={'ha'}
                                            >
                                                <div
                                                    className={
                                                        styles.WizardFormConfigurationStep4NetworkTypeItem
                                                    }
                                                >
                                                    <span>Multi AZ </span>
                                                </div>
                                            </Radio.Button>
                                        </Col>
                                    </Row>
                                </Radio.Group>
                            </Form.Item>
                        </>
                    )}

                    {network?.vpc_available && networkType && (
                        <Form.Item
                            name="vpcUuid"
                            label="VPC"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select a VPC',
                                },
                            ]}
                        >
                            <Select
                                data-testid={`${testId}VPCSelect`}
                                onChange={handleVpcChange as any}
                                placeholder="Select a VPC or Create a new one"
                                className={
                                    styles.WizardFormConfigurationStep4VPC
                                }
                            >
                                {vpcs?.map((v: Vpc) => {
                                    return (
                                        <Select.Option
                                            value={v.id}
                                            key={v.id}
                                        >{`${v.getName()} - ${v.getCidrIpv4Block()}`}</Select.Option>
                                    );
                                })}
                                <Select.Option value="new" key="new">
                                    Create new VPC
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    )}

                    {networkType && (availabilityZones.length || 0) > 1 && (
                        <>
                            <p>Select a zone</p>
                            <Form.Item
                                name="availabilityZones"
                                label=""
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select availability zones',
                                    },
                                ]}
                            >
                                {networkType === 'regular' ? (
                                    <Radio.Group
                                        data-testid={`${testId}AvailabilityZones`}
                                        onChange={onAvailabilityZoneChange}
                                        className={
                                            styles.WizardFormConfigurationStep4Group
                                        }
                                    >
                                        <Row gutter={16}>
                                            {availabilityZones?.map(
                                                (az: any) => {
                                                    return (
                                                        <Col
                                                            key={`${testId}${az.code}AvailabilityZoneSingle`}
                                                            xs={12}
                                                            sm={8}
                                                        >
                                                            <Radio.Button
                                                                data-testid={`${testId}AvailabilityZoneSingle${az.code}`}
                                                                key={az.code}
                                                                value={az.code}
                                                                className={
                                                                    styles.WizardFormConfigurationStep4RadioTypeItem
                                                                }
                                                            >
                                                                <div
                                                                    className={
                                                                        styles.WizardFormConfigurationStep4NetworkTypeItem
                                                                    }
                                                                >
                                                                    <span>
                                                                        {
                                                                            az.name
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </Radio.Button>
                                                        </Col>
                                                    );
                                                }
                                            )}
                                        </Row>
                                    </Radio.Group>
                                ) : (
                                    <CardContentAvailabilityZoneMulti
                                        availabilityZones={availabilityZones}
                                        onChange={
                                            handleMultiAvailabilityZoneChange
                                        }
                                        selectedZones={zones}
                                        testId={`${testId}AvailabilityZoneMulti`}
                                        radio={false}
                                    />
                                )}
                                <Input type="hidden" />
                            </Form.Item>
                        </>
                    )}
                </>
            )}
            <div className={styles.StepsExtraSmallScreen}>
                <StepsExtra />
            </div>
        </div>
    ) : (
        <></>
    );
}

export function isOnlyPublicNetworkEnabled(networks: any[]) {
    const availableNetworks = networks.filter((n) => n.enabled);
    return (
        availableNetworks.length === 1 && availableNetworks[0].code === 'public'
    );
}
