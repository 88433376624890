import React, { ReactElement } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import styles from './TopQueriesTable.module.less';
import TopQuery from '../../../types/TopQuery';
import AppTable from '../../AppTable';

interface Props extends CcxComponentProps {
    title?: string;
    query: TopQuery;
}

function TopQueriesTable({ title = 'Query', query }: Props): ReactElement {
    const columns = [
        {
            title: 'Temporary tables',
            children: [
                {
                    title: 'Disk',
                    dataIndex: 'disk',
                    key: 'disk',
                },
                {
                    title: 'RAM',
                    dataIndex: 'ram',
                    key: 'ram',
                },
            ],
        },
        {
            title: 'Lock Time',
            children: [
                {
                    title: 'Min',
                    key: 'minTime',
                    render: (text: any, record: any) => (
                        <span>{record.minTime} ms</span>
                    ),
                },
                {
                    title: 'Max',
                    key: 'maxTime',
                    render: (text: any, record: any) => (
                        <span>{record.maxTime} ms</span>
                    ),
                },
                {
                    title: 'Avg',
                    key: 'avgTime',
                    render: (text: any, record: any) => (
                        <span>{record.avgTime} ms</span>
                    ),
                },
            ],
        },
        {
            title: 'Rows',
            children: [
                {
                    title: 'Examined',
                    dataIndex: 'examinedRows',
                    key: 'examinedRows',
                },
                {
                    title: 'Affected',
                    dataIndex: 'affectedRows',
                    key: 'affectedRows',
                },
                {
                    title: 'Sent',
                    dataIndex: 'sentRows',
                    key: 'sentRows',
                },
            ],
        },
    ];

    return (
        <div className={styles.TopQueriesTable}>
            <h5>{title}</h5>
            <span>{query.digestText}</span>
            <AppTable
                columns={columns}
                data={[query]}
                rowKey="uniqueKey"
                pagination={false}
                expandable={false}
            />
        </div>
    );
}

export default TopQueriesTable;
