export type PrometheusDiskIopsStatsBackendApiProps = {
    created: number;
    reads: number;
    writes: number;
};

export default class PrometheusDiskIopsStats {
    readonly created: number;
    readonly reads: number;
    readonly writes: number;

    constructor(props: PrometheusDiskIopsStatsBackendApiProps) {
        this.created = props.created;
        this.reads = props.reads;
        this.writes = props.writes;
    }
}
