import ApiService from './ApiService';
import { API_DEPLOYMENT_V3 } from '../core/CcxEnv';
import DeploymentsItem from '../types/DeploymentsItem';
import DeploymentOptions from '../types/DeploymentOptions';

// constants
const API_URL = API_DEPLOYMENT_V3;

export default class DataStoreServiceSingle extends ApiService {
    /**
     * Get Data Store.
     */
    static async getDataStore(
        dataStoreUuid: string,
        deploymentOptions: DeploymentOptions
    ): Promise<any> {
        // @TODO: Use the projectUuid when the backend is ready
        // const response = await this.request('GET', `deployments/${projectUuid}`);
        try {
            const response = await this.request(
                'GET',
                `data-stores/${dataStoreUuid}`
            );
            return new DeploymentsItem(response, deploymentOptions);
        } catch (e) {
            throw e;
        }
    }

    protected static getApiUrl() {
        return API_URL;
    }
}
