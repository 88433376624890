import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Deployments from '../types/Deployments';
import DataStoreService from '../services/DataStoresService';
import { objectToQueryParams } from '../core/helpers';

interface DatastoreType {
    datastores: Deployments | undefined;
    page: number;
    per_page: number;
    tags: string[];
    db_vendors: string[];
    totalItems: number;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
}
const initialState = {
    datastores: undefined,
    page: 1,
    per_page: 10,
    tags: [],
    db_vendors: [],
    totalItems: 1,
    isError: false,
    isSuccess: false,
    isLoading: false,
} as DatastoreType;

export type GetAllDataStoresParams =
    | undefined
    | {
          page: number;
          per_page: number;
          tags: string[];
          db_vendors: string[];
      };

export const getAllDataStores = createAsyncThunk(
    'datastores/getDatastores',
    async (params: GetAllDataStoresParams, { getState }: any) => {
        try {
            const { datastores } = getState();
            if (!params) {
                params = {
                    page: datastores.page,
                    per_page: datastores.per_page,
                    tags: datastores.tags,
                    db_vendors: datastores.db_vendors,
                };
            }
            const paramString: string = params
                ? objectToQueryParams(params)
                : '';
            const { deploymentOptions } = getState()?.deploymentOptions;
            const response = await DataStoreService.getDataStores(
                'default',
                deploymentOptions,
                paramString
            );
            return { ...response, ...params };
        } catch (e) {
            console.error(e);
        }
    }
);

export const datastoresSlice = createSlice({
    name: 'datastores',
    initialState,
    reducers: {
        getAllDataStores: (state, action) => {
            state.isLoading = false;
            state.datastores = action.payload?.datastores;
            state.totalItems = action.payload?.totalItems;
            state.isSuccess = true;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllDataStores.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAllDataStores.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.datastores = action.payload?.datastores;
                state.totalItems = action.payload?.totalItems;
                state.page = action.payload?.page;
                state.per_page = action.payload?.per_page;
                state.tags = action.payload?.tags;
                state.db_vendors = action.payload?.db_vendors;
            })
            .addCase(getAllDataStores.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            });
    },
});

export default datastoresSlice.reducer;
