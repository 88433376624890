import React, { ReactElement } from 'react';
import { Radio } from 'antd';
import CcxComponentProps from '../../../core/CcxComponent';
import styles from './CardContentAvailabilityZoneMulti.module.less';
import { Select } from 'antd';
import { Typography } from 'antd';

interface Props extends CcxComponentProps {
    availabilityZones?: any[];
    onChange?: Function;
    selectedZones?: any[];
    radio?: boolean;
}

function CardContentAvailabilityZoneMulti({
    availabilityZones,
    onChange,
    selectedZones,
    testId = 'CardContentAvailabilityZoneMulti',
    radio = true,
}: Props): ReactElement {
    const handleOnChange1 = (e: any) => {
        onChange && onChange(0, radio ? e.target.value : e);
    };
    const handleOnChange2 = (e: any) => {
        onChange && onChange(1, radio ? e.target.value : e);
    };
    const handleOnChange3 = (e: any) => {
        onChange && onChange(2, radio ? e.target.value : e);
    };

    const getOnChange = (i: number) => {
        if (i === 1) return handleOnChange1;
        if (i === 2) return handleOnChange2;
        if (i === 3) return handleOnChange3;
        return null;
    };

    const { Text } = Typography;

    return (
        <div
            className={
                radio
                    ? styles.CardContentAvailabilityZoneMulti
                    : styles.CardContentAvailabilityZoneMultiSelect
            }
            data-testid={`${testId}AvailabilityZone`}
        >
            {[1, 2, 3].map((i: number) => {
                return (
                    <div key={`${testId}AvailabilityZoneInstance${i}`}>
                        <div
                            className={
                                radio
                                    ? styles.CardContentAvailabilityZoneMultiTitle
                                    : styles.CardContentAvailabilityZoneMultiTitleSelect
                            }
                            data-testid={`${testId}AvailabilityZoneInstance${i}Title`}
                        >
                            {!radio && <Text type="danger">*</Text>} Instance{' '}
                            {i}
                        </div>
                        <div
                            className={
                                styles.CardContentAvailabilityZoneMultiZones
                            }
                        >
                            {radio ? (
                                <Radio.Group
                                    className={
                                        styles.CardContentAvailabilityZoneMultiRadioGroup
                                    }
                                    data-testid={`${testId}AvailabilityZoneInstance${i}`}
                                    defaultValue={
                                        selectedZones && selectedZones[i - 1]
                                    }
                                    onChange={getOnChange(i) as any}
                                    size="large"
                                >
                                    {availabilityZones?.map((az: any) => {
                                        return (
                                            <Radio.Button
                                                data-testid={`${testId}AvailabilityZoneInstance${i}${az.code}`}
                                                key={az.code}
                                                value={az.code}
                                            >
                                                {az.name}
                                            </Radio.Button>
                                        );
                                    })}
                                </Radio.Group>
                            ) : (
                                <Select
                                    data-testid={`${testId}AvailabilityZoneInstance${i}`}
                                    placeholder="Select a zone"
                                    defaultValue={
                                        selectedZones && selectedZones[i - 1]
                                    }
                                    onChange={getOnChange(i) as any}
                                >
                                    {availabilityZones?.map((az: any) => {
                                        return (
                                            <Select.Option
                                                data-testid={`${testId}AvailabilityZoneInstance${i}${az.code}`}
                                                key={az.code}
                                                value={az.code}
                                            >
                                                {az.name}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default CardContentAvailabilityZoneMulti;
