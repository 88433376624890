import DbUser from './DbUser';

export type DbUsersClassProps = {
    accounts: any[];
    // reply_received: string;
    // request_created: string;
    // request_id: number;
    // request_processed: string;
    // request_status: string;
    // request_user_id: number;
    // total: number;
};

export interface DbUsersInterface {
    accounts: DbUser[];
    // replyReceived: string;
    // requestCreated: string;
    // requestId: number;
    // requestProcessed: string;
    // requestStatus: string;
    // requestUserId: number;
    // total: number;
}

export default class DbUsers implements DbUsersInterface {
    readonly accounts: DbUser[];
    // readonly replyReceived: string;
    // readonly requestCreated: string;
    // readonly requestId: number;
    // readonly requestProcessed: string;
    // readonly requestStatus: string;
    // readonly requestUserId: number;
    // readonly total: number;

    constructor(props?: any[]) {
        this.accounts =
            props?.map((a) => {
                return new DbUser(a);
            }) || [];
        // this.replyReceived = props.reply_received;
        // this.requestCreated = props.request_created;
        // this.requestId = props.request_id;
        // this.requestProcessed = props.request_processed;
        // this.requestStatus = props.request_status;
        // this.requestUserId = props.request_user_id;
        // this.total = props.total;
    }
}
