import React, { ReactElement } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import styles from './AppBorderedPanel.module.less';

interface Props extends CcxComponentProps {
    children: ReactElement;
}

const AppBorderedPanel: React.FC<Props> = ({
    children,
    testId = 'AppBorderedPanel',
}) => {
    return (
        <section data-testid={testId} className={styles.AppBorderedPanel}>
            {children}
        </section>
    );
};

export default AppBorderedPanel;
