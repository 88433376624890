import React, { useReducer } from 'react';
import { StateContext, StateContextProps } from '../context/StateContext';

export const StateProvider = ({
    reducer,
    initialState,
    children,
}: StateContextProps) => {
    return (
        <StateContext.Provider value={useReducer(reducer, initialState)}>
            {children}
        </StateContext.Provider>
    );
};
