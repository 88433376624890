export interface GrowthDbsClassProps {
    db_name: string;
    data_size: number;
    index_size: number;
    row_count: number;
    table_count: number;
}

export interface GrowthDbsInterface {
    dbName: string;
    dataSize: number;
    indexSize: number;
    rowCount: number;
    tableCount: number;
}

export interface DateRangeInterface {
    fromDate: string;
    toDate: string;
}

export interface DbGrowthChartInterface {
    date: number;
    realValue: number;
    type: number;
    value: string;
}

export class GrowthDbs implements GrowthDbsInterface {
    readonly dbName: string;
    readonly dataSize: number;
    readonly indexSize: number;
    readonly rowCount: number;
    readonly tableCount: number;

    constructor(props: GrowthDbsClassProps) {
        this.dbName = props.db_name;
        this.dataSize = props.data_size;
        this.rowCount = props.row_count;
        this.indexSize = props.index_size;
        this.tableCount = props.table_count;
    }
}

export interface DbGrowthDirectoryClassProps {
    class_name: string;
    created: string;
    database_count: number;
    data_size: number;
    datadir: string;
    free_datadir_size: number;
    hostname: string;
    index_size: number;
    port: number;
    total_datadir_size: number;
    dbs: GrowthDbsInterface[];
}

export interface DbGrowthDirectoryInterface {
    className: string;
    createdAt: string;
    databaseCount: number;
    dataSize: number;
    freeDataSize: number;
    dataDir: string;
    hostName: string;
    indexSize: number;
    port: number;
    totalDirSize: number;
    dbs: GrowthDbsInterface[];
}

export class DbGrowth implements DbGrowthDirectoryInterface {
    readonly className: string;
    readonly createdAt: string;
    readonly databaseCount: number;
    readonly dataSize: number;
    readonly dataDir: string;
    readonly freeDataSize: number;
    readonly hostName: string;
    readonly indexSize: number;
    readonly port: number;
    readonly totalDirSize: number;
    readonly dbs: GrowthDbs[];

    constructor(props: DbGrowthDirectoryClassProps) {
        this.className = props.class_name;
        this.createdAt = props.created;
        this.databaseCount = props.database_count;
        this.dataSize = props.data_size;
        this.freeDataSize = props.free_datadir_size;
        this.dataDir = props.datadir;
        this.hostName = props.hostname;
        this.indexSize = props.index_size;
        this.port = props.port;
        this.totalDirSize = props.total_datadir_size;
        this.dbs = props.dbs.map((dbsProps: any) => new GrowthDbs(dbsProps));
    }
}
