import { Tooltip } from 'antd';
import { Row, Col } from 'antd';
import React, { ReactElement, useEffect, useState } from 'react';
import CcxComponentProps from '../../../../core/CcxComponent';
import DeploymentsItem from '../../../../types/DeploymentsItem';
import AppFlagIcon from '../../../ccx/common/AppFlagIcon';
import SmallContainer from '../../../ccx/common/SmallContainer';
import CcxIconInfoCircleOutlined from '../../../ccx/icons/CcxIconInfoCircleOutlined';
import LazyLoader from '../../../LazyLoader';
import styles from './DataStoreOverviewCloudInformation.module.less';
import { getCloudProviderLogo } from '../DataStoreCloudProviderLogo';
import { useAppSelector } from '../../../../redux/hooks';

interface Props extends CcxComponentProps {
    dataStore?: DeploymentsItem;
}

function DataStoreOverviewCloudInformation({
    dataStore,
    testId = 'DataStoreOverviewCloudInformation',
}: Props): ReactElement {
    const [cards, setCards] = useState<any>([]);
    const [countryCode, setCountryCode] = useState<string | undefined>(
        undefined
    );
    const { deploymentOptions } = useAppSelector(
        (state) => state.deploymentOptions
    );

    useEffect(() => {
        if (deploymentOptions && dataStore) {
            setCountryCode(
                deploymentOptions?.getCloudRegion(
                    dataStore?.getCloudProvider().code,
                    dataStore.cloudRegion.code
                )
            );
        }
    }, [dataStore, deploymentOptions]);

    useEffect(() => {
        if (dataStore) {
            const cardData = [
                {
                    label: (
                        <div
                            className={
                                styles.DataStoreOverviewCloudInformationLabel
                            }
                        >
                            Provider
                        </div>
                    ),
                    testId: `${testId}CardProvider`,
                    content: (
                        <SmallContainer
                            topChildren={
                                <>
                                    {getCloudProviderLogo(
                                        dataStore.getCloudProvider()
                                    )}
                                    <div>
                                        {dataStore.getFullCloudProviderName()}
                                    </div>
                                </>
                            }
                        />
                    ),
                    span: 9,
                },
                {
                    label: (
                        <div
                            className={
                                styles.DataStoreOverviewCloudInformationLabel
                            }
                        >
                            Region
                        </div>
                    ),
                    testId: `${testId}CardRegion`,
                    content: (
                        <SmallContainer
                            topChildren={
                                <>
                                    {countryCode && (
                                        <AppFlagIcon code={countryCode} />
                                    )}
                                    <div>
                                        {dataStore?.isLintasarta()
                                            ? dataStore?.preferredRegion
                                            : dataStore?.cloudRegion
                                                  .display_code}
                                    </div>
                                </>
                            }
                        />
                    ),
                    span: 8,
                },
                {
                    label: (
                        <div
                            className={
                                styles.DataStoreOverviewCloudInformationLabel
                            }
                        >
                            VPC
                        </div>
                    ),
                    testId: `${testId}CardVpc`,
                    content: (
                        <div>
                            {dataStore.vpc ? (
                                dataStore?.vpc?.getVpcName()
                            ) : (
                                <Tooltip
                                    placement="bottom"
                                    title="Only available on AWS private networks."
                                >
                                    Not available <CcxIconInfoCircleOutlined />
                                </Tooltip>
                            )}
                        </div>
                    ),
                    span: 7,
                },
            ];

            if (!dataStore.getCloudProvider().isAws()) {
                cardData.pop();
            }

            setCards(cardData);
        }
    }, [dataStore, countryCode]);

    return (
        <div
            className={styles.DataStoreOverviewCloudInformation}
            data-testid={testId}
        >
            <div className={styles.DataStoreOverviewCloudInformationContent}>
                {dataStore ? (
                    <>
                        <header>Cloud</header>
                        <main>
                            <Row
                                className={
                                    styles.DataStoreOverviewCloudInformationColumns
                                }
                            >
                                {cards.map((c: any, i: number) => {
                                    return (
                                        <Col span={c.span} key={`cards${i}`}>
                                            <SmallContainer
                                                testId={c.testId}
                                                topChildren={c.label}
                                                middleChildren={c.content}
                                            />
                                        </Col>
                                    );
                                })}
                            </Row>
                        </main>
                    </>
                ) : (
                    <LazyLoader
                        type="row"
                        testId={`${testId}Loader`}
                        rows={2}
                    />
                )}
            </div>
        </div>
    );
}

export default DataStoreOverviewCloudInformation;
