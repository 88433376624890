import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ContentService from '../services/ContentService';
import DeploymentOptions from '../types/DeploymentOptions';

interface DeploymentOptionsType {
    deploymentOptions: DeploymentOptions | undefined;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
}
const initialState = {
    deploymentOptions: undefined,
    isError: false,
    isSuccess: false,
    isLoading: false,
} as DeploymentOptionsType;

export const getDeploymentOptions = createAsyncThunk(
    'datastores/getDeploymentOptions',
    async () => {
        try {
            const res = await ContentService.getDeploymentWizardOptions();
            return new DeploymentOptions(res);
        } catch (e) {
            console.error(e);
        }
    }
);

export const deploymentOptionSlice = createSlice({
    name: 'datastores/getDeploymentOptions',
    initialState,
    reducers: {
        getDeploymentOptions: (state, action) => {
            state.isLoading = false;
            state.deploymentOptions = action.payload?.deploymentOptions;
            state.isSuccess = true;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDeploymentOptions.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getDeploymentOptions.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.deploymentOptions = action.payload;
            })
            .addCase(getDeploymentOptions.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            });
    },
});

export default deploymentOptionSlice.reducer;
