import Error404 from './Error404';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface Props {
    testId?: string;
}

export default function Error404Page({ testId = 'Error404Page' }: Props) {
    const history = useHistory();
    const handleHomeClick = () => {
        history.push({
            pathname: '/',
        });
    };

    return <Error404 testId={testId} onHomeClick={handleHomeClick} />;
}
