import React, { ReactElement } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import Vpc from '../../../types/Vpc';
import AppFlagIcon from '../../ccx/common/AppFlagIcon';
import styles from './InstanceInformation.module.less';
import DeploymentOptions from '../../../types/DeploymentOptions';
import AppLogoBrand from '../../ccx/common/AppLogoBrand';
import Icon from '@ant-design/icons/lib/components/Icon';

interface Props extends CcxComponentProps {
    vpc: Vpc;
    countryCode: string;
    deploymentOptions: DeploymentOptions;
}

function InstanceInformation({
    vpc,
    countryCode,
    testId = 'InstanceInformation',
    deploymentOptions,
}: Props): ReactElement {
    const getCloudProviderLogo = (
        deploymentOptions: DeploymentOptions | undefined,
        p: string
    ) => {
        if (deploymentOptions) {
            const url = deploymentOptions?.getCloudProviderLogo(p);
            return (
                <Icon
                    component={() => (
                        <img src={url} alt={url} width={50} height={20} />
                    )}
                />
            );
        }
        return null;
    };

    return (
        <div className={styles.InstanceInformation} data-testid={testId}>
            <div data-testid={`${testId}Top`}>
                {getCloudProviderLogo(deploymentOptions, vpc.cloud)}
                <AppFlagIcon code={countryCode} />
                {vpc.region}
            </div>
            <div data-testid={`${testId}Bottom`}>
                <strong>{vpc.getName()}</strong>
            </div>
        </div>
    );
}

export default InstanceInformation;
