import React, { ReactElement, useEffect, useState } from 'react';
import LazyLoader from './components/LazyLoader';
import '@fontsource/rubik';

interface Props {
    children: any;
}

function AppLoader({ children }: Props): ReactElement {
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        // check for a dummy font size to make sure the font is loaded
        (document as any).fonts
            ?.load('14px Rubik')
            .then(() => setIsReady(true));
    }, []);

    if (isReady) {
        return <>{children}</>;
    }

    return <LazyLoader type="row" />;
}

export default AppLoader;
