import React, { useState } from 'react';
import { ChartsContext } from '../context/ChartsContext';

export interface ChartsProps {
    children: React.ReactNode;
}

export const ChartsProvider = ({ children }: ChartsProps) => {
    const [chartRange, setChartRange] = useState<string | undefined>(undefined);

    return (
        <ChartsContext.Provider
            value={{
                chartRange,
                setChartRange,
            }}
        >
            {children}
        </ChartsContext.Provider>
    );
};
