import { useEffect, useState } from 'react';
import DbUsers from '../../types/DbUsers';
import DbUserService from '../../services/DbUserService';

export default function useDbUsers(uuid: string | undefined) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [dbUsers, setDbUsers] = useState<DbUsers | undefined>(undefined);

    const load = async () => {
        if (uuid) {
            setLoading(true);

            try {
                const details = await DbUserService.getAllUsers(uuid);
                setDbUsers(details);
                setLoading(false);
                setError(null);
            } catch (e: any) {
                setLoading(false);
                setError(e);
            }

            setLoading(false);
        }
    };

    const refresh = () => {
        load();
    };

    // get deployments for the given user
    useEffect(() => {
        refresh();
    }, [uuid]);

    return { dbUsers, refresh, loading, error };
}
