import { useEffect, useState } from 'react';
import BackupSchedule from '../../types/BackupSchedule';
import BackupService from '../../services/BackupService';
import { backupApiInterface, BackupScheduleDTO } from '../../sdk/BackupsApi';

export default function useBackupSchedules(uuid: string | undefined) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [backupSchedules, setBackupSchedules] = useState<
        BackupSchedule[] | undefined | any
    >(undefined);

    const load = async () => {
        if (uuid) {
            setLoading(true);

            try {
                const details = await BackupService.getAllBackupSchedules(uuid);
                setBackupSchedules(details);
                setLoading(false);
                setError(null);
            } catch (e: any) {
                setLoading(false);
                setError(e);
            }

            setLoading(false);
        }
    };

    const refresh = () => {
        load();
    };

    // get deployments for the given user
    useEffect(() => {
        refresh();
    }, [uuid]);

    return { backupSchedules, refresh, loading, error };
}
