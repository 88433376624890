import { ReactElement, Suspense, useEffect, useState } from 'react';
import { Alert, Badge, Button, Popover, Tabs, Tooltip } from 'antd';
import CcxComponentProps from '../../core/CcxComponent';
import styles from './Projects.module.less';
import { Link, useHistory, useParams } from 'react-router-dom';
import LazyLoader from '../LazyLoader';
import DataStores from './dataStores/DataStores';
import Vpcs from './vpcs/Vpcs';
import CreateVpcButton from '../vpc/CreateVpcButton';
import { vpcDisabled } from '../../core/CcxEnv';
import { userReachedDatastoreLimit } from '../layout/AppCreateButton';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import useCmonStatus from '../../core/hooks/useCmonStatus';
import DatastoreFilterPopover, {
    OptionsInterface,
} from './DatastoreFilterPopover';
import { FilterOutlined } from '@ant-design/icons';
import { clearIntervals } from '../../slices/jobs.slice';
import Deployment from '../../types/Deployment';

interface UrlProps {
    projectUuid: string;
    activeTab: string;
}

interface Props extends CcxComponentProps {}

interface FilterOptionsInterface {
    datastoreTypes: OptionsInterface[];
    tagsList: OptionsInterface[];
}

export interface SelectedFilterOptionsInterface {
    dbVendors: string[];
    tags: string[];
}

function Projects({ testId = 'Projects' }: Props): ReactElement {
    const history = useHistory();
    const { projectUuid, activeTab } = useParams<UrlProps>();
    const [openPopover, setOpenPopover] = useState<boolean>(false);

    const [filterOptions, setFilterOptions] = useState<FilterOptionsInterface>({
        datastoreTypes: [],
        tagsList: [],
    });

    const [selectedFilterOptions, setSelectedFilterOptions] =
        useState<SelectedFilterOptionsInterface>({
            dbVendors: [],
            tags: [],
        });

    const [filterCount, setFilterCount] = useState<number>(0);

    const { datastores } = useAppSelector((state) => state.datastores);
    const { user } = useAppSelector((state) => state.user);
    const { status } = useCmonStatus();
    const dispatch = useAppDispatch();

    const limitReached = userReachedDatastoreLimit(datastores, user);
    useEffect(() => {
        if (datastores) {
            datastores.map((datastore: Deployment) =>
                dispatch(
                    clearIntervals({ dataStoreUuid: datastore.dataStoreUuid })
                )
            );
        }
    }, [datastores, dispatch]);

    useEffect(() => {
        if (datastores)
            setFilterOptions({
                tagsList: datastores.getTagsForFiltering(),
                datastoreTypes: datastores.getVendorsForFiltering(),
            });
    }, [datastores]);

    const onChangeDatastoreCheckbox = (value: string[]) => {
        setSelectedFilterOptions({
            ...selectedFilterOptions,
            dbVendors: value,
        });
        setFilterCount(value.length + selectedFilterOptions.tags.length);
    };

    const onChangeDatastoreTags = (value: string[]) => {
        setSelectedFilterOptions({
            ...selectedFilterOptions,
            tags: value,
        });
        setFilterCount(value.length + selectedFilterOptions.dbVendors.length);
    };

    const openFilter = () => {
        setOpenPopover(!openPopover);
    };

    const operations =
        activeTab === 'data-stores' ? (
            <>
                <Popover
                    content={
                        <DatastoreFilterPopover
                            options={filterOptions}
                            onChangeDbTypesFilter={onChangeDatastoreCheckbox}
                            onChangeTagsFilter={onChangeDatastoreTags}
                        />
                    }
                    title={<strong>Filters</strong>}
                    trigger="click"
                    onOpenChange={() => openFilter()}
                    open={openPopover}
                    overlayInnerStyle={{
                        borderRadius: 16,
                    }}
                    overlayStyle={{
                        width: '250px',
                    }}
                >
                    <Badge
                        size="default"
                        className={styles.FilterBadge}
                        count={filterCount}
                    >
                        <Button className={styles.FilterButton}>
                            <FilterOutlined />
                        </Button>
                    </Badge>
                </Popover>
                <Tooltip title={limitReached ? user?.disabledText : ''}>
                    <Link to={`/projects/${projectUuid}/${activeTab}/add`}>
                        <Button
                            data-testid={`${testId}CreateNewButton`}
                            type="primary"
                            disabled={limitReached}
                            className={styles.ProjectsOperationButton}
                        >
                            + Create{' '}
                            {activeTab === 'data-stores' ? 'datastore' : 'VPC'}
                        </Button>
                    </Link>
                </Tooltip>
            </>
        ) : (
            <>
                <CreateVpcButton
                    buttonText="+ Create VPC"
                    buttonType="primary"
                    className={styles.ProjectsOperationButton}
                    size="middle"
                />
            </>
        );

    return (
        <section className={styles.Projects} data-testid={testId}>
            {status && !status.isFunctional() && (
                <Alert
                    className={styles.CmonStatusAlert}
                    showIcon
                    type="warning"
                    message={
                        <div className={styles.CmonStatusAlertMessage}>
                            Attention: Our system is currently undergoing
                            maintenance to enhance your experience. We
                            appreciate your patience as we work to restore full
                            functionality.
                        </div>
                    }
                />
            )}

            <Tabs
                className={styles.ProjectsTabs}
                data-testid={`${testId}Tabs`}
                tabBarExtraContent={operations}
                type="card"
                activeKey={activeTab}
                onChange={(tabKey: any) => {
                    history.push(`/projects/${projectUuid}/${tabKey}`);
                }}
            >
                <Tabs.TabPane tab="Datastores" key="data-stores">
                    <Suspense fallback={<LazyLoader />}>
                        <DataStores
                            selectedFilters={selectedFilterOptions}
                            filterCount={
                                selectedFilterOptions.dbVendors.length +
                                selectedFilterOptions.tags.length
                            }
                        />
                    </Suspense>
                </Tabs.TabPane>

                {!vpcDisabled && (
                    <Tabs.TabPane tab="VPCs" key="vpcs">
                        <Suspense fallback={<LazyLoader />}>
                            <Vpcs />
                        </Suspense>
                    </Tabs.TabPane>
                )}
            </Tabs>
        </section>
    );
}

export default Projects;
