export type DbAccountClassProps = {
    username: string;
    password: string;
    host: string;
    database: string;
    privileges: string;
};

export interface DbAccountInterface {
    getUsername(): string;
    getPassword(): string;
    getHost(): string;
    getDatabase(): string;
}

export default class DbAccount implements DbAccountInterface {
    readonly username: string;
    readonly password: string;
    readonly host: string;
    readonly database: string;
    readonly privileges: string;

    constructor(props: DbAccountClassProps) {
        this.username = props.username;
        this.password = props.password;
        this.host = props.host;
        this.database = props.database;
        this.privileges = props.privileges;
    }

    getUsername(): string {
        return this.username;
    }

    getPassword(): string {
        return this.password;
    }

    getHost(): string {
        return this.host;
    }

    getDatabase(): string {
        return this.database;
    }
}
