export type PrometheusProxySqlServerConnectionsStatsBackendApiProps = {
    created: number;
    server_connections_aborted: number;
    server_connections_connected: number;
    server_connections_created: number;
    server_connections_delayed: number;
};

export default class PrometheusProxySqlServerConnectionsStats {
    readonly created: number;
    readonly serverConnectionsAborted: number;
    readonly serverConnectionsConnected: number;
    readonly serverConnectionsCreated: number;
    readonly serverConnectionsDelayed: number;

    constructor(
        props: PrometheusProxySqlServerConnectionsStatsBackendApiProps
    ) {
        this.created = props.created;
        this.serverConnectionsAborted = props.server_connections_aborted;
        this.serverConnectionsConnected = props.server_connections_connected;
        this.serverConnectionsCreated = props.server_connections_created;
        this.serverConnectionsDelayed = props.server_connections_delayed;
    }
}
