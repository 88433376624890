const countries = [
    { code: 'AF', name: 'Afghanistan', isEu: false },
    { code: 'AX', name: '\u00c5land Islands', isEu: false },
    { code: 'AL', name: 'Albania', isEu: false },
    { code: 'DZ', name: 'Algeria', isEu: false },
    { code: 'AS', name: 'American Samoa', isEu: false },
    { code: 'AD', name: 'Andorra', isEu: false },
    { code: 'AO', name: 'Angola', isEu: false },
    { code: 'AI', name: 'Anguilla', isEu: false },
    { code: 'AQ', name: 'Antarctica', isEu: false },
    { code: 'AG', name: 'Antigua and Barbuda', isEu: false },
    { code: 'AR', name: 'Argentina', isEu: false },
    { code: 'AM', name: 'Armenia', isEu: false },
    { code: 'AW', name: 'Aruba', isEu: false },
    { code: 'AU', name: 'Australia', isEu: false },
    { code: 'AT', name: 'Austria', isEu: true },
    { code: 'AZ', name: 'Azerbaijan', isEu: false },
    { code: 'BS', name: 'Bahamas', isEu: false },
    { code: 'BH', name: 'Bahrain', isEu: false },
    { code: 'BD', name: 'Bangladesh', isEu: false },
    { code: 'BB', name: 'Barbados', isEu: false },
    { code: 'BY', name: 'Belarus', isEu: false },
    { code: 'BE', name: 'Belgium', isEu: true },
    { code: 'BZ', name: 'Belize', isEu: false },
    { code: 'BJ', name: 'Benin', isEu: false },
    { code: 'BM', name: 'Bermuda', isEu: false },
    { code: 'BT', name: 'Bhutan', isEu: false },
    { code: 'BO', name: 'Bolivia, Plurinational State of', isEu: false },
    { code: 'BQ', name: 'Bonaire, Sint Eustatius and Saba', isEu: false },
    { code: 'BA', name: 'Bosnia and Herzegovina', isEu: false },
    { code: 'BW', name: 'Botswana', isEu: false },
    { code: 'BV', name: 'Bouvet Island', isEu: false },
    { code: 'BR', name: 'Brazil', isEu: false },
    { code: 'IO', name: 'British Indian Ocean Territory', isEu: false },
    { code: 'BN', name: 'Brunei Darussalam', isEu: false },
    { code: 'BG', name: 'Bulgaria', isEu: true },
    { code: 'BF', name: 'Burkina Faso', isEu: false },
    { code: 'BI', name: 'Burundi', isEu: false },
    { code: 'KH', name: 'Cambodia', isEu: false },
    { code: 'CM', name: 'Cameroon', isEu: false },
    { code: 'CA', name: 'Canada', isEu: false },
    { code: 'CV', name: 'Cape Verde', isEu: false },
    { code: 'KY', name: 'Cayman Islands', isEu: false },
    { code: 'CF', name: 'Central African Republic', isEu: false },
    { code: 'TD', name: 'Chad', isEu: false },
    { code: 'CL', name: 'Chile', isEu: false },
    { code: 'CN', name: 'China', isEu: false },
    { code: 'CX', name: 'Christmas Island', isEu: false },
    { code: 'CC', name: 'Cocos (Keeling) Islands', isEu: false },
    { code: 'CO', name: 'Colombia', isEu: false },
    { code: 'KM', name: 'Comoros', isEu: false },
    { code: 'CG', name: 'Congo', isEu: false },
    { code: 'CD', name: 'Congo, the Democratic Republic of the', isEu: false },
    { code: 'CK', name: 'Cook Islands', isEu: false },
    { code: 'CR', name: 'Costa Rica', isEu: false },
    { code: 'CI', name: "C\u00f4te d'Ivoire" },
    { code: 'HR', name: 'Croatia', isEu: true },
    { code: 'CU', name: 'Cuba', isEu: false },
    { code: 'CW', name: 'Cura\u00e7ao', isEu: false },
    { code: 'CY', name: 'Cyprus', isEu: true },
    { code: 'CZ', name: 'Czech Republic', isEu: true },
    { code: 'DK', name: 'Denmark', isEu: true },
    { code: 'DJ', name: 'Djibouti', isEu: false },
    { code: 'DM', name: 'Dominica', isEu: false },
    { code: 'DO', name: 'Dominican Republic', isEu: false },
    { code: 'EC', name: 'Ecuador', isEu: false },
    { code: 'EG', name: 'Egypt', isEu: false },
    { code: 'SV', name: 'El Salvador', isEu: false },
    { code: 'GQ', name: 'Equatorial Guinea', isEu: false },
    { code: 'ER', name: 'Eritrea', isEu: false },
    { code: 'EE', name: 'Estonia', isEu: true },
    { code: 'ET', name: 'Ethiopia', isEu: false },
    { code: 'FK', name: 'Falkland Islands (Malvinas)', isEu: false },
    { code: 'FO', name: 'Faroe Islands', isEu: false },
    { code: 'FJ', name: 'Fiji', isEu: false },
    { code: 'FI', name: 'Finland', isEu: true },
    { code: 'FR', name: 'France', isEu: true },
    { code: 'GF', name: 'French Guiana', isEu: false },
    { code: 'PF', name: 'French Polynesia', isEu: false },
    { code: 'TF', name: 'French Southern Territories', isEu: false },
    { code: 'GA', name: 'Gabon', isEu: false },
    { code: 'GM', name: 'Gambia', isEu: false },
    { code: 'GE', name: 'Georgia', isEu: false },
    { code: 'DE', name: 'Germany', isEu: true },
    { code: 'GH', name: 'Ghana', isEu: false },
    { code: 'GI', name: 'Gibraltar', isEu: false },
    { code: 'GR', name: 'Greece', isEu: true },
    { code: 'GL', name: 'Greenland', isEu: false },
    { code: 'GD', name: 'Grenada', isEu: false },
    { code: 'GP', name: 'Guadeloupe', isEu: false },
    { code: 'GU', name: 'Guam', isEu: false },
    { code: 'GT', name: 'Guatemala', isEu: false },
    { code: 'GG', name: 'Guernsey', isEu: false },
    { code: 'GN', name: 'Guinea', isEu: false },
    { code: 'GW', name: 'Guinea-Bissau', isEu: false },
    { code: 'GY', name: 'Guyana', isEu: false },
    { code: 'HT', name: 'Haiti', isEu: false },
    { code: 'HM', name: 'Heard Island and McDonald Islands', isEu: false },
    { code: 'VA', name: 'Holy See (Vatican City State)', isEu: false },
    { code: 'HN', name: 'Honduras', isEu: false },
    { code: 'HK', name: 'Hong Kong', isEu: false },
    { code: 'HU', name: 'Hungary', isEu: true },
    { code: 'IS', name: 'Iceland', isEu: false },
    { code: 'IN', name: 'India', isEu: false },
    { code: 'ID', name: 'Indonesia', isEu: false },
    { code: 'IR', name: 'Iran, Islamic Republic of', isEu: false },
    { code: 'IQ', name: 'Iraq', isEu: false },
    { code: 'IE', name: 'Ireland', isEu: true },
    { code: 'IM', name: 'Isle of Man', isEu: false },
    { code: 'IL', name: 'Israel', isEu: false },
    { code: 'IT', name: 'Italy', isEu: true },
    { code: 'JM', name: 'Jamaica', isEu: false },
    { code: 'JP', name: 'Japan', isEu: false },
    { code: 'JE', name: 'Jersey', isEu: false },
    { code: 'JO', name: 'Jordan', isEu: false },
    { code: 'KZ', name: 'Kazakhstan', isEu: false },
    { code: 'KE', name: 'Kenya', isEu: false },
    { code: 'KI', name: 'Kiribati', isEu: false },
    { code: 'KP', name: "Korea, Democratic People's Republic of" },
    { code: 'KR', name: 'Korea, Republic of', isEu: false },
    { code: 'KW', name: 'Kuwait', isEu: false },
    { code: 'KG', name: 'Kyrgyzstan', isEu: false },
    { code: 'LA', name: "Lao People's Democratic Republic" },
    { code: 'LV', name: 'Latvia', isEu: true },
    { code: 'LB', name: 'Lebanon', isEu: false },
    { code: 'LS', name: 'Lesotho', isEu: false },
    { code: 'LR', name: 'Liberia', isEu: false },
    { code: 'LY', name: 'Libya', isEu: false },
    { code: 'LI', name: 'Liechtenstein', isEu: false },
    { code: 'LT', name: 'Lithuania', isEu: true },
    { code: 'LU', name: 'Luxembourg', isEu: true },
    { code: 'MO', name: 'Macao', isEu: false },
    {
        code: 'MK',
        name: 'Macedonia, the Former Yugoslav Republic of',
        isEu: false,
    },
    { code: 'MG', name: 'Madagascar', isEu: false },
    { code: 'MW', name: 'Malawi', isEu: false },
    { code: 'MY', name: 'Malaysia', isEu: false },
    { code: 'MV', name: 'Maldives', isEu: false },
    { code: 'ML', name: 'Mali', isEu: false },
    { code: 'MT', name: 'Malta', isEu: true },
    { code: 'MH', name: 'Marshall Islands', isEu: false },
    { code: 'MQ', name: 'Martinique', isEu: false },
    { code: 'MR', name: 'Mauritania', isEu: false },
    { code: 'MU', name: 'Mauritius', isEu: false },
    { code: 'YT', name: 'Mayotte', isEu: false },
    { code: 'MX', name: 'Mexico', isEu: false },
    { code: 'FM', name: 'Micronesia, Federated States of', isEu: false },
    { code: 'MD', name: 'Moldova, Republic of', isEu: false },
    { code: 'MC', name: 'Monaco', isEu: false },
    { code: 'MN', name: 'Mongolia', isEu: false },
    { code: 'ME', name: 'Montenegro', isEu: false },
    { code: 'MS', name: 'Montserrat', isEu: false },
    { code: 'MA', name: 'Morocco', isEu: false },
    { code: 'MZ', name: 'Mozambique', isEu: false },
    { code: 'MM', name: 'Myanmar', isEu: false },
    { code: 'NA', name: 'Namibia', isEu: false },
    { code: 'NR', name: 'Nauru', isEu: false },
    { code: 'NP', name: 'Nepal', isEu: false },
    { code: 'NL', name: 'Netherlands', isEu: true },
    { code: 'NC', name: 'New Caledonia', isEu: false },
    { code: 'NZ', name: 'New Zealand', isEu: false },
    { code: 'NI', name: 'Nicaragua', isEu: false },
    { code: 'NE', name: 'Niger', isEu: false },
    { code: 'NG', name: 'Nigeria', isEu: false },
    { code: 'NU', name: 'Niue', isEu: false },
    { code: 'NF', name: 'Norfolk Island', isEu: false },
    { code: 'MP', name: 'Northern Mariana Islands', isEu: false },
    { code: 'NO', name: 'Norway', isEu: false },
    { code: 'OM', name: 'Oman', isEu: false },
    { code: 'PK', name: 'Pakistan', isEu: false },
    { code: 'PW', name: 'Palau', isEu: false },
    { code: 'PS', name: 'Palestine, State of', isEu: false },
    { code: 'PA', name: 'Panama', isEu: false },
    { code: 'PG', name: 'Papua New Guinea', isEu: false },
    { code: 'PY', name: 'Paraguay', isEu: false },
    { code: 'PE', name: 'Peru', isEu: false },
    { code: 'PH', name: 'Philippines', isEu: false },
    { code: 'PN', name: 'Pitcairn', isEu: false },
    { code: 'PL', name: 'Poland', isEu: true },
    { code: 'PT', name: 'Portugal', isEu: true },
    { code: 'PR', name: 'Puerto Rico', isEu: false },
    { code: 'QA', name: 'Qatar', isEu: false },
    { code: 'RE', name: 'R\u00e9union', isEu: false },
    { code: 'RO', name: 'Romania', isEu: true },
    { code: 'RU', name: 'Russian Federation', isEu: false },
    { code: 'RW', name: 'Rwanda', isEu: false },
    { code: 'BL', name: 'Saint Barth\u00e9lemy', isEu: false },
    {
        code: 'SH',
        name: 'Saint Helena, Ascension and Tristan da Cunha',
        isEu: false,
    },
    { code: 'KN', name: 'Saint Kitts and Nevis', isEu: false },
    { code: 'LC', name: 'Saint Lucia', isEu: false },
    { code: 'MF', name: 'Saint Martin (French part)', isEu: false },
    { code: 'PM', name: 'Saint Pierre and Miquelon', isEu: false },
    { code: 'VC', name: 'Saint Vincent and the Grenadines', isEu: false },
    { code: 'WS', name: 'Samoa', isEu: false },
    { code: 'SM', name: 'San Marino', isEu: false },
    { code: 'ST', name: 'Sao Tome and Principe', isEu: false },
    { code: 'SA', name: 'Saudi Arabia', isEu: false },
    { code: 'SN', name: 'Senegal', isEu: false },
    { code: 'RS', name: 'Serbia', isEu: false },
    { code: 'SC', name: 'Seychelles', isEu: false },
    { code: 'SL', name: 'Sierra Leone', isEu: false },
    { code: 'SG', name: 'Singapore', isEu: false },
    { code: 'SX', name: 'Sint Maarten (Dutch part)', isEu: false },
    { code: 'SK', name: 'Slovakia', isEu: true },
    { code: 'SI', name: 'Slovenia', isEu: true },
    { code: 'SB', name: 'Solomon Islands', isEu: false },
    { code: 'SO', name: 'Somalia', isEu: false },
    { code: 'ZA', name: 'South Africa', isEu: false },
    {
        code: 'GS',
        name: 'South Georgia and the South Sandwich Islands',
        isEu: false,
    },
    { code: 'SS', name: 'South Sudan', isEu: false },
    { code: 'ES', name: 'Spain', isEu: true },
    { code: 'LK', name: 'Sri Lanka', isEu: false },
    { code: 'SD', name: 'Sudan', isEu: false },
    { code: 'SR', name: 'Suriname', isEu: false },
    { code: 'SJ', name: 'Svalbard and Jan Mayen', isEu: false },
    { code: 'SZ', name: 'Swaziland', isEu: false },
    { code: 'SE', name: 'Sweden', isEu: true },
    { code: 'CH', name: 'Switzerland', isEu: false },
    { code: 'SY', name: 'Syrian Arab Republic', isEu: false },
    { code: 'TW', name: 'Taiwan, Province of China', isEu: false },
    { code: 'TJ', name: 'Tajikistan', isEu: false },
    { code: 'TZ', name: 'Tanzania, United Republic of', isEu: false },
    { code: 'TH', name: 'Thailand', isEu: false },
    { code: 'TL', name: 'Timor-Leste', isEu: false },
    { code: 'TG', name: 'Togo', isEu: false },
    { code: 'TK', name: 'Tokelau', isEu: false },
    { code: 'TO', name: 'Tonga', isEu: false },
    { code: 'TT', name: 'Trinidad and Tobago', isEu: false },
    { code: 'TN', name: 'Tunisia', isEu: false },
    { code: 'TR', name: 'Turkey', isEu: false },
    { code: 'TM', name: 'Turkmenistan', isEu: false },
    { code: 'TC', name: 'Turks and Caicos Islands', isEu: false },
    { code: 'TV', name: 'Tuvalu', isEu: false },
    { code: 'UG', name: 'Uganda', isEu: false },
    { code: 'UA', name: 'Ukraine', isEu: false },
    { code: 'AE', name: 'United Arab Emirates', isEu: false },
    { code: 'GB', name: 'United Kingdom', isEu: false },
    { code: 'US', name: 'United States', isEu: false },
    { code: 'UM', name: 'United States Minor Outlying Islands', isEu: false },
    { code: 'UY', name: 'Uruguay', isEu: false },
    { code: 'UZ', name: 'Uzbekistan', isEu: false },
    { code: 'VU', name: 'Vanuatu', isEu: false },
    { code: 'VE', name: 'Venezuela, Bolivarian Republic of', isEu: false },
    { code: 'VN', name: 'Viet Nam', isEu: false },
    { code: 'VG', name: 'Virgin Islands, British', isEu: false },
    { code: 'VI', name: 'Virgin Islands, U.S.', isEu: false },
    { code: 'WF', name: 'Wallis and Futuna', isEu: false },
    { code: 'EH', name: 'Western Sahara', isEu: false },
    { code: 'YE', name: 'Yemen', isEu: false },
    { code: 'ZM', name: 'Zambia', isEu: false },
    { code: 'ZW', name: 'Zimbabwe', isEu: false },
];

export default class CountryService {
    public static listCountries() {
        return countries;
    }

    public static getCountryName(countrycode: string) {
        if (!countrycode) {
            return;
        }

        const country = countries.filter((c) => {
            return c.code.toLowerCase() === countrycode.toLowerCase();
        });

        return country && country.length ? country[0].name : undefined;
    }

    // country list from https://europa.eu/european-union/about-eu/countries_en
    public static isEu(countrycode: string) {
        if (!countrycode) {
            return false;
        }

        const country = countries.filter((c) => {
            return c.code.toLowerCase() === countrycode.toLowerCase();
        });

        if (country && country.length) {
            return country[0].isEu;
        }

        return false;
    }
}
