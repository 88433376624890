import ApiService from './ApiService';

const API_URL = 'https://api.ipify.org?format=json';

export default class IpifyService extends ApiService {
    /**
     * Get the user's IP address
     * @returns {Promise<string>} User's IP address
     */
    static async getIPAddress(): Promise<any> {
        try {
            const response = await fetch(API_URL);
            const data = await response.json();
            return data;
        } catch (error) {
            throw new Error('Failed to fetch IP address');
        }
    }
}
