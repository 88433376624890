import React, { ReactElement, useEffect, useState } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import CmonSqlStat from '../../../types/CmonSqlStat';
import FriendlyTimestamp from '../../../types/FriendlyTimestamp';
import AppChart from '../../AppChart';
import AppChartTinyArea from '../../AppChartTinyArea';
import LazyLoader from '../../LazyLoader';
import ChartCustomTooltip from './ChartCustomTooltip';

import styles from './QueriesPerSecondChart.module.less';

interface Props extends CcxComponentProps {
    data?: CmonSqlStat[];
    loading: boolean;
    isPostgreSql?: boolean;
    tiny?: boolean;
}

function QueriesPerSecondChart({
    data,
    isPostgreSql,
    loading,
    tiny = true,
    testId = 'QueriesPerSecondChart',
}: Props): ReactElement {
    const [chartData, setChartData] = useState<any>([]);

    useEffect(() => {
        if (data && data.length > 0) {
            const formattedData = data.map((stat: CmonSqlStat) => {
                const yValue = Math.round(
                    isPostgreSql ? stat.commits : stat.queries
                );
                if (tiny) {
                    return yValue;
                } else {
                    return {
                        yValue,
                        xValue: new FriendlyTimestamp(stat.created).getDate(),
                    };
                }
            });

            setChartData(formattedData.flat());
        }
    }, [data]);

    const chartConfig = tiny
        ? {
              height: 75,
              width: 200,
              autoFit: false,
              smooth: false,
              areaStyle: {
                  fill: '#d6e3fd',
              },
          }
        : {
              description: {
                  visible: true,
                  text: '',
              },
              areaStyle: {
                  fill: '#530099',
              },
              height: 100,
              width: 500,
              autoFit: true,
              xField: 'xValue',
              yField: 'yValue',
              annotations: [
                  {
                      type: 'line',
                      start: ['min', 'median'],
                      end: ['max', 'median'],
                      style: {
                          stroke: 'grey',
                          lineDash: [2, 2],
                      },
                  },
              ],
              xAxis: {
                  type: 'time',
                  mask: 'HH:mm',
                  tickCount: 5,
              },
              yAxis: {
                  label: {
                      formatter: (text: string, item: any, index: number) => {
                          return text;
                      },
                  },
                  tickCount: 3,
              },
              animation: false,
              seriesField: 'series',
              tooltip: {
                  customContent: (title: any, data: any) => {
                      const getTitle = () => {
                          if (!data || data.length === 0) return '';
                          return data[0]?.data?.xValue.toString();
                      };

                      const getContent = () => {
                          if (!data || data.length === 0) return [];
                          return data.map((d: any) => ({
                              color: d?.color,
                              label: d?.data?.series,
                              value: d?.data?.yValue,
                          }));
                      };

                      const tooltipData = {
                          title: getTitle(),
                          content: getContent(),
                      };

                      return (
                          <ChartCustomTooltip
                              title={tooltipData.title}
                              content={tooltipData.content}
                          />
                      );
                  },
              },
          };

    return loading ? (
        <LazyLoader testId={testId} />
    ) : chartData.length > 0 ? (
        <div>
            {tiny ? (
                <AppChartTinyArea
                    data={chartData}
                    config={chartConfig}
                    testId={testId}
                />
            ) : (
                <AppChart
                    type="area"
                    data={chartData}
                    config={chartConfig}
                    loading={loading}
                    testId={testId}
                    bordered={false}
                />
            )}
        </div>
    ) : (
        <div>
            <div className={styles.MonitorInstallationSpinner}>
                <LazyLoader testId={testId} />
            </div>
            <p className={styles.MonitorInstallationMessage}>
                Monitoring is being set up ...
            </p>
        </div>
    );
}

export default QueriesPerSecondChart;
