import { BackupSettingsDTO } from '../sdk/BackupsApi';

export type BackupSettingsClassProps = BackupSettingsDTO & {
    full_source?: number;
    inc_source?: number;
};

export interface BackupSettingsInterface {
    fullFrequency?: number;
    fullStartHour?: number;
    incFrequency?: number;
    fullCron?: string;
    incCron?: string;
}

export default class BackupSettings implements BackupSettingsInterface {
    readonly fullFrequency?: number;
    readonly fullStartHour?: number;
    readonly incFrequency?: number;
    readonly fullCron?: string;
    readonly incCron?: string;

    constructor(props: BackupSettingsClassProps) {
        this.fullFrequency = props.full_frequency || props.full_source;
        this.fullStartHour = props.full_start_hour;
        this.incFrequency = props.inc_frequency || props.inc_source;
        this.fullCron = props.full_cron;
        this.incCron = props.inc_cron;
    }
}
