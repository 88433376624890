export type PrometheusHaProxyCurrentSessionRateBackendServersStatsBackendApiProps =
    {
        created: number;
        haproxy_server_current_session_rate: number;
    };

export default class PrometheusHaProxyCurrentSessionRateBackendServersStats {
    readonly created: number;
    readonly haproxyServerCurrentSessionRate: number;

    constructor(
        props: PrometheusHaProxyCurrentSessionRateBackendServersStatsBackendApiProps
    ) {
        this.created = props.created;
        this.haproxyServerCurrentSessionRate =
            props.haproxy_server_current_session_rate;
    }
}
