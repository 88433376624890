import InstanceVolumeIopsValue from './InstanceVolumeIopsValue';

export type InstanceVolumeIopsClassProps = {
    [provider: string]: any;
};

export interface InstanceVolumeIopsInterface {
    [provider: string]: any;
}

export default class InstanceVolumeIops implements InstanceVolumeIopsInterface {
    [provider: string]: any;

    constructor(props: InstanceVolumeIopsClassProps) {
        for (const provider in props) {
            if (Object.prototype.hasOwnProperty.call(props, provider)) {
                this[provider] = props[provider];

                if (this[provider]) {
                    Object.keys(this[provider]).map(
                        (k: any) =>
                            (this[provider][k] = new InstanceVolumeIopsValue(
                                this[provider][k]
                            ))
                    );
                } else {
                    this[provider] = {};
                }
            }
        }
    }
}
