export type PrometheusPostgreSqlInsertStatsBackendApiProps = {
    created: number;
    instance: string;
    pg_stat_database_tup_inserted: number;
};

export default class PrometheusPostgreSqlInsertStats {
    readonly created: number;
    readonly instance: string;
    readonly pgStatDatabaseTupInserted: number;

    constructor(props: PrometheusPostgreSqlInsertStatsBackendApiProps) {
        this.created = props.created;
        this.instance = props.instance;
        this.pgStatDatabaseTupInserted = props.pg_stat_database_tup_inserted;
    }
}
