export type PrometheusRedisSlaveLagStatsBackendApiProps = {
    created: number;
    connected_slave_lag: number;
};

export default class PrometheusRedisSlaveLagStats {
    readonly created: number;
    readonly connectedSlaveLag: number;

    constructor(props: PrometheusRedisSlaveLagStatsBackendApiProps) {
        this.created = props.created;
        this.connectedSlaveLag = props.connected_slave_lag;
    }
}
