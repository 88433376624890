import React, { ReactElement } from 'react';
import { Col, Row, Card, Tooltip, Button, notification } from 'antd';
import styles from './AppCodeCard.module.less';
import { useHover } from '../core/hooks/useHover';
import { CopyOutlined, CopyFilled } from '@ant-design/icons/lib/icons';
import CcxComponentProps from '../core/CcxComponent';
import CcxIconCheckCircleTwoTone from './ccx/icons/CcxIconCheckCircleTwoTone';

interface AppCodeCardProps extends CcxComponentProps {
    children?: any;
    className?: string;
    usageExample?: string[];
}

function AppCodeCard({
    children,
    className,
    usageExample,
    testId = 'AppCodeCard',
}: AppCodeCardProps): ReactElement {
    const [hoverRef, isHovered] = useHover<HTMLDivElement>();

    const handleClick = () => {
        copyMessage(usageExample ? usageExample.join('\r\n') : children);
        notification.open({
            message: 'Clipboard Updated',
            description: 'Copied to clipboard.',
            icon: <CcxIconCheckCircleTwoTone twoToneColor="#52c41a" />,
        });
    };

    const copyMessage = (val: string | undefined) => {
        if (!val) {
            return;
        }

        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    };

    return (
        <Card
            className={`${styles.AppCodeCard}`}
            bordered={false}
            data-testid={testId}
        >
            <Row style={{ width: '100%', alignItems: 'center' }}>
                <Col span={23}>
                    <code>{children}</code>
                </Col>
                <Col span={1} className={styles.AppCodeCardCol}>
                    <div ref={hoverRef}>
                        <Tooltip
                            placement="bottom"
                            title={'Click to copy to clipboard.'}
                            {...{
                                onClick: handleClick,
                            }}
                        >
                            <Button
                                type="link"
                                className={styles.AppCodeCardCopyIcon}
                            >
                                {isHovered ? <CopyFilled /> : <CopyOutlined />}
                            </Button>
                        </Tooltip>
                    </div>
                </Col>
            </Row>
        </Card>
    );
}

export default AppCodeCard;
