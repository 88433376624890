import AppChart from '../AppChart';
import styles from './DatabaseGrowthChart.module.less';
import { Datum, PlotEvent } from '@ant-design/charts';
import numbro from 'numbro';
import moment from 'moment';
import ChartCustomTooltip from '../ccx/charts/ChartCustomTooltip';
import { DbGrowthChartInterface } from '../../types/DbGrowth';

interface DatabaseGrowthChartProps {
    loading: boolean;
    activeDate: moment.Moment;
    onClickChartColumn: Function;
    chartData: DbGrowthChartInterface[];
}

const ChartTypeFree: string = 'Free';
const ChartTypeOther: string = 'Other';
const ChartTypeIndexSize: string = 'Index Size';
const ChartTypeDataSize: string = 'Data Size';

interface ColorMap {
    [key: string]: string;
}

function DatabaseGrowthChart({
    loading,
    chartData,
    activeDate,
    onClickChartColumn,
}: DatabaseGrowthChartProps) {
    const getColor = (type: string, date: string = '') => {
        const defaultColors: ColorMap = {
            [ChartTypeFree]: '#D9D9D980',
            [ChartTypeDataSize]: '#753FA780',
            [ChartTypeIndexSize]: '#8870b6',
            [ChartTypeOther]: '#391085',
        };

        const currentDateColors: ColorMap = {
            [ChartTypeFree]: '#fce7f3',
            [ChartTypeDataSize]: '#fbcfe8',
            [ChartTypeIndexSize]: '#f9a8d4',
            [ChartTypeOther]: '#f472b6',
        };
        if (
            moment(activeDate).format('DD/MM') === moment(date).format('DD/MM')
        ) {
            return currentDateColors[type];
        }
        return defaultColors[type];
    };

    return (
        <div className={styles.DatabasesGrowthChart}>
            <AppChart
                type="column"
                data={chartData}
                config={{
                    isStack: true,
                    xField: 'date',
                    yField: 'value',
                    seriesField: 'type',
                    interactions: [
                        {
                            type: 'active-region',
                            enable: false,
                        },
                    ],
                    legend: {
                        layout: 'horizontal',
                        position: 'top-left',
                        marker: {
                            symbol: 'circle',
                        },
                    },
                    color: ({ type }: Datum) => {
                        return getColor(type);
                    },
                    columnStyle: ({ type, date }: Datum) => {
                        return {
                            fill: getColor(type, date),
                        };
                    },
                    onEvent: (plot: any, event: PlotEvent) => {
                        if (event.type === 'click') {
                            onClickChartColumn(event?.data);
                        }
                    },
                    appendPadding: [20, 20, 20, 20],
                    yAxis: {
                        meta: {
                            base: 1,
                            minLimit: 1,
                        },
                        label: {
                            formatter: (text: string) => {
                                return numbro(parseInt(text, 10)).format({
                                    output: 'byte',
                                    base: 'binary',
                                    spaceSeparated: true,
                                    mantissa: 1,
                                });
                            },
                        },
                    },
                    xAxis: {
                        label: {
                            offset: 10,
                            formatter: (text: string) => {
                                return moment(text).format('DD/MM');
                            },
                        },
                        tickLine: null,
                    },
                    tooltip: {
                        customContent: (title: any, data: any) => {
                            const getTitle = () => {
                                if (!data || data.length === 0) return '';
                                return (
                                    <strong>
                                        Summary for{' '}
                                        {moment(activeDate).format('DD/MM/YY')}
                                    </strong>
                                );
                            };

                            const getContent = () => {
                                if (!data || data.length === 0) return [];
                                return data.map((d: any) => ({
                                    color: getColor(d?.data?.type, d.title),
                                    label: d?.data?.type,
                                    value: numbro(
                                        d?.data?.realValue || d?.data?.value
                                    ).format({
                                        output: 'byte',
                                        base: 'binary',
                                        spaceSeparated: true,
                                        mantissa: 1,
                                    }),
                                }));
                            };

                            const tooltipData = {
                                title: getTitle(),
                                content: getContent(),
                            };

                            return (
                                <ChartCustomTooltip
                                    title={tooltipData.title}
                                    content={tooltipData.content}
                                />
                            );
                        },
                    },
                    animation: false,
                }}
                loading={loading}
            />
        </div>
    );
}

export default DatabaseGrowthChart;
