import React, { ReactElement } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import styles from './AddServiceDatabaseVendorStep.module.less';
import WizardSelectTypeStep, {
    WizardSelectTypeStepItem,
} from '../../../tmp/WizardSelectTypeStep';
import { Space } from 'antd';
import { Button } from 'antd';
import DatabaseVendor from '../../../types/DatabaseVendor';
interface Props extends CcxComponentProps {
    selectedTechType?: string;
    selectedTech?: DatabaseVendor;
    handleTechTypeSelect: Function;
    handleTechTypeContinueClick: any;
    handleBackClick: Function;
    actionType?: string;
    dbVendors?: WizardSelectTypeStepItem[];
    dbVersion?: string;
}

export default function AddServiceDatabaseVendorStep({
    testId = 'AddServiceDatabaseVendorStep',
    selectedTechType,
    selectedTech,
    handleTechTypeSelect,
    handleTechTypeContinueClick,
    handleBackClick,
    actionType,
    dbVendors,
    dbVersion,
}: Props): ReactElement {
    return (
        <div
            className={styles.AddServiceDatabaseVendorStepContainer}
            data-testid={testId}
        >
            <h4 data-testid={`${testId}title`}>Select a database</h4>
            <WizardSelectTypeStep
                defaultValue={selectedTechType}
                onSelect={(item) => handleTechTypeSelect(item.key)}
                onConfirm={handleTechTypeContinueClick}
                dbVersion={dbVersion}
                footer={
                    <Space
                        style={{
                            width: '100%',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button onClick={handleBackClick as any}>Back</Button>
                        {selectedTech && (
                            <Button
                                type="primary"
                                onClick={handleTechTypeContinueClick}
                            >
                                Continue
                            </Button>
                        )}
                    </Space>
                }
                items={(actionType && dbVendors) || []}
            />
        </div>
    );
}
