import InstanceSize, { InstanceSizeClassProps } from './InstanceSize';

export type InstanceSizesClassProps = {
    [provider: string]: InstanceSizeClassProps[];
};

export interface InstanceSizesInterface {
    [provider: string]: InstanceSize[];
}

export default class InstanceSizes implements InstanceSizesInterface {
    [provider: string]: InstanceSize[];

    constructor(props: InstanceSizesClassProps) {
        for (const provider in props) {
            if (Object.prototype.hasOwnProperty.call(props, provider)) {
                this[provider] = (props[provider] || []).map(
                    (instanceSize: InstanceSizeClassProps) =>
                        new InstanceSize(instanceSize)
                );
            }
        }
    }
}
