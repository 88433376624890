export type PrometheusMySqlScanOperationsStatsBackendApiProps = {
    created: number;
    select_scan: number;
    delete_scan: number;
    update_scan: number;
};

export default class PrometheusMySqlScanOperationsStats {
    readonly created: number;
    readonly selectScan: number;
    readonly deleteScan: number;
    readonly updateScan: number;

    constructor(props: PrometheusMySqlScanOperationsStatsBackendApiProps) {
        this.created = props.created;
        this.selectScan = props.select_scan;
        this.deleteScan = props.delete_scan;
        this.updateScan = props.update_scan;
    }
}
