import React, { ReactElement } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import DeploymentsItem from '../../../types/DeploymentsItem';
import AppFlagIcon from '../../ccx/common/AppFlagIcon';
import SmallContainer from '../../ccx/common/SmallContainer';
import { getCloudProviderLogo } from './DataStoreCloudProviderLogo';

interface Props extends CcxComponentProps {
    countryCode: string;
    dataStore: DeploymentsItem;
}

function CloudInformation({
    countryCode,
    dataStore,
    testId = 'CloudInformation',
}: Props): ReactElement {
    const extended = true;
    const top = (
        <>
            {getCloudProviderLogo(dataStore.getCloudProvider(), extended)}
            <div>{dataStore.getFullCloudProviderName()}</div>
        </>
    );
    const middle = (
        <>
            <AppFlagIcon code={countryCode} />
            <div>
                {dataStore?.isLintasarta()
                    ? dataStore?.preferredRegion
                    : dataStore?.cloudRegion.display_code}
            </div>
        </>
    );

    return (
        <SmallContainer
            testId={testId}
            topChildren={top}
            middleChildren={middle}
        />
    );
}

export default CloudInformation;
