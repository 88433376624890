import React, { ReactElement } from 'react';
import { Col, Row, Space } from 'antd';
import styles from './CcxSectionHeader.module.less';
import CcxComponentProps from '../../../core/CcxComponent';

interface Props extends CcxComponentProps {
    children?: ReactElement;
}

function CcxSectionHeader({
    children,
    testId = 'CcxSectionHeader',
}: Props): ReactElement {
    return (
        <Row className={styles.CcxSectionHeader} data-testid={testId}>
            <Col>
                <Space>{children}</Space>
            </Col>
        </Row>
    );
}

export default CcxSectionHeader;
