import React, { ReactElement } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import AppCdnLogo from './AppCdnLogo';
import styles from './AppLogoBrand.module.less';

interface Props extends CcxComponentProps {
    height?: number;
    logo: string;
    size: string | number;
    width?: number;
}

function AppLogoBrand({
    height,
    logo,
    size,
    testId,
    width,
}: Props): ReactElement {
    return (
        <AppCdnLogo
            className={styles.AppLogoBrand}
            height={height}
            logo={logo}
            size={size || 'xlarge'}
            testId={testId || 'AppLogoBrand'}
            width={width}
        />
    );
}

export default AppLogoBrand;
