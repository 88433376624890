export type PrometheusHaProxyBackendsUpDownStatsBackendApiProps = {
    created: number;
    haproxy_backend_up: number;
    haproxy_backend_down: number;
};

export default class PrometheusHaProxyBackendsUpDownStats {
    readonly created: number;
    readonly haproxyBackendUp: number;
    readonly haproxyBackendDown: number;

    constructor(props: PrometheusHaProxyBackendsUpDownStatsBackendApiProps) {
        this.created = props.created;
        this.haproxyBackendUp = props.haproxy_backend_up;
        this.haproxyBackendDown = props.haproxy_backend_down;
    }
}
