import { ClockCircleOutlined } from '@ant-design/icons';
import React, { ReactElement } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';

interface Props extends CcxComponentProps {}

function CcxIconClockCircle({
    testId = 'CcxIconClockCircle',
}: Props): ReactElement {
    return <ClockCircleOutlined data-testid={testId} />;
}

export default CcxIconClockCircle;
