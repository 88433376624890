import CloudInstance, { CloudInstanceClassProps } from './CloudInstance';

export default class DbNode extends CloudInstance {
    constructor(props: CloudInstanceClassProps) {
        super(props);
    }

    getHostPort() {
        return `${this.publicIp || this.privateIp}:${this.port}`;
    }
}
