export type DataStoreMaintenanceSettingsClassProps = {
    day_of_week: number;
    end_hour: number;
    start_hour: number;
};

export interface DataStoreMaintenanceSettingsInterface {
    getDayOfWeek: Function;
    getEndHour: Function;
    getStartHour: Function;
}

export default class DataStoreMaintenanceSettings
    implements DataStoreMaintenanceSettingsInterface
{
    readonly dayOfWeek: number;
    readonly endHour: number;
    readonly startHour: number;

    constructor(props: DataStoreMaintenanceSettingsClassProps) {
        this.dayOfWeek = props.day_of_week;
        this.endHour = props.end_hour;
        this.startHour = props.start_hour;
    }

    getDayOfWeek(): number {
        return this.dayOfWeek;
    }

    getEndHour(): number {
        return this.endHour;
    }

    getStartHour(): number {
        return this.startHour;
    }
}
