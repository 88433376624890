import InstanceVolumeSize from './InstanceVolumeSize';

export type InstanceVolumeSizesClassProps = {
    [provider: string]: any;
};

export interface InstanceVolumeSizesInterface {
    [provider: string]: any;
}

export default class InstanceVolumeSizes
    implements InstanceVolumeSizesInterface
{
    [provider: string]: any;

    constructor(props: InstanceVolumeSizesClassProps) {
        for (const provider in props) {
            if (Object.prototype.hasOwnProperty.call(props, provider)) {
                this[provider] = props[provider];

                if (this[provider]) {
                    Object.keys(this[provider]).map(
                        (k: any) =>
                            (this[provider][k] = new InstanceVolumeSize(
                                this[provider][k]
                            ))
                    );
                } else {
                    this[provider] = {};
                }
            }
        }
    }
}
