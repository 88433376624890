import React, { ReactElement } from 'react';
import CcxComponentProps from '../../core/CcxComponent';

import styles from './BackupColumns.module.less';

interface Props extends CcxComponentProps {
    title: string;
    subTitle: any;
    last?: boolean;
}

function BackupColumns({
    title,
    subTitle,
    last = false,
    testId = 'BackupColumns',
}: Props): ReactElement {
    return (
        <div
            className={last ? styles.BackupColumnsLast : styles.BackupColumns}
            data-testid={testId}
        >
            <div data-testid={`${testId}Top`}>{title}</div>
            <div data-testid={`${testId}Bottom`}>{subTitle}</div>
        </div>
    );
}

export default BackupColumns;
