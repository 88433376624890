import { BackupScheduleDTO } from '../sdk/BackupsApi';

export enum BackupScheduleStatus {
    SCHEDULED = 'SCHEDULED',
    PAUSED = 'PAUSED',
}

export enum BackupScheduleType {
    FULL = 'full',
    INCREMENTAL = 'incremental',
}

export type BackupScheduleClassProps = BackupScheduleDTO & {};
export interface BackupScheduleInterface {
    backupMethod?: string;
    backupType?: BackupScheduleType;
    status?: string;
    recurrence?: string;
    createdAt?: string;
}

export default class BackupSchedule implements BackupScheduleInterface {
    readonly backupMethod?: string;
    readonly backupType?: BackupScheduleType;
    readonly status?: string;
    readonly recurrence?: string;
    readonly createdAt?: string;

    constructor(props: BackupScheduleClassProps) {
        this.backupMethod = props.backup_method;
        this.backupType = props.backup_type as BackupScheduleType;
        this.status = props.status;
        this.recurrence = props.recurrence;
        this.createdAt = props.created_at;
    }

    getCronString() {
        return this.recurrence?.replace('TZ=UTC ', '');
    }

    isActive() {
        return this.status === 'SCHEDULED';
    }
}
