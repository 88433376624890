import React, { ReactElement } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import AppLogoBrand from './AppLogoBrand';

interface Props extends CcxComponentProps {
    height?: number;
    width?: number;
    useExtended?: boolean;
}

function AppLogoBrandMssql({
    height = 28,
    width = 90,
    testId = 'AppLogoBrandMssql',
    useExtended = true,
}: Props): ReactElement {
    return (
        <AppLogoBrand
            logo={'vendor-mssql-brand-logo'}
            height={height}
            width={width}
            size=""
            testId={testId}
        />
    );
}

export default AppLogoBrandMssql;
