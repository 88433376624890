import React, { ReactElement, useEffect, useState } from 'react';
import useCpuStatsCompare from '../../../core/hooks/useCpuStatsCompare';
import useDiskStatsCompare from '../../../core/hooks/useDiskStatsCompare';
import useMemoryStatsCompare from '../../../core/hooks/useMemoryStatsCompare';
import useNetStatsCompare from '../../../core/hooks/useNetStatsCompare';
import AppEmpty from '../../AppEmpty';
import styles from './HostCompareDashboard.module.less';
import { Col, Row } from 'antd';
import CpuUsageChart from '../../ccx/charts/CpuUsageChart';
import DiskIopsChart from '../../ccx/charts/DiskIopsChart';
import DiskIOUtilizationChart from '../../ccx/charts/DiskIOUtilizationChart';
import DiskSpaceUsageChart from '../../ccx/charts/DiskSpaceUsageChart';
import DiskThroughputChart from '../../ccx/charts/DiskThroughputChart';
import LoadAvgChart from '../../ccx/charts/LoadAvgChart';
import NetworkUsageChart from '../../ccx/charts/NetworkUsageChart';
import RamUsageChart from '../../ccx/charts/RamUsageChart';
import RamUtilizationChart from '../../ccx/charts/RamUtilizationChart';

interface Props {
    uuid: string;
    from?: string;
    to?: string;
    interval: number;
    hidden?: boolean;
    displaySummary?: boolean;
    selectedNodes: any[];
}

function HostCompareDashboard({
    uuid,
    from,
    to,
    interval,
    hidden,
    displaySummary,
    selectedNodes,
}: Props): ReactElement {
    const {
        stats: memoryStats,
        refresh: memoryStatsRefresh,
        loading: memoryStatsLoading,
    } = useMemoryStatsCompare(uuid, selectedNodes, from, to);
    const {
        stats: cpuStats,
        refresh: cpuStatsRefresh,
        loading: cpuStatsLoading,
    } = useCpuStatsCompare(uuid, selectedNodes, from, to);
    const {
        stats: netStats,
        refresh: netStatsRefresh,
        loading: netStatsLoading,
    } = useNetStatsCompare(uuid, selectedNodes, from, to);
    const {
        stats: diskStats,
        refresh: diskStatsRefresh,
        loading: diskStatsLoading,
    } = useDiskStatsCompare(uuid, selectedNodes, from, to);
    const [memoryInterval, setMemoryInterval] = useState<number | undefined>(
        undefined
    );
    const [cpuInterval, setCpuInterval] = useState<number | undefined>(
        undefined
    );
    const [netInterval, setNetInterval] = useState<number | undefined>(
        undefined
    );
    const [diskInterval, setDiskInterval] = useState<number | undefined>(
        undefined
    );
    const [refreshInterval, setRefreshInterval] = useState(interval);

    useEffect(() => {
        let interval: any;
        if (memoryStats && memoryStats.length > 0 && refreshInterval > 0) {
            interval = setInterval(() => {
                memoryStatsRefresh();
            }, refreshInterval);
            setMemoryInterval(interval);
        }
        return () => clearInterval(interval);
    }, [memoryStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (cpuStats && cpuStats.length > 0 && refreshInterval > 0) {
            interval = setInterval(() => {
                cpuStatsRefresh();
            }, refreshInterval);
            setCpuInterval(interval);
        }
        return () => clearInterval(interval);
    }, [cpuStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (netStats && netStats.length > 0 && refreshInterval > 0) {
            interval = setInterval(() => {
                netStatsRefresh();
            }, refreshInterval);
            setNetInterval(interval);
        }
        return () => clearInterval(interval);
    }, [netStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (diskStats && diskStats.length > 0 && refreshInterval > 0) {
            interval = setInterval(() => {
                diskStatsRefresh();
            }, refreshInterval);
            setDiskInterval(interval);
        }
        return () => clearInterval(interval);
    }, [diskStats, refreshInterval]);

    useEffect(() => {
        if (interval === 0) {
            // when interval is 0, we must pause the refresh (clear existing intervals)
            clearInterval(memoryInterval);
            clearInterval(cpuInterval);
            clearInterval(netInterval);
            clearInterval(diskInterval);
            setMemoryInterval(0);
            setCpuInterval(0);
            setNetInterval(0);
            setDiskInterval(0);
        }
        setRefreshInterval(interval);
    }, [interval]);

    useEffect(() => {
        if (hidden) {
            // clear interval to stop updating data if the tab is not active (charts are not visible)
            clearInterval(memoryInterval);
            clearInterval(cpuInterval);
            clearInterval(netInterval);
            clearInterval(diskInterval);
            setMemoryInterval(0);
            setCpuInterval(0);
            setNetInterval(0);
            setDiskInterval(0);
        } else if (
            memoryInterval === 0 ||
            cpuInterval === 0 ||
            netInterval === 0 ||
            diskInterval === 0
        ) {
            // force refresh if the interval is 0, otherwise, the interval will refresh data soon
            // interval is undefined on the first load
            // interval will be 0 when paused or tab is hidden
            memoryStatsRefresh();
            cpuStatsRefresh();
            netStatsRefresh();
            diskStatsRefresh();
        }
    }, [hidden]);

    return (
        <section className={styles.HostCompareDashboard}>
            {selectedNodes.length > 0 ? (
                <Row
                    gutter={[
                        { xs: 8, sm: 16, md: 24, lg: 32, xl: 40, xxl: 48 },
                        { xs: 8, sm: 16, md: 24, lg: 32, xl: 40, xxl: 48 },
                    ]}
                >
                    {selectedNodes.map((n: any, idx: number) => (
                        <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} key={n}>
                            <h1>{n}</h1>

                            {/* CPU */}
                            <Row
                                gutter={[
                                    {
                                        xs: 8,
                                        sm: 16,
                                        md: 24,
                                        lg: 32,
                                        xl: 40,
                                        xxl: 48,
                                    },
                                    {
                                        xs: 8,
                                        sm: 16,
                                        md: 24,
                                        lg: 32,
                                        xl: 40,
                                        xxl: 48,
                                    },
                                ]}
                                className={styles.HostCompareDashboardRow}
                            >
                                <Col
                                    className={styles.HostCompareDashboardCol}
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                >
                                    <LoadAvgChart
                                        data={cpuStats ? cpuStats[idx] : []}
                                        loading={cpuStatsLoading}
                                        displaySummary={displaySummary}
                                    />
                                </Col>
                                <Col
                                    className={styles.HostCompareDashboardCol}
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                >
                                    <CpuUsageChart
                                        data={cpuStats ? cpuStats[idx] : []}
                                        loading={cpuStatsLoading}
                                        displaySummary={displaySummary}
                                    />
                                </Col>

                                {/* RAM */}
                                <Col
                                    className={styles.HostCompareDashboardCol}
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                >
                                    <RamUsageChart
                                        data={
                                            memoryStats ? memoryStats[idx] : []
                                        }
                                        loading={memoryStatsLoading}
                                        displaySummary={displaySummary}
                                    />
                                </Col>
                                <Col
                                    className={styles.HostCompareDashboardCol}
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                >
                                    <RamUtilizationChart
                                        data={
                                            memoryStats ? memoryStats[idx] : []
                                        }
                                        loading={memoryStatsLoading}
                                        displaySummary={displaySummary}
                                    />
                                </Col>

                                {/* NETWORK */}
                                <Col
                                    className={styles.HostCompareDashboardCol}
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                >
                                    <NetworkUsageChart
                                        data={netStats ? netStats[idx] : []}
                                        loading={netStatsLoading}
                                        displaySummary={displaySummary}
                                    />
                                </Col>

                                {/* DISK */}
                                <Col
                                    className={styles.HostCompareDashboardCol}
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                >
                                    <DiskSpaceUsageChart
                                        data={diskStats ? diskStats[idx] : []}
                                        loading={diskStatsLoading}
                                        displaySummary={displaySummary}
                                    />
                                </Col>
                                <Col
                                    className={styles.HostCompareDashboardCol}
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                >
                                    <DiskIOUtilizationChart
                                        data={diskStats ? diskStats[idx] : []}
                                        loading={diskStatsLoading}
                                        displaySummary={displaySummary}
                                    />
                                </Col>

                                <Col
                                    className={styles.HostCompareDashboardCol}
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                >
                                    <DiskIopsChart
                                        data={diskStats ? diskStats[idx] : []}
                                        loading={diskStatsLoading}
                                        displaySummary={displaySummary}
                                    />
                                </Col>
                                <Col
                                    className={styles.HostCompareDashboardCol}
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                >
                                    <DiskThroughputChart
                                        data={diskStats ? diskStats[idx] : []}
                                        loading={diskStatsLoading}
                                        displaySummary={displaySummary}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    ))}
                </Row>
            ) : (
                <AppEmpty message="No Hosts selected yet." />
            )}
        </section>
    );
}

export default HostCompareDashboard;
