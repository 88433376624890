import React, { ReactElement } from 'react';
import CcxComponentProps from '../../../../core/CcxComponent';
import DeploymentsItem from '../../../../types/DeploymentsItem';
import Service from '../../../../types/Service';
import AppCodeCard from '../../../AppCodeCard';
import SecretText from '../../../SecretText';
import styles from './ServiceConnectionInformation.module.less';
import useDatastoreServiceDsn from './useDatastoreServiceDsn';

interface Props extends CcxComponentProps {
    dataStore: DeploymentsItem;
    service: Service;
    type?: string;
}

function ServiceConnectionInformation({
    dataStore,
    service,
    type = 'db',
    testId = 'ServiceConnectionInformation',
}: Props): ReactElement {
    const { dsn, username, password, database, hostPort, dbType } =
        useDatastoreServiceDsn({ dataStore, service, type });

    const dsnWithInsecure = dsn + ' --insecure';

    const getHostnameOrIP = (host: string) => {
        if (host?.includes(':')) {
            const ip = host?.split(':');
            return `@${ip[0]}`;
        } else {
            const splittedFqdn = host?.split('.');
            return splittedFqdn && `@${splittedFqdn[0]}...`;
        }
    };

    return (
        <div
            className={styles.ServiceConnectionInformation}
            data-testid={testId}
        >
            <AppCodeCard
                className={styles.ServiceConnectionInformationCode}
                usageExample={[dataStore?.isRedis() ? dsnWithInsecure : dsn]}
            >
                {dataStore?.isMSSQL() ? (
                    <div
                        className={
                            styles.ServiceConnectionInformationCodeContent
                        }
                    >
                        <div>
                            Data Source={hostPort},{service.getPort()};
                        </div>
                        <div>User ID={username};</div>
                        <div
                            className={
                                styles.ServiceConnectionInformationCodeSubContentText
                            }
                        >
                            <span>Password=</span>
                            <SecretText
                                visibleText={`_CLICK_TO_REVEAL_PASSWORD_`}
                                hiddenText={`${password}`}
                                tooltipText="Click to show / hide password"
                            />
                            <span>;</span>
                        </div>
                    </div>
                ) : (
                    <div
                        className={
                            styles.ServiceConnectionInformationCodeContent
                        }
                    >
                        <div>{dbType}</div>
                        <div>://</div>
                        <SecretText
                            visibleText={`_CLICK_TO_REVEAL_PASSWORD_`}
                            hiddenText={`${username}:${password}`}
                            tooltipText="Click to show / hide username and password"
                        />
                        <div>{getHostnameOrIP(hostPort)}</div>
                        <div>
                            {dataStore?.isRedis() ? null : (
                                <div>/{database}</div>
                            )}
                        </div>
                    </div>
                )}
            </AppCodeCard>
        </div>
    );
}

export default ServiceConnectionInformation;
