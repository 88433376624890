import React, { ReactElement } from 'react';
import AppTable from './AppTable';
import styles from './AppChartSummary.module.less';

interface Props {
    data?: any[];
}

function AppChartSummary({ data }: Props): ReactElement {
    const columns = [
        {
            title: 'Series',
            dataIndex: 'label',
            key: 'label',
        },
        {
            title: 'Avg',
            dataIndex: 'avg',
            key: 'avg',
            align: 'right',
        },
        {
            title: 'Min',
            dataIndex: 'min',
            key: 'min',
            align: 'right',
        },
        {
            title: 'Max',
            dataIndex: 'max',
            key: 'max',
            align: 'right',
        },
    ];

    if (data && data.length > 0) {
        return (
            <AppTable
                columns={columns}
                data={data}
                rowKey="series"
                size="small"
                bordered={true}
                pagination={{
                    hideOnSinglePage: true,
                }}
                className={styles.AppChartSummary}
                expandable={false}
            />
        );
    }

    return <></>;
}

export default AppChartSummary;
