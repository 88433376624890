import styles from './AppContentTemplate.module.less';
import React from 'react';
import { Content } from 'antd/lib/layout/layout';

type AppContentTemplateProps = {
    children?: React.ReactNode;
    alert?: React.ReactNode;
};
export default function AppContentTemplate({
    children,
    alert,
}: AppContentTemplateProps) {
    return (
        <Content className={styles.AppContent} data-testid="AppContent">
            <div
                className={styles.AppContentContainer}
                data-testid="AppContentContainer"
            >
                {alert}
                {children}
            </div>
        </Content>
    );
}
