import React from 'react';
import styles from './AppRangePicker.module.less';
import { DatePicker, Radio } from 'antd';
import { Space } from 'antd';
import AppLabeledComponent from './AppLabeledComponent';
import { Alert } from 'antd';
import dayjs from 'dayjs';
import CcxComponentProps from '../../../core/CcxComponent';

interface Props extends CcxComponentProps {
    value?: string;
    quickRanges?: any;
    onChange?: Function;
    disabled?: boolean;
}

const AppRangePicker: React.FC<Props> = ({
    onChange,
    value,
    disabled,
    testId = 'AppRangePicker',
}) => {
    const RANGE_PICKER_QUICK_RANGES = [
        { label: '30 min', value: '30m' },
        { label: '1 hour', value: '1h' },
        { label: '1 day', value: '1d' },
        { label: '1 week', value: '1w' },
    ];

    const quickRanges = RANGE_PICKER_QUICK_RANGES;
    const showCustom = false;
    const { RangePicker } = DatePicker;

    const handleOnChangePreset = (e: any) => {
        onChange && onChange(e.target.value);
    };

    const handleOnChangeCustom = (value: any) => {
        onChange && onChange(value[0].unix(), value[1].unix());
    };

    const disabledDate = (current: any) => {
        // CCX-152: Can not select days before 2 weeks
        const tooLate = current > dayjs();
        const tooEarly = current < dayjs().subtract(2, 'week');

        return tooEarly || tooLate;
    };

    const extraFooter = () => (
        <Alert
            message="Maximum data retention range is 2 weeks."
            type="info"
            showIcon
            className={styles.AppRangePickerExtraFooter}
        />
    );

    return (
        <Space className={styles.AppRangePicker} data-testid={testId}>
            <AppLabeledComponent label="Preset ranges:" softText={false}>
                <Radio.Group
                    value={value}
                    optionType="button"
                    onChange={handleOnChangePreset}
                    disabled={disabled}
                    className={styles.AppRangePickerRadio}
                >
                    {quickRanges.map(({ label, value }, index) => (
                        <Radio.Button key={value} value={value}>
                            <span>{label}</span>
                        </Radio.Button>
                    ))}
                </Radio.Group>
            </AppLabeledComponent>
            {showCustom ? (
                <AppLabeledComponent label="Custom range:">
                    <RangePicker
                        onChange={handleOnChangeCustom}
                        className={
                            value === 'custom'
                                ? styles.AppRangePickerCustomSelected
                                : styles.AppRangePickerCustomUnSelected
                        }
                        disabledDate={disabledDate}
                        renderExtraFooter={extraFooter}
                        disabled={disabled}
                    />
                </AppLabeledComponent>
            ) : null}
        </Space>
    );
};

export default AppRangePicker;
