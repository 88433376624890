export type PrometheusPostgreSqlSelectFetchedStatsBackendApiProps = {
    created: number;
    instance: string;
    pg_stat_database_tup_fetched: number;
};

export default class PrometheusPostgreSqlSelectFetchedStats {
    readonly created: number;
    readonly instance: string;
    readonly pgStatDatabaseTupFetched: number;

    constructor(props: PrometheusPostgreSqlSelectFetchedStatsBackendApiProps) {
        this.created = props.created;
        this.instance = props.instance;
        this.pgStatDatabaseTupFetched = props.pg_stat_database_tup_fetched;
    }
}
