import Service, { ServiceClassProps } from './Service';

export type LbServiceClassProps = {};

export interface LbServiceInterface {}

export default class LbService extends Service implements LbServiceInterface {
    // constructor(props: ServiceClassProps) {
    //   super(props);
    // }
}
