import React, { ReactElement } from 'react';
import AppForm from '../../components/ccx/common/AppForm';

interface Props {
    fields?: any[];
    errorFields?: any[];
}

export default function ConnectionAssistantFormStep1({
    fields,
    errorFields,
}: Props): ReactElement {
    return <AppForm fields={fields} errorFields={errorFields} useGrid={true} />;
}
