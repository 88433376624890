import { Tooltip } from 'antd';
import React, { ReactElement } from 'react';
import CcxComponentProps from '../../../../core/CcxComponent';
import DotLabelStatus from '../../../ccx/common/DotLabelStatus';
import SmallContainer from '../../../ccx/common/SmallContainer';
import Service from '../../../../types/Service';

interface Props extends CcxComponentProps {
    service?: Service;
    updating?: boolean;
}

function ServiceStatus({
    service,
    testId = 'ServiceStatus',
    updating = false,
}: Props): ReactElement {
    let StatusComponent = null;

    if (service?.isStatusOk()) {
        StatusComponent = (
            <DotLabelStatus
                type="ok"
                glow={updating}
                label={service?.getStatusText()}
                testId={`${testId}StatusComponent`}
            />
        );
    } else if (service?.isStatusError()) {
        StatusComponent = (
            <DotLabelStatus
                type="error"
                glow={updating}
                label={service?.getStatusText()}
                testId={`${testId}StatusComponent`}
            />
        );
    } else if (service?.isStatusWarning()) {
        StatusComponent = (
            <DotLabelStatus
                type="warning"
                glow={updating}
                label={service?.getStatusText()}
                testId={`${testId}StatusComponent`}
            />
        );
    } else {
        StatusComponent = (
            <DotLabelStatus
                type="normal"
                glow={updating}
                label={service?.getStatusText()}
                testId={`${testId}StatusComponent`}
            />
        );
    }

    const tooltipTitle =
        service?.isStatusError() || service?.isStatusWarning()
            ? null
            : `${service?.getStatusText()}`;

    const top = (
        <Tooltip placement="bottom" title={tooltipTitle}>
            {StatusComponent}{' '}
        </Tooltip>
    );

    return <SmallContainer testId={testId} topChildren={top} />;
}

export default ServiceStatus;
