import React, { ReactElement } from 'react';
import styles from './LbCompareDashboard.module.less';
import Deployment from '../../../types/Deployment';
import ProxySqlLbCompareDashboard from './ProxySqlLbCompareDashboard';
import HaProxyLbCompareDashboard from './HaProxyLbCompareDashboard';

type LbCompareDashboardProps = {
    uuid: string;
    from?: string;
    to?: string;
    interval: number;
    deployment?: Deployment;
    hidden?: boolean;
    displaySummary?: boolean;
    selectedNodes: any[];
};

function LbCompareDashboard({
    uuid,
    from,
    to,
    interval,
    deployment,
    hidden,
    displaySummary,
    selectedNodes,
}: LbCompareDashboardProps): ReactElement {
    return (
        <section className={styles.LbCompareDashboard}>
            {deployment?.isClusterPostgreSql() ? (
                <HaProxyLbCompareDashboard
                    uuid={uuid}
                    from={from}
                    to={to}
                    interval={interval}
                    hidden={hidden}
                    displaySummary={displaySummary}
                    selectedNodes={selectedNodes}
                />
            ) : (
                <ProxySqlLbCompareDashboard
                    uuid={uuid}
                    from={from}
                    to={to}
                    interval={interval}
                    hidden={hidden}
                    displaySummary={displaySummary}
                    selectedNodes={selectedNodes}
                />
            )}
        </section>
    );
}

export default LbCompareDashboard;
