import { Alert, Form } from 'antd';
import CronFormat from '@severalnines/bar-frontend-components/build/lib/Format/CronFormat';
import React from 'react';
import { FormInstance } from 'antd/lib/form';

type BackupScheduleInfoProps = {
    form: FormInstance;
    message: string;
};
export default function BackupScheduleInfo({
    form,
    message,
}: BackupScheduleInfoProps) {
    return (
        <Form.Item noStyle={true} shouldUpdate={true}>
            {() => (
                <Alert
                    type={'info'}
                    message={
                        <span>
                            {message}{' '}
                            <b>
                                <CronFormat>
                                    {form.getFieldValue('cron')}
                                </CronFormat>
                            </b>
                        </span>
                    }
                />
            )}
        </Form.Item>
    );
}
