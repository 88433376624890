export type PrometheusProxySqlQueriesBackendStatsBackendApiProps = {
    created: number;
    queries_backends_bytes_sent: number;
    queries_backends_bytes_recv: number;
};

export default class PrometheusProxySqlQueriesBackendStats {
    readonly created: number;
    readonly queriesBackendsBytesSent: number;
    readonly queriesBackendsBytesRecv: number;

    constructor(props: PrometheusProxySqlQueriesBackendStatsBackendApiProps) {
        this.created = props.created;
        this.queriesBackendsBytesSent = props.queries_backends_bytes_sent;
        this.queriesBackendsBytesRecv = props.queries_backends_bytes_recv;
    }
}
