import React, { ReactElement, useState } from 'react';
import styles from './ExpandableText.module.less';
import { Tooltip } from 'antd';
import Hoverable from './Hoverable';

export type ExpandableTextProps = {
    collapsedText?: string;
    expandedText?: string;
    showDateOnHover?: boolean;
};

function ExpandableText({
    collapsedText,
    expandedText,
    showDateOnHover = false,
}: ExpandableTextProps): ReactElement {
    const [expanded, setExpanded] = useState(false);

    const handleClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Hoverable>
            <Tooltip
                placement="bottom"
                title={
                    showDateOnHover
                        ? expandedText
                        : 'Click to switch display mode.'
                }
                className={styles.ExpandableText}
                {...{
                    onClick: handleClick,
                }}
            >
                {!showDateOnHover && expanded ? (
                    <span>{expandedText}</span>
                ) : (
                    <span>{collapsedText}</span>
                )}
            </Tooltip>
        </Hoverable>
    );
}

export default ExpandableText;
