import React, { ReactElement, useEffect, useState } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import FriendlyTimestamp from '../../../types/FriendlyTimestamp';
import PrometheusCpuUsageStats from '../../../types/PrometheusCpuUsageStats';
import AppChart from '../../AppChart';
import ChartCustomTooltip from './ChartCustomTooltip';

interface Props extends CcxComponentProps {
    data?: PrometheusCpuUsageStats[];
    loading: boolean;
    displaySummary?: boolean;
    height?: number;
    width?: number;
}

function PrometheusCpuUsageChart({
    data,
    loading,
    displaySummary,
    testId = 'PrometheusCpuUsageChart',
    width,
    height = 250,
}: Props): ReactElement {
    const [chartData, setChartData] = useState<any>([]);
    const [summary, setSummary] = useState<any>([]);

    const setChartSummary = (data: any, series: any) => {
        const max: number[] = [];
        const min: number[] = [];
        const sum: number[] = [];
        const avg: number[] = [];

        series.forEach((s: any) => {
            max[s.value] = 0;
            min[s.value] = 0;
            sum[s.value] = 0;
            avg[s.value] = 0;
        });

        data.forEach((d: any) => {
            series.forEach((s: any) => {
                sum[s.value] += d[s.value];

                min[s.value] =
                    d[s.value] < min[s.value] ? d[s.value] : min[s.value];
                max[s.value] =
                    d[s.value] > max[s.value] ? d[s.value] : max[s.value];
            });
        });

        series.forEach((s: any) => {
            avg[s.value] = sum[s.value] / data.length;
        });

        const dataSummary = series.map((s: any) => {
            return {
                serie: s.value,
                label: s.label,
                avg: `${(avg[s.value] * 100).toFixed(2)}%`,
                min: `${(min[s.value] * 100).toFixed(2)}%`,
                max: `${(max[s.value] * 100).toFixed(2)}%`,
                sum: `${(sum[s.value] * 100).toFixed(2)}%`,
            };
        });

        setSummary(dataSummary);
    };

    useEffect(() => {
        if (data && data.length > 0) {
            const formattedData = data.map((stat: PrometheusCpuUsageStats) => {
                return [
                    {
                        yValue: stat.idle,
                        series: 'Idle',
                        xValue: new FriendlyTimestamp(stat.created).getDate(),
                    },
                    {
                        yValue: stat.user,
                        series: 'User',
                        xValue: new FriendlyTimestamp(stat.created).getDate(),
                    },
                    {
                        yValue: stat.system,
                        series: 'System',
                        xValue: new FriendlyTimestamp(stat.created).getDate(),
                    },

                    {
                        yValue: stat.ioWait,
                        series: 'IO Wait',
                        xValue: new FriendlyTimestamp(stat.created).getDate(),
                    },
                    {
                        yValue: stat.softIrq,
                        series: 'Soft IRQ',
                        xValue: new FriendlyTimestamp(stat.created).getDate(),
                    },
                    {
                        yValue: stat.irq,
                        series: 'IRQ',
                        xValue: new FriendlyTimestamp(stat.created).getDate(),
                    },

                    {
                        yValue: stat.steal,
                        series: 'Steal',
                        xValue: new FriendlyTimestamp(stat.created).getDate(),
                    },

                    {
                        yValue: stat.nice,
                        series: 'Nice',
                        xValue: new FriendlyTimestamp(stat.created).getDate(),
                    },
                ];
            });
            setChartData(formattedData.flat());

            const summarySeries = [
                {
                    value: 'ioWait',
                    label: 'IO Wait',
                },
                {
                    value: 'irq',
                    label: 'IRQ',
                },
                {
                    value: 'softIrq',
                    label: 'Soft IRQ',
                },
                {
                    value: 'steal',
                    label: 'Steal',
                },
                {
                    value: 'system',
                    label: 'System',
                },
                {
                    value: 'user',
                    label: 'User',
                },
                {
                    value: 'idle',
                    label: 'Idle',
                },
                {
                    value: 'nice',
                    label: 'Nice',
                },
            ];
            setChartSummary(data, summarySeries);
        }
    }, [data]);

    const chartConfig = {
        title: {
            visible: true,
            text: 'CPU usage',
        },
        description: {
            visible: true,
            text: '',
        },
        height,
        width,
        autoFit: !width && !height,
        xField: 'xValue',
        yField: 'yValue',
        yAxis: {
            label: {
                formatter: (text: string, item: any, index: number) => {
                    return `${text}%`;
                },
            },
        },
        xAxis: {
            type: 'time',
            mask: 'HH:mm',
        },
        animation: false,
        seriesField: 'series',
        tooltip: {
            customContent: (title: any, data: any) => {
                const getTitle = () => {
                    if (!data || data.length === 0) return '';
                    return data[0]?.data?.xValue.toString();
                };

                const getContent = () => {
                    if (!data || data.length === 0) return [];
                    return data.map((d: any) => ({
                        color: d?.color,
                        label: d?.data?.series,
                        value: `${d?.data?.yValue.toFixed(0)}%`,
                    }));
                };

                const tooltipData = {
                    title: getTitle(),
                    content: getContent(),
                };

                return (
                    <ChartCustomTooltip
                        title={tooltipData.title}
                        content={tooltipData.content}
                    />
                );
            },
        },
    };

    return (
        <AppChart
            type="area"
            data={chartData}
            config={chartConfig}
            summary={summary}
            loading={loading}
            displaySummary={displaySummary}
            testId={testId}
        />
    );
}

export default PrometheusCpuUsageChart;
