export type BackupClassProps = {
    backup_id: number;
    backup_method: string;
    backup_type: string;
    chain_up: number;
    children: number;
    ended_at: string;
    has_children: boolean;
    parent_id: number;
    size: number;
    started_at: string;
    status: string;
};

export interface BackupInterface {
    backupId: number;
    parentId: number;
    // children: number;
    hasChildren: boolean;
    backupMethod: string;
    backupType: string;
    status: string;
    startedAt: string;
    endedAt: string;
    size: number;
    duration: string;
    isStatusError: Function;
    isStatusOk: Function;
    isStatusWarning: Function;
}

export const BACKUP_STATUS_ERROR = 'Failed';
export const BACKUP_STATUS_OK = 'Completed';
export const BACKUP_STATUS_WARNING = 'Warning';

export default class Backup implements BackupInterface {
    readonly backupId: number;
    readonly parentId: number;
    // readonly children: number;
    readonly hasChildren: boolean;
    readonly backupMethod: string;
    readonly backupType: string;
    readonly status: string;
    readonly startedAt: string;
    readonly endedAt: string;
    readonly size: number;
    readonly duration: string;

    constructor(props: BackupClassProps) {
        this.backupId = props.backup_id;
        this.parentId = props.parent_id;
        // this.children = props.children;
        this.hasChildren = props.has_children;
        this.backupMethod = props.backup_method;
        this.backupType = props.backup_type;
        this.status = props.status;
        this.startedAt = props.started_at;
        this.endedAt = props.ended_at;
        this.size = props.size;
        this.duration = '';
    }

    isStatusOk(): boolean {
        return this.status === BACKUP_STATUS_OK;
    }

    isStatusWarning(): boolean {
        return this.status === BACKUP_STATUS_WARNING;
    }

    isStatusError(): boolean {
        return this.status === BACKUP_STATUS_ERROR;
    }
}
