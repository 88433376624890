import React, { useState } from 'react';
import { VpcsContext } from '../context/VpcsContext';
import useVpcsCount from '../hooks/useVpcsCount';

export interface VpcsProps {
    children: React.ReactNode;
}

export const VpcsProvider = ({ children }: VpcsProps) => {
    const { vpcsCount, loading, refresh } = useVpcsCount();
    const [cloud, setCloud] = useState<string | undefined>(undefined);
    const [region, setRegion] = useState<string | undefined>(undefined);

    return (
        <VpcsContext.Provider
            value={{
                vpcsCount,
                refresh,
                loading,
                cloud,
                setCloud,
                region,
                setRegion,
            }}
        >
            {children}
        </VpcsContext.Provider>
    );
};
