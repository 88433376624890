export type VpcAwsAvailabilityZoneClassProps = {
    id: string;
    name: string;
    region: string;
};

export interface VpcAwsAvailabilityZoneInterface {
    availabilityZoneId: string;
    name: string;
    region: string;
}

export default class VpcAwsAvailabilityZone
    implements VpcAwsAvailabilityZoneInterface
{
    readonly availabilityZoneId: string;
    readonly name: string;
    readonly region: string;

    constructor(props: VpcAwsAvailabilityZoneClassProps) {
        this.availabilityZoneId = props.id;
        this.name = props.name;
        this.region = props.region;
    }
}
