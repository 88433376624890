import CcxContextualMenuIcon from '../ccx/icons/CcxContextualMenuIcon';
import { Button, Dropdown, Menu } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import DeploymentsItem from '../../types/DeploymentsItem';
import ButtonWithOverlayDialog from '../common/ButtonWithOverlayDialog';
import BackupsScheduleEditDrawer from './BackupsScheduleEditDrawer';
import BackupSchedule from '../../types/BackupSchedule';
import BackupsScheduleSwitchButton from './BackupsScheduleSwitchButton';

type BackupsScheduleActionsMenuProps = {
    disabled?: boolean;
    deployment: DeploymentsItem;
    schedule: BackupSchedule;
    onActionPerformed?: () => void;
};
export default function BackupsScheduleActionsMenu({
    disabled,
    deployment,
    schedule,
    onActionPerformed,
}: BackupsScheduleActionsMenuProps) {
    const menu = (
        <Menu>
            <Menu.Item key="pause">
                <BackupsScheduleSwitchButton
                    stretchedClick={true}
                    deployment={deployment}
                    schedule={schedule}
                    onSuccess={onActionPerformed}
                />
            </Menu.Item>
            <Menu.Item key="edit">
                <ButtonWithOverlayDialog
                    stretchedClick={true}
                    data-testid="edit-schedule-button"
                    button={
                        <Button
                            role="button"
                            icon={<EditOutlined />}
                            type={'text'}
                        >
                            Edit
                        </Button>
                    }
                    overlay={
                        <BackupsScheduleEditDrawer
                            deployment={deployment}
                            schedule={schedule}
                            onSuccess={onActionPerformed}
                        />
                    }
                />
            </Menu.Item>
        </Menu>
    );
    // @todo create common component for action menu and maybe reuse ActionMenu from bar-frontend-components
    return (
        <Dropdown
            overlay={menu}
            disabled={disabled}
            placement="bottomRight"
            trigger={['click']}
        >
            <Button icon={<CcxContextualMenuIcon />} />
        </Dropdown>
    );
}
