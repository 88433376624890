import DeploymentsItem from '../../types/DeploymentsItem';
import styles from './BackupsListCreateDatastoreButton.module.less';
import { useState } from 'react';
import { Button, Col, Row, Typography } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import ProvisionService, {
    AddDeploymentV2Props,
} from '../../services/ProvisionService';
import { useHistory } from 'react-router';
import CreateDatastorePopover from './CreateDatastorePopover';

type BackupsListCreateDatastoreButtonProps = {
    deployment: DeploymentsItem;
    record: any;
    actionIcon?: any;
};

export default function BackupsListCreateDatastoreButton({
    deployment,
    record,
    actionIcon,
}: BackupsListCreateDatastoreButtonProps) {
    const [openPopover, setOpenPopover] = useState<boolean>(false);
    const history = useHistory();

    const onCreateDatastore = async () => {
        try {
            const backupToCreateDatastore: AddDeploymentV2Props =
                deployment.getDatastoreCreationData(record.backupId);
            await ProvisionService.addDeploymentV2(backupToCreateDatastore);

            history.push({
                pathname: '/',
                state: { deploying: true },
            });
        } catch (e) {
            console.log('Oops errors!');
        }

        setOpenPopover(!openPopover);
    };

    const handleCancel = () => {
        setOpenPopover(!openPopover);
    };

    return (
        <CreateDatastorePopover
            isVisible={openPopover}
            setOpenPopover={setOpenPopover}
            actionIcon={actionIcon}
            content={
                <div className={styles.PopoverContainer}>
                    <Row justify="center" className={styles.PopoverContentRow}>
                        <Col sm={3}>
                            <ExclamationCircleFilled
                                className={styles.ExclamationCircleFilledColor}
                                color="#ffac0a"
                            />
                        </Col>
                        <Col sm={21}>
                            <strong>
                                Do you want to create a datastore from the
                                backup?
                            </strong>
                            <Typography.Paragraph>
                                The datastore is created on the same cloud and
                                uses the same instance configuration.
                            </Typography.Paragraph>
                        </Col>
                    </Row>
                    <Row className={styles.PopoverActions} justify="center">
                        <Col sm={6}>
                            <Button
                                className={styles.BorderRadius}
                                size="small"
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                        </Col>
                        <Col sm={6}>
                            <Button
                                type="primary"
                                className={styles.BorderRadius}
                                size="small"
                                onClick={onCreateDatastore}
                            >
                                Create
                            </Button>
                        </Col>
                    </Row>
                </div>
            }
        />
    );
}
