export type PrometheusCpuUsageStatsBackendApiProps = {
    created: number;
    idle: number;
    user: number;
    system: number;
    iowait: number;
    softirq: number;
    irq: number;
    steal: number;
    nice: number;
};

export default class PrometheusCpuUsageStats {
    readonly created: number;
    readonly idle: number;
    readonly user: number;
    readonly system: number;
    readonly ioWait: number;
    readonly softIrq: number;
    readonly irq: number;
    readonly steal: number;
    readonly nice: number;

    constructor(props: PrometheusCpuUsageStatsBackendApiProps) {
        this.created = props.created;
        this.idle = props.idle;
        this.user = props.user;
        this.system = props.system;
        this.ioWait = props.iowait;
        this.softIrq = props.softirq;
        this.irq = props.irq;
        this.steal = props.steal;
        this.nice = props.nice;
    }
}
