import React, { ReactElement } from 'react';
import styles from './DatabaseInformation.module.less';
import CcxComponentProps from '../../../core/CcxComponent';
import DeploymentsItem from '../../../types/DeploymentsItem';
import CcxIcon1NodeDeployment from '../../ccx/icons/CcxIcon1NodeDeployment';
import AppLogoBrandMardiaDb from '../../ccx/common/AppLogoBrandMardiaDb';
import AppLogoBrandPostgreSql from '../../ccx/common/AppLogoBrandPostgreSql';
import AppLogoBrandPercona from '../../ccx/common/AppLogoBrandPercona';
import AppLogoBrandRedis from '../../ccx/common/AppLogoBrandRedis';
import SmallContainer from '../../ccx/common/SmallContainer';
import AppLogoBrandMssql from '../../ccx/common/AppLogoBrandMssql';

interface Props extends CcxComponentProps {
    dataStore: DeploymentsItem;
}

function DatabaseInformation({
    dataStore,
    testId = 'DatabaseInformation',
}: Props): ReactElement {
    const getLogo = (ds: DeploymentsItem) => {
        if (ds.isMariaDb()) {
            return (
                <AppLogoBrandMardiaDb
                    useExtended={false}
                    width={18}
                    height={18}
                />
            );
        }
        if (ds.isPostgreSql()) {
            return (
                <AppLogoBrandPostgreSql
                    useExtended={false}
                    width={16}
                    height={16}
                />
            );
        }
        if (ds.isPercona()) {
            return (
                <AppLogoBrandPercona
                    useExtended={false}
                    width={18}
                    height={18}
                />
            );
        }
        if (ds.isRedis()) {
            return (
                <AppLogoBrandRedis useExtended={false} width={16} height={16} />
            );
        }
        if (ds.isMSSQL()) {
            return (
                <AppLogoBrandMssql useExtended={false} width={16} height={16} />
            );
        }
        return null;
    };

    const top = (
        <>
            {getLogo(dataStore)}
            <div>
                {dataStore?.getDbVendorName()}
                {` `}
                {dataStore?.db.dbVersion}
            </div>
        </>
    );
    const bottom = (
        <>
            {dataStore.getNumberOfNodes() && (
                <CcxIcon1NodeDeployment height={16} width={16} />
            )}
            <div>
                {dataStore.getNumberOfNodes()}{' '}
                {dataStore.getNumberOfNodes() === 1 ? 'node' : 'nodes'}
            </div>
        </>
    );

    return (
        <SmallContainer
            testId={testId}
            bottomChildren={bottom}
            topChildren={top}
        />
    );
}

export default DatabaseInformation;
