import { useEffect, useState } from 'react';
import Deployment from '../../types/Deployment';
import FirewallService from '../../services/FirewallService';

export default function useFirewalls(uuid: string | undefined) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [firewalls, setFirewalls] = useState<Deployment | undefined | any>(
        undefined
    );

    const load = async () => {
        if (uuid) {
            setLoading(true);

            try {
                const details = await FirewallService.getAllRules(uuid);
                setFirewalls(details);
                setLoading(false);
                setError(null);
            } catch (e: any) {
                setLoading(false);
                setError(e);
            }

            setLoading(false);
        }
    };

    const refresh = () => {
        load();
    };

    // get deployments for the given user
    useEffect(() => {
        refresh();
    }, [uuid]);

    return { firewalls, refresh, loading, error };
}
