import TopQuery from './TopQuery';

export type TopQueriesClassProps = {
    error: string;
    timestamp: number;
    request_status: string;
    data: any[];
};

export interface TopQueriesInterface {
    error: string;
    timestamp: number;
    requestStatus: string;
    topQueries: TopQuery[];
    totalExecutionTime: number;
}

export default class TopQueries implements TopQueriesInterface {
    readonly error: string;
    readonly timestamp: number;
    readonly requestStatus: string;
    readonly topQueries: TopQuery[];
    readonly totalExecutionTime: number;

    constructor(props: TopQueriesClassProps) {
        this.topQueries = props.data?.map((a) => {
            return new TopQuery(a);
        });
        this.timestamp = props.timestamp;
        this.error = props.error;
        this.requestStatus = props.request_status;
        this.totalExecutionTime = this.getTotalExecutionTime();
    }

    getTotalExecutionTime(): number {
        return this.topQueries.reduce((prev, curr) => {
            return prev + curr.sumTime;
        }, 0);
    }
}
