export type PrometheusRedisTotalKeysStatsBackendApiProps = {
    created: number;
    db_keys: number;
};

export default class PrometheusRedisTotalKeysStats {
    readonly created: number;
    readonly dbKeys: number;

    constructor(props: PrometheusRedisTotalKeysStatsBackendApiProps) {
        this.created = props.created;
        this.dbKeys = props.db_keys;
    }
}
