import React, { ReactElement, useState } from 'react';
import ButtonModalForm from '../ButtonModalForm';
import FirewallService, { AddRuleProps } from '../../services/FirewallService';
import CcxIconPlusCircleOutlined from '../ccx/icons/CcxIconPlusCircleOutlined';
import { notification } from 'antd';
import CcxIconCheckCircleTwoTone from '../ccx/icons/CcxIconCheckCircleTwoTone';
import CcxIconCloseCircleTwoTone from '../ccx/icons/CcxIconCloseCircleTwoTone';
import DeploymentsItem from '../../types/DeploymentsItem';

type AddFirewallRuleProps = {
    uuid: string;
    onSuccess: Function;
    currentDeployment?: DeploymentsItem | null | undefined;
    ipAddress: string;
};

function AddFirewallRule({
    uuid,
    onSuccess,
    currentDeployment,
    ipAddress,
}: AddFirewallRuleProps): ReactElement {
    const [form, setForm] = useState<any>(undefined);

    const fields = [
        {
            name: ['source'],
            testId: 'AddFirewallRuleSource',
            required: true,
            label: 'Source',
            helpText: `Your IP: ${ipAddress}`,
            placeholder: 'Enter CIDR',
            type: 'cidr',
            onBlur: ({ target }: any) => {
                const { value } = target;
                const [ip, range] = value.split('/');

                if (range) {
                    form.setFieldsValue({
                        source: value,
                    });
                } else {
                    if (ip.length > 0) {
                        form.setFieldsValue({
                            source: `${value}/32`,
                        });
                    }
                }

                return;
            },
            tips: <>Source address CIDR, i.e.: 1.2.3.4/32</>,
        },
        {
            name: ['description'],
            testId: 'AddFirewallRuleDescription',
            required: false,
            label: 'Description',
            tips: (
                <>
                    You can add a description for the rule, which can help you
                    identify it later. A description can be up to 255 characters
                    in length. Allowed characters are a-z, A-Z, 0-9, spaces, and
                    ._-:/()#,@[]+=;{}!$*
                </>
            ),
            maxLength: 255,
            validators: [
                {
                    message: `Allowed characters are a-z, A-Z, 0-9, spaces, and ._-:/()#,@[]+=;{}!$*`,
                    pattern:
                        /^[a-zA-Z0-9\s._\-\:\/\(\)#\,@\[\]\+\=\;\{\}!$\*]*$/,
                },
            ],
        },
    ];

    const doApiRequest = async ({ source, description }: AddRuleProps) => {
        try {
            await FirewallService.addRule({
                uuid,
                source: source.trim(),
                description: description ? description.trim() : undefined,
            });

            notification.open({
                message: 'Add trusted source',
                description: `New trusted source successfully added!`,
                icon: <CcxIconCheckCircleTwoTone twoToneColor="#52c41a" />,
            });

            return true;
        } catch (e) {
            notification.open({
                message: 'Add trusted source',
                description: `There was an error adding your trusted source. ${e}`,
                icon: <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />,
            });

            console.error(e);

            throw e;
        }
    };

    return (
        <ButtonModalForm
            title="Create trusted client source address"
            buttonText="Create trusted source"
            buttonIcon={<CcxIconPlusCircleOutlined />}
            onSubmit={doApiRequest}
            onSuccess={onSuccess}
            fields={fields}
            submitText="Create"
            formLayout="vertical"
            useGrid={true}
            onFormLoad={setForm}
            disabled={!currentDeployment?.operable}
        >
            <span>
                <p>
                    A trusted client source address is an IP allowed to connect
                    to the datastore.
                </p>
            </span>
        </ButtonModalForm>
    );
}

export default AddFirewallRule;
