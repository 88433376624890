import { Alert, Switch } from 'antd';
import { Form, Row, Col } from 'antd';
import { ReactElement } from 'react';
import CcxComponentProps from '../../../../core/CcxComponent';
import styles from './DataStoreSettingsAutoScale.module.less';
import './DatastoreSettingsDbParameters.less';
import {
    AUTOSCALE_STORAGE_PERCENTAGE,
    AUTOSCALE_STORAGE_THRESHOLD,
} from '../../../../core/CcxEnv';

interface Props extends CcxComponentProps {
    form: any;
    onChange: Function;
    autoscaleStorage: boolean | undefined;
}

function DataStoreSettingsAutoScale({
    form,
    onChange,
    autoscaleStorage,
    testId = 'DataStoreSettingsAutoScale',
}: Props): ReactElement {
    const handleOnChange = (value: boolean) => {
        if (autoscaleStorage !== value) {
            onChange(value);
        }
    };

    return (
        <div data-testid={testId}>
            <h2 data-testid={`${testId}Title`}>
                <span className={styles.DataStoreSettingsAutoScaleLabel}>
                    Auto scaling storage size
                </span>
            </h2>

            <Form form={form} layout="vertical">
                <Row gutter={24}>
                    <Col span={3}>Auto scale</Col>
                    <Col>
                        <Form.Item noStyle>
                            <Form.Item name="autoscaleStorage">
                                <Switch
                                    data-testid={`${testId}Switch`}
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    onChange={handleOnChange}
                                    checked={autoscaleStorage}
                                />
                            </Form.Item>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            {autoscaleStorage && (
                <Alert
                    message={`The storage will automatically scale by ${AUTOSCALE_STORAGE_PERCENTAGE}% when the used space exceeds ${AUTOSCALE_STORAGE_THRESHOLD}%`}
                    showIcon
                    type="info"
                />
            )}
        </div>
    );
}

export default DataStoreSettingsAutoScale;
