import React, { ReactElement } from 'react';
import AppContent from './AppContent';
import AppLayoutTemplate from './AppLayoutTemplate';

function AppLayout(): ReactElement {
    return (
        <AppLayoutTemplate>
            <AppContent />
        </AppLayoutTemplate>
    );
}

export default AppLayout;
