import React, { ReactElement } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import Vpc from '../../../types/Vpc';
import styles from './VpcPeerings.module.less';

interface Props extends CcxComponentProps {
    vpc: Vpc;
    peerings?: any[];
}

function VpcPeerings({
    vpc,
    testId = 'VpcPeerings',
    peerings = [],
}: Props): ReactElement {
    return (
        <div className={styles.VpcPeerings} data-testid={testId}>
            <div data-testid={`${testId}Peering`}>
                {peerings.length} connection{peerings.length !== 1 ? 's' : ''}
            </div>
        </div>
    );
}

export default VpcPeerings;
