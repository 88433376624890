import { Popover, Space } from 'antd';
import { Dispatch, ReactNode, SetStateAction } from 'react';

interface CreateDatastorePopoverProps {
    isVisible: boolean;
    setOpenPopover: Dispatch<SetStateAction<boolean>>;
    actionIcon: string;
    content: ReactNode;
}

function CreateDatastorePopover({
    isVisible,
    setOpenPopover,
    actionIcon,
    content,
}: CreateDatastorePopoverProps) {
    return (
        <Popover open={isVisible} placement="left" content={content}>
            <Space
                size={5}
                onClick={(e) => {
                    e.stopPropagation();
                    setOpenPopover(!isVisible);
                }}
            >
                {actionIcon}
            </Space>
        </Popover>
    );
}

export default CreateDatastorePopover;
