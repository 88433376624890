import styles from './DatastoreEmailNotification.module.less';
import { Form } from 'antd';
import AppEmailsNotification from '../../../ccx/common/Form/AppEmailsNotification';

interface DatastoreEmailNotificationProps {
    readonly form: any;
    readonly testId?: string;
    readonly emailsList: string[];
    readonly saveEmailNotifications: Function;
}

function DatastoreEmailNotification({
    form,
    emailsList,
    saveEmailNotifications,
    testId = 'DatastoreEmailNotification',
}: DatastoreEmailNotificationProps) {
    return (
        <div
            className={styles.DataStoreSettingsEmailNotification}
            data-testid={testId}
        >
            <h2 data-testid={`${testId}Title`}>
                <span
                    className={styles.DataStoreSettingsEmailNotificationLabel}
                >
                    Notifications
                </span>
            </h2>
            <Form form={form} layout="vertical">
                <AppEmailsNotification
                    emailsList={emailsList}
                    saveEmailNotifications={saveEmailNotifications}
                    label="Configure recipients of notification emails"
                />
            </Form>
        </div>
    );
}

export default DatastoreEmailNotification;
