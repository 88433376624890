import { Col, Row } from 'antd';
import styles from './DatastoreScalingCard.module.less';

interface DatastoreScalingCardProps {
    stepScalingTitle: string;
    stepScalingCount: string;
}

export default function DatastoreScalingCard({
    stepScalingTitle,
    stepScalingCount,
}: DatastoreScalingCardProps) {
    return (
        <Row className={styles.DatastoreScalingCardVolumeRow}>
            <Col className={styles.DatastoreScalingCardVolumeCol}>
                <div className={styles.DatastoreScalingCardVolumeInfo}>
                    <span>{stepScalingTitle}</span>
                    <span className={styles.DatastoreScalingCardVolumeCount}>
                        {stepScalingCount}
                    </span>
                </div>
            </Col>
        </Row>
    );
}
