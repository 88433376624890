import React, { ReactElement } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import styles from './CurrentBillingPeriod.module.less';

interface Props extends CcxComponentProps {
    currentBillingPeriod: any;
}

function CurrentBillingPeriod({
    testId = 'CurrentBillingPeriod',
    currentBillingPeriod,
}: Props): ReactElement {
    return (
        <div className={styles.CurrentBillingPeriod} data-testid={testId}>
            <div data-testid={`${testId}Top`}>Current billing period</div>
            <div data-testid={`${testId}Middle`}>
                <strong>
                    {currentBillingPeriod.from && currentBillingPeriod.to
                        ? `${currentBillingPeriod.from} - ${currentBillingPeriod.to}`
                        : 'N/A'}
                </strong>
            </div>
        </div>
    );
}

export default CurrentBillingPeriod;
