import { useEffect, useState } from 'react';
import BillingService from '../../services/BillingService';
import Card from '../../types/Card';
import SubscriptionBillingUser from '../../types/SubscriptionBillingUser';

export default function useCards() {
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [cards, setCards] = useState<Card[] | undefined>(undefined);
    const [billingUser, setBillingUser] = useState<
        SubscriptionBillingUser | undefined
    >(undefined);

    const load = async () => {
        try {
            const { cards, billingUser } = await BillingService.getCards();
            setCards(cards);
            setBillingUser(billingUser);
            setError(null);
        } catch (e: any) {
            setError(e);
        }
    };

    const refresh = () => {
        setUpdating(true);
        load();
        setUpdating(false);
    };

    // get deployments for the given user
    useEffect(() => {
        const init = async () => {
            setLoading(true);
            await load();
            setLoading(false);
        };

        init();
    }, []);

    return { cards, billingUser, refresh, loading, updating, error };
}
