import Job from './Job';

export type JobsClassProps = {
    total: number;
    jobs: any[];
};

export interface JobsInterface {
    total: number;
    jobs: Job[];
}

export default class Jobs implements JobsInterface {
    readonly jobs: Job[];
    readonly total: number;

    constructor(props: JobsClassProps) {
        this.jobs = props.jobs?.map((j) => {
            return new Job(j);
        });
        this.total = props.total;
    }
}
