/* tslint:disable */
/* eslint-disable */
/**
 * CCX VPC Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2.0.0
 * Contact: support@severalnines.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * Error response
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * Error code
     * @type {number}
     * @memberof ErrorResponse
     */
    code?: number;
    /**
     * Error message
     * @type {string}
     * @memberof ErrorResponse
     */
    err: string;
}
/**
 * A subnet in a VPC
 * @export
 * @interface Subnet
 */
export interface Subnet {
    /**
     * Cloud availability zone
     * @type {string}
     * @memberof Subnet
     */
    availability_zone?: string;
    /**
     * Subnet state
     * @type {string}
     * @memberof Subnet
     */
    state?: string;
}
/**
 * VPC object
 * @export
 * @interface Vpc
 */
export interface Vpc {
    /**
     * ID of the VPC
     * @type {string}
     * @memberof Vpc
     */
    id: string;
    /**
     * User chosen name
     * @type {string}
     * @memberof Vpc
     */
    name: string;
    /**
     * Full descriptor of the cloud space containing the VPC
     * @type {string}
     * @memberof Vpc
     */
    cloudspace?: string;
    /**
     * Cloud provider code
     * @type {string}
     * @memberof Vpc
     */
    cloud: string;
    /**
     * Cloud provider region code
     * @type {string}
     * @memberof Vpc
     */
    region: string;
    /**
     * Cloud project ID, if exists
     * @type {string}
     * @memberof Vpc
     */
    project?: string;
    /**
     * IPv4 CIDR
     * @type {string}
     * @memberof Vpc
     */
    cidr_ipv4_block: string;
    /**
     * VPC state
     * @type {string}
     * @memberof Vpc
     */
    state?: string;
    /**
     * More verbose status message
     * @type {string}
     * @memberof Vpc
     */
    state_message?: string;
    /**
     * Number of data stores using this VPC
     * @type {number}
     * @memberof Vpc
     */
    datastores?: number;
    /**
     * Subnets of a VPC
     * @type {{ [key: string]: Subnet; }}
     * @memberof Vpc
     */
    subnets?: { [key: string]: Subnet };
}
/**
 * VPC Peering connection
 * @export
 * @interface VpcPeering
 */
export interface VpcPeering {
    /**
     * Peering ID
     * @type {string}
     * @memberof VpcPeering
     */
    id?: string;
    /**
     * Cloud region code of the target VPC to peer to
     * @type {string}
     * @memberof VpcPeering
     */
    target_cloud_region?: string;
    /**
     * Target VPC\'s owner account ID
     * @type {string}
     * @memberof VpcPeering
     */
    target_cloud_user_id?: string;
    /**
     * Target VPC\'s cloud ID
     * @type {string}
     * @memberof VpcPeering
     */
    target_cloud_vpc_id?: string;
    /**
     * Target VPC\'s IPv4 CIRD
     * @type {string}
     * @memberof VpcPeering
     */
    target_cidr?: string;
    /**
     * Status of the peering connection
     * @type {string}
     * @memberof VpcPeering
     */
    state?: string;
    /**
     * More verbose status message
     * @type {string}
     * @memberof VpcPeering
     */
    state_message?: string;
}
/**
 *
 * @export
 * @interface VpcsGet200Response
 */
export interface VpcsGet200Response {
    /**
     *
     * @type {number}
     * @memberof VpcsGet200Response
     */
    count?: number;
    /**
     *
     * @type {Array<Vpc>}
     * @memberof VpcsGet200Response
     */
    vpcs?: Array<Vpc>;
}
/**
 *
 * @export
 * @interface VpcsPost201Response
 */
export interface VpcsPost201Response {
    /**
     *
     * @type {Vpc}
     * @memberof VpcsPost201Response
     */
    vpc?: Vpc;
}
/**
 *
 * @export
 * @interface VpcsPostRequest
 */
export interface VpcsPostRequest {
    /**
     * VPC name
     * @type {string}
     * @memberof VpcsPostRequest
     */
    name?: string;
    /**
     * Cloud provider code - for basic usage, just set cloud/region
     * @type {string}
     * @memberof VpcsPostRequest
     */
    cloudspace?: string;
    /**
     * Cloud provider code
     * @type {string}
     * @memberof VpcsPostRequest
     */
    cloud?: string;
    /**
     * Cloud region code
     * @type {string}
     * @memberof VpcsPostRequest
     */
    region?: string;
    /**
     * IPv4 CIDR
     * @type {string}
     * @memberof VpcsPostRequest
     */
    cidr_ipv4_block: string;
}
/**
 *
 * @export
 * @interface VpcsVpcIdPeeringsGet200Response
 */
export interface VpcsVpcIdPeeringsGet200Response {
    /**
     *
     * @type {Array<VpcPeering>}
     * @memberof VpcsVpcIdPeeringsGet200Response
     */
    peerings?: Array<VpcPeering>;
}
/**
 *
 * @export
 * @interface VpcsVpcIdPeeringsPost201Response
 */
export interface VpcsVpcIdPeeringsPost201Response {
    /**
     *
     * @type {VpcPeering}
     * @memberof VpcsVpcIdPeeringsPost201Response
     */
    peering?: VpcPeering;
}
/**
 *
 * @export
 * @interface VpcsVpcIdPeeringsPostRequest
 */
export interface VpcsVpcIdPeeringsPostRequest {
    /**
     * IPv4 CIDR
     * @type {string}
     * @memberof VpcsVpcIdPeeringsPostRequest
     */
    target_cidr?: string;
    /**
     * Cloud region code
     * @type {string}
     * @memberof VpcsVpcIdPeeringsPostRequest
     */
    target_cloud_region?: string;
    /**
     * Cloud account ID
     * @type {string}
     * @memberof VpcsVpcIdPeeringsPostRequest
     */
    target_cloud_user_id?: string;
    /**
     * Cloud provider\'s VPC ID
     * @type {string}
     * @memberof VpcsVpcIdPeeringsPostRequest
     */
    target_cloud_vpc_id?: string;
}

/**
 * VPCApi - axios parameter creator
 * @export
 */
export const VPCApiAxiosParamCreator = function (
    configuration?: Configuration
) {
    return {
        /**
         * Can be optionally filtered by cloud provider and/or region
         * @summary Lists VPCs accessible to the user
         * @param {string} [cloud] The cloud provider code
         * @param {string} [region] Cloud region code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vpcsGet: async (
            cloud?: string,
            region?: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/vpcs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: 'GET',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (cloud !== undefined) {
                localVarQueryParameter['cloud'] = cloud;
            }

            if (region !== undefined) {
                localVarQueryParameter['region'] = region;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Creates a new VPC
         * @param {VpcsPostRequest} vpcsPostRequest Create VPC request - needs either cloudspace, or cloud+region
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vpcsPost: async (
            vpcsPostRequest: VpcsPostRequest,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'vpcsPostRequest' is not null or undefined
            assertParamExists('vpcsPost', 'vpcsPostRequest', vpcsPostRequest);
            const localVarPath = `/vpcs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                vpcsPostRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Removes a VPC
         * @param {string} vpcId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vpcsVpcIdDelete: async (
            vpcId: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'vpcId' is not null or undefined
            assertParamExists('vpcsVpcIdDelete', 'vpcId', vpcId);
            const localVarPath = `/vpcs/{vpc_id}`.replace(
                `{${'vpc_id'}}`,
                encodeURIComponent(String(vpcId))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: 'DELETE',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Returns VPC details
         * @param {string} vpcId
         * @param {boolean} [update] Whether to force update of status fields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vpcsVpcIdGet: async (
            vpcId: string,
            update?: boolean,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'vpcId' is not null or undefined
            assertParamExists('vpcsVpcIdGet', 'vpcId', vpcId);
            const localVarPath = `/vpcs/{vpc_id}`.replace(
                `{${'vpc_id'}}`,
                encodeURIComponent(String(vpcId))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: 'GET',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (update !== undefined) {
                localVarQueryParameter['update'] = update;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * VPCApi - functional programming interface
 * @export
 */
export const VPCApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = VPCApiAxiosParamCreator(configuration);
    return {
        /**
         * Can be optionally filtered by cloud provider and/or region
         * @summary Lists VPCs accessible to the user
         * @param {string} [cloud] The cloud provider code
         * @param {string} [region] Cloud region code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vpcsGet(
            cloud?: string,
            region?: string,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<VpcsGet200Response>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vpcsGet(
                cloud,
                region,
                options
            );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration
            );
        },
        /**
         *
         * @summary Creates a new VPC
         * @param {VpcsPostRequest} vpcsPostRequest Create VPC request - needs either cloudspace, or cloud+region
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vpcsPost(
            vpcsPostRequest: VpcsPostRequest,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<VpcsPost201Response>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vpcsPost(
                vpcsPostRequest,
                options
            );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration
            );
        },
        /**
         *
         * @summary Removes a VPC
         * @param {string} vpcId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vpcsVpcIdDelete(
            vpcId: string,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.vpcsVpcIdDelete(vpcId, options);
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration
            );
        },
        /**
         *
         * @summary Returns VPC details
         * @param {string} vpcId
         * @param {boolean} [update] Whether to force update of status fields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vpcsVpcIdGet(
            vpcId: string,
            update?: boolean,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<VpcsPost201Response>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.vpcsVpcIdGet(
                    vpcId,
                    update,
                    options
                );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration
            );
        },
    };
};

/**
 * VPCApi - factory interface
 * @export
 */
export const VPCApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = VPCApiFp(configuration);
    return {
        /**
         * Can be optionally filtered by cloud provider and/or region
         * @summary Lists VPCs accessible to the user
         * @param {string} [cloud] The cloud provider code
         * @param {string} [region] Cloud region code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vpcsGet(
            cloud?: string,
            region?: string,
            options?: any
        ): AxiosPromise<VpcsGet200Response> {
            return localVarFp
                .vpcsGet(cloud, region, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Creates a new VPC
         * @param {VpcsPostRequest} vpcsPostRequest Create VPC request - needs either cloudspace, or cloud+region
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vpcsPost(
            vpcsPostRequest: VpcsPostRequest,
            options?: any
        ): AxiosPromise<VpcsPost201Response> {
            return localVarFp
                .vpcsPost(vpcsPostRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Removes a VPC
         * @param {string} vpcId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vpcsVpcIdDelete(vpcId: string, options?: any): AxiosPromise<void> {
            return localVarFp
                .vpcsVpcIdDelete(vpcId, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Returns VPC details
         * @param {string} vpcId
         * @param {boolean} [update] Whether to force update of status fields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vpcsVpcIdGet(
            vpcId: string,
            update?: boolean,
            options?: any
        ): AxiosPromise<VpcsPost201Response> {
            return localVarFp
                .vpcsVpcIdGet(vpcId, update, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * VPCApi - object-oriented interface
 * @export
 * @class VPCApi
 * @extends {BaseAPI}
 */
export class VPCApi extends BaseAPI {
    /**
     * Can be optionally filtered by cloud provider and/or region
     * @summary Lists VPCs accessible to the user
     * @param {string} [cloud] The cloud provider code
     * @param {string} [region] Cloud region code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VPCApi
     */
    public vpcsGet(
        cloud?: string,
        region?: string,
        options?: AxiosRequestConfig
    ) {
        return VPCApiFp(this.configuration)
            .vpcsGet(cloud, region, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Creates a new VPC
     * @param {VpcsPostRequest} vpcsPostRequest Create VPC request - needs either cloudspace, or cloud+region
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VPCApi
     */
    public vpcsPost(
        vpcsPostRequest: VpcsPostRequest,
        options?: AxiosRequestConfig
    ) {
        return VPCApiFp(this.configuration)
            .vpcsPost(vpcsPostRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Removes a VPC
     * @param {string} vpcId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VPCApi
     */
    public vpcsVpcIdDelete(vpcId: string, options?: AxiosRequestConfig) {
        return VPCApiFp(this.configuration)
            .vpcsVpcIdDelete(vpcId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Returns VPC details
     * @param {string} vpcId
     * @param {boolean} [update] Whether to force update of status fields
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VPCApi
     */
    public vpcsVpcIdGet(
        vpcId: string,
        update?: boolean,
        options?: AxiosRequestConfig
    ) {
        return VPCApiFp(this.configuration)
            .vpcsVpcIdGet(vpcId, update, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * VPCPeeringApi - axios parameter creator
 * @export
 */
export const VPCPeeringApiAxiosParamCreator = function (
    configuration?: Configuration
) {
    return {
        /**
         *
         * @summary Returns peering connections for a VPC
         * @param {string} vpcId
         * @param {boolean} [update] Whether to force update of status fields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vpcsVpcIdPeeringsGet: async (
            vpcId: string,
            update?: boolean,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'vpcId' is not null or undefined
            assertParamExists('vpcsVpcIdPeeringsGet', 'vpcId', vpcId);
            const localVarPath = `/vpcs/{vpc_id}/peerings`.replace(
                `{${'vpc_id'}}`,
                encodeURIComponent(String(vpcId))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: 'GET',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (update !== undefined) {
                localVarQueryParameter['update'] = update;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Removes a VPC peering
         * @param {string} vpcId
         * @param {string} peeringId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vpcsVpcIdPeeringsPeeringIdDelete: async (
            vpcId: string,
            peeringId: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'vpcId' is not null or undefined
            assertParamExists(
                'vpcsVpcIdPeeringsPeeringIdDelete',
                'vpcId',
                vpcId
            );
            // verify required parameter 'peeringId' is not null or undefined
            assertParamExists(
                'vpcsVpcIdPeeringsPeeringIdDelete',
                'peeringId',
                peeringId
            );
            const localVarPath = `/vpcs/{vpc_id}/peerings/{peering_id}`
                .replace(`{${'vpc_id'}}`, encodeURIComponent(String(vpcId)))
                .replace(
                    `{${'peering_id'}}`,
                    encodeURIComponent(String(peeringId))
                );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: 'DELETE',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Gets a VPC peering
         * @param {string} vpcId
         * @param {string} peeringId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vpcsVpcIdPeeringsPeeringIdGet: async (
            vpcId: string,
            peeringId: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'vpcId' is not null or undefined
            assertParamExists('vpcsVpcIdPeeringsPeeringIdGet', 'vpcId', vpcId);
            // verify required parameter 'peeringId' is not null or undefined
            assertParamExists(
                'vpcsVpcIdPeeringsPeeringIdGet',
                'peeringId',
                peeringId
            );
            const localVarPath = `/vpcs/{vpc_id}/peerings/{peering_id}`
                .replace(`{${'vpc_id'}}`, encodeURIComponent(String(vpcId)))
                .replace(
                    `{${'peering_id'}}`,
                    encodeURIComponent(String(peeringId))
                );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: 'GET',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Creates a new peering connection for a VPC
         * @param {string} vpcId
         * @param {VpcsVpcIdPeeringsPostRequest} vpcsVpcIdPeeringsPostRequest Create VPC peering request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vpcsVpcIdPeeringsPost: async (
            vpcId: string,
            vpcsVpcIdPeeringsPostRequest: VpcsVpcIdPeeringsPostRequest,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'vpcId' is not null or undefined
            assertParamExists('vpcsVpcIdPeeringsPost', 'vpcId', vpcId);
            // verify required parameter 'vpcsVpcIdPeeringsPostRequest' is not null or undefined
            assertParamExists(
                'vpcsVpcIdPeeringsPost',
                'vpcsVpcIdPeeringsPostRequest',
                vpcsVpcIdPeeringsPostRequest
            );
            const localVarPath = `/vpcs/{vpc_id}/peerings`.replace(
                `{${'vpc_id'}}`,
                encodeURIComponent(String(vpcId))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                vpcsVpcIdPeeringsPostRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * VPCPeeringApi - functional programming interface
 * @export
 */
export const VPCPeeringApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator =
        VPCPeeringApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Returns peering connections for a VPC
         * @param {string} vpcId
         * @param {boolean} [update] Whether to force update of status fields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vpcsVpcIdPeeringsGet(
            vpcId: string,
            update?: boolean,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<VpcsVpcIdPeeringsGet200Response>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.vpcsVpcIdPeeringsGet(
                    vpcId,
                    update,
                    options
                );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration
            );
        },
        /**
         *
         * @summary Removes a VPC peering
         * @param {string} vpcId
         * @param {string} peeringId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vpcsVpcIdPeeringsPeeringIdDelete(
            vpcId: string,
            peeringId: string,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.vpcsVpcIdPeeringsPeeringIdDelete(
                    vpcId,
                    peeringId,
                    options
                );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration
            );
        },
        /**
         *
         * @summary Gets a VPC peering
         * @param {string} vpcId
         * @param {string} peeringId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vpcsVpcIdPeeringsPeeringIdGet(
            vpcId: string,
            peeringId: string,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<VpcsVpcIdPeeringsPost201Response>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.vpcsVpcIdPeeringsPeeringIdGet(
                    vpcId,
                    peeringId,
                    options
                );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration
            );
        },
        /**
         *
         * @summary Creates a new peering connection for a VPC
         * @param {string} vpcId
         * @param {VpcsVpcIdPeeringsPostRequest} vpcsVpcIdPeeringsPostRequest Create VPC peering request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vpcsVpcIdPeeringsPost(
            vpcId: string,
            vpcsVpcIdPeeringsPostRequest: VpcsVpcIdPeeringsPostRequest,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<VpcsVpcIdPeeringsPost201Response>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.vpcsVpcIdPeeringsPost(
                    vpcId,
                    vpcsVpcIdPeeringsPostRequest,
                    options
                );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration
            );
        },
    };
};

/**
 * VPCPeeringApi - factory interface
 * @export
 */
export const VPCPeeringApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = VPCPeeringApiFp(configuration);
    return {
        /**
         *
         * @summary Returns peering connections for a VPC
         * @param {string} vpcId
         * @param {boolean} [update] Whether to force update of status fields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vpcsVpcIdPeeringsGet(
            vpcId: string,
            update?: boolean,
            options?: any
        ): AxiosPromise<VpcsVpcIdPeeringsGet200Response> {
            return localVarFp
                .vpcsVpcIdPeeringsGet(vpcId, update, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Removes a VPC peering
         * @param {string} vpcId
         * @param {string} peeringId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vpcsVpcIdPeeringsPeeringIdDelete(
            vpcId: string,
            peeringId: string,
            options?: any
        ): AxiosPromise<void> {
            return localVarFp
                .vpcsVpcIdPeeringsPeeringIdDelete(vpcId, peeringId, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Gets a VPC peering
         * @param {string} vpcId
         * @param {string} peeringId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vpcsVpcIdPeeringsPeeringIdGet(
            vpcId: string,
            peeringId: string,
            options?: any
        ): AxiosPromise<VpcsVpcIdPeeringsPost201Response> {
            return localVarFp
                .vpcsVpcIdPeeringsPeeringIdGet(vpcId, peeringId, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Creates a new peering connection for a VPC
         * @param {string} vpcId
         * @param {VpcsVpcIdPeeringsPostRequest} vpcsVpcIdPeeringsPostRequest Create VPC peering request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vpcsVpcIdPeeringsPost(
            vpcId: string,
            vpcsVpcIdPeeringsPostRequest: VpcsVpcIdPeeringsPostRequest,
            options?: any
        ): AxiosPromise<VpcsVpcIdPeeringsPost201Response> {
            return localVarFp
                .vpcsVpcIdPeeringsPost(
                    vpcId,
                    vpcsVpcIdPeeringsPostRequest,
                    options
                )
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * VPCPeeringApi - object-oriented interface
 * @export
 * @class VPCPeeringApi
 * @extends {BaseAPI}
 */
export class VPCPeeringApi extends BaseAPI {
    /**
     *
     * @summary Returns peering connections for a VPC
     * @param {string} vpcId
     * @param {boolean} [update] Whether to force update of status fields
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VPCPeeringApi
     */
    public vpcsVpcIdPeeringsGet(
        vpcId: string,
        update?: boolean,
        options?: AxiosRequestConfig
    ) {
        return VPCPeeringApiFp(this.configuration)
            .vpcsVpcIdPeeringsGet(vpcId, update, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Removes a VPC peering
     * @param {string} vpcId
     * @param {string} peeringId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VPCPeeringApi
     */
    public vpcsVpcIdPeeringsPeeringIdDelete(
        vpcId: string,
        peeringId: string,
        options?: AxiosRequestConfig
    ) {
        return VPCPeeringApiFp(this.configuration)
            .vpcsVpcIdPeeringsPeeringIdDelete(vpcId, peeringId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Gets a VPC peering
     * @param {string} vpcId
     * @param {string} peeringId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VPCPeeringApi
     */
    public vpcsVpcIdPeeringsPeeringIdGet(
        vpcId: string,
        peeringId: string,
        options?: AxiosRequestConfig
    ) {
        return VPCPeeringApiFp(this.configuration)
            .vpcsVpcIdPeeringsPeeringIdGet(vpcId, peeringId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Creates a new peering connection for a VPC
     * @param {string} vpcId
     * @param {VpcsVpcIdPeeringsPostRequest} vpcsVpcIdPeeringsPostRequest Create VPC peering request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VPCPeeringApi
     */
    public vpcsVpcIdPeeringsPost(
        vpcId: string,
        vpcsVpcIdPeeringsPostRequest: VpcsVpcIdPeeringsPostRequest,
        options?: AxiosRequestConfig
    ) {
        return VPCPeeringApiFp(this.configuration)
            .vpcsVpcIdPeeringsPost(vpcId, vpcsVpcIdPeeringsPostRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }
}
