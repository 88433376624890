import { Col, Row } from 'antd';
import styles from './DatastoreScalingInfoCard.module.less';
import SmallContainer from '../../ccx/common/SmallContainer';
import { useEffect, useState } from 'react';
import DeploymentsItem from '../../../types/DeploymentsItem';
import AppFlagIcon from '../../ccx/common/AppFlagIcon';
import AppLogoBrandMardiaDb from '../../ccx/common/AppLogoBrandMardiaDb';
import AppLogoBrandPostgreSql from '../../ccx/common/AppLogoBrandPostgreSql';
import AppLogoBrandPercona from '../../ccx/common/AppLogoBrandPercona';
import AppLogoBrandRedis from '../../ccx/common/AppLogoBrandRedis';
import AppLogoBrandMssql from '../../ccx/common/AppLogoBrandMssql';
import { getCloudProviderLogo } from './DataStoreCloudProviderLogo';
import { useAppSelector } from '../../../redux/hooks';

interface DatastoreScalingInfoCardProps {
    readonly dataStore: DeploymentsItem | undefined;
    readonly countryCode: string | undefined;
    readonly setCountryCode: Function;
}

export default function DatastoreScalingInfoCard({
    dataStore,
    setCountryCode,
    countryCode,
}: DatastoreScalingInfoCardProps) {
    const [cards, setCards] = useState<any>([]);
    const { deploymentOptions } = useAppSelector(
        (state) => state.deploymentOptions
    );

    const getLogo = (ds: DeploymentsItem) => {
        if (ds.isMariaDb()) {
            return (
                <AppLogoBrandMardiaDb
                    useExtended={false}
                    width={40}
                    height={40}
                />
            );
        }
        if (ds.isPostgreSql()) {
            return (
                <AppLogoBrandPostgreSql
                    useExtended={false}
                    width={40}
                    height={40}
                />
            );
        }
        if (ds.isPercona()) {
            return (
                <AppLogoBrandPercona
                    useExtended={false}
                    width={40}
                    height={40}
                />
            );
        }
        if (ds.isRedis()) {
            return (
                <AppLogoBrandRedis useExtended={false} width={40} height={40} />
            );
        }
        if (ds.isMSSQL()) {
            return (
                <AppLogoBrandMssql useExtended={false} width={40} height={40} />
            );
        }
        return null;
    };

    useEffect(() => {
        if (deploymentOptions && dataStore) {
            setCountryCode(
                deploymentOptions?.getCloudRegion(
                    dataStore?.getCloudProvider().code,
                    dataStore.cloudRegion.code
                )
            );
        }
    }, [dataStore, deploymentOptions]);

    useEffect(() => {
        if (dataStore) {
            const cardData = [
                {
                    content: (
                        <SmallContainer
                            topChildren={
                                <div
                                    className={
                                        styles.DatastoreScalingInfoCardLogo
                                    }
                                >
                                    {getLogo(dataStore)}
                                </div>
                            }
                        />
                    ),
                    span: { xs: 4, sm: 2 },
                },
                {
                    content: (
                        <SmallContainer
                            topChildren={
                                <div
                                    className={
                                        styles.DatastoreScalingInfoCardName
                                    }
                                >
                                    {dataStore?.getName()}
                                </div>
                            }
                            middleChildren={
                                <div>{dataStore?.getDbVendorName()}</div>
                            }
                        />
                    ),
                    span: { xs: 6, sm: 8 },
                },
                {
                    content: (
                        <SmallContainer
                            topChildren={
                                <>
                                    {getCloudProviderLogo(
                                        dataStore.getCloudProvider()
                                    )}
                                    <div>
                                        {dataStore.getFullCloudProviderName()}
                                    </div>
                                </>
                            }
                            middleChildren={
                                <>
                                    {countryCode && (
                                        <AppFlagIcon code={countryCode} />
                                    )}
                                    <div>
                                        {dataStore.cloudRegion.display_code}
                                    </div>
                                </>
                            }
                        />
                    ),
                    span: { xs: 14, sm: 12 },
                },
            ];

            setCards(cardData);
        }
    }, [dataStore, countryCode]);
    return (
        <div>
            <p>
                <strong>Datastore</strong>
            </p>
            <div className={styles.DatastoreScalingInfoCard}>
                <Row className={styles.DatastoreScalingInfoCardRow}>
                    {cards.map((c: any, i: number) => {
                        return (
                            <Col {...c.span} key={`cards${i}`}>
                                <SmallContainer
                                    testId={c.testId}
                                    topChildren={c.label}
                                    middleChildren={c.content}
                                />
                            </Col>
                        );
                    })}
                </Row>
            </div>
        </div>
    );
}
