import ApiService from './ApiService';
import CloudProvider, { CloudProviderClassProps } from '../types/CloudProvider';
import Country, { CountryClassProps } from '../types/Country';
import { API_CONTENT } from '../core/CcxEnv';
import DeploymentOptions from '../types/DeploymentOptions';
import InstanceSize from '../types/InstanceSize';

// constants
const API_URL = API_CONTENT;

type ContentProps = {
    countries: Country[];
    cloudProviders: CloudProvider[];
};

export type InstanceSizeItemProps = {
    size: string;
    label: string;
    cpu: number;
    ram: number;
};

export type InstanceSizeProps = {
    region: string;
    development: InstanceSize[];
    production: InstanceSize[];
};

export default class ContentService extends ApiService {
    /**
     * Get list of CIDRs.
     */
    static async getCidrs(): Promise<string[]> {
        return await this.request('GET', `cidrs`);
    }

    /**
     * Get list of cloud providers.
     */
    static async getProviders(): Promise<CloudProvider[]> {
        const response = await this.request('GET', `cloud-providers`);
        return response?.map(
            (r: CloudProviderClassProps) => new CloudProvider(r)
        );
    }

    /**
     * Get list of countries.
     */
    static async getCountries(): Promise<Country[]> {
        const response = await this.request('GET', `countries`);
        return response?.map((r: CountryClassProps) => new Country(r));
    }

    /**
     * Get client public ip.
     */
    static async getClientIp(): Promise<string> {
        const response = await this.request('GET', `ip`);
        return response?.ip;
    }

    /**
     * Return list of instance type / sizes.
     * @TODO: This is hardcoded now
     */
    static async getInstanceSize(
        cloudCode: string
    ): Promise<InstanceSizeProps[]> {
        return await this.request('GET', `instances/${cloudCode}`);
    }

    /**
     * Return logic data to use on the deployment wizard.
     */
    static async getDeploymentWizardOptions(): Promise<DeploymentOptions> {
        return await this.request('GET', `deploy-wizard`);
    }

    protected static getApiUrl() {
        return API_URL;
    }
}
