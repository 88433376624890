export type PrometheusPostgreSqlCheckpointsRequestedAndTimedStatsBackendApiProps =
    {
        created: number;
        pg_stat_bgwriter_checkpoints_req: number;
        pg_stat_bgwriter_checkpoints_timed: number;
    };

export default class PrometheusPostgreSqlCheckpointsRequestedAndTimedStats {
    readonly created: number;
    readonly pgStatBgwriterCheckpointsReq: number;
    readonly pgStatBgwriterCheckpointsTimed: number;

    constructor(
        props: PrometheusPostgreSqlCheckpointsRequestedAndTimedStatsBackendApiProps
    ) {
        this.created = props.created;
        this.pgStatBgwriterCheckpointsReq =
            props.pg_stat_bgwriter_checkpoints_req;
        this.pgStatBgwriterCheckpointsTimed =
            props.pg_stat_bgwriter_checkpoints_timed;
    }
}
