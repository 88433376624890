import QueryProcess from './QueryProcess';

export type QueryProcessesClassProps = {
    error: string;
    timestamp: number;
    request_status: string;
    data: any[];
};

export interface QueryProcessesInterface {
    error: string;
    timestamp: number;
    requestStatus: string;
    queryProcesses: QueryProcess[];
}

export default class QueryProcesses implements QueryProcessesInterface {
    readonly error: string;
    readonly timestamp: number;
    readonly requestStatus: string;
    readonly queryProcesses: QueryProcess[];

    constructor(props: QueryProcessesClassProps) {
        this.queryProcesses = props.data?.map((a) => {
            return new QueryProcess(a);
        });
        this.timestamp = props.timestamp;
        this.error = props.error;
        this.requestStatus = props.request_status;
    }
}
