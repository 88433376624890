export type PrometheusDiskThroughputStatsBackendApiProps = {
    created: number;
    read_bytes: number;
    written_bytes: number;
};

export default class PrometheusDiskThroughputStats {
    readonly created: number;
    readonly readBytes: number;
    readonly writtenBytes: number;

    constructor(props: PrometheusDiskThroughputStatsBackendApiProps) {
        this.created = props.created;
        this.readBytes = props.read_bytes;
        this.writtenBytes = props.written_bytes;
    }
}
