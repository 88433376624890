export type PrometheusHaProxyMaxSessionBackendServersStatsBackendApiProps = {
    created: number;
    haproxy_server_max_sessions: number;
};

export default class PrometheusHaProxyMaxSessionBackendServersStats {
    readonly created: number;
    readonly haproxyServerMaxSessions: number;

    constructor(
        props: PrometheusHaProxyMaxSessionBackendServersStatsBackendApiProps
    ) {
        this.created = props.created;
        this.haproxyServerMaxSessions = props.haproxy_server_max_sessions;
    }
}
