import ApiService from './ApiService';
import Deployment from '../types/Deployment';
import { API_DEPLOYMENT } from '../core/CcxEnv';

// constants
const API_URL = API_DEPLOYMENT;

export default class DataStoreService extends ApiService {
    /**
     * Get details from a Data Store
     * @param {string} dataStoreUuid
     */
    static async getDataStore(dataStoreUuid: string): Promise<Deployment> {
        const response = await this.request(
            'GET',
            `deployment/${dataStoreUuid}`
        );
        return new Deployment(response);
    }

    protected static getApiUrl() {
        return API_URL;
    }
}
