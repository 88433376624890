import React, { ReactElement } from 'react';
import { Layout } from 'antd';
import {
    BuildNumber,
    hideCloudProvider,
    PricingLink,
    ToSLink,
    PrivacyPolicyLink,
} from '../../core/CcxEnv';
import styles from './AppFooter.module.less';
import { Typography } from 'antd';

function AppFooter(): ReactElement {
    const { Footer } = Layout;

    const { Text } = Typography;

    const d = new Date();

    return (
        <Footer className={styles.Footer} data-testid="AppFooter">
            {!hideCloudProvider ? (
                <Text type="secondary">
                    <div className={styles.FooterTop}>
                        © Copyright Severalnines {d.getFullYear()}. All Rights
                        Reserved
                        {BuildNumber > 0 && <>{` | Version: ${BuildNumber}`}</>}
                    </div>
                    <div>
                        <a
                            href={ToSLink}
                            download
                            target="_blank"
                            rel="noreferrer"
                        >
                            Terms of service
                        </a>{' '}
                        |{' '}
                        <a
                            href={PrivacyPolicyLink}
                            download
                            target="_blank"
                            rel="noreferrer"
                        >
                            Privacy policy
                        </a>
                    </div>
                </Text>
            ) : undefined}
        </Footer>
    );
}

export default AppFooter;
