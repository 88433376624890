import { useEffect, useState } from 'react';
import Credentials from '../../types/Credentials';
import AuthorizationService from '../../services/AuthorizationService';

export default function useCredentials(uuid?: string) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [updating, setUpdating] = useState<boolean>(false);
    const [credentials, setCredentials] = useState<
        Credentials[] | undefined | any
    >(undefined);

    const load = async () => {
        try {
            const credentials = await AuthorizationService.getAllCredentials();
            setCredentials(credentials);
            setError(null);
        } catch (e: any) {
            setError(e);
        }
    };

    const refresh = () => {
        setUpdating(true);
        load();
        setUpdating(false);
    };

    // get credentials for the given user
    useEffect(() => {
        const init = async () => {
            setLoading(true);
            await load();
            setLoading(false);
        };

        init();
    }, [uuid]);

    return { credentials, refresh, loading, error, updating };
}
