import React, { ReactElement, useEffect, useState } from 'react';
import { Dropdown, Space, Typography, Badge, Button, Menu } from 'antd';
import CcxComponentProps from '../../core/CcxComponent';
import Job from '../../types/Job';
import CcxIconBellOutlined from '../ccx/icons/CcxIconBellOutlined';
import styles from './AppHeaderNotifications.module.less';
import AppNotificationItem from './AppNotificationItem';
import { Divider } from 'antd';
import { useAppSelector } from '../../redux/hooks';
import { useParams } from 'react-router';

interface Props extends CcxComponentProps {}

interface UrlProps {
    dataStoreUuid?: string;
}

function AppHeaderNotifications({
    testId = 'AppHeaderNotifications',
}: Props): ReactElement {
    const { Text } = Typography;
    const { datastores } = useAppSelector((state) => state.datastores);
    const params: any = useParams();
    const dataStoreUuid = params[0].split('/')[4];
    const { jobs } = useAppSelector((state) => state.jobs);
    const [activeJobs, setActiveJobs] = useState<Job[]>([]);

    useEffect(() => {
        if (dataStoreUuid && jobs[dataStoreUuid]?.length > 0) {
            setActiveJobs(jobs[dataStoreUuid]);
        } else if (datastores) {
            setActiveJobs(datastores.getActiveJobs());
        }
    }, [datastores, jobs]);

    const menu = (
        <Menu
            data-testid={`${testId}Menu`}
            className={styles.AppHeaderNotificationsMenu}
        >
            <div className={styles.AppHeaderNotificationsTitle}>
                <Text type="secondary">Latest notifications</Text>
            </div>

            {activeJobs?.map((job: Job) => {
                return (
                    <React.Fragment key={`${job.jobId}Frag`}>
                        <Divider
                            className={styles.AppHeaderNotificationsDivider}
                        />
                        <Menu.Item key={job.jobId}>
                            <AppNotificationItem notification={job} />
                        </Menu.Item>
                    </React.Fragment>
                );
            })}
        </Menu>
    );

    return (
        <Dropdown
            overlay={menu}
            trigger={['click']}
            placement="bottomCenter"
            className={styles.AppHeaderNotifications}
            data-testid={testId}
        >
            <Button type="link" data-testid="AppHeaderNotificationsButton">
                <Space size={8}>
                    {activeJobs?.length > 0 ? (
                        <Badge
                            dot={true}
                            className={styles.AppHeaderNotificationsBadge}
                        >
                            <CcxIconBellOutlined testId="AppHeaderNotificationsIcon" />
                        </Badge>
                    ) : (
                        <CcxIconBellOutlined testId="AppHeaderNotificationsIcon" />
                    )}
                </Space>
            </Button>
        </Dropdown>
    );
}

export default AppHeaderNotifications;
