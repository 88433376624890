export type PrometheusPostgreSqlActiveSessionsStatsBackendApiProps = {
    created: number;
    instance: string;
    active: number;
};

export default class PrometheusPostgreSqlActiveSessionsStats {
    readonly created: number;
    readonly instance: string;
    readonly active: number;

    constructor(props: PrometheusPostgreSqlActiveSessionsStatsBackendApiProps) {
        this.created = props.created;
        this.instance = props.instance;
        this.active = props.active;
    }
}
