import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

export default class FriendlyTimestamp {
    readonly date: Date;

    constructor(timestamp: number) {
        this.date = new Date(timestamp * 1000);
    }

    getFriendlyDate(): string {
        return dayjs(this.date).fromNow();
    }

    getFormattedFullDate(): string {
        return dayjs(this.date).format('YYYY-MM-DD HH:mm:ss ZZ');
    }

    getFormattedDate(): string {
        return dayjs(this.date).format('YYYY-MM-DD');
    }

    getFormattedTime(): string {
        return dayjs(this.date).format('HH:mm:ss');
    }

    getDate(): Date {
        return this.date;
    }
}
