import { InfoCircleTwoTone } from '@ant-design/icons';
import React from 'react';

type Props = {
    twoToneColor?: string;
};

const CcxIconInfoCircleTwoTone = ({ twoToneColor }: Props) => {
    return (
        <InfoCircleTwoTone
            data-testid="CcxIconInfoCircleTwoTone"
            twoToneColor={twoToneColor}
        />
    );
};

export default CcxIconInfoCircleTwoTone;
