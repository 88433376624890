import React, { ReactElement, ReactNode } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import styles from './TextFormat.module.less';

interface Props extends CcxComponentProps {
    children: ReactNode;
    breakWord?: any;
    bold?: any;
    muted?: any;
    info?: any;
    success?: any;
    warning?: any;
    error?: any;
    danger?: any;
    type: any;
}

function TextFormat({
    children,
    breakWord,
    bold,
    muted,
    info,
    success,
    warning,
    error,
    danger,
    type,
    testId = 'TextFormat',
}: Props): ReactElement {
    let css = styles.TextFormatInfo;
    if (type === 'danger' || type === 'error') css = styles.TextFormatDanger;
    if (type === 'warning') css = styles.TextFormatWarning;
    if (type === 'success') css = styles.TextFormatSuccess;
    if (type === 'info') css = styles.TextFormatInfo;
    css = breakWord ? `${css} ${styles.TextFormatBreakWord}` : css;
    css = bold ? `${css} ${styles.TextFormatBold}` : css;
    css = muted ? `${css} ${styles.TextFormatMuted}` : css;

    return (
        <div className={css} data-testid={testId}>
            {children}
        </div>
    );
}

export default TextFormat;
