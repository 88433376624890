export type BillingUserDataClassProps = {
    company_name: string;
    eu_vat_id: string;
    first_name: string;
    last_name: string;
};

export interface BillingUserDataInterface {
    companyName: string;
    euVatId: string;
    firstName: string;
    lastName: string;
}

export default class BillingUserData implements BillingUserDataInterface {
    readonly companyName: string;
    readonly euVatId: string;
    readonly firstName: string;
    readonly lastName: string;

    constructor(props: BillingUserDataClassProps) {
        this.companyName = props.company_name || 'N/A';
        this.euVatId = props.eu_vat_id || 'N/A';
        this.firstName = props.first_name;
        this.lastName = props.last_name;
    }
}
