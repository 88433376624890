export type SetupIntentClassProps = {
    status: string;
    setup_intent_id: string;
    payment_method_id: string;
};

export interface SetupIntentInterface {
    status: string;
    setupIntentID: string;
    paymentMethodID: string;
}

export default class SetupIntent implements SetupIntentInterface {
    readonly status: string;
    readonly setupIntentID: string;
    readonly paymentMethodID: string;

    constructor(props: SetupIntentClassProps) {
        this.status = props.status;
        this.setupIntentID = props.setup_intent_id;
        this.paymentMethodID = props.payment_method_id;
    }
}
