export type PrometheusPostgreSqlDiskUsageStatsBackendApiProps = {
    created: number;
    disk_usage: number;
};

export default class PrometheusPostgreSqlDiskUsageStats {
    readonly created: number;
    readonly diskUsage: number;

    constructor(props: PrometheusPostgreSqlDiskUsageStatsBackendApiProps) {
        this.created = props.created;
        this.diskUsage = props.disk_usage;
    }
}
