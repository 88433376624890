import React, { ReactElement } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import CcxComponentProps from '../../../core/CcxComponent';
import styles from './CurrentBalance.module.less';
import PrettyNumber from '../../ccx/common/PrettyNumber';

interface Props extends CcxComponentProps {
    currentBalance: number;
    suffix?: string;
}

function CurrentBalance({
    testId = 'CurrentBalance',
    currentBalance = 0,
    suffix,
}: Props): ReactElement {
    return (
        <div className={styles.CurrentBalance} data-testid={testId}>
            <div data-testid={`${testId}Top`}>
                Current balance&nbsp;
                <QuestionCircleOutlined />
            </div>
            <div data-testid={`${testId}Middle`}>
                <strong>
                    <PrettyNumber
                        precision={2}
                        sufix={suffix}
                        value={currentBalance}
                    />
                </strong>
            </div>
        </div>
    );
}

export default CurrentBalance;
