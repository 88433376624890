import React, { ReactElement, useEffect, useState } from 'react';
import { Col, Row, Table, Tooltip } from 'antd';
import CcxComponentProps from '../../../core/CcxComponent';
import styles from './AppGridTable.module.less';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

interface Props extends CcxComponentProps {
    columns: any[];
    rows: any;
    isDatastore?: boolean;
}

function AppGridTable({
    columns,
    rows,
    testId = 'AppGridTable',
    isDatastore = false,
}: Props): ReactElement {
    return (
        <div
            className={
                isDatastore
                    ? styles.AppGridTableContainer
                    : styles.AppGridTableSmallContainer
            }
        >
            <div className={styles.AppGridTable} data-testid={testId}>
                <Row
                    className={styles.AppGridTableHeader}
                    data-testid={`${testId}Header`}
                    justify="start"
                >
                    {columns?.map((c: any, i: number) => (
                        <Col
                            className={styles.AppGridTableHeaderCol}
                            data-testid={`${testId}HeaderCol${i}`}
                            key={i}
                            span={c.span}
                        >
                            {c.title}
                        </Col>
                    ))}
                </Row>
                {rows?.map((r: any, i: number) => {
                    let cssClassname = styles.AppGridTableDataRow;
                    cssClassname = r?.borderAlert
                        ? `${cssClassname} ${styles.AppGridTableDataRowAlert}`
                        : `${cssClassname} ${styles.AppGridTableDataRowNormal}`;

                    const row = (
                        <Row
                            className={cssClassname}
                            data-testid={`${testId}DataRow${i}`}
                            justify="start"
                            key={i}
                        >
                            {r?.columns?.map((c: any, i: number) => {
                                let cssClassName;
                                switch (c.align) {
                                    case 'center':
                                        cssClassName =
                                            styles.AppGridTableDataColCenter;
                                        break;
                                    case 'end':
                                        cssClassName =
                                            styles.AppGridTableDataColEnd;
                                        break;
                                    case 'start':
                                    default:
                                        cssClassName =
                                            styles.AppGridTableDataColStart;
                                        break;
                                }
                                cssClassName = c.disabled
                                    ? `${cssClassName} ${styles.AppGridTableDataColDisabled}`
                                    : `${cssClassName} ${styles.AppGridTableDataCol}`;

                                return (
                                    <Col
                                        className={cssClassName}
                                        data-testid={`${testId}DataCol${i}`}
                                        key={i}
                                        span={c.span}
                                    >
                                        {c.content}
                                    </Col>
                                );
                            })}
                        </Row>
                    );

                    return r?.disabled ? (
                        <Spin
                            tip={r?.statusOverlayText}
                            indicator={
                                <LoadingOutlined
                                    className={styles.AppGridTableDeletingIcon}
                                    spin
                                />
                            }
                        >
                            {row}
                        </Spin>
                    ) : (
                        row
                    );
                })}
            </div>
        </div>
    );
}

export default AppGridTable;
