export type PrometheusProxySqlQueryCacheGetSetStatsBackendApiProps = {
    created: number;
    query_cache_count_get_ok: number;
    query_cache_count_get: number;
    query_cache_count_set: number;
};

export default class PrometheusProxySqlQueryCacheGetSetStats {
    readonly created: number;
    readonly queryCacheCountGetOk: number;
    readonly queryCacheCountGet: number;
    readonly queryCacheCountSet: number;

    constructor(props: PrometheusProxySqlQueryCacheGetSetStatsBackendApiProps) {
        this.created = props.created;
        this.queryCacheCountGetOk = props.query_cache_count_get_ok;
        this.queryCacheCountGet = props.query_cache_count_get;
        this.queryCacheCountSet = props.query_cache_count_set;
    }
}
