export type InstanceVolumeTypeClassProps = {
    code: string;
    has_iops: boolean;
    info?: string;
    name: string;
    verified_level: number;
    iops_price: number;
    price: number;
};

export interface InstanceVolumeTypeInterface {
    code: string;
    hasIops: boolean;
    info?: string;
    name: string;
    verifiedLevel: number;
    iopsPrice: number;
    price: number;
}

export default class InstanceVolumeType implements InstanceVolumeTypeInterface {
    readonly code: string;
    readonly hasIops: boolean;
    readonly info?: string;
    readonly name: string;
    readonly verifiedLevel: number;
    readonly iopsPrice: number;
    readonly price: number;

    constructor(props: InstanceVolumeTypeClassProps) {
        this.code = props.code;
        this.hasIops = props.has_iops;
        this.info = props.info;
        this.name = props.name;
        this.verifiedLevel = props.verified_level;
        this.iopsPrice = props.iops_price ? props.iops_price : 0;
        this.price = props.price ? props.price : 0;
    }

    getMonthlyPrice(): number {
        return this.price / 100;
    }

    getMonthlyIOPSPrice(): number {
        return this.iopsPrice / 100;
    }
}
