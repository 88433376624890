import React, { ReactElement, useEffect, useState } from 'react';
import styles from './ProxySqlLbDashboard.module.less';
import { Col, Row } from 'antd';
import usePrometheusProxySqlActiveTransactionsStats from '../../../core/hooks/usePrometheusProxySqlActiveTransactionsStats';
import usePrometheusProxySqlClientConnectionsStats from '../../../core/hooks/usePrometheusProxySqlClientConnectionsStats';
import usePrometheusProxySqlDataReceivedHostgroupDistributionStats from '../../../core/hooks/usePrometheusProxySqlDataReceivedHostgroupDistributionStats';
import usePrometheusProxySqlDataSentHostgroupDistributionStats from '../../../core/hooks/usePrometheusProxySqlDataSentHostgroupDistributionStats';
import usePrometheusProxySqlLatencyHostgroupDistributionStats from '../../../core/hooks/usePrometheusProxySqlLatencyHostgroupDistributionStats';
import usePrometheusProxySqlMemoryUtilizationStats from '../../../core/hooks/usePrometheusProxySqlMemoryUtilizationStats';
import usePrometheusProxySqlQueriesBackendStats from '../../../core/hooks/usePrometheusProxySqlQueriesBackendStats';
import usePrometheusProxySqlQueriesHostgroupDistributionStats from '../../../core/hooks/usePrometheusProxySqlQueriesHostgroupDistributionStats';
import usePrometheusProxySqlQueryCacheGetSetStats from '../../../core/hooks/usePrometheusProxySqlQueryCacheGetSetStats';
import usePrometheusProxySqlQueryCacheInOutBytesStats from '../../../core/hooks/usePrometheusProxySqlQueryCacheInOutBytesStats';
import usePrometheusProxySqlQueryCacheMemoryStats from '../../../core/hooks/usePrometheusProxySqlQueryCacheMemoryStats';
import usePrometheusProxySqlQuestionsStats from '../../../core/hooks/usePrometheusProxySqlQuestionsStats';
import usePrometheusProxySqlServerConnectionsStats from '../../../core/hooks/usePrometheusProxySqlServerConnectionsStats';
import usePrometheusProxySqlSlowQueriesStats from '../../../core/hooks/usePrometheusProxySqlSlowQueriesStats';
import PrometheusProxySqlQuestionsChart from '../../ccx/charts/PrometheusProxySqlQuestionsChart';
import PrometheusProxySqlSlowQueriesChart from '../../ccx/charts/PrometheusProxySqlSlowQueriesChart';
import PrometheusProxySqlQueriesHostgroupDistributionChart from '../../ccx/charts/PrometheusProxySqlQueriesHostgroupDistributionChart';
import PrometheusProxySqlLatencyHostgroupDistributionChart from '../../ccx/charts/PrometheusProxySqlLatencyHostgroupDistributionChart';
import PrometheusProxySqlDataSentHostgroupDistributionChart from '../../ccx/charts/PrometheusProxySqlDataSentHostgroupDistributionChart';
import PrometheusProxySqlDataReceivedHostgroupDistributionChart from '../../ccx/charts/PrometheusProxySqlDataReceivedHostgroupDistributionChart';
import PrometheusProxySqlMemoryUtilizationChart from '../../ccx/charts/PrometheusProxySqlMemoryUtilizationChart';
import PrometheusProxySqlQueryCacheInOutBytesChart from '../../ccx/charts/PrometheusProxySqlQueryCacheInOutBytesChart';
import PrometheusProxySqlQueryCacheGetSetChart from '../../ccx/charts/PrometheusProxySqlQueryCacheGetSetChart';
import PrometheusProxySqlQueryCacheMemoryChart from '../../ccx/charts/PrometheusProxySqlQueryCacheMemoryChart';
import PrometheusProxySqlServerConnectionsChart from '../../ccx/charts/PrometheusProxySqlServerConnectionsChart';
import PrometheusProxySqlClientConnectionsChart from '../../ccx/charts/PrometheusProxySqlClientConnectionsChart';
import PrometheusProxySqlQueriesBackendChart from '../../ccx/charts/PrometheusProxySqlQueriesBackendChart';
import PrometheusProxySqlActiveTransactionsChart from '../../ccx/charts/PrometheusProxySqlActiveTransactionsChart';

type ProxySqlLbDashboardProps = {
    uuid: string;
    host_uuid: string;
    port: number;
    from?: string;
    to?: string;
    interval: number;
    hidden?: boolean;
    displaySummary?: boolean;
    onLoading?: Function;
};

function ProxySqlLbDashboard({
    uuid,
    host_uuid,
    port,
    from,
    to,
    interval,
    hidden,
    displaySummary,
    onLoading,
}: ProxySqlLbDashboardProps): ReactElement {
    const {
        stats: activeTransactionsStats,
        refresh: activeTransactionsStatsRefresh,
        loading: activeTransactionsStatsLoading,
    } = usePrometheusProxySqlActiveTransactionsStats(uuid, host_uuid, from, to);

    const {
        stats: clientConnectionsStats,
        refresh: clientConnectionsStatsRefresh,
        loading: clientConnectionsStatsLoading,
    } = usePrometheusProxySqlClientConnectionsStats(uuid, host_uuid, from, to);

    const {
        stats: dataReceivedHostgroupDistributionStats,
        refresh: dataReceivedHostgroupDistributionStatsRefresh,
        loading: dataReceivedHostgroupDistributionStatsLoading,
    } = usePrometheusProxySqlDataReceivedHostgroupDistributionStats(
        uuid,
        host_uuid,
        from,
        to
    );

    const {
        stats: dataSentHostgroupDistributionStats,
        refresh: dataSentHostgroupDistributionStatsRefresh,
        loading: dataSentHostgroupDistributionStatsLoading,
    } = usePrometheusProxySqlDataSentHostgroupDistributionStats(
        uuid,
        host_uuid,
        from,
        to
    );

    const {
        stats: latencyHostgroupDistributionStats,
        refresh: latencyHostgroupDistributionStatsRefresh,
        loading: latencyHostgroupDistributionStatsLoading,
    } = usePrometheusProxySqlLatencyHostgroupDistributionStats(
        uuid,
        host_uuid,
        from,
        to
    );

    const {
        stats: memoryUtilizationStats,
        refresh: memoryUtilizationStatsRefresh,
        loading: memoryUtilizationStatsLoading,
    } = usePrometheusProxySqlMemoryUtilizationStats(uuid, host_uuid, from, to);

    const {
        stats: queriesBackendStats,
        refresh: queriesBackendStatsRefresh,
        loading: queriesBackendStatsLoading,
    } = usePrometheusProxySqlQueriesBackendStats(uuid, host_uuid, from, to);

    const {
        stats: queriesHostgroupDistributionStats,
        refresh: queriesHostgroupDistributionStatsRefresh,
        loading: queriesHostgroupDistributionStatsLoading,
    } = usePrometheusProxySqlQueriesHostgroupDistributionStats(
        uuid,
        host_uuid,
        from,
        to
    );

    const {
        stats: queryCacheGetSetStats,
        refresh: queryCacheGetSetStatsRefresh,
        loading: queryCacheGetSetStatsLoading,
    } = usePrometheusProxySqlQueryCacheGetSetStats(uuid, host_uuid, from, to);

    const {
        stats: queryCacheInOutBytesStats,
        refresh: queryCacheInOutBytesStatsRefresh,
        loading: queryCacheInOutBytesStatsLoading,
    } = usePrometheusProxySqlQueryCacheInOutBytesStats(
        uuid,
        host_uuid,
        from,
        to
    );

    const {
        stats: queryCacheMemoryStats,
        refresh: queryCacheMemoryStatsRefresh,
        loading: queryCacheMemoryStatsLoading,
    } = usePrometheusProxySqlQueryCacheMemoryStats(uuid, host_uuid, from, to);

    const {
        stats: questionsStats,
        refresh: questionsStatsRefresh,
        loading: questionsStatsLoading,
    } = usePrometheusProxySqlQuestionsStats(uuid, host_uuid, from, to);

    const {
        stats: serverConnectionsStats,
        refresh: serverConnectionsStatsRefresh,
        loading: serverConnectionsStatsLoading,
    } = usePrometheusProxySqlServerConnectionsStats(uuid, host_uuid, from, to);

    const {
        stats: slowQueriesStats,
        refresh: slowQueriesStatsRefresh,
        loading: slowQueriesStatsLoading,
    } = usePrometheusProxySqlSlowQueriesStats(uuid, host_uuid, from, to);

    const [
        activeTransactionsStatsInterval,
        setActiveTransactionsStatsInterval,
    ] = useState<number | undefined>(undefined);
    const [clientConnectionsStatsInterval, setClientConnectionsStatsInterval] =
        useState<number | undefined>(undefined);
    const [
        dataReceivedHostgroupDistributionStatsInterval,
        setDataReceivedHostgroupDistributionStatsInterval,
    ] = useState<number | undefined>(undefined);
    const [
        dataSentHostgroupDistributionStatsInterval,
        setDataSentHostgroupDistributionStatsInterval,
    ] = useState<number | undefined>(undefined);
    const [
        latencyHostgroupDistributionStatsInterval,
        setLatencyHostgroupDistributionStatsInterval,
    ] = useState<number | undefined>(undefined);
    const [memoryUtilizationStatsInterval, setMemoryUtilizationStatsInterval] =
        useState<number | undefined>(undefined);
    const [queriesBackendStatsInterval, setQueriesBackendStatsInterval] =
        useState<number | undefined>(undefined);
    const [
        queriesHostgroupDistributionStatsInterval,
        setQueriesHostgroupDistributionStatsInterval,
    ] = useState<number | undefined>(undefined);
    const [queryCacheGetSetStatsInterval, setQueryCacheGetSetStatsInterval] =
        useState<number | undefined>(undefined);
    const [
        queryCacheInOutBytesStatsInterval,
        setQueryCacheInOutBytesStatsInterval,
    ] = useState<number | undefined>(undefined);
    const [queryCacheMemoryStatsInterval, setQueryCacheMemoryStatsInterval] =
        useState<number | undefined>(undefined);
    const [questionsStatsInterval, setQuestionsStatsInterval] = useState<
        number | undefined
    >(undefined);
    const [serverConnectionsStatsInterval, setServerConnectionsStatsInterval] =
        useState<number | undefined>(undefined);
    const [slowQueriesStatsInterval, setSlowQueriesStatsInterval] = useState<
        number | undefined
    >(undefined);

    const [refreshInterval, setRefreshInterval] = useState(interval);

    useEffect(() => {
        let interval: any;
        if (activeTransactionsStats && refreshInterval > 0) {
            interval = setInterval(() => {
                activeTransactionsStatsRefresh();
            }, refreshInterval);
            setActiveTransactionsStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [activeTransactionsStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (clientConnectionsStats && refreshInterval > 0) {
            interval = setInterval(() => {
                clientConnectionsStatsRefresh();
            }, refreshInterval);
            setClientConnectionsStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [clientConnectionsStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (dataReceivedHostgroupDistributionStats && refreshInterval > 0) {
            interval = setInterval(() => {
                dataReceivedHostgroupDistributionStatsRefresh();
            }, refreshInterval);
            setDataReceivedHostgroupDistributionStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [dataReceivedHostgroupDistributionStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (dataSentHostgroupDistributionStats && refreshInterval > 0) {
            interval = setInterval(() => {
                dataSentHostgroupDistributionStatsRefresh();
            }, refreshInterval);
            setDataSentHostgroupDistributionStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [dataSentHostgroupDistributionStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (latencyHostgroupDistributionStats && refreshInterval > 0) {
            interval = setInterval(() => {
                latencyHostgroupDistributionStatsRefresh();
            }, refreshInterval);
            setLatencyHostgroupDistributionStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [latencyHostgroupDistributionStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (memoryUtilizationStats && refreshInterval > 0) {
            interval = setInterval(() => {
                memoryUtilizationStatsRefresh();
            }, refreshInterval);
            setMemoryUtilizationStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [memoryUtilizationStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (queriesBackendStats && refreshInterval > 0) {
            interval = setInterval(() => {
                queriesBackendStatsRefresh();
            }, refreshInterval);
            setQueriesBackendStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [queriesBackendStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (queriesHostgroupDistributionStats && refreshInterval > 0) {
            interval = setInterval(() => {
                queriesHostgroupDistributionStatsRefresh();
            }, refreshInterval);
            setQueriesHostgroupDistributionStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [queriesHostgroupDistributionStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (queryCacheGetSetStats && refreshInterval > 0) {
            interval = setInterval(() => {
                queryCacheGetSetStatsRefresh();
            }, refreshInterval);
            setQueryCacheGetSetStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [queryCacheGetSetStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (queryCacheInOutBytesStats && refreshInterval > 0) {
            interval = setInterval(() => {
                queryCacheInOutBytesStatsRefresh();
            }, refreshInterval);
            setQueryCacheInOutBytesStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [queryCacheInOutBytesStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (queryCacheMemoryStats && refreshInterval > 0) {
            interval = setInterval(() => {
                queryCacheMemoryStatsRefresh();
            }, refreshInterval);
            setQueryCacheMemoryStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [queryCacheMemoryStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (questionsStats && refreshInterval > 0) {
            interval = setInterval(() => {
                questionsStatsRefresh();
            }, refreshInterval);
            setQuestionsStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [questionsStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (serverConnectionsStats && refreshInterval > 0) {
            interval = setInterval(() => {
                serverConnectionsStatsRefresh();
            }, refreshInterval);
            setServerConnectionsStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [serverConnectionsStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (slowQueriesStats && refreshInterval > 0) {
            interval = setInterval(() => {
                slowQueriesStatsRefresh();
            }, refreshInterval);
            setSlowQueriesStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [slowQueriesStats, refreshInterval]);

    useEffect(() => {
        if (interval === 0) {
            // when interval is 0, we must pause the refresh (clear existing intervals)
            clearInterval(activeTransactionsStatsInterval);
            clearInterval(clientConnectionsStatsInterval);
            clearInterval(dataReceivedHostgroupDistributionStatsInterval);
            clearInterval(dataSentHostgroupDistributionStatsInterval);
            clearInterval(latencyHostgroupDistributionStatsInterval);
            clearInterval(memoryUtilizationStatsInterval);
            clearInterval(queriesBackendStatsInterval);
            clearInterval(queriesHostgroupDistributionStatsInterval);
            clearInterval(queryCacheGetSetStatsInterval);
            clearInterval(queryCacheInOutBytesStatsInterval);
            clearInterval(queryCacheMemoryStatsInterval);
            clearInterval(questionsStatsInterval);
            clearInterval(serverConnectionsStatsInterval);
            clearInterval(slowQueriesStatsInterval);
            setActiveTransactionsStatsInterval(0);
            setClientConnectionsStatsInterval(0);
            setDataReceivedHostgroupDistributionStatsInterval(0);
            setDataSentHostgroupDistributionStatsInterval(0);
            setLatencyHostgroupDistributionStatsInterval(0);
            setMemoryUtilizationStatsInterval(0);
            setQueriesBackendStatsInterval(0);
            setQueriesHostgroupDistributionStatsInterval(0);
            setQueryCacheGetSetStatsInterval(0);
            setQueryCacheInOutBytesStatsInterval(0);
            setQueryCacheMemoryStatsInterval(0);
            setQuestionsStatsInterval(0);
            setServerConnectionsStatsInterval(0);
            setSlowQueriesStatsInterval(0);
        }
        setRefreshInterval(interval);
        setActiveTransactionsStatsInterval(interval);
        setClientConnectionsStatsInterval(interval);
        setDataReceivedHostgroupDistributionStatsInterval(interval);
        setDataSentHostgroupDistributionStatsInterval(interval);
        setLatencyHostgroupDistributionStatsInterval(interval);
        setMemoryUtilizationStatsInterval(interval);
        setQueriesBackendStatsInterval(interval);
        setQueriesHostgroupDistributionStatsInterval(interval);
        setQueryCacheGetSetStatsInterval(interval);
        setQueryCacheInOutBytesStatsInterval(interval);
        setQueryCacheMemoryStatsInterval(interval);
        setQuestionsStatsInterval(interval);
        setServerConnectionsStatsInterval(interval);
        setSlowQueriesStatsInterval(interval);
    }, [interval]);

    useEffect(() => {
        if (hidden) {
            // clear interval to stop updating data if the tab is not active (charts are not visible)
            clearInterval(activeTransactionsStatsInterval);
            clearInterval(clientConnectionsStatsInterval);
            clearInterval(dataReceivedHostgroupDistributionStatsInterval);
            clearInterval(dataSentHostgroupDistributionStatsInterval);
            clearInterval(latencyHostgroupDistributionStatsInterval);
            clearInterval(memoryUtilizationStatsInterval);
            clearInterval(queriesBackendStatsInterval);
            clearInterval(queriesHostgroupDistributionStatsInterval);
            clearInterval(queryCacheGetSetStatsInterval);
            clearInterval(queryCacheInOutBytesStatsInterval);
            clearInterval(queryCacheMemoryStatsInterval);
            clearInterval(questionsStatsInterval);
            clearInterval(serverConnectionsStatsInterval);
            clearInterval(slowQueriesStatsInterval);
            setActiveTransactionsStatsInterval(0);
            setClientConnectionsStatsInterval(0);
            setDataReceivedHostgroupDistributionStatsInterval(0);
            setDataSentHostgroupDistributionStatsInterval(0);
            setLatencyHostgroupDistributionStatsInterval(0);
            setMemoryUtilizationStatsInterval(0);
            setQueriesBackendStatsInterval(0);
            setQueriesHostgroupDistributionStatsInterval(0);
            setQueryCacheGetSetStatsInterval(0);
            setQueryCacheInOutBytesStatsInterval(0);
            setQueryCacheMemoryStatsInterval(0);
            setQuestionsStatsInterval(0);
            setServerConnectionsStatsInterval(0);
            setSlowQueriesStatsInterval(0);
        } else if (
            activeTransactionsStatsInterval === 0 ||
            clientConnectionsStatsInterval === 0 ||
            dataReceivedHostgroupDistributionStatsInterval === 0 ||
            dataSentHostgroupDistributionStatsInterval === 0 ||
            latencyHostgroupDistributionStatsInterval === 0 ||
            memoryUtilizationStatsInterval === 0 ||
            queriesBackendStatsInterval === 0 ||
            queriesHostgroupDistributionStatsInterval === 0 ||
            queryCacheGetSetStatsInterval === 0 ||
            queryCacheInOutBytesStatsInterval === 0 ||
            queryCacheMemoryStatsInterval === 0 ||
            questionsStatsInterval === 0 ||
            serverConnectionsStatsInterval === 0 ||
            slowQueriesStatsInterval === 0
        ) {
            // force refresh if the interval is 0, otherwise, the interval will refresh data soon
            // interval is undefined on the first load
            // interval will be 0 when paused or tab is hidden
            activeTransactionsStatsRefresh();
            clientConnectionsStatsRefresh();
            dataReceivedHostgroupDistributionStatsRefresh();
            dataSentHostgroupDistributionStatsRefresh();
            latencyHostgroupDistributionStatsRefresh();
            memoryUtilizationStatsRefresh();
            queriesBackendStatsRefresh();
            queriesHostgroupDistributionStatsRefresh();
            queryCacheGetSetStatsRefresh();
            queryCacheInOutBytesStatsRefresh();
            queryCacheMemoryStatsRefresh();
            questionsStatsRefresh();
            serverConnectionsStatsRefresh();
            slowQueriesStatsRefresh();
        }
    }, [hidden]);

    useEffect(() => {
        if (
            activeTransactionsStatsLoading ||
            clientConnectionsStatsLoading ||
            dataReceivedHostgroupDistributionStatsLoading ||
            dataSentHostgroupDistributionStatsLoading ||
            latencyHostgroupDistributionStatsLoading ||
            memoryUtilizationStatsLoading ||
            queriesBackendStatsLoading ||
            queriesHostgroupDistributionStatsLoading ||
            queryCacheGetSetStatsLoading ||
            queryCacheInOutBytesStatsLoading ||
            queryCacheMemoryStatsLoading ||
            questionsStatsLoading ||
            serverConnectionsStatsLoading ||
            slowQueriesStatsLoading
        ) {
            onLoading && onLoading(true);
        } else {
            onLoading && onLoading(false);
        }
    }, [
        activeTransactionsStatsLoading,
        clientConnectionsStatsLoading,
        dataReceivedHostgroupDistributionStatsLoading,
        dataSentHostgroupDistributionStatsLoading,
        latencyHostgroupDistributionStatsLoading,
        memoryUtilizationStatsLoading,
        queriesBackendStatsLoading,
        queriesHostgroupDistributionStatsLoading,
        queryCacheGetSetStatsLoading,
        queryCacheInOutBytesStatsLoading,
        queryCacheMemoryStatsLoading,
        questionsStatsLoading,
        serverConnectionsStatsLoading,
        slowQueriesStatsLoading,
    ]);

    return (
        <section className={styles.ProxySqlLbDashboard}>
            <Row
                gutter={[
                    32,
                    { xs: 8, sm: 16, md: 24, lg: 32, xl: 40, xxl: 48 },
                ]}
                className={styles.ProxySqlLbDashboardRow}
            >
                <Col
                    className={styles.ProxySqlLbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusProxySqlQuestionsChart
                        data={questionsStats}
                        loading={questionsStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.ProxySqlLbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusProxySqlSlowQueriesChart
                        data={slowQueriesStats}
                        loading={slowQueriesStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.ProxySqlLbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusProxySqlQueriesHostgroupDistributionChart
                        data={queriesHostgroupDistributionStats}
                        loading={queriesHostgroupDistributionStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.ProxySqlLbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusProxySqlLatencyHostgroupDistributionChart
                        data={latencyHostgroupDistributionStats}
                        loading={latencyHostgroupDistributionStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.ProxySqlLbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusProxySqlDataSentHostgroupDistributionChart
                        data={dataSentHostgroupDistributionStats}
                        loading={dataSentHostgroupDistributionStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.ProxySqlLbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusProxySqlDataReceivedHostgroupDistributionChart
                        data={dataReceivedHostgroupDistributionStats}
                        loading={dataReceivedHostgroupDistributionStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.ProxySqlLbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusProxySqlActiveTransactionsChart
                        data={activeTransactionsStats}
                        loading={activeTransactionsStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.ProxySqlLbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusProxySqlQueriesBackendChart
                        data={queriesBackendStats}
                        loading={queriesBackendStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.ProxySqlLbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusProxySqlClientConnectionsChart
                        data={clientConnectionsStats}
                        loading={clientConnectionsStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.ProxySqlLbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusProxySqlServerConnectionsChart
                        data={serverConnectionsStats}
                        loading={serverConnectionsStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.ProxySqlLbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusProxySqlQueryCacheMemoryChart
                        data={queryCacheMemoryStats}
                        loading={queryCacheMemoryStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.ProxySqlLbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusProxySqlQueryCacheGetSetChart
                        data={queryCacheGetSetStats}
                        loading={queryCacheGetSetStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.ProxySqlLbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusProxySqlQueryCacheInOutBytesChart
                        data={queryCacheInOutBytesStats}
                        loading={queryCacheInOutBytesStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.ProxySqlLbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusProxySqlMemoryUtilizationChart
                        data={memoryUtilizationStats}
                        loading={memoryUtilizationStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
            </Row>
        </section>
    );
}

export default ProxySqlLbDashboard;
