import React, { ReactElement } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import CcxIconStopOutlined from '../icons/CcxIconStopOutlined';
import styles from './NoChartAvailable.module.less';

interface Props extends CcxComponentProps {}

function NoChartAvailable({
    testId = 'NoChartAvailable',
}: Props): ReactElement {
    return (
        <div className={styles.NoChartAvailable} data-testid={testId}>
            <CcxIconStopOutlined />
            <div>Data is not available</div>
        </div>
    );
}

export default NoChartAvailable;
