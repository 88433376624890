type ReducerProps = {
    state: any;
    action: any;
};

export const initialState = {
    deployments: [],
    deployment: null,
    node: null,
};

export default function reducer({ state, action }: ReducerProps) {
    switch (action.type) {
        case 'SELECT_DEPLOYMENT':
            return { ...state, deployment: action.deployment };
        case 'SELECT_NODE':
            return { ...state, node: action.node };
        default:
            return state;
    }
}
