import { useEffect, useState } from 'react';
import ServiceService from '../../services/ServiceService';

interface Props {
    dataStoreUuid?: string;
}

export default function useDataStoreServices({ dataStoreUuid }: Props) {
    const [loading, setLoading] = useState<boolean>(true);
    const [updating, setUpdating] = useState<boolean>(false);
    const [services, setServices] = useState<any>(undefined);
    const [error, setError] = useState<string | null>(null);

    const load = async () => {
        if (dataStoreUuid) {
            try {
                const response = await ServiceService.getAllServices(
                    dataStoreUuid
                );
                setServices(response);
            } catch (err: any) {
                console.error(err);
                setError(err);
            }
        } else {
            setError('No datastore selected.');
        }
    };

    const refresh = () => {
        const update = async () => {
            setUpdating(true);
            await load();
            setUpdating(false);
        };
        update();
    };

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            await load();
            setLoading(false);
        };

        if (!services) {
            init();
        } else {
            refresh();
        }
    }, [dataStoreUuid]);

    return { services, refresh, error, loading, updating };
}
