export type PrometheusMySqlQueriesStatsBackendApiProps = {
    created: number;
    queries: number;
    instance: string;
};

export default class PrometheusMySqlQueriesStats {
    readonly created: number;
    readonly queries: number;
    readonly instance: string;

    constructor(props: PrometheusMySqlQueriesStatsBackendApiProps) {
        this.created = props.created;
        this.queries = props.queries;
        this.instance = props.instance;
    }
}
