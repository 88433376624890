import React, { ReactElement } from 'react';
import CcxComponentProps from '../../core/CcxComponent';
import { Button } from 'antd';
import { Tooltip } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

interface Props extends CcxComponentProps {
    buttonType?: string;
    buttonText?: string;
    disabled?: boolean;
    disabledText?: string;
    className?: string;
    size?: SizeType;
}

export type UrlProps = {
    projectUuid: string;
};

function CreateVpcButton({
    buttonText = '+ Create VPC',
    buttonType,
    className,
    testId = 'CreateVpcButton',
    disabled,
    disabledText,
    size = 'small',
}: Props): ReactElement {
    const history = useHistory();
    const { projectUuid } = useParams<UrlProps>();

    const handleClick = () => {
        history.push(`/projects/${projectUuid}/services/new/vpc`);
    };

    return (
        <Tooltip title={disabled ? disabledText : ''}>
            <Button
                onClick={handleClick}
                disabled={disabled}
                type={buttonType as any}
                className={className}
                data-testid={testId}
                size={size}
            >
                {buttonText}
            </Button>
        </Tooltip>
    );
}

export default CreateVpcButton;
