import { FormInstance } from 'antd/lib/form';
import { ReactElement, useEffect } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import CloudProvider from '../../../types/CloudProvider';
import DeploymentOptions from '../../../types/DeploymentOptions';
import InstanceVolumeIopsValue from '../../../types/InstanceVolumeIopsValue';
import InstanceVolumeSize from '../../../types/InstanceVolumeSize';
import InstanceVolumeType from '../../../types/InstanceVolumeType';
import User from '../../../types/User';
import InstanceSizeForm from './InstanceSizeForm';
import DatabaseVendor from '../../../types/DatabaseVendor';
import styles from './WizardFormConfigurationStep3.module.less';
interface Props extends CcxComponentProps {
    selectedTech?: DatabaseVendor;
    handleInstanceSizeChange: Function;
    deploymentOptions?: DeploymentOptions;
    cloudProvider: CloudProvider | undefined;
    user?: User;
    handleVolumeTypeChange: Function;
    volumeSize?: InstanceVolumeSize;
    volumeType?: InstanceVolumeType;
    volumeIops?: InstanceVolumeIopsValue;
    enteredVolumeSize?: number;
    setEnteredVolumeSize: Function;
    setEnteredIOPS: Function;
    form: FormInstance;
    StepsExtra: Function;
}

export function WizardFormConfigurationStep3({
    testId = 'WizardFormConfigurationStep3',
    selectedTech,
    handleInstanceSizeChange,
    deploymentOptions,
    cloudProvider,
    user,
    handleVolumeTypeChange,
    volumeSize,
    volumeType,
    volumeIops,
    enteredVolumeSize,
    setEnteredVolumeSize,
    setEnteredIOPS,
    form,
    StepsExtra,
}: Props): ReactElement {
    useEffect(() => {
        if (!volumeType?.hasIops) {
            form.setFieldsValue({
                volumeIops: undefined,
            });
        }
    }, [volumeType]);

    useEffect(() => {
        if (volumeSize) {
            setEnteredVolumeSize && setEnteredVolumeSize(volumeSize?.default);
        }
    }, [volumeSize]);

    useEffect(() => {
        if (volumeType?.hasIops && volumeIops) {
            setEnteredIOPS && setEnteredIOPS(volumeIops?.default);
        } else {
            setEnteredIOPS && setEnteredIOPS(undefined);
        }
    }, [volumeIops]);

    useEffect(() => {
        if (cloudProvider && deploymentOptions) {
            const instanceSizes =
                deploymentOptions?.getInstanceSizes(cloudProvider);

            if (instanceSizes?.length === 1) {
                form.setFieldsValue({
                    instanceSize: instanceSizes[0],
                });
                handleInstanceSizeChange({
                    target: { value: instanceSizes[0] },
                });
            }

            const volumeTypes =
                deploymentOptions?.getVolumeTypes(cloudProvider);

            if (volumeTypes?.length === 1) {
                form.setFieldsValue({
                    volumeType: volumeTypes[0],
                });
                handleVolumeTypeChange({ target: { value: volumeTypes[0] } });
            }
        }
    }, [cloudProvider, deploymentOptions]);

    return (
        <div data-testid={testId}>
            <InstanceSizeForm
                testId={testId}
                selectedTech={selectedTech}
                handleInstanceSizeChange={handleInstanceSizeChange}
                deploymentOptions={deploymentOptions}
                cloudProvider={cloudProvider}
                user={user}
                form={form}
                handleVolumeTypeChange={handleVolumeTypeChange}
                volumeSize={volumeSize}
                volumeType={volumeType}
                volumeIops={volumeIops}
                enteredVolumeSize={enteredVolumeSize}
                setEnteredVolumeSize={setEnteredVolumeSize}
                setEnteredIOPS={setEnteredIOPS}
            />
            <div className={styles.StepsExtraSmallScreen}>
                <StepsExtra />
            </div>
        </div>
    );
}
