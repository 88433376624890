export type CardClassProps = {
    brand: string;
    country: string;
    default: boolean;
    exp_month: number;
    exp_year: number;
    fingerprint: string;
    id: string;
    last4: string;
    error: string;
};

export interface CardInterface {
    brand: string;
    country: string;
    default: boolean;
    expMonth: number;
    expYear: number;
    fingerprint: string;
    id: string;
    last4: string;
    error: string;
}

export default class Card implements CardInterface {
    readonly brand: string;
    readonly country: string;
    readonly default: boolean;
    readonly expMonth: number;
    readonly expYear: number;
    readonly fingerprint: string;
    readonly id: string;
    readonly last4: string;
    readonly error: string;

    constructor(props: CardClassProps) {
        this.brand = props.brand;
        this.country = props.country;
        this.default = props.default;
        this.expMonth = props.exp_month;
        this.expYear = props.exp_year;
        this.fingerprint = props.fingerprint;
        this.id = props.id;
        this.last4 = props.last4;
        this.error = props.error;
    }

    getElegantBrandName(): string {
        return this.brand.charAt(0).toUpperCase() + this.brand.slice(1);
    }

    isValid(): boolean {
        return this.error.length === 0 ? true : false;
    }
}
