import ApiService from './ApiService';
import { API_PROFILES } from '../core/CcxEnv';
import User from '../types/User';

// constants
const API_URL = API_PROFILES;

export default class ProfileService extends ApiService {
    /**
     * update profile
     * @param {string} new_user
     */

    static async updateProfile(new_user: string): Promise<User> {
        const requestData = {
            data: {
                additional_fields: {
                    new_user,
                },
            },
        };
        const res = await this.request('PUT', `user-data`, requestData);
        return new User(res.user);
    }

    protected static getApiUrl() {
        return API_URL;
    }
}
