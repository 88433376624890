import React, { ReactElement } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import CcxIconExclamationCircle from '../icons/CcxIconExclamationCircle';
import AppLabeledIconStatus from './AppLabeledIconStatus';
import styles from './IconLabelStatusError.module.less';

interface Props extends CcxComponentProps {
    label: string;
    showIcon?: boolean;
}

function IconLabelStatusError({
    label,
    showIcon,
    testId = 'IconLabelStatusError',
}: Props): ReactElement {
    return (
        <AppLabeledIconStatus
            label={label}
            icon={showIcon === true ? <CcxIconExclamationCircle /> : undefined}
            className={styles.IconLabelStatusError}
            testId={testId}
        />
    );
}

export default IconLabelStatusError;
