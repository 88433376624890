import FriendlyDateTime from './FriendlyDateTime';

export type CloudInstanceClassProps = {
    admin_port: number;
    cloud_provider: string;
    cluster_uuid: string;
    created_at: string;
    disks: string;
    fqdn: string;
    host_status: string;
    host_type: null;
    host_uuid: string;
    hostname: string;
    instance_id: string;
    instance_type: string;
    last_seen_at: string;
    listening_port: number;
    listening_port_ro: number;
    node_type: string;
    public_ip: string;
    port: number;
    private_ip: string;
    region: string;
    role: string;
    security_group: string;
    service_started_at: string;
    subnet: string;
    unique_id: number;
    updated_at: string;
    vpc: string;
};

export interface CloudInstanceInterface {
    adminPort: number;
    cloudProvider: string;
    clusterUuid: string;
    createdAt: string;
    disks: string;
    fqdn: string;
    formattedNodeType: string;
    hostStatus: string;
    hostType: null;
    hostUuid: string;
    hostname: string;
    icon: string;
    instance: string;
    instanceId: string;
    instanceType: string;
    key: string;
    lastSeen: string;
    lastSeenAt: string;
    lastSeenFull: string;
    listeningPort: number;
    listeningPortRo: number;
    nodeType: string;
    onlineFor: string;
    publicIp: string;
    port: number;
    privateIp: string;
    region: string;
    role: string;
    securityGroup: string;
    serviceStartedAt: string;
    status: string;
    subnet: string;
    uniqueId: number;
    updatedAt: string;
    vpc: string;
}

const CMON_NODETYPE_GALERA = 'galera';
const CMON_NODETYPE_MYSQL = 'mysql';
const CMON_NODETYPE_MSSQL = 'mssql';
const CMON_NODETYPE_PROXYSQL = 'proxysql';
const CMON_NODETYPE_HAPROXY = 'haproxy';
const CMON_NODETYPE_POSTGRESQL = 'postgres';
const CMON_NODETYPE_REDIS = 'redis';

export default class CloudInstance implements CloudInstanceInterface {
    readonly adminPort: number;
    readonly cloudProvider: string;
    readonly clusterUuid: string;
    readonly createdAt: string;
    readonly disks: string;
    readonly fqdn: string;
    readonly formattedNodeType: string;
    readonly hostStatus: string;
    readonly hostType: null;
    readonly hostUuid: string;
    readonly hostname: string;
    readonly icon: string;
    readonly instance: string;
    readonly instanceId: string;
    readonly instanceType: string;
    readonly key: string;
    readonly lastSeen: string;
    readonly lastSeenAt: string;
    readonly lastSeenFull: string;
    readonly listeningPort: number;
    readonly listeningPortRo: number;
    readonly nodeType: string;
    readonly onlineFor: string;
    readonly publicIp: string;
    readonly port: number;
    readonly privateIp: string;
    readonly region: string;
    readonly role: string;
    readonly securityGroup: string;
    readonly serviceStartedAt: string;
    readonly status: string;
    readonly subnet: string;
    readonly uniqueId: number;
    readonly updatedAt: string;
    readonly vpc: string;

    constructor(props: CloudInstanceClassProps) {
        this.adminPort = props.admin_port;
        this.hostUuid = props.host_uuid;
        this.clusterUuid = props.cluster_uuid;
        this.cloudProvider = props.cloud_provider;
        this.hostType = props.host_type;
        this.instanceId = props.instance_id;
        this.instanceType = props.instance_type;
        this.region = props.region;
        this.publicIp = props.public_ip;
        this.privateIp = props.private_ip;
        this.fqdn = props.fqdn;
        this.disks = props.disks;
        this.vpc = props.vpc;
        this.subnet = props.subnet;
        this.securityGroup = props.security_group;
        this.createdAt = props.created_at;
        this.updatedAt = props.updated_at;
        this.serviceStartedAt = props.service_started_at;
        this.uniqueId = props.unique_id;
        this.lastSeenAt = props.last_seen_at;
        this.port = props.port;
        this.hostname = props.hostname;
        this.hostStatus = props.host_status;
        this.role = props.role;
        this.nodeType = props.node_type;
        this.listeningPort = props.listening_port;
        this.listeningPortRo = props.listening_port_ro;

        this.status = this.getStatusText();
        this.lastSeen = new FriendlyDateTime({
            date: this.lastSeenAt,
        }).getFriendlyDate();
        this.lastSeenFull = new FriendlyDateTime({
            date: this.lastSeenAt,
        }).getFullDate();
        this.key = `${this.uniqueId}`;
        this.icon = '';
        this.formattedNodeType = this.getFormattedNodeType();
        this.onlineFor = new FriendlyDateTime({
            date: this.serviceStartedAt,
        }).getFriendlyDate();
        this.instance = `${this.publicIp || this.privateIp}:${this.port}`;
    }

    getFqdn(short: boolean = false) {
        // the backend may be returning fqdn or publicIp as null => try fqdn > publicIp > privateIp
        // to display something in the UI and avoid "undefined" strings or errors
        if (short) {
            return this.fqdn?.split('.')[0] || this.publicIp || this.privateIp;
        }
        return this.fqdn || this.publicIp || this.privateIp;
    }

    getRoleName(): string {
        if (this.isMaster() || this.isSlave()) {
            return this.role
                .replaceAll('master', 'primary')
                .replaceAll('slave', 'replica');
        } else {
            return this.role;
        }
    }

    getServiceConnectionName(): string {
        if (this.isMaster() || this.isSlave()) {
            return this.role
                .replaceAll('master', 'primary')
                .replaceAll('slave', 'replica');
        } else {
            return this.role
                .replaceAll('PRIMARY', 'primary')
                .replaceAll('SECONDARY', 'replica');
        }
    }

    getFqdnWithRole(short: boolean = true): string {
        return `${this.getFqdn(short)}/${this.getRoleName()}`;
    }

    getFqdnPortWithRole(short: boolean = true): string {
        return `${this.getFqdn(short)}:${this.port}/${this.getRoleName()}`;
    }

    isDb(): boolean {
        return this.isGalera() || this.isPostgreSql();
    }

    isLb(): boolean {
        return this.isProxySql() || this.isHaProxy();
    }

    isGalera(): boolean {
        return this.nodeType === CMON_NODETYPE_GALERA;
    }

    isPostgreSql(): boolean {
        return this.nodeType === CMON_NODETYPE_POSTGRESQL;
    }

    isMySql(): boolean {
        return (
            this.nodeType === CMON_NODETYPE_GALERA ||
            this.nodeType === CMON_NODETYPE_MYSQL
        );
    }

    isMSSQL(): boolean {
        return this.nodeType === CMON_NODETYPE_MSSQL;
    }

    isRedis(): boolean {
        return this.nodeType === CMON_NODETYPE_REDIS;
    }

    isProxySql(): boolean {
        return this.nodeType === CMON_NODETYPE_PROXYSQL;
    }

    isHaProxy(): boolean {
        return this.nodeType === CMON_NODETYPE_HAPROXY;
    }

    isOk(): boolean {
        return this.hostStatus === 'CmonHostOnline';
    }

    isError(): boolean {
        return (
            this.hostStatus === 'CmonHostShutDown' ||
            this.hostStatus === 'CmonHostFailed' ||
            this.hostStatus === 'CmonHostOffLine'
        );
    }

    getFqdnPort(short: boolean = true): string {
        return `${this.getFqdn(short)}:${this.port}`;
    }

    protected getStatusText(): string {
        switch (this.hostStatus) {
            case 'CmonHostOnline':
                return 'Online';
            case 'CmonHostOffLine':
                return 'Offline';
            case 'CmonHostShutDown':
                return 'ShutDown';
            case 'CmonHostFailed':
                return 'Failed';
            case 'CmonHostRecovery':
                return 'Recovering';
            case 'CmonHostUnknown':
            default:
                return 'Unknown';
        }
    }

    getFormattedNodeType(): string {
        if (this.isGalera()) {
            return 'Galera';
        } else if (this.isProxySql()) {
            return 'ProxySQL';
        } else if (this.isHaProxy()) {
            return 'HAProxy';
        } else if (this.isMaster()) {
            return 'Master';
        } else if (this.isSlave()) {
            return 'Slave';
        } else if (this.isSlave()) {
            return 'Slave';
        } else {
            // load balancers wil
            return this.role;
        }
    }

    isMaster(): boolean {
        return this.role === 'master';
    }

    isSlave(): boolean {
        return this.role === 'slave';
    }
}
