import React, { ReactElement, ReactNode } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import AppEmpty from '../../AppEmpty';
import CcxIconCheckCircle from '../icons/CcxIconCheckCircle';
import styles from './ChartCustomTooltip.module.less';

export interface ChartCustomTooltipRowType {
    color?: string;
    label: string;
    value: string | number;
}

interface Props extends CcxComponentProps {
    title: ReactNode;
    content: ChartCustomTooltipRowType[];
    summary?: ChartCustomTooltipRowType[];
}

function ChartCustomTooltip({
    content,
    summary,
    testId = 'ChartCustomTooltip',
    title,
}: Props): ReactElement {
    return (
        <div className={styles.ChartCustomTooltip} data-testid={testId}>
            <div
                className={styles.ChartCustomTooltipTitle}
                data-testid={`${testId}Title`}
            >
                {title}
            </div>

            <div
                className={styles.ChartCustomTooltipContent}
                data-testid={`${testId}Content`}
            >
                {content && content.length > 0 ? (
                    content.map((c: ChartCustomTooltipRowType, i: number) => {
                        return (
                            <div
                                className={styles.ChartCustomTooltipRow}
                                key={`content${i}`}
                            >
                                {c.color && (
                                    <div
                                        data-testid={'ChartCustomTooltipColor'}
                                        className={
                                            styles.ChartCustomTooltipColor
                                        }
                                        style={{ backgroundColor: c.color }}
                                    ></div>
                                )}
                                <div className={styles.ChartCustomTooltipLabel}>
                                    {c.label}
                                </div>
                                <div className={styles.ChartCustomTooltipValue}>
                                    {c.value}
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <AppEmpty />
                )}
            </div>

            {summary && summary.length > 0 && (
                <div
                    className={styles.ChartCustomTooltipSummary}
                    data-testid={`${testId}Summary`}
                >
                    {summary.map((c: ChartCustomTooltipRowType, i: number) => {
                        return (
                            <div
                                className={styles.ChartCustomTooltipRow}
                                key={`summary${i}`}
                            >
                                <div
                                    className={styles.ChartCustomTooltipColor}
                                ></div>
                                <div className={styles.ChartCustomTooltipLabel}>
                                    {c.label}
                                </div>
                                <div className={styles.ChartCustomTooltipValue}>
                                    {c.value}
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
}

export default ChartCustomTooltip;
