import React, { ReactElement } from 'react';
import styles from './AppUserMenu.module.less';
import UserMenu from './UserMenu';
import { useAppSelector } from '../../redux/hooks';

interface Props {
    testId?: string;
}

function AppUserMenu({ testId = 'AppUserMenu' }: Props): ReactElement {
    const { user } = useAppSelector((state) => state.user);

    return (
        <div data-testid={testId} className={styles.AppUserMenu}>
            <UserMenu user={user} />
        </div>
    );
}

export default AppUserMenu;
