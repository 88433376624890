export type PrometheusHaProxyBackendServersTotalBytesInStatsBackendApiProps = {
    created: number;
    haproxy_server_bytes_in_total: number;
};

export default class PrometheusHaProxyBackendServersTotalBytesInStats {
    readonly created: number;
    readonly haproxyServerBytesInTotal: number;

    constructor(
        props: PrometheusHaProxyBackendServersTotalBytesInStatsBackendApiProps
    ) {
        this.created = props.created;
        this.haproxyServerBytesInTotal = props.haproxy_server_bytes_in_total;
    }
}
