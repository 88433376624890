import { ReactElement, useEffect, useState } from 'react';
import styles from './PostgreSqlDbDashboard.module.less';
import { Col, Row } from 'antd';
import usePrometheusPostgreSqlActiveSessionsStats from '../../../core/hooks/usePrometheusPostgreSqlActiveSessionsStats';
import usePrometheusPostgreSqlDeleteStats from '../../../core/hooks/usePrometheusPostgreSqlDeleteStats';
import usePrometheusPostgreSqlInsertStats from '../../../core/hooks/usePrometheusPostgreSqlInsertStats';
import usePrometheusPostgreSqlSelectFetchedStats from '../../../core/hooks/usePrometheusPostgreSqlSelectFetchedStats';
import usePrometheusPostgreSqlSelectReturnedStats from '../../../core/hooks/usePrometheusPostgreSqlSelectReturnedStats';
import usePrometheusPostgreSqlUpdateStats from '../../../core/hooks/usePrometheusPostgreSqlUpdateStats';
import PrometheusPostgreSqlSelectFetchedChart from '../../ccx/charts/PrometheusPostgreSqlSelectFetchedChart';
import PrometheusPostgreSqlInsertChart from '../../ccx/charts/PrometheusPostgreSqlInsertChart';
import PrometheusPostgreSqlUpdateChart from '../../ccx/charts/PrometheusPostgreSqlUpdateChart';
import PrometheusPostgreSqlDeleteChart from '../../ccx/charts/PrometheusPostgreSqlDeleteChart';
import PrometheusPostgreSqlSelectReturnedChart from '../../ccx/charts/PrometheusPostgreSqlSelectReturnedChart';
import PrometheusPostgreSqlActiveSessionsChart from '../../ccx/charts/PrometheusPostgreSqlActiveSessionsChart';

type PostgreSqlDbDashboardProps = {
    uuid: string;
    port: number;
    from?: string;
    to?: string;
    interval: number;
    hidden?: boolean;
    displaySummary?: boolean;
    onLoading?: Function;
    hostUUIDs?: string[];
};

function PostgreSqlDbDashboardSummary({
    uuid,
    port,
    from,
    to,
    interval,
    hidden,
    displaySummary,
    onLoading,
    hostUUIDs,
}: PostgreSqlDbDashboardProps): ReactElement {
    const {
        stats: activeSessionsStats,
        refresh: activeSessionsStatsRefresh,
        loading: activeSessionsStatsLoading,
    } = usePrometheusPostgreSqlActiveSessionsStats(uuid, '', from, to);

    const {
        stats: deleteStats,
        refresh: deleteStatsRefresh,
        loading: deleteStatsLoading,
    } = usePrometheusPostgreSqlDeleteStats(uuid, '', from, to);

    const {
        stats: insertStats,
        refresh: insertStatsRefresh,
        loading: insertStatsLoading,
    } = usePrometheusPostgreSqlInsertStats(uuid, '', from, to);

    const {
        stats: selectFetchedStats,
        refresh: selectFetchedStatsRefresh,
        loading: selectFetchedStatsLoading,
    } = usePrometheusPostgreSqlSelectFetchedStats(uuid, '', from, to);

    const {
        stats: selectReturnedStats,
        refresh: selectReturnedStatsRefresh,
        loading: selectReturnedStatsLoading,
    } = usePrometheusPostgreSqlSelectReturnedStats(uuid, '', from, to);

    const {
        stats: updateStats,
        refresh: updateStatsRefresh,
        loading: updateStatsLoading,
    } = usePrometheusPostgreSqlUpdateStats(uuid, '', from, to);

    const [activeSessionsStatsInterval, setActiveSessionsStatsInterval] =
        useState<number | undefined>(undefined);
    const [deleteStatsInterval, setDeleteStatsInterval] = useState<
        number | undefined
    >(undefined);
    const [insertStatsInterval, setInsertStatsInterval] = useState<
        number | undefined
    >(undefined);
    const [selectFetchedStatsInterval, setSelectFetchedStatsInterval] =
        useState<number | undefined>(undefined);
    const [selectReturnedStatsInterval, setSelectReturnedStatsInterval] =
        useState<number | undefined>(undefined);
    const [updateStatsInterval, setUpdateStatsInterval] = useState<
        number | undefined
    >(undefined);

    const [refreshInterval, setRefreshInterval] = useState(interval);

    useEffect(() => {
        let interval: any;
        if (activeSessionsStats && refreshInterval > 0) {
            interval = setInterval(() => {
                activeSessionsStatsRefresh();
            }, refreshInterval);
            setActiveSessionsStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [activeSessionsStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (deleteStats && refreshInterval > 0) {
            interval = setInterval(() => {
                deleteStatsRefresh();
            }, refreshInterval);
            setDeleteStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [deleteStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (insertStats && refreshInterval > 0) {
            interval = setInterval(() => {
                insertStatsRefresh();
            }, refreshInterval);
            setInsertStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [insertStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (selectFetchedStats && refreshInterval > 0) {
            interval = setInterval(() => {
                selectFetchedStatsRefresh();
            }, refreshInterval);
            setSelectFetchedStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [selectFetchedStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (selectReturnedStats && refreshInterval > 0) {
            interval = setInterval(() => {
                selectReturnedStatsRefresh();
            }, refreshInterval);
            setSelectReturnedStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [selectReturnedStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (updateStats && refreshInterval > 0) {
            interval = setInterval(() => {
                updateStatsRefresh();
            }, refreshInterval);
            setUpdateStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [updateStats, refreshInterval]);

    useEffect(() => {
        if (interval === 0) {
            // when interval is 0, we must pause the refresh (clear existing intervals)
            clearInterval(activeSessionsStatsInterval);
            clearInterval(deleteStatsInterval);
            clearInterval(insertStatsInterval);
            clearInterval(selectFetchedStatsInterval);
            clearInterval(selectReturnedStatsInterval);
            clearInterval(updateStatsInterval);
            setActiveSessionsStatsInterval(0);
            setDeleteStatsInterval(0);
            setInsertStatsInterval(0);
            setSelectFetchedStatsInterval(0);
            setSelectReturnedStatsInterval(0);
            setUpdateStatsInterval(0);
        }
        setRefreshInterval(interval);
    }, [interval]);

    useEffect(() => {
        if (hidden) {
            // clear interval to stop updating data if the tab is not active (charts are not visible)
            clearInterval(activeSessionsStatsInterval);
            clearInterval(deleteStatsInterval);
            clearInterval(insertStatsInterval);
            clearInterval(selectFetchedStatsInterval);
            clearInterval(selectReturnedStatsInterval);
            clearInterval(updateStatsInterval);
            setActiveSessionsStatsInterval(0);
            setDeleteStatsInterval(0);
            setInsertStatsInterval(0);
            setSelectFetchedStatsInterval(0);
            setSelectReturnedStatsInterval(0);
            setUpdateStatsInterval(0);
        } else if (
            activeSessionsStatsInterval === 0 ||
            deleteStatsInterval === 0 ||
            insertStatsInterval === 0 ||
            selectFetchedStatsInterval === 0 ||
            selectReturnedStatsInterval === 0 ||
            updateStatsInterval === 0
        ) {
            // force refresh if the interval is 0, otherwise, the interval will refresh data soon
            // interval is undefined on the first load
            // interval will be 0 when paused or tab is hidden
            activeSessionsStatsRefresh();
            deleteStatsRefresh();
            insertStatsRefresh();
            selectFetchedStatsRefresh();
            selectReturnedStatsRefresh();
            updateStatsRefresh();
        }
    }, [hidden]);

    useEffect(() => {
        if (
            activeSessionsStatsLoading ||
            deleteStatsLoading ||
            insertStatsLoading ||
            selectFetchedStatsLoading ||
            selectReturnedStatsLoading ||
            updateStatsLoading
        ) {
            onLoading && onLoading(true);
        } else {
            onLoading && onLoading(false);
        }
    }, [
        activeSessionsStatsLoading,
        deleteStatsLoading,
        insertStatsLoading,
        selectFetchedStatsLoading,
        selectReturnedStatsLoading,
        updateStatsLoading,
    ]);

    return (
        <section
            data-testid="PostgreSqlDbDashboard"
            className={styles.PostgreSqlDbDashboard}
        >
            <Row
                gutter={[
                    32,
                    { xs: 8, sm: 16, md: 24, lg: 32, xl: 40, xxl: 48 },
                ]}
                className={styles.PostgreSqlDbDashboardRow}
            >
                <Col
                    className={styles.PostgreSqlDbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusPostgreSqlInsertChart
                        data={insertStats}
                        loading={insertStatsLoading}
                        displaySummary={displaySummary}
                        hostUUIDs={hostUUIDs}
                    />
                </Col>

                <Col
                    className={styles.PostgreSqlDbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusPostgreSqlUpdateChart
                        data={updateStats}
                        loading={updateStatsLoading}
                        displaySummary={displaySummary}
                        hostUUIDs={hostUUIDs}
                    />
                </Col>

                <Col
                    className={styles.PostgreSqlDbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusPostgreSqlDeleteChart
                        data={deleteStats}
                        loading={deleteStatsLoading}
                        displaySummary={displaySummary}
                        hostUUIDs={hostUUIDs}
                    />
                </Col>
                <Col
                    className={styles.PostgreSqlDbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusPostgreSqlSelectFetchedChart
                        data={selectFetchedStats}
                        loading={selectFetchedStatsLoading}
                        displaySummary={displaySummary}
                        hostUUIDs={hostUUIDs}
                    />
                </Col>
                <Col
                    className={styles.PostgreSqlDbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusPostgreSqlSelectReturnedChart
                        data={selectReturnedStats}
                        loading={selectReturnedStatsLoading}
                        displaySummary={displaySummary}
                        hostUUIDs={hostUUIDs}
                    />
                </Col>

                <Col
                    className={styles.PostgreSqlDbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusPostgreSqlActiveSessionsChart
                        data={activeSessionsStats}
                        loading={activeSessionsStatsLoading}
                        displaySummary={displaySummary}
                        hostUUIDs={hostUUIDs}
                    />
                </Col>
            </Row>
        </section>
    );
}

export default PostgreSqlDbDashboardSummary;
