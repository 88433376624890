import styles from './DatastoreScalingStep1.module.less';
import { Alert, Col, Form, InputNumber, Row } from 'antd';
import DatastoreScalingStepHeader from './DatastoreScalingStepHeader';
import DeploymentsItem from '../../../types/DeploymentsItem';
import InstanceVolumeSize from '../../../types/InstanceVolumeSize';
import DatastoreScalingCard from './DatastoreScalingCard';
import { DatastoreConfigurations } from './services/DataStoreNodeConfiguration';

interface DataStoreScalingProps {
    readonly dataStore: DeploymentsItem | undefined;
    readonly volumeSize: InstanceVolumeSize;
    readonly testId?: string;
    readonly showSubtitle?: boolean;
    readonly configuration: DatastoreConfigurations | undefined;
    readonly onChangeConfigurations: Function;
}

export default function DatastoreScaling({
    dataStore,
    volumeSize,
    showSubtitle = true,
    configuration,
    onChangeConfigurations,
    testId = 'DatastoreScalingStep1',
}: DataStoreScalingProps) {
    const isUpgradeStorageValid =
        (configuration?.storage ?? 0) < (dataStore?.volumeSize ?? 0) + 10;

    const cleanValue = (value: string, unitToRemove: string): string => {
        return value.replace(' ', '').replace(unitToRemove, '');
    };

    const parser = (displayValue: string | undefined): number => {
        const cleanedValue = cleanValue(displayValue ?? '', volumeSize.unit);
        return Number(cleanedValue);
    };

    return (
        <DatastoreScalingStepHeader
            title="Storage capacity"
            subtitle={
                showSubtitle ? 'Enter desired extra storage capacity' : ''
            }
        >
            <Row
                className={styles.DatastoreScalingStep1SliderRow}
                gutter={[16, 16]}
                data-testid={testId}
            >
                {dataStore && isUpgradeStorageValid ? (
                    <Col span={24}>
                        <Alert
                            className={styles.DatastoreScalingStep2Alert}
                            message={`New storage must be 10 GB greater than ${dataStore?.volumeSize} GB`}
                            showIcon
                            type="info"
                        />
                    </Col>
                ) : null}
                <Col span={16}>
                    {volumeSize && (
                        <Form.Item
                            name="volumeSize"
                            label=""
                            rules={[
                                {
                                    required: true,
                                    message: `Value must be 10 GB greater than ${dataStore?.volumeSize} GB`,
                                },
                            ]}
                            extra={
                                <small data-testid={`${testId}InputMessage`}>
                                    The desired value must be in the range of{' '}
                                    {volumeSize?.min}
                                    GB and {volumeSize?.max}GB
                                </small>
                            }
                        >
                            <InputNumber
                                min={dataStore?.volumeSize}
                                max={volumeSize?.max}
                                data-testid={`${testId}Input`}
                                className={
                                    styles.DatastoreScalingStepFormInputNumber
                                }
                                size="large"
                                formatter={(value?: number) => `${value}`}
                                onChange={(value) => {
                                    if (value)
                                        onChangeConfigurations({
                                            ...configuration,
                                            storage: value,
                                        });
                                }}
                                value={configuration?.storage}
                                defaultValue={configuration?.storage}
                                parser={parser}
                            />
                        </Form.Item>
                    )}
                </Col>
                <Col span={8}>
                    <DatastoreScalingCard
                        stepScalingTitle="Change to: "
                        stepScalingCount={`${
                            configuration?.storage
                                ? configuration?.storage
                                : dataStore?.volumeSize
                        } GB`}
                    />
                </Col>
            </Row>
        </DatastoreScalingStepHeader>
    );
}
