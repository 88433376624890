export type PrometheusPostgreSqlDiskLatencyStatsBackendApiProps = {
    created: number;
    read_time_seconds: number;
    write_time_seconds: number;
};

export default class PrometheusPostgreSqlDiskLatencyStats {
    readonly created: number;
    readonly readTimeSeconds: number;
    readonly writeTimeSeconds: number;

    constructor(props: PrometheusPostgreSqlDiskLatencyStatsBackendApiProps) {
        this.created = props.created;
        this.readTimeSeconds = props.read_time_seconds;
        this.writeTimeSeconds = props.write_time_seconds;
    }
}
