import React, {
    ReactElement,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import styles from './AppChartLine.module.less';
import { Line } from '@ant-design/charts';
import { ChartsContext } from '../core/context/ChartsContext';

export type AppChartLineProps = {
    config: any;
    data: any[];
};

function AppChartLine({ config, data }: AppChartLineProps): ReactElement {
    const ref = useRef<any>();

    const downloadImage = () => {
        // ref.current?.downloadImage();
    };

    const [chartConfig, setChartConfig] = useState<any>({
        ...config,
        meta: {
            xValue: {
                tickMethod: 'time',
            },
        },
    });

    const chartsContext = useContext(ChartsContext);

    useEffect(() => {
        if (chartConfig) {
            if (chartsContext.chartRange === '1w') {
                setChartConfig({
                    ...chartConfig,
                    xAxis: {
                        type: 'time',
                        mask: 'YYYY-MM-DD',
                    },
                    meta: {
                        xValue: {
                            tickMethod: 'time',
                            tickInterval: 60 * 60 * 24,
                        },
                    },
                });
            } else {
                setChartConfig({
                    ...chartConfig,
                    xAxis: {
                        type: 'time',
                        mask: 'HH:mm',
                    },
                    meta: {
                        xValue: {
                            tickMethod: 'time',
                        },
                    },
                });
            }
        }
    }, [chartsContext.chartRange]);

    return (
        <div className={styles.AppChartLine} onClick={downloadImage}>
            <Line {...chartConfig} chartRef={ref} data={data} />
        </div>
    );
}

export default AppChartLine;
