export type PrometheusHaProxyBackendCurrentQueuedRequestsStatsBackendApiProps =
    {
        created: number;
        haproxy_backend_current_queue: number;
    };

export default class PrometheusHaProxyBackendCurrentQueuedRequestsStats {
    readonly created: number;
    readonly haproxyBackendCurrentQueue: number;

    constructor(
        props: PrometheusHaProxyBackendCurrentQueuedRequestsStatsBackendApiProps
    ) {
        this.created = props.created;
        this.haproxyBackendCurrentQueue = props.haproxy_backend_current_queue;
    }
}
