import CloudProviderRegion, {
    CloudProviderRegionClassProps,
} from '../types/CloudProviderRegion';

export const CLOUD_PROVIDER_AWS = 'aws';
export const CLOUD_PROVIDER_SAFESPRING = 'safespring';
export const CLOUD_PROVIDER_CITYCLOUD = 'citycloud';
export const CLOUD_PROVIDER_GOOGLECLOUD = 'gce';
export const CLOUD_PROVIDER_VMWARE = 'vmware';
export const CLOUD_PROVIDER_VULTR = 'vultr';
export const CLOUD_PROVIDER_LINTASARTA = 'lintasarta';
export const CLOUD_PROVIDER_ELASTX = 'elastx';
export const CLOUD_PROVIDER_SYSELEVEN = 'syseleven';
export const CLOUD_PROVIDER_GOOGLECLOUDPLATFORM = 'gcp';
export const CLOUD_PROVIDER_CLEURA_STO = 'cleura-sto';
export const CLOUD_PROVIDER_CLEURA_KNA = 'cleura-kna';
export const CLOUD_PROVIDER_CLEURA_FRA = 'cleura-fra';

export type CloudProviderClassProps = {
    code: string;
    name: string;
    regions: CloudProviderRegionClassProps[];
    logo: string;
    group: string;
};

export interface CloudProviderInterface {
    code: string;
    name: string;
    regions: CloudProviderRegion[];
    logo: string;
    group: string;
    isAws: Function;
    isSafespring: Function;
    isCityCloud: Function;
    isGoogle: Function;
    isVultr: Function;
    getFullName: Function;
}

export default class CloudProvider implements CloudProviderInterface {
    readonly code: string;
    readonly name: string;
    readonly regions: CloudProviderRegion[];
    readonly logo: string;
    readonly group: string;

    constructor(props: CloudProviderClassProps) {
        this.code = props.code;
        this.name = props.name;
        this.regions = props.regions?.map(
            (r: CloudProviderRegionClassProps) => {
                return new CloudProviderRegion(r);
            }
        );
        this.logo = props.logo;
        this.group = props.group;
    }

    getCloudProviderLogo(): any {
        return this.logo;
    }

    isAws(): boolean {
        return this.code === CLOUD_PROVIDER_AWS;
    }

    isSafespring(): boolean {
        return this.code === CLOUD_PROVIDER_SAFESPRING;
    }

    isCityCloud(): boolean {
        return this.code === CLOUD_PROVIDER_CITYCLOUD;
    }

    isGoogle(): boolean {
        return this.code === CLOUD_PROVIDER_GOOGLECLOUD;
    }

    isVMWare(): boolean {
        return this.code === CLOUD_PROVIDER_VMWARE;
    }

    isVultr(): boolean {
        return this.code === CLOUD_PROVIDER_VULTR;
    }

    isLintasarta(): boolean {
        return this.code === CLOUD_PROVIDER_LINTASARTA;
    }

    isElastx(): boolean {
        return this.code === CLOUD_PROVIDER_ELASTX;
    }

    isSyseleven(): boolean {
        return this.code === CLOUD_PROVIDER_SYSELEVEN;
    }

    isOpenStack(): boolean {
        return (
            this.isLintasarta() ||
            this.isSafespring() ||
            this.isElastx() ||
            this.isSyseleven()
        );
    }

    getFullName(): string {
        switch (this.code) {
            case CLOUD_PROVIDER_AWS:
                return 'Amazon Web Services';
            case CLOUD_PROVIDER_SAFESPRING:
                return 'Safespring';
            case CLOUD_PROVIDER_CITYCLOUD:
                return 'City Cloud';
            case CLOUD_PROVIDER_GOOGLECLOUD:
                return 'Google Cloud Computing';
            case CLOUD_PROVIDER_VULTR:
                return 'Vultr';
            case CLOUD_PROVIDER_LINTASARTA:
                return 'Lintasarta';
            case CLOUD_PROVIDER_ELASTX:
                return 'Elastx';
            case CLOUD_PROVIDER_SYSELEVEN:
                return 'Syseleven';
            case CLOUD_PROVIDER_GOOGLECLOUDPLATFORM:
                return 'Google Cloud Platform';
            case CLOUD_PROVIDER_CLEURA_STO:
            case CLOUD_PROVIDER_CLEURA_KNA:
            case CLOUD_PROVIDER_CLEURA_FRA:
                return 'Cleura';
            default:
                return 'Unknown';
        }
    }

    getRegionContinents(): string[] {
        const allContinents = this.regions.map(
            (value: CloudProviderRegion) => value.continentCode
        );

        return Array.from(new Set(allContinents));
    }
    getDefaultRegion(): CloudProviderRegion {
        return this.regions[0];
    }
}
