import React, { ReactElement, useEffect, useState } from 'react';
import styles from './HaProxyLbDashboard.module.less';
import { Col, Row } from 'antd';
import usePrometheusHaProxyBackendCurrentQueuedRequestsStats from '../../../core/hooks/usePrometheusHaProxyBackendCurrentQueuedRequestsStats';
import usePrometheusHaProxyBackendMaxQueuedRequestsStats from '../../../core/hooks/usePrometheusHaProxyBackendMaxQueuedRequestsStats';
import usePrometheusHaProxyBackendServersTotalBytesInStats from '../../../core/hooks/usePrometheusHaProxyBackendServersTotalBytesInStats';
import usePrometheusHaProxyBackendServersTotalBytesOutStats from '../../../core/hooks/usePrometheusHaProxyBackendServersTotalBytesOutStats';
import usePrometheusHaProxyBackendServersUpDownStats from '../../../core/hooks/usePrometheusHaProxyBackendServersUpDownStats';
import usePrometheusHaProxyBackendsUpDownStats from '../../../core/hooks/usePrometheusHaProxyBackendsUpDownStats';
import usePrometheusHaProxyBackendTotalBytesInStats from '../../../core/hooks/usePrometheusHaProxyBackendTotalBytesInStats';
import usePrometheusHaProxyBackendTotalNumberOfConnectionErrorsStats from '../../../core/hooks/usePrometheusHaProxyBackendTotalNumberOfConnectionErrorsStats';
import usePrometheusHaProxyBackendTotalBytesOutStats from '../../../core/hooks/usePrometheusHaProxyBackendTotalBytesOutStats';
import usePrometheusHaProxyCurrentSessionRateBackendsStats from '../../../core/hooks/usePrometheusHaProxyCurrentSessionRateBackendsStats';
import usePrometheusHaProxyCurrentSessionsBackendServersStats from '../../../core/hooks/usePrometheusHaProxyCurrentSessionsBackendServersStats';
import usePrometheusHaProxyCurrentSessionsByBackendStats from '../../../core/hooks/usePrometheusHaProxyCurrentSessionsByBackendStats';
import usePrometheusHaProxyCurrentSessionRateBackendServersStats from '../../../core/hooks/usePrometheusHaProxyCurrentSessionRateBackendServersStats';
import usePrometheusHaProxyMaxSessionBackendServersStats from '../../../core/hooks/usePrometheusHaProxyMaxSessionBackendServersStats';
import usePrometheusHaProxySessionsStats from '../../../core/hooks/usePrometheusHaProxySessionsStats';
import usePrometheusHaProxyTotalInOutStats from '../../../core/hooks/usePrometheusHaProxyTotalInOutStats';
import PrometheusHaProxyBackendsUpDownChart from '../../ccx/charts/PrometheusHaProxyBackendsUpDownChart';
import PrometheusHaProxyBackendServersUpDownChart from '../../ccx/charts/PrometheusHaProxyBackendServersUpDownChart';
import PrometheusHaProxyBackendTotalBytesInChart from '../../ccx/charts/PrometheusHaProxyBackendTotalBytesInChart';
import PrometheusHaProxyTotalInOutChart from '../../ccx/charts/PrometheusHaProxyTotalInOutChart';
import PrometheusHaProxyBackendServersTotalBytesInChart from '../../ccx/charts/PrometheusHaProxyBackendServersTotalBytesInChart';
import PrometheusHaProxyBackendServersTotalBytesOutChart from '../../ccx/charts/PrometheusHaProxyBackendServersTotalBytesOutChart';
import PrometheusHaProxyBackendTotalBytesOutChart from '../../ccx/charts/PrometheusHaProxyBackendTotalBytesOutChart';
import PrometheusHaProxyCurrentSessionsBackendServersChart from '../../ccx/charts/PrometheusHaProxyCurrentSessionsBackendServersChart';
import PrometheusHaProxyCurrentSessionsByBackendChart from '../../ccx/charts/PrometheusHaProxyCurrentSessionsByBackendChart';
import PrometheusHaProxyCurrentSessionRateBackendsChart from '../../ccx/charts/PrometheusHaProxyCurrentSessionRateBackendsChart';
import PrometheusHaProxyCurrentSessionRateBackendServersChart from '../../ccx/charts/PrometheusHaProxyCurrentSessionRateBackendServersChart';
import PrometheusHaProxyMaxSessionBackendServersChart from '../../ccx/charts/PrometheusHaProxyMaxSessionBackendServersChart';
import PrometheusHaProxySessionsChart from '../../ccx/charts/PrometheusHaProxySessionsChart';
import PrometheusHaProxyBackendTotalNumberOfConnectionErrorsChart from '../../ccx/charts/PrometheusHaProxyBackendTotalNumberOfConnectionErrorsChart';
import PrometheusHaProxyBackendMaxQueuedRequestsChart from '../../ccx/charts/PrometheusHaProxyBackendMaxQueuedRequestsChart';
import PrometheusHaProxyBackendCurrentQueuedRequestsChart from '../../ccx/charts/PrometheusHaProxyBackendCurrentQueuedRequestsChart';

type HaProxyLbDashboardProps = {
    uuid: string;
    host_uuid: string;
    port: number;
    from?: string;
    to?: string;
    interval: number;
    hidden?: boolean;
    displaySummary?: boolean;
    onLoading?: Function;
};

function HaProxyLbDashboard({
    uuid,
    host_uuid,
    port,
    from,
    to,
    interval,
    hidden,
    displaySummary,
    onLoading,
}: HaProxyLbDashboardProps): ReactElement {
    const {
        stats: backendCurrentQueuedRequestsStats,
        refresh: backendCurrentQueuedRequestsStatsRefresh,
        loading: backendCurrentQueuedRequestsStatsLoading,
    } = usePrometheusHaProxyBackendCurrentQueuedRequestsStats(
        uuid,
        host_uuid,
        from,
        to
    );

    const {
        stats: backendMaxQueuedRequestsStats,
        refresh: backendMaxQueuedRequestsStatsRefresh,
        loading: backendMaxQueuedRequestsStatsLoading,
    } = usePrometheusHaProxyBackendMaxQueuedRequestsStats(
        uuid,
        host_uuid,
        from,
        to
    );

    const {
        stats: backendServersTotalBytesInStats,
        refresh: backendServersTotalBytesInStatsRefresh,
        loading: backendServersTotalBytesInStatsLoading,
    } = usePrometheusHaProxyBackendServersTotalBytesInStats(
        uuid,
        host_uuid,
        from,
        to
    );

    const {
        stats: backendServersTotalBytesOutStats,
        refresh: backendServersTotalBytesOutStatsRefresh,
        loading: backendServersTotalBytesOutStatsLoading,
    } = usePrometheusHaProxyBackendServersTotalBytesOutStats(
        uuid,
        host_uuid,
        from,
        to
    );

    const {
        stats: backendServersUpDownStats,
        refresh: backendServersUpDownStatsRefresh,
        loading: backendServersUpDownStatsLoading,
    } = usePrometheusHaProxyBackendServersUpDownStats(
        uuid,
        host_uuid,
        from,
        to
    );

    const {
        stats: backendTotalBytesInStats,
        refresh: backendTotalBytesInStatsRefresh,
        loading: backendTotalBytesInStatsLoading,
    } = usePrometheusHaProxyBackendTotalBytesInStats(uuid, host_uuid, from, to);

    const {
        stats: backendTotalBytesOutStats,
        refresh: backendTotalBytesOutStatsRefresh,
        loading: backendTotalBytesOutStatsLoading,
    } = usePrometheusHaProxyBackendTotalBytesOutStats(
        uuid,
        host_uuid,
        from,
        to
    );

    const {
        stats: backendTotalNumberOfConnectionErrorsStats,
        refresh: backendTotalNumberOfConnectionErrorsStatsRefresh,
        loading: backendTotalNumberOfConnectionErrorsStatsLoading,
    } = usePrometheusHaProxyBackendTotalNumberOfConnectionErrorsStats(
        uuid,
        host_uuid,
        from,
        to
    );

    const {
        stats: backendsUpDownStats,
        refresh: backendsUpDownStatsRefresh,
        loading: backendsUpDownStatsLoading,
    } = usePrometheusHaProxyBackendsUpDownStats(uuid, host_uuid, from, to);

    const {
        stats: currentSessionRateBackendServersStats,
        refresh: currentSessionRateBackendServersStatsRefresh,
        loading: currentSessionRateBackendServersStatsLoading,
    } = usePrometheusHaProxyCurrentSessionRateBackendServersStats(
        uuid,
        host_uuid,
        from,
        to
    );

    const {
        stats: currentSessionRateBackendsStats,
        refresh: currentSessionRateBackendsStatsRefresh,
        loading: currentSessionRateBackendsStatsLoading,
    } = usePrometheusHaProxyCurrentSessionRateBackendsStats(
        uuid,
        host_uuid,
        from,
        to
    );

    const {
        stats: currentSessionsBackendServersStats,
        refresh: currentSessionsBackendServersStatsRefresh,
        loading: currentSessionsBackendServersStatsLoading,
    } = usePrometheusHaProxyCurrentSessionsBackendServersStats(
        uuid,
        host_uuid,
        from,
        to
    );

    const {
        stats: currentSessionsByBackendStats,
        refresh: currentSessionsByBackendStatsRefresh,
        loading: currentSessionsByBackendStatsLoading,
    } = usePrometheusHaProxyCurrentSessionsByBackendStats(
        uuid,
        host_uuid,
        from,
        to
    );

    const {
        stats: maxSessionBackendServersStats,
        refresh: maxSessionBackendServersStatsRefresh,
        loading: maxSessionBackendServersStatsLoading,
    } = usePrometheusHaProxyMaxSessionBackendServersStats(
        uuid,
        host_uuid,
        from,
        to
    );

    const {
        stats: sessionsStats,
        refresh: sessionsStatsRefresh,
        loading: sessionsStatsLoading,
    } = usePrometheusHaProxySessionsStats(uuid, host_uuid, from, to);

    const {
        stats: totalInOutStats,
        refresh: totalInOutStatsRefresh,
        loading: totalInOutStatsLoading,
    } = usePrometheusHaProxyTotalInOutStats(uuid, host_uuid, from, to);

    const [
        backendCurrentQueuedRequestsStatsInterval,
        setBackendCurrentQueuedRequestsStatsInterval,
    ] = useState<number | undefined>(undefined);
    const [
        backendMaxQueuedRequestsStatsInterval,
        setBackendMaxQueuedRequestsStatsInterval,
    ] = useState<number | undefined>(undefined);
    const [
        backendServersTotalBytesInStatsInterval,
        setBackendServersTotalBytesInStatsInterval,
    ] = useState<number | undefined>(undefined);
    const [
        backendServersTotalBytesOutStatsInterval,
        setBackendServersTotalBytesOutStatsInterval,
    ] = useState<number | undefined>(undefined);
    const [
        backendServersUpDownStatsInterval,
        setBackendServersUpDownStatsInterval,
    ] = useState<number | undefined>(undefined);
    const [
        backendTotalBytesInStatsInterval,
        setBackendTotalBytesInStatsInterval,
    ] = useState<number | undefined>(undefined);
    const [
        backendTotalBytesOutStatsInterval,
        setBackendTotalBytesOutStatsInterval,
    ] = useState<number | undefined>(undefined);
    const [
        backendTotalNumberOfConnectionErrorsStatsInterval,
        setBackendTotalNumberOfConnectionErrorsStatsInterval,
    ] = useState<number | undefined>(undefined);
    const [backendsUpDownStatsInterval, setBackendsUpDownStatsInterval] =
        useState<number | undefined>(undefined);
    const [
        currentSessionRateBackendServersStatsInterval,
        setCurrentSessionRateBackendServersStatsInterval,
    ] = useState<number | undefined>(undefined);
    const [
        currentSessionRateBackendsStatsInterval,
        setCurrentSessionRateBackendsStatsInterval,
    ] = useState<number | undefined>(undefined);
    const [
        currentSessionsBackendServersStatsInterval,
        setCurrentSessionsBackendServersStatsInterval,
    ] = useState<number | undefined>(undefined);
    const [
        currentSessionsByBackendStatsInterval,
        setCurrentSessionsByBackendStatsInterval,
    ] = useState<number | undefined>(undefined);
    const [
        maxSessionBackendServersStatsInterval,
        setMaxSessionBackendServersStatsInterval,
    ] = useState<number | undefined>(undefined);
    const [sessionsStatsInterval, setSessionsStatsInterval] = useState<
        number | undefined
    >(undefined);
    const [totalInOutStatsInterval, setTotalInOutStatsInterval] = useState<
        number | undefined
    >(undefined);

    const [refreshInterval, setRefreshInterval] = useState(interval);

    useEffect(() => {
        let interval: any;
        if (backendCurrentQueuedRequestsStats && refreshInterval > 0) {
            interval = setInterval(() => {
                backendCurrentQueuedRequestsStatsRefresh();
            }, refreshInterval);
            setBackendCurrentQueuedRequestsStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [backendCurrentQueuedRequestsStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (backendMaxQueuedRequestsStats && refreshInterval > 0) {
            interval = setInterval(() => {
                backendMaxQueuedRequestsStatsRefresh();
            }, refreshInterval);
            setBackendCurrentQueuedRequestsStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [backendMaxQueuedRequestsStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (backendServersTotalBytesInStats && refreshInterval > 0) {
            interval = setInterval(() => {
                backendServersTotalBytesInStatsRefresh();
            }, refreshInterval);
            setBackendMaxQueuedRequestsStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [backendServersTotalBytesInStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (backendServersTotalBytesOutStats && refreshInterval > 0) {
            interval = setInterval(() => {
                backendServersTotalBytesOutStatsRefresh();
            }, refreshInterval);
            setBackendServersTotalBytesInStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [backendServersTotalBytesOutStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (backendServersUpDownStats && refreshInterval > 0) {
            interval = setInterval(() => {
                backendServersUpDownStatsRefresh();
            }, refreshInterval);
            setBackendServersTotalBytesOutStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [backendServersUpDownStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (backendTotalBytesInStats && refreshInterval > 0) {
            interval = setInterval(() => {
                backendTotalBytesInStatsRefresh();
            }, refreshInterval);
            setBackendServersUpDownStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [backendTotalBytesInStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (backendTotalBytesOutStats && refreshInterval > 0) {
            interval = setInterval(() => {
                backendTotalBytesOutStatsRefresh();
            }, refreshInterval);
            setBackendTotalBytesInStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [backendTotalBytesOutStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (backendTotalNumberOfConnectionErrorsStats && refreshInterval > 0) {
            interval = setInterval(() => {
                backendTotalNumberOfConnectionErrorsStatsRefresh();
            }, refreshInterval);
            setBackendTotalBytesOutStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [backendTotalNumberOfConnectionErrorsStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (backendsUpDownStats && refreshInterval > 0) {
            interval = setInterval(() => {
                backendsUpDownStatsRefresh();
            }, refreshInterval);
            setBackendTotalNumberOfConnectionErrorsStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [backendsUpDownStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (currentSessionRateBackendServersStats && refreshInterval > 0) {
            interval = setInterval(() => {
                currentSessionRateBackendServersStatsRefresh();
            }, refreshInterval);
            setBackendsUpDownStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [currentSessionRateBackendServersStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (currentSessionRateBackendsStats && refreshInterval > 0) {
            interval = setInterval(() => {
                currentSessionRateBackendsStatsRefresh();
            }, refreshInterval);
            setCurrentSessionRateBackendServersStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [currentSessionRateBackendsStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (currentSessionsBackendServersStats && refreshInterval > 0) {
            interval = setInterval(() => {
                currentSessionsBackendServersStatsRefresh();
            }, refreshInterval);
            setCurrentSessionsBackendServersStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [currentSessionsBackendServersStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (currentSessionsByBackendStats && refreshInterval > 0) {
            interval = setInterval(() => {
                currentSessionsByBackendStatsRefresh();
            }, refreshInterval);
            setCurrentSessionsByBackendStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [currentSessionsByBackendStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (maxSessionBackendServersStats && refreshInterval > 0) {
            interval = setInterval(() => {
                maxSessionBackendServersStatsRefresh();
            }, refreshInterval);
            setMaxSessionBackendServersStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [maxSessionBackendServersStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (sessionsStats && refreshInterval > 0) {
            interval = setInterval(() => {
                sessionsStatsRefresh();
            }, refreshInterval);
            setSessionsStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [sessionsStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (totalInOutStats && refreshInterval > 0) {
            interval = setInterval(() => {
                totalInOutStatsRefresh();
            }, refreshInterval);
            setTotalInOutStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [totalInOutStats, refreshInterval]);

    useEffect(() => {
        if (interval === 0) {
            // when interval is 0, we must pause the refresh (clear existing intervals)
            clearInterval(backendCurrentQueuedRequestsStatsInterval);
            clearInterval(backendMaxQueuedRequestsStatsInterval);
            clearInterval(backendServersTotalBytesInStatsInterval);
            clearInterval(backendServersTotalBytesOutStatsInterval);
            clearInterval(backendServersUpDownStatsInterval);
            clearInterval(backendTotalBytesInStatsInterval);
            clearInterval(backendTotalBytesOutStatsInterval);
            clearInterval(backendTotalNumberOfConnectionErrorsStatsInterval);
            clearInterval(backendsUpDownStatsInterval);
            clearInterval(currentSessionRateBackendServersStatsInterval);
            clearInterval(currentSessionRateBackendsStatsInterval);
            clearInterval(currentSessionsBackendServersStatsInterval);
            clearInterval(currentSessionsByBackendStatsInterval);
            clearInterval(maxSessionBackendServersStatsInterval);
            clearInterval(sessionsStatsInterval);
            clearInterval(totalInOutStatsInterval);
            setBackendCurrentQueuedRequestsStatsInterval(0);
            setBackendMaxQueuedRequestsStatsInterval(0);
            setBackendServersTotalBytesInStatsInterval(0);
            setBackendServersTotalBytesOutStatsInterval(0);
            setBackendServersUpDownStatsInterval(0);
            setBackendTotalBytesInStatsInterval(0);
            setBackendTotalBytesOutStatsInterval(0);
            setBackendTotalNumberOfConnectionErrorsStatsInterval(0);
            setBackendsUpDownStatsInterval(0);
            setCurrentSessionRateBackendServersStatsInterval(0);
            setCurrentSessionRateBackendsStatsInterval(0);
            setCurrentSessionsBackendServersStatsInterval(0);
            setCurrentSessionsByBackendStatsInterval(0);
            setMaxSessionBackendServersStatsInterval(0);
            setSessionsStatsInterval(0);
            setTotalInOutStatsInterval(0);
        }
        setRefreshInterval(interval);
    }, [interval]);

    useEffect(() => {
        if (hidden) {
            // clear interval to stop updating data if the tab is not active (charts are not visible)
            clearInterval(backendCurrentQueuedRequestsStatsInterval);
            clearInterval(backendMaxQueuedRequestsStatsInterval);
            clearInterval(backendServersTotalBytesInStatsInterval);
            clearInterval(backendServersTotalBytesOutStatsInterval);
            clearInterval(backendServersUpDownStatsInterval);
            clearInterval(backendTotalBytesInStatsInterval);
            clearInterval(backendTotalBytesOutStatsInterval);
            clearInterval(backendTotalNumberOfConnectionErrorsStatsInterval);
            clearInterval(backendsUpDownStatsInterval);
            clearInterval(currentSessionRateBackendServersStatsInterval);
            clearInterval(currentSessionRateBackendsStatsInterval);
            clearInterval(currentSessionsBackendServersStatsInterval);
            clearInterval(currentSessionsByBackendStatsInterval);
            clearInterval(maxSessionBackendServersStatsInterval);
            clearInterval(sessionsStatsInterval);
            clearInterval(totalInOutStatsInterval);
            setBackendCurrentQueuedRequestsStatsInterval(0);
            setBackendMaxQueuedRequestsStatsInterval(0);
            setBackendServersTotalBytesInStatsInterval(0);
            setBackendServersTotalBytesOutStatsInterval(0);
            setBackendServersUpDownStatsInterval(0);
            setBackendTotalBytesInStatsInterval(0);
            setBackendTotalBytesOutStatsInterval(0);
            setBackendTotalNumberOfConnectionErrorsStatsInterval(0);
            setBackendsUpDownStatsInterval(0);
            setCurrentSessionRateBackendServersStatsInterval(0);
            setCurrentSessionRateBackendsStatsInterval(0);
            setCurrentSessionsBackendServersStatsInterval(0);
            setCurrentSessionsByBackendStatsInterval(0);
            setMaxSessionBackendServersStatsInterval(0);
            setSessionsStatsInterval(0);
            setTotalInOutStatsInterval(0);
        } else if (
            backendCurrentQueuedRequestsStatsInterval === 0 ||
            backendMaxQueuedRequestsStatsInterval === 0 ||
            backendServersTotalBytesInStatsInterval === 0 ||
            backendServersTotalBytesOutStatsInterval === 0 ||
            backendServersUpDownStatsInterval === 0 ||
            backendTotalBytesInStatsInterval === 0 ||
            backendTotalBytesOutStatsInterval === 0 ||
            backendTotalNumberOfConnectionErrorsStatsInterval === 0 ||
            backendsUpDownStatsInterval === 0 ||
            currentSessionRateBackendServersStatsInterval === 0 ||
            currentSessionRateBackendsStatsInterval === 0 ||
            currentSessionsBackendServersStatsInterval === 0 ||
            currentSessionsByBackendStatsInterval === 0 ||
            maxSessionBackendServersStatsInterval === 0 ||
            sessionsStatsInterval === 0 ||
            totalInOutStatsInterval === 0
        ) {
            // force refresh if the interval is 0, otherwise, the interval will refresh data soon
            // interval is undefined on the first load
            // interval will be 0 when paused or tab is hidden
            backendCurrentQueuedRequestsStatsRefresh();
            backendMaxQueuedRequestsStatsRefresh();
            backendServersTotalBytesInStatsRefresh();
            backendServersTotalBytesOutStatsRefresh();
            backendServersUpDownStatsRefresh();
            backendTotalBytesInStatsRefresh();
            backendTotalBytesOutStatsRefresh();
            backendTotalNumberOfConnectionErrorsStatsRefresh();
            backendsUpDownStatsRefresh();
            currentSessionRateBackendServersStatsRefresh();
            currentSessionRateBackendsStatsRefresh();
            currentSessionsBackendServersStatsRefresh();
            currentSessionsByBackendStatsRefresh();
            maxSessionBackendServersStatsRefresh();
            sessionsStatsRefresh();
            totalInOutStatsRefresh();
        }
    }, [hidden]);

    useEffect(() => {
        if (
            backendCurrentQueuedRequestsStatsLoading ||
            backendMaxQueuedRequestsStatsLoading ||
            backendServersTotalBytesInStatsLoading ||
            backendServersTotalBytesOutStatsLoading ||
            backendServersUpDownStatsLoading ||
            backendTotalBytesInStatsLoading ||
            backendTotalBytesOutStatsLoading ||
            backendTotalNumberOfConnectionErrorsStatsLoading ||
            backendsUpDownStatsLoading ||
            currentSessionRateBackendServersStatsLoading ||
            currentSessionRateBackendsStatsLoading ||
            currentSessionsBackendServersStatsLoading ||
            currentSessionsByBackendStatsLoading ||
            maxSessionBackendServersStatsLoading ||
            sessionsStatsLoading ||
            totalInOutStatsLoading
        ) {
            onLoading && onLoading(true);
        } else {
            onLoading && onLoading(false);
        }
    }, [
        backendCurrentQueuedRequestsStatsLoading,
        backendMaxQueuedRequestsStatsLoading,
        backendServersTotalBytesInStatsLoading,
        backendServersTotalBytesOutStatsLoading,
        backendServersUpDownStatsLoading,
        backendTotalBytesInStatsLoading,
        backendTotalBytesOutStatsLoading,
        backendTotalNumberOfConnectionErrorsStatsLoading,
        backendsUpDownStatsLoading,
        currentSessionRateBackendServersStatsLoading,
        currentSessionRateBackendsStatsLoading,
        currentSessionsBackendServersStatsLoading,
        currentSessionsByBackendStatsLoading,
        maxSessionBackendServersStatsLoading,
        sessionsStatsLoading,
        totalInOutStatsLoading,
    ]);

    return (
        <section className={styles.HaProxyLbDashboard}>
            <Row
                gutter={[
                    32,
                    { xs: 8, sm: 16, md: 24, lg: 32, xl: 40, xxl: 48 },
                ]}
                className={styles.HaProxyLbDashboardRow}
            >
                <Col
                    className={styles.HaProxyLbDashboardCol}
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={8}
                >
                    <PrometheusHaProxyBackendsUpDownChart
                        data={backendsUpDownStats}
                        loading={backendsUpDownStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.HaProxyLbDashboardCol}
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={8}
                >
                    <PrometheusHaProxyBackendServersUpDownChart
                        data={backendServersUpDownStats}
                        loading={backendServersUpDownStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.HaProxyLbDashboardCol}
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                >
                    <PrometheusHaProxyTotalInOutChart
                        data={totalInOutStats}
                        loading={totalInOutStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.HaProxyLbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusHaProxyBackendTotalBytesInChart
                        data={backendTotalBytesInStats}
                        loading={backendTotalBytesInStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.HaProxyLbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusHaProxyBackendTotalBytesOutChart
                        data={backendTotalBytesOutStats}
                        loading={backendTotalBytesOutStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.HaProxyLbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusHaProxyBackendServersTotalBytesInChart
                        data={backendServersTotalBytesInStats}
                        loading={backendServersTotalBytesInStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.HaProxyLbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusHaProxyBackendServersTotalBytesOutChart
                        data={backendServersTotalBytesOutStats}
                        loading={backendServersTotalBytesOutStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.HaProxyLbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusHaProxyCurrentSessionsByBackendChart
                        data={currentSessionsByBackendStats}
                        loading={currentSessionsByBackendStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.HaProxyLbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusHaProxyCurrentSessionRateBackendsChart
                        data={currentSessionRateBackendsStats}
                        loading={currentSessionRateBackendsStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.HaProxyLbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusHaProxyCurrentSessionsBackendServersChart
                        data={currentSessionsBackendServersStats}
                        loading={currentSessionsBackendServersStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.HaProxyLbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusHaProxyCurrentSessionRateBackendServersChart
                        data={currentSessionRateBackendServersStats}
                        loading={currentSessionRateBackendServersStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.HaProxyLbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusHaProxyMaxSessionBackendServersChart
                        data={maxSessionBackendServersStats}
                        loading={maxSessionBackendServersStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.HaProxyLbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusHaProxySessionsChart
                        data={sessionsStats}
                        loading={sessionsStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.HaProxyLbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusHaProxyBackendTotalNumberOfConnectionErrorsChart
                        data={backendTotalNumberOfConnectionErrorsStats}
                        loading={
                            backendTotalNumberOfConnectionErrorsStatsLoading
                        }
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.HaProxyLbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusHaProxyBackendMaxQueuedRequestsChart
                        data={backendMaxQueuedRequestsStats}
                        loading={backendMaxQueuedRequestsStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.HaProxyLbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusHaProxyBackendCurrentQueuedRequestsChart
                        data={backendCurrentQueuedRequestsStats}
                        loading={backendCurrentQueuedRequestsStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
            </Row>
        </section>
    );
}

export default HaProxyLbDashboard;
