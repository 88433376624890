export type InstanceVolumeSizeClassProps = {
    default: number;
    max: number;
    min: number;
    unit: string;
};

export interface InstanceVolumeSizeInterface {
    default: number;
    max: number;
    min: number;
    unit: string;
}

export default class InstanceVolumeSize implements InstanceVolumeSizeInterface {
    readonly default: number;
    readonly max: number;
    readonly min: number;
    readonly unit: string;

    constructor(props: InstanceVolumeSizeClassProps) {
        this.default = props.default;
        this.max = props.max;
        this.min = props.min;
        this.unit = props.unit;
    }
}
