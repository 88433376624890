import React, { ReactElement } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import AppIcon from '../../AppIcon';
import styles from './CcxLogo.module.less';

interface Props extends CcxComponentProps {
    logo: any;
    size?: string;
}

function CcxLogo({ className, logo, size, testId }: Props): ReactElement {
    const cssSize = size || 'xlarge';
    return (
        <div
            className={className || styles.CcxLogo}
            data-testid={testId || 'CcxLogo'}
        >
            <div className={styles.CcxLogoIconBackground}></div>
            <AppIcon size={cssSize} icon={logo} />
        </div>
    );
}

export default CcxLogo;
