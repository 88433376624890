export type PrometheusMySqlHandlersStatsBackendApiProps = {
    created: number;
    handlers_read_rnd: number;
    handlers_read_rnd_next: number;
    handlers_read_next: number;
    handlers_read_key: number;
    handlers_read_first: number;
    handlers_read_last: number;
    handlers_delete: number;
    handlers_update: number;
    handlers_write: number;
};

export default class PrometheusMySqlHandlersStats {
    readonly created: number;
    readonly handlersReadRnd: number;
    readonly handlersReadRndNext: number;
    readonly handlersReadNext: number;
    readonly handlersReadKey: number;
    readonly handlersReadFirst: number;
    readonly handlersReadLast: number;
    readonly handlersDelete: number;
    readonly handlersUpdate: number;
    readonly handlersWrite: number;

    constructor(props: PrometheusMySqlHandlersStatsBackendApiProps) {
        this.created = props.created;
        this.handlersReadRnd = props.handlers_read_rnd;
        this.handlersReadRndNext = props.handlers_read_rnd_next;
        this.handlersReadNext = props.handlers_read_next;
        this.handlersReadKey = props.handlers_read_key;
        this.handlersReadFirst = props.handlers_read_first;
        this.handlersReadLast = props.handlers_read_last;
        this.handlersDelete = props.handlers_delete;
        this.handlersUpdate = props.handlers_update;
        this.handlersWrite = props.handlers_write;
    }
}
