import React, { ReactElement } from 'react';
import { useHover } from '../core/hooks/useHover';
import styles from './Hoverable.module.less';

export type HoverableProps = {
    icon?: any;
    children?: any;
};

function Hoverable({ icon, children }: HoverableProps): ReactElement {
    const [hoverRef, isHovered] = useHover<HTMLDivElement>();

    return (
        <div ref={hoverRef}>
            {children}
            {isHovered ? (
                <span className={styles.HoverableIcon}>{icon}</span>
            ) : null}
        </div>
    );
}

export default Hoverable;
