import { Form, Select } from 'antd';
import AppFormEmailTag from '../AppFormEmailTag';

interface AppEmailsNotificationProps {
    readonly label: string;
    readonly emailsList: string[] | undefined;
    readonly saveEmailNotifications: Function;
}

const AppEmailsNotification = ({
    label,
    emailsList,
    saveEmailNotifications,
}: AppEmailsNotificationProps) => {
    return (
        <Form.Item
            label={label}
            name="notificationRecipients"
            rules={[
                () => ({
                    validator(_, value) {
                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        const isValidEmail = value.every((email: string) => {
                            return emailRegex.test(email);
                        });
                        if (!isValidEmail) {
                            return Promise.reject(
                                new Error(
                                    'All entries must be valid email addresses'
                                )
                            );
                        }
                        return Promise.resolve();
                    },
                }),
            ]}
            wrapperCol={{ span: 24 }}
            extra={
                'Use semicolon (;) to separate email addresses, remove all addresses to disable'
            }
        >
            <Select
                mode="tags"
                open={false}
                placeholder="Enter recipient's email address"
                showSearch={false}
                tagRender={(props: any) => <AppFormEmailTag {...props} />}
                value={emailsList}
                tokenSeparators={[';']}
                maxTagCount={5}
                onChange={(value: string[]) => saveEmailNotifications(value)}
            />
        </Form.Item>
    );
};

export default AppEmailsNotification;
