export type PrometheusRedisConnectedClientsStatsBackendApiProps = {
    created: number;
    connected_clients: number;
};

export default class PrometheusRedisConnectedClientsStats {
    readonly created: number;
    readonly connectedClients: number;

    constructor(props: PrometheusRedisConnectedClientsStatsBackendApiProps) {
        this.created = props.created;
        this.connectedClients = props.connected_clients;
    }
}
