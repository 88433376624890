import React from 'react';
import { MessageOutlined } from '@ant-design/icons';
import CcxComponentProps from '../../../core/CcxComponent';

interface Props extends CcxComponentProps {}

const CcxIconMessageOutlined = ({
    testId = 'CcxIconMessageOutlined',
}: Props) => {
    return <MessageOutlined data-testid={testId} />;
};

export default CcxIconMessageOutlined;
