export type PrometheusMySqlAbortedConnectionsStatsBackendApiProps = {
    created: number;
    instance: string;
    aborted_connects: number;
    aborted_clients: number;
    access_denied_errors: number;
};

export default class PrometheusMySqlAbortedConnectionsStats {
    readonly created: number;
    readonly instance: string;
    readonly abortedConnects: number;
    readonly abortedClients: number;
    readonly accessDeniedErrors: number;

    constructor(props: PrometheusMySqlAbortedConnectionsStatsBackendApiProps) {
        this.created = props.created;
        this.instance = props.instance;
        this.abortedConnects = props.aborted_connects;
        this.abortedClients = props.aborted_clients;
        this.accessDeniedErrors = props.access_denied_errors;
    }
}
