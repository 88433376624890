import { Col, Radio, RadioGroupProps, Row, Tooltip } from 'antd';
import React, { ReactElement } from 'react';
import { RadioButtonProps } from 'antd/lib/radio/radioButton';
import { TooltipProps } from 'antd/lib/tooltip';
import classNames from 'classnames';
import styles from './AppRadioGroupGrid.module.less';

type AppRadioGroupGridProps = RadioGroupProps & {
    items: ReactElement<AppRadioGridButtonProps>[];
};
export default function AppRadioGroupGrid({
    items,
    ...rest
}: AppRadioGroupGridProps) {
    return (
        <Radio.Group className={styles.AppRadioGroupGrid} {...rest}>
            <Row>
                {items.map((item) => (
                    <Col xs={20} sm={12} lg={8} key={`${item.key}`}>
                        {item}
                    </Col>
                ))}
            </Row>
        </Radio.Group>
    );
}

type AppRadioGridButtonProps = RadioButtonProps & {
    tooltip?: TooltipProps['title'];
    size?: 'large';
};

export function AppRadioGridButton({
    children,
    tooltip,
    size,
    ...rest
}: AppRadioGridButtonProps) {
    let button = <Radio.Button {...rest}>{children}</Radio.Button>;
    if (tooltip) {
        button = <Tooltip title={tooltip}>{button}</Tooltip>;
    }

    return (
        <div
            className={classNames(styles.AppRadioGridButton, {
                [styles.AppRadioGridButtonLarge]: size === 'large',
            })}
        >
            {button}
        </div>
    );
}
