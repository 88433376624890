import React, { ReactElement } from 'react';
import { Select } from 'antd';
import styles from './AppForm.module.less';
import CcxComponentProps from '../../../core/CcxComponent';

interface Props extends CcxComponentProps {
    field: any; // @TODO: define a AppFormFlagSelectField interface
    onPressEnter?: Function;
    onChange?: Function;
}

function AppFormFlagSelect({
    field,
    onChange,
    onPressEnter,
    testId = 'AppFormFlagSelect',
}: Props): ReactElement {
    return (
        <div data-testid={testId}>
            <Select
                showSearch
                placeholder={field.placeholder}
                optionFilterProp="children"
                className={styles.AppFormInput}
                disabled={field.disabled}
                filterOption={(input: any, option: any) =>
                    option?.props?.children?.props?.children[1]
                        .toLowerCase()
                        .indexOf(input?.toLowerCase()) >= 0
                }
                onChange={field.onChange}
                data-testid={`${testId}Select`}
            >
                {field.options.map((o: any, i: number) => {
                    return (
                        <Select.Option
                            data-testid={`${testId}SelectOption${i}`}
                            key={o.value}
                            value={o.value}
                        >
                            {o.label}
                        </Select.Option>
                    );
                })}
            </Select>
        </div>
    );
}

export default AppFormFlagSelect;
