import React, { ReactElement } from 'react';
import { Alert, Button, Col, Result, Row } from 'antd';
import { useHistory } from 'react-router-dom';

type Error500Props = {
    error: Error;
    onHomeClick: () => void;
};

/**
 * Error 404 Page
 */
function Error500({ error, onHomeClick }: Error500Props): ReactElement {
    const SubTitle = (
        <Row>
            <Col span={12} offset={6}>
                <Alert
                    message="Oops! There was an error"
                    description={<pre>{error.message}</pre>}
                    type="error"
                    showIcon
                />
            </Col>
        </Row>
    );

    return (
        <Result
            status="500"
            title="500"
            subTitle={SubTitle}
            extra={
                <Button type="primary" onClick={onHomeClick}>
                    Back Home
                </Button>
            }
        />
    );
}

export default Error500;
