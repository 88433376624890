import React from 'react';
import moment from 'moment';
import { DbGrowthChartInterface, GrowthDbs } from '../../types/DbGrowth';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Row, Table, Tooltip, Typography } from 'antd';
import styles from './DatabaseGrowthTable.module.less';
import numbro from 'numbro';

interface DatabaseGrowthTableProps {
    activeDate: moment.Moment;
    dataSource: GrowthDbs[] | undefined;
}

const headingDateFormat = 'MMM DD YYYY';

function DatabaseGrowthTable({
    activeDate,
    dataSource,
}: DatabaseGrowthTableProps) {
    const renderValue = (value: number) => {
        return numbro(value).format({
            output: 'byte',
            base: 'binary',
            spaceSeparated: true,
            mantissa: 1,
        });
    };

    const databaseColumns = [
        {
            title: <strong>Name</strong>,
            dataIndex: 'dbName',
            key: 'dbName',
        },
        {
            title: <strong>Tables</strong>,
            dataIndex: 'tableCount',
            key: 'tableCount',
        },
        {
            title: <strong>Data Size</strong>,
            dataIndex: 'dataSize',
            key: 'dataSize',
            render: renderValue,
        },
        {
            title: <strong>Index Size</strong>,
            dataIndex: 'indexSize',
            key: 'indexSize',
            render: renderValue,
        },
        {
            title: <strong>Row Count</strong>,
            dataIndex: 'rowCount',
            key: 'rowCount',
        },
    ];
    return (
        <>
            {' '}
            <Row className={styles.DatabasesGrowthTable}>
                <Col span={24}>
                    <Typography.Title level={5}>
                        Top Largest Databases -{' '}
                        {moment(activeDate).format(headingDateFormat)}
                        {` `}
                        {dataSource && dataSource?.length > 1 && (
                            <Tooltip
                                placement="top"
                                title={`Displaying the top ${dataSource?.length} largest databases for selected date.`}
                            >
                                <InfoCircleOutlined />
                            </Tooltip>
                        )}
                    </Typography.Title>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table columns={databaseColumns} dataSource={dataSource} />
                </Col>
            </Row>
        </>
    );
}

export default DatabaseGrowthTable;
