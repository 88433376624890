import React from 'react';
import DeploymentsItem from '../../../../types/DeploymentsItem';
import Service from '../../../../types/Service';
import ServiceDSNCopyButton from './ServiceDSNCopyButton';
import { Space } from 'antd';
import TypographyText from '@severalnines/bar-frontend-components/build/lib/General/TypographyText';
import styles from './ServiceInstanceFormat.module.less';

export default ServiceInstanceFormat;
export type ServiceInstanceFormatProps = {
    instance: string;
    dataStore?: DeploymentsItem;
    service?: Service;
    type?: string;
};

function ServiceInstanceFormat({
    instance,
    dataStore,
    service,
    type,
    ...rest
}: ServiceInstanceFormatProps) {
    const formatInstanceName = (instance: string) => {
        const instanceName = instance.split(':')[0];
        const port =
            instance.split(':').length === 2 ? instance.split(':')[1] : '';
        const ipRegex = /^([0-9]{1,3}\.){3}[0-9]{1,3}$/;
        if (ipRegex.test(instanceName)) {
            const formattedInstance = `${instanceName}${
                port ? `:${port}` : ''
            }`;
            return formattedInstance;
        } else {
            const instanceFqdn = instanceName.split('.')[0];
            const formattedInstance = `${instanceFqdn}${
                port ? `:${port}` : ''
            }`;
            return formattedInstance;
        }
    };

    return (
        <Space className={styles.ServiceInstanceFormat} size={0}>
            <span>
                <TypographyText
                    className={styles.ServiceInstanceFormat_instance}
                    ellipsis={{
                        tooltip: formatInstanceName(instance),
                    }}
                >
                    {formatInstanceName(instance)}
                </TypographyText>
            </span>
            {dataStore && service && (
                <ServiceDSNCopyButton dataStore={dataStore} service={service} />
            )}
        </Space>
    );
}
