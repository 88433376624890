export type JsonErrorProps = {
    error: string;
    code: number;
};

export default class JsonError extends Error {
    public readonly code: number;

    constructor({ error, code }: JsonErrorProps) {
        super(error || 'Unknown json error');
        this.code = code || 0;
    }
}
