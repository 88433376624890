import React, { ReactElement } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import AppCdnSvg from './AppCdnSvg';
import styles from './AppCdnIcon.module.less';

interface Props extends CcxComponentProps {
    height?: number;
    icon: string;
    size: string | number;
    width?: number;
}

function AppCdnIcon({
    className,
    height,
    icon,
    size,
    testId,
    width,
}: Props): ReactElement {
    return (
        <AppCdnSvg
            className={className || styles.AppCdnIcon}
            filename={`icons/${icon}`}
            height={height}
            size={size || 'xlarge'}
            testId={testId || 'AppCdnIcon'}
            width={width}
        />
    );
}

export default AppCdnIcon;
