import { useEffect, useState } from 'react';
import BillingService from '../../services/BillingService';
import Coupon from '../../types/Coupon';

export default function useCoupons() {
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [coupon, setCoupon] = useState<Coupon | undefined>(undefined);

    const load = async () => {
        try {
            const details = await BillingService.getCoupons();

            setCoupon(details.coupon);
            setError(null);
        } catch (e: any) {
            setError(e);
        }
    };

    const refresh = () => {
        setUpdating(true);
        load();
        setUpdating(false);
    };

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            await load();
            setLoading(false);
        };

        init();
    }, []);

    return { coupon, refresh, loading, updating, error };
}
