export type PrometheusRedisBlockedClientsStatsBackendApiProps = {
    created: number;
    blocked_clients: number;
};

export default class PrometheusRedisBlockedClientsStats {
    readonly created: number;
    readonly blockedClients: number;

    constructor(props: PrometheusRedisBlockedClientsStatsBackendApiProps) {
        this.created = props.created;
        this.blockedClients = props.blocked_clients;
    }
}
