import { FilePdfOutlined, FileTextOutlined } from '@ant-design/icons';
import React, { ReactElement, useEffect, useState } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import { PricingLink } from '../../../core/CcxEnv';
import useInvoices from '../../../core/hooks/useInvoices';
import useNextInvoice from '../../../core/hooks/useNextInvoice';
import Invoice from '../../../types/Invoice';
import AppTable from '../../AppTable';
import AppGridTable from '../../ccx/common/AppGridTable';
import IconLabelStatusError from '../../ccx/common/IconLabelStatusError';
import IconLabelStatusOk from '../../ccx/common/IconLabelStatusOk';
import PrettyNumber from '../../ccx/common/PrettyNumber';
import LazyLoader from '../../LazyLoader';
import ChangePaymentMethod from './ChangePaymentMethod';
import CurrentBalance from './CurrentBalance';
import CurrentBillingPeriod from './CurrentBillingPeriod';
import PaymentMethod from './PaymentMethod';
import styles from './PaymentsInvoices.module.less';

interface Props extends CcxComponentProps {}

function PaymentsInvoices({
    testId = 'PaymentsInvoices',
}: Props): ReactElement {
    const [tableData, setTableData] = useState<any[]>([]);
    const [currentBalance, setCurrentBalance] = useState<number>(0);
    const [currentBillingPeriod, setCurrentBillingPeriod] = useState<any>({});
    const [paymentMethod, setPaymentMethod] = useState<any>({});

    const { invoices, loading } = useInvoices();
    const { nextInvoice, loading: loadingNext } = useNextInvoice();

    const formatDate = (date: Date) => {
        const dd = `0${date.getDate()}`.slice(-2);
        const mm = `0${date.getMonth() + 1}`.slice(-2);
        const yy = date.getFullYear().toString().substr(-2);

        return `${dd}/${mm}/${yy}`;
    };

    useEffect(() => {
        if (invoices && invoices.length > 0) {
            const data = invoices.map((invoice: Invoice) => {
                return {
                    ...invoice,
                    paymentMethod: invoice.getPaymentMethodString(),
                    status: invoice.getStatus(),
                    amount: invoice.amount / 100,
                    isStatusOk: invoice.isStatusOk,
                };
            });
            setTableData(data);

            if (nextInvoice) {
                const lastInvoice: Invoice = invoices[0];
                setCurrentBalance(nextInvoice.amount / 100);

                setCurrentBillingPeriod({
                    from: formatDate(new Date(lastInvoice.date)),
                    to: formatDate(new Date(nextInvoice.date)),
                });

                setPaymentMethod({
                    brand: lastInvoice?.paymentMethod?.card?.brand,
                    last4: lastInvoice?.paymentMethod?.card?.last4,
                });
            }
        }
    }, [invoices, nextInvoice]);

    const columns: any[] = [];
    const rows: any[] = [
        {
            borderAlert: false,
            statusOverlayText: '',
            columns: [
                {
                    align: 'center',
                    content: (
                        <CurrentBalance
                            currentBalance={currentBalance}
                            suffix={nextInvoice?.currencySuffix}
                        />
                    ),
                    span: 7,
                },
                {
                    align: 'center',
                    content: (
                        <CurrentBillingPeriod
                            currentBillingPeriod={currentBillingPeriod}
                        />
                    ),
                    span: 5,
                },
                {
                    align: 'center',
                    content: <PaymentMethod paymentMethod={paymentMethod} />,
                    span: 5,
                },
                {
                    align: 'center',
                    content: <ChangePaymentMethod />,
                    span: 5,
                },
            ],
            disabled: false,
        },
    ];

    let StatusComponent = null;

    const tableColumns = [
        {
            title: 'Invoice number',
            dataIndex: 'invoiceNumber',
            key: 'invoiceNumber',
        },
        {
            title: 'Payment method',
            key: 'paymentMethod',
            render: (value: string, record: any) => {
                return record.paymentMethod.toUpperCase();
            },
        },
        {
            title: 'Status',
            key: 'status',
            render: (value: string, record: Invoice) => {
                if (record.isStatusOk()) {
                    StatusComponent = (
                        <div className={styles.PaymentsInvoicesStatus}>
                            <div
                                className={
                                    styles.PaymentsInvoicesStatusDotGreen
                                }
                            ></div>
                            <IconLabelStatusOk label={record?.status} />
                        </div>
                    );
                } else {
                    StatusComponent = (
                        <div className={styles.PaymentsInvoicesStatus}>
                            <div
                                className={styles.PaymentsInvoicesStatusDotRed}
                            ></div>
                            <IconLabelStatusError label={record?.status} />
                        </div>
                    );
                }
                return StatusComponent;
            },
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            sorter: (a: any, b: any) =>
                new Date(a.date).getTime() - new Date(b.date).getTime(),
        },
        {
            title: 'Amount',
            key: 'amount',
            render: (value: string, record: any) => {
                return (
                    <PrettyNumber
                        precision={2}
                        prefix={nextInvoice?.currencyPrefix}
                        value={record.amount}
                    />
                );
            },
        },
        {
            title: 'Download as PDF',
            key: 'pdf',
            render: (value: string, record: any) => {
                return (
                    <a href={record.pdf} download target="_self">
                        Download{` `} <FilePdfOutlined />
                    </a>
                );
            },
        },
    ];

    return (
        <section data-testid={`${testId}`}>
            {loading || loadingNext ? (
                <LazyLoader type="row" testId={`${testId}Loader`} />
            ) : (
                <>
                    <div
                        data-testid={`${testId}SubscriptionsHeading`}
                        className={styles.PaymentsInvoicesHeading}
                    >
                        <h1 data-testid={`${testId}SubscriptionsTitle`}>
                            <strong>Subscriptions</strong>
                        </h1>

                        <a
                            href={PricingLink}
                            download
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FileTextOutlined /> View pricing plans
                        </a>
                    </div>

                    <AppGridTable
                        columns={columns}
                        rows={rows}
                        testId={`${testId}GridTable`}
                    />

                    <h1
                        data-testid={`${testId}PaymentHistoryTitle`}
                        className={styles.PaymentsInvoicesPaymentHistory}
                    >
                        <strong>Payment history</strong>
                    </h1>
                    <AppTable
                        columns={tableColumns}
                        testId={`${testId}Table`}
                        data={tableData}
                        rowKey="subID"
                        expandable={false}
                        pagination={{
                            hideOnSinglePage: true,
                            pageSize: 5,
                        }}
                    />
                </>
            )}
        </section>
    );
}

export default PaymentsInvoices;
