export type DatabaseVendorTypeClassProps = {
    code: string;
    name: string;
    size_hints: any;
};

export interface DatabaseVendorTypeInterface {
    code: string;
    name: string;
    sizeHints: any;
    getSizeHintsByNumber: Function;
}

export default class DatabaseVendorType implements DatabaseVendorTypeInterface {
    readonly code: string;
    readonly name: string;
    readonly sizeHints: any;

    constructor(props: DatabaseVendorTypeClassProps) {
        this.code = props.code;
        this.name = props.name;
        this.sizeHints = props.size_hints;
    }

    getSizeHintsByNumber(value: number): string {
        return this.sizeHints ? this.sizeHints[value] || undefined : undefined;
    }

    getSizeHintsName(value: number): string {
        if (this.sizeHints[value]?.name?.includes('master')) {
            return this.sizeHints[value]?.name?.replaceAll('master', 'primary');
        } else
            return this.sizeHints
                ? this.sizeHints[value]?.name || undefined
                : undefined;
    }

    getSizeHintsInfoByNumber(value: number): string {
        return this.sizeHints
            ? this.sizeHints[value]?.info || undefined
            : undefined;
    }

    getRecommendedConfiguration(): boolean {
        return [
            'redis',
            'postgres_streaming',
            'replication',
            'mssql_single',
        ].includes(this.code);
    }
}
