import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

export type FriendlyDateTimeClassProps = {
    date: string;
};

export interface FriendlyDateTimeInterface {
    date?: Date;
}

export default class FriendlyDateTime implements FriendlyDateTimeInterface {
    readonly date?: Date;

    constructor(props: FriendlyDateTimeClassProps) {
        this.date = props.date ? new Date(props.date) : undefined;
    }

    getFriendlyDate(): string {
        return this.date ? dayjs(this.date).fromNow() : '---';
    }

    getFullDate(): string {
        return this.date
            ? dayjs(this.date).format('YYYY-MM-DD HH:mm:ss ZZ')
            : '---';
    }

    getDate(): string {
        return this.date
            ? dayjs(this.date).format('YYYY-MM-DD HH:mm:ss')
            : '---';
    }

    getTime(): string {
        return this.date ? dayjs(this.date).format('HH:mm:ss') : '---';
    }
}
