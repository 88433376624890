import ApiService from './ApiService';
import FirewallRule from '../types/FirewallRule';
import { API_FIREWALL } from '../core/CcxEnv';

// constants
const API_URL = API_FIREWALL;

export type AddRuleProps = {
    uuid: string;
    source: string;
    description?: string;
};

export type DeleteRuleProps = {
    rule: any;
    uuid: string;
};

type DeleteAllRulesInterface = {
    source: string;
    description: string;
};

export type DeleteAllRulesProps = {
    rule: DeleteAllRulesInterface;
    uuid: string;
};

export default class FirewallService extends ApiService {
    /**
     * Get list of all firewall rules.
     * @param {string} uuid
     */
    static async getAllRules(uuid: string): Promise<FirewallRule[]> {
        const response = await this.request('GET', `firewalls/${uuid}`);
        return response.map((r: any) => new FirewallRule(r));
    }

    /**
     * Add a Firewall rule
     * @param {AddRuleProps} props
     */
    static async addRule({
        uuid,
        source,
        description,
    }: AddRuleProps): Promise<any> {
        const postData = {
            data: {
                source,
                description,
            },
        };
        return await this.request('POST', `firewall/${uuid}`, postData);
    }

    /**
     * Delete a Firewall rule
     * @param {FirewallRule} rule
     * @param {string} uuid
     */
    static async deleteRule({ rule, uuid }: DeleteRuleProps): Promise<any> {
        const requestData = {
            data: {
                description: rule?.description,
                port: rule?.port,
                source: rule?.source,
            },
        };
        return await this.request('DELETE', `firewall/${uuid}`, requestData);
    }

    static async deleteAllRule({
        rule,
        uuid,
    }: DeleteAllRulesProps): Promise<any> {
        const requestData = {
            data: {
                description: rule?.description,
                source: rule?.source,
            },
        };
        return await this.request('DELETE', `firewall/${uuid}`, requestData);
    }

    protected static getApiUrl() {
        return API_URL;
    }
}
