import ApiService from './ApiService';
import Vpc from '../types/Vpc';
import VpcAwsAvailabilityZone, {
    VpcAwsAvailabilityZoneClassProps,
} from '../types/VpcAwsAvailabilityZone';
import VpcAwsSubnet, { VpcAwsSubnetClassProps } from '../types/VpcAwsSubnet';
import { API_VPC, API_VPC_V2 } from '../core/CcxEnv';
import VpcPeering from '../types/VpcPeering';
import {
    vpcApiInstance,
    VpcDTO,
    vpcPeeringApiInstance,
    VpcPeeringDTO,
} from '../sdk/VpcApi';

type GetAllAvailabilityZoneParams = {
    cloud: string;
    region: string;
};

type GetAllVpcParams = {
    cloud: string;
    region: string;
};

type GetVpcParams = {
    cloud: string;
    region: string;
    vpcUuid: string;
};

export type CreateVpcParams = {
    cidrIpv4Block: string;
    cloud: string;
    region: string;
    vpcName: string;
};

type DeleteVpcParams = {
    vpcUuid: string;
};

type GetAllSubnetParams = {
    cloud: string;
    region: string;
    vpcId: string;
};

type CreateSubnetParams = {
    cloud: string;
    region: string;
    vpcId: string;
    availabilityZone: string;
    cidrBlock: string;
};

type DeleteSubnetParams = {
    cloud: string;
    region: string;
    vpcId: string;
    subnetId: string;
};

type GetAllPeerParams = {
    cloud: string;
    region: string;
};

export type CreatePeerParams = {
    accepterCidr: string;
    accepterOwnerId: string;
    accepterRegion: string;
    accepterVpcId: string;
    cloud?: string;
    region?: string;
    vpcUuid: string;
};

type DeletePeeringParams = {
    vpcId: string;
    cloud?: string;
    region?: string;
    peeringId: string;
};

export default class VpcService extends ApiService {
    /**
     * Get list of AZ.
     * @param {string} cloud
     * @param {string} region
     */
    static async getAllAvailabilityZone({
        cloud,
        region,
    }: GetAllAvailabilityZoneParams): Promise<VpcAwsAvailabilityZone[]> {
        const response = await this.request('GET', `${cloud}/${region}/azs`);
        return response.map(
            (az: VpcAwsAvailabilityZoneClassProps) =>
                new VpcAwsAvailabilityZone(az)
        );
    }

    /**
     * Get list of VPCs.
     * @param {string} cloud
     * @param {string} region
     */
    static async getAllVpc({
        cloud,
        region,
    }: GetAllVpcParams): Promise<Vpc[] | undefined> {
        const response = await vpcApiInstance.vpcsGet(cloud, region);
        return response?.data?.vpcs?.map((vpc: VpcDTO) => new Vpc(vpc));
    }

    /**
     * Get Total Count of VPCs.
     */
    static async getTotalVpc(): Promise<number> {
        const response = await vpcApiInstance.vpcsGet();
        return response?.data?.count || 0;
    }

    /**
     * Get a VPC.
     * @param {string} cloud
     * @param {string} region
     * @param {string} vpcUuid
     */
    static async getVpc({
        cloud,
        region,
        vpcUuid,
    }: GetVpcParams): Promise<Vpc | undefined> {
        const result = await vpcApiInstance.vpcsVpcIdGet(vpcUuid);
        if (result.data.vpc) {
            return new Vpc(result.data.vpc);
        }
    }

    /**
     * Create a VPC
     * @param {string} accountId
     */
    static async createVpc({
        cidrIpv4Block,
        cloud,
        region,
        vpcName,
    }: CreateVpcParams): Promise<any> {
        return await vpcApiInstance.vpcsPost({
            cloud,
            region,
            cidr_ipv4_block: cidrIpv4Block,
            name: vpcName,
        });
    }

    /**
     * Delete a VPC
     * @param {string} cloud
     * @param {string} region
     * @param {string} vpcId
     */
    static async deleteVpc({ vpcUuid }: DeleteVpcParams): Promise<any> {
        return await vpcApiInstance.vpcsVpcIdDelete(vpcUuid);
    }

    /**
     * Get list of Subnets for a VPC.
     * @param {string} cloud
     * @param {string} region
     * @param {string} vpcId
     */
    static async getAllSubnet({
        cloud,
        region,
        vpcId,
    }: GetAllSubnetParams): Promise<VpcAwsSubnet[]> {
        const response = await this.request(
            'GET',
            `${cloud}/${region}/vpc/${vpcId}/subnets`
        );
        return response.map(
            (subnet: VpcAwsSubnetClassProps) => new VpcAwsSubnet(subnet)
        );
    }

    /**
     * Create a Subnet
     * @param {string} availabilityZone
     * @param {string} cidrBlock
     * @param {string} cloud
     * @param {string} region
     * @param {string} vpcId
     */
    static async createSubnet({
        availabilityZone,
        cidrBlock,
        cloud,
        region,
        vpcId,
    }: CreateSubnetParams): Promise<any> {
        const requestData = {
            data: {
                cloud,
                region,
                vpc_id: vpcId,
                availability_zone: availabilityZone,
                cidr_block: cidrBlock,
            },
        };
        return await this.request(
            'POST',
            `${cloud}/${region}/vpc/${vpcId}/subnet`,
            requestData
        );
    }

    /**
     * Delete a Subnet
     * @param {string} cloud
     * @param {string} region
     * @param {string} subnetId
     * @param {string} vpcId
     */
    static async deleteSubnet({
        cloud,
        region,
        subnetId,
        vpcId,
    }: DeleteSubnetParams): Promise<any> {
        return await this.request(
            'DELETE',
            `${cloud}/${region}/vpc/${vpcId}/subnet/${subnetId}`
        );
    }

    /**
     * Get list of Peers.
     * @param {string} cloud
     * @param {string} region
     */
    static async getAllPeer({
        cloud,
        region,
    }: GetAllPeerParams): Promise<VpcPeering[]> {
        throw new Error('Not implemented');
        const response = await this.request(
            'GET',
            `${cloud}/${region}/peerings`
        );
        return response.map((vpc: any) => new VpcPeering(vpc));
    }

    static async getVpcPeer(vpcId: string): Promise<VpcPeering[]> {
        const response = await vpcPeeringApiInstance.vpcsVpcIdPeeringsGet(
            vpcId
        );
        return (
            response.data.peerings?.map(
                (vpc: VpcPeeringDTO) =>
                    new VpcPeering({ ...vpc, vpc_id: vpcId })
            ) || []
        );
    }

    /**
     * Create a Peer
     * @param {string} cloud
     * @param {string} internetGatewayId
     * @param {string} privateSubnetId
     * @param {string} region
     * @param {string} routeTableId
     * @param {string} vpcId
     */
    static async createPeer({
        accepterCidr,
        accepterOwnerId,
        accepterRegion,
        accepterVpcId,
        cloud,
        region,
        vpcUuid,
    }: CreatePeerParams): Promise<any> {
        const response = await vpcPeeringApiInstance.vpcsVpcIdPeeringsPost(
            vpcUuid,
            {
                target_cidr: accepterCidr,
                target_cloud_user_id: accepterOwnerId,
                target_cloud_region: accepterRegion,
                target_cloud_vpc_id: accepterVpcId,
            }
        );

        return response?.data;
    }

    /**
     * Delete a Subnet
     * @param {string} vpcId
     * @param {string} cloud
     * @param {string} region
     * @param {string} peeringId
     */
    static async deletePeer({
        vpcId,
        cloud,
        region,
        peeringId,
    }: DeletePeeringParams): Promise<any> {
        return await vpcPeeringApiInstance.vpcsVpcIdPeeringsPeeringIdDelete(
            vpcId,
            peeringId
        );
    }

    protected static getApiUrl(version: number) {
        if (version === 2) {
            return API_VPC_V2;
        }
        return API_VPC;
    }
}
