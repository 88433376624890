import { useEffect, useState } from 'react';
import VpcService from '../../services/VpcService';

export default function useVpcs(cloud?: string, region?: string) {
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [vpcsCount, setVpcsCount] = useState<number | undefined>(undefined);

    const load = async () => {
        try {
            const totalVpc = await VpcService.getTotalVpc();

            setVpcsCount(totalVpc);
            setError(null);
        } catch (e: any) {
            setError(e);
        }
    };

    const refresh = () => {
        setUpdating(true);
        load();
        setUpdating(false);
    };

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            await load();
            setLoading(false);
        };

        init();
    }, []);

    return { vpcsCount, refresh, loading, updating, error };
}
