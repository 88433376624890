import { Space as AntSpace, SpaceProps as AntSpaceProps } from 'antd';
import { Property } from 'csstype';

type AppSpaceProps = AntSpaceProps & {
    wide?: boolean;
    justify?: Property.JustifyContent;
};
export default function Space({
    wide,
    justify,
    style,
    ...rest
}: AppSpaceProps) {
    return (
        <AntSpace
            style={{
                width: wide ? '100%' : undefined,
                justifyContent: justify,
                ...style,
            }}
            {...rest}
        />
    );
}
