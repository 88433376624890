import { CloseCircleOutlined } from '@ant-design/icons';
import React from 'react';
import CcxComponentProps from '../../../core/CcxComponent';

interface Props extends CcxComponentProps {}

const CcxIconCloseCircleOutlined = ({
    testId = 'CcxIconCloseCircleOutlined',
}: Props) => <CloseCircleOutlined data-testid={testId} />;

export default CcxIconCloseCircleOutlined;
