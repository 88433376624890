export type InstanceVolumeIopsValueClassProps = {
    default: number;
    max: number;
    max_per_gb: number;
    min: number;
};

export interface InstanceVolumeIopsValueInterface {
    default: number;
    max: number;
    maxPerGb: number;
    min: number;
}

export default class InstanceVolumeIopsValue
    implements InstanceVolumeIopsValueInterface
{
    readonly default: number;
    readonly max: number;
    readonly maxPerGb: number;
    readonly min: number;

    constructor(props: InstanceVolumeIopsValueClassProps) {
        this.default = props.default;
        this.max = props.max;
        this.maxPerGb = props.max_per_gb;
        this.min = props.min;
    }
}
