import React, { ReactElement } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import styles from './AppLabeledIconStatus.module.less';

interface Props extends CcxComponentProps {
    className?: string;
    icon?: ReactElement;
    label?: string;
}

function AppLabeledIconStatus({
    className,
    icon,
    label,
    testId = 'AppLabeledIconStatus',
}: Props): ReactElement {
    return (
        <div
            className={`${styles.AppLabeledIconStatus} ${className}`}
            data-testid={testId}
        >
            <div
                className={styles.AppLabeledIconStatusIcon}
                data-testid={`${testId}Icon`}
            >
                {icon}
            </div>
            <div
                className={styles.AppLabeledIconStatusLabel}
                data-testid={`${testId}Label`}
            >
                {label}
            </div>
        </div>
    );
}

export default AppLabeledIconStatus;
