import ApiService from './ApiService';
import { API_V2 } from '../core/CcxEnv';
import CmonStatus from '../types/CmonStatus';

// Constants
const CMON_API_URL = API_V2;

export default class CmonService extends ApiService {
    /**
     * Get CMON Status.
     */
    static async getCmonStatus(): Promise<CmonStatus | void> {
        try {
            const response = await this.request('GET', `status`);
            return new CmonStatus(response);
        } catch (error) {
            console.error('Error occurred while fetching CMON status:', error);
        }
    }

    protected static getApiUrl() {
        return CMON_API_URL;
    }
}
