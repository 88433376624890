import React from 'react';
import { ButtonProps } from 'antd';
import StretchedClick from './StretchedClick';

type ButtonOverlayDialogProps = {
    visible: boolean;
    onCancel: (...args: any) => void;
    onSuccess: (...args: any) => void;
};
type ButtonWithOverlayDialogProps = {
    button: React.ReactElement<ButtonProps>;
    overlay: React.ReactElement<ButtonOverlayDialogProps>;
    destroyOnClose?: boolean;
    stretchedClick?: boolean;
};
export default function ButtonWithOverlayDialog({
    overlay,
    button,
    destroyOnClose = true,
    stretchedClick = false,
    ...rest
}: ButtonWithOverlayDialogProps) {
    const [visible, setVisible] = React.useState(false);

    const handleButtonClick = (e: any) => {
        setVisible(true);
        button.props?.onClick?.(e);
    };

    const handleCancel = (...args: any) => {
        setVisible(false);
        overlay.props?.onCancel?.(...args);
    };

    const handleSuccess = (...args: any) => {
        setVisible(false);
        overlay.props?.onSuccess?.(...args);
    };

    const buttonRender = React.cloneElement(button, {
        ...button.props,
        onClick: stretchedClick ? () => true : handleButtonClick,
    });

    return (
        <>
            {stretchedClick && (
                <StretchedClick handleClick={handleButtonClick} />
            )}

            {buttonRender}
            {(!destroyOnClose || (destroyOnClose && visible)) &&
                React.cloneElement(overlay, {
                    ...overlay.props,
                    onSuccess: handleSuccess,
                    onCancel: handleCancel,
                    visible,
                })}
        </>
    );
}
