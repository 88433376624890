import { useEffect, useState } from 'react';
import useDataStore from '../../../../core/hooks/useDataStore';
import useDatabases from '../../../../core/hooks/useDatabases';
import useDbUsers from '../../../../core/hooks/useDbUsers';
import ConnectionAssistantWidget from '../../../dbUsers/ConnectionAssistant';
import DbUser from '../../../../types/DbUser';
import DeploymentsItem from '../../../../types/DeploymentsItem';

interface ActionMenuProps {
    dataStoreUuid: string | undefined;
    currentDeployment: DeploymentsItem | undefined;
}

function ConnectionAssistant({
    dataStoreUuid,
    currentDeployment,
}: ActionMenuProps) {
    const { databases } = useDatabases(dataStoreUuid);
    const { dataStore: deployment } = useDataStore(dataStoreUuid);
    const [hosts, setHosts] = useState<any>(undefined);
    const [dbService, setDbService] = useState('');
    const [usersHosts, setUsersHosts] = useState<string[]>([]);
    const { dbUsers } = useDbUsers(dataStoreUuid);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (deployment) {
            setHosts(deployment.getHosts());
        }
    }, [deployment]);

    useEffect(() => {
        setDbService(currentDeployment?.getServiceName() || '');
    }, [currentDeployment]);

    useEffect(() => {
        if (dbUsers && dbUsers.accounts && dbUsers.accounts?.length) {
            setUsersHosts(
                dbUsers.accounts?.map((u: DbUser) => {
                    return u.username;
                })
            );
        }
    }, [dbUsers]);

    useEffect(() => {
        if (hosts && dbService && currentDeployment && databases && usersHosts)
            setLoading(false);
    }, [hosts, currentDeployment, dbService, databases, usersHosts]);

    return !loading ? (
        <ConnectionAssistantWidget
            databases={databases?.databases}
            users={usersHosts}
            hosts={hosts}
            service={dbService}
            datastore={currentDeployment as any}
        />
    ) : null;
}

export default ConnectionAssistant;
