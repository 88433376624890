import { useEffect } from 'react';
import { fetchCmonStatus } from '../../slices/cmon.slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

export default function useCmonStatus() {
    const dispatch = useAppDispatch();

    const { status, loading, error } = useAppSelector((state) => state.cmon);

    const refresh = () => {
        dispatch(fetchCmonStatus());
    };

    useEffect(() => {
        dispatch(fetchCmonStatus());

        const intervalId = setInterval(() => {
            refresh();
        }, 20000);

        return () => {
            clearInterval(intervalId);
        };
    }, [dispatch]);

    return { status, error, loading };
}
