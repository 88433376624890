import React, { ReactElement } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import styles from './VpcDataStores.module.less';

interface Props extends CcxComponentProps {
    dataStores: number;
}

function VpcDataStores({
    testId = 'VpcDataStores',
    dataStores = 0,
}: Props): ReactElement {
    return (
        <div className={styles.VpcDataStores} data-testid={testId}>
            <div data-testid={`${testId}DataStore`}>
                {dataStores} datastore{dataStores !== 1 ? 's' : ''}
            </div>
        </div>
    );
}

export default VpcDataStores;
