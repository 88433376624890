import React, { ReactElement } from 'react';
import styles from './AppChartTinyArea.module.less';
import { TinyArea } from '@ant-design/charts';
import CcxComponentProps from '../core/CcxComponent';

interface AppChartTinyAreaProps extends CcxComponentProps {
    config: any;
    data: any[];
}

function AppChartTinyArea({
    config,
    data,
    testId = 'AppChartTinyArea',
}: AppChartTinyAreaProps): ReactElement {
    return (
        <div className={styles.AppChartTinyArea} data-testid={testId}>
            <TinyArea {...config} data={data} />
        </div>
    );
}

export default AppChartTinyArea;
