export type AutoScale = {
    enabled: boolean;
};

export type DataStoreSpecClassProps = {
    autoscale_storage: AutoScale;
};

export default class DataStoreSpec {
    readonly autoscale_storage: AutoScale;

    constructor(props: DataStoreSpecClassProps) {
        this.autoscale_storage = props.autoscale_storage;
    }
}
