import { DbGrowth } from './DbGrowth';

export interface DbGrowthDataDirectoryInterface {
    dbGrowthData: DbGrowth[];
}

export default class DbGrowthData implements DbGrowthDataDirectoryInterface {
    readonly dbGrowthData: DbGrowth[];

    constructor(props?: DbGrowth[]) {
        this.dbGrowthData =
            props?.map((d: any) => {
                return new DbGrowth(d);
            }) || [];
    }
}
