import { ReactElement } from 'react';
import CcxComponentProps from '../../../../core/CcxComponent';
import DeploymentsItem from '../../../../types/DeploymentsItem';
import Service from '../../../../types/Service';
import AppCodeCard from '../../../AppCodeCard';
import SecretText from '../../../SecretText';
import styles from './ServiceAccessConnectionInformation.module.less';
import useDatastoreServiceDsn from './useDatastoreServiceDsn';

interface Props extends CcxComponentProps {
    dataStore: DeploymentsItem;
    connectionMethod: string;
    service: Service;
    type?: string;
}

function ServiceAccessConnectionInformation({
    connectionMethod,
    dataStore,
    service,
    type = 'db',
    testId = 'ServiceAccessConnectionInformation',
}: Props): ReactElement {
    const { username, password, database, dbType } = useDatastoreServiceDsn({
        dataStore,
        service,
        type,
    });

    const connectionString =
        connectionMethod === 'dsn'
            ? `${dbType}://${username}:${password}@${dataStore?.getPrimaryReplicaUrl(
                  service
              )}${!dataStore?.isRedis() ? `/${database}` : ''}`
            : `mysql -uccxadmin -p${password} -h${dataStore?.getPrimaryReplicaUrl(
                  service
              )} -P3306 ccxdb`;

    return (
        <div
            className={styles.ServiceAccessConnectionInformation}
            data-testid={testId}
        >
            <AppCodeCard
                className={styles.ServiceAccessConnectionInformationCode}
                usageExample={[connectionString]}
            >
                <div
                    className={
                        styles.ServiceAccessConnectionInformationCodeContent
                    }
                >
                    {connectionMethod === 'dsn' ? (
                        <>
                            <div>{dbType}</div>
                            <div>://</div>
                            <SecretText
                                visibleText={`_CLICK_TO_REVEAL_PASSWORD_`}
                                hiddenText={`${username}:${password}`}
                                tooltipText="Click to show / hide username and password"
                            />
                            <div>{`@${dataStore?.getPrimaryReplicaUrl(
                                service
                            )}${
                                !dataStore?.isRedis() ? `/${database}` : ''
                            }`}</div>
                        </>
                    ) : (
                        <>
                            <div>mysql -uccxadmin -p</div>
                            <div>
                                <SecretText
                                    visibleText={`_CLICK_TO_REVEAL_PASSWORD_`}
                                    hiddenText={`${password}`}
                                    tooltipText="Click to show / hide password"
                                />
                            </div>
                            <div>
                                &nbsp;-h
                                {dataStore?.getPrimaryReplicaUrl(service)}
                            </div>
                            <div>&nbsp;-P3306 ccxdb</div>
                        </>
                    )}
                </div>
            </AppCodeCard>
        </div>
    );
}

export default ServiceAccessConnectionInformation;
