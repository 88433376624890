import { useEffect, useState } from 'react';
import Backup from '../../types/Backup';
import BackupService from '../../services/BackupService';

type CmonBackupsResponse = {
    backups: any[];
    total: number;
};

export default function useBackups(uuid?: string, page = 1, pageSize = 5) {
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [total, setTotal] = useState<any>(null);
    const [error, setError] = useState<Error | null>(null);
    const [backups, setBackups] = useState<Backup[] | undefined | any>(
        undefined
    );

    const load = async () => {
        if (uuid) {
            try {
                const details = await BackupService.getAllBackups(
                    uuid,
                    page,
                    pageSize
                );
                setBackups(details.backups);
                setTotal(details.total);
                setError(null);
            } catch (e: any) {
                setError(e);
            }
        }
    };

    const refresh = () => {
        setUpdating(true);
        load();
        setUpdating(false);
    };

    // get deployments for the given user
    useEffect(() => {
        const init = async () => {
            setLoading(true);
            await load();
            setLoading(false);
        };

        init();
    }, [uuid, page, pageSize]);

    return {
        backups,
        refresh,
        loading,
        updating,
        error,
        page,
        pageSize,
        total,
    };
}
