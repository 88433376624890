export type PrometheusRamUsageStatsBackendApiProps = {
    created: number;
    total: number;
    available: number;
    free: number;
    used: number;
};

export default class PrometheusRamUsageStats {
    readonly created: number;
    readonly total: number;
    readonly available: number;
    readonly free: number;
    readonly used: number;

    constructor(props: PrometheusRamUsageStatsBackendApiProps) {
        this.created = props.created;
        this.total = props.total;
        this.available = props.available;
        this.free = props.free;
        this.used = props.used;
    }
}
