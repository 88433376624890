import { useEffect, useState } from 'react';
import VpcService from '../../services/VpcService';
import Vpc from '../../types/Vpc';

export default function useVpcs(cloud?: string, region?: string) {
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [vpcs, setVpcs] = useState<Vpc[] | undefined>(undefined);

    const load = async () => {
        // vpcs are for only aws datastores
        if (cloud && region && ['aws', 'lintasarta'].includes(cloud)) {
            try {
                const details = await VpcService.getAllVpc({
                    cloud,
                    region,
                });
                setVpcs(details);
                setError(null);
            } catch (e: any) {
                setError(e);
            }
        }
    };

    const refresh = () => {
        setUpdating(true);
        load();
        setUpdating(false);
    };

    // get deployments for the given user
    useEffect(() => {
        const init = async () => {
            setLoading(true);
            await load();
            setLoading(false);
        };

        init();
    }, [cloud, region]);

    return { vpcs, refresh, loading, updating, error };
}
