export type DbUserClassProps = {
    grants: string;
    host_allow: string;
    user_name: string;
    uniqueGrants: string[];
    auth_plugin: string;
};

export interface DbUserInterface {
    grants: string;
    hostAllow: string;
    username: string;
    uniqueKey: string; // this is used for the Table component, a unique key for each value
    uniqueGrants: string[];
    authPlugin: string;
}

export const DEFAULT_DATABASE_USER = 'ccxadmin';

export default class DbUser implements DbUserInterface {
    readonly grants: string;
    readonly hostAllow: string;
    readonly username: string;
    readonly uniqueKey: string;
    readonly uniqueGrants: string[];
    readonly authPlugin: string;

    constructor(props: DbUserClassProps) {
        this.grants = props.grants;
        this.hostAllow = props.host_allow;
        this.username = props.user_name;
        this.uniqueKey = `${props.user_name}-${props.host_allow}`;
        this.uniqueGrants = [];
        this.authPlugin = props.auth_plugin;
    }

    /** Return array with a list of unique grants
     * Example:
     * From this.grants: "CREATE USER;db1:ALL PRIVILEGES,GRANT OPTION;db2:SELECT;db3:SELECT
     * Returns: ["CREATE USER", "ALL PRIVILEGES", "GRANT OPTION", "SELECT"]
     *
     */
    getUniqueGrants() {
        if (this.grants.length > 0) {
            const regex = /^.*\:(.*)$/;

            // get parsed raw data:
            // 1: split ';'
            // 2: run regex
            // 3: flatten results into a single array
            const grants = this.grants
                .split(';')
                .map((g) => g.replace(regex, '$1').split(','))
                .flat(1);

            // remove duplicates and return data
            // see: https://medium.com/dailyjs/how-to-remove-array-duplicates-in-es6-5daa8789641c
            return grants.filter((item, i) => grants.indexOf(item) === i);
        }
        return [];
    }

    isDefaultUser(): boolean {
        return this.username === DEFAULT_DATABASE_USER;
    }
}
