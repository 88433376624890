import DotLabelStatus, {
    DotLabelStatusProps,
} from '../ccx/common/DotLabelStatus';
import React from 'react';
import { BackupScheduleStatus } from '../../types/BackupSchedule';

type BackupScheduleStatusFormatProps = DotLabelStatusProps & {
    status?: BackupScheduleStatus | string;
};
export default function BackupScheduleStatusFormat({
    status,
    ...rest
}: BackupScheduleStatusFormatProps) {
    return (
        <DotLabelStatus
            type={getBackupScheduleStatusType(status)}
            label={getBackupScheduleStatusLabel(status)}
            {...rest}
        />
    );
}

function getBackupScheduleStatusLabel(status?: BackupScheduleStatus | string) {
    switch (status) {
        case BackupScheduleStatus.SCHEDULED:
            return 'Active';
        case BackupScheduleStatus.PAUSED:
            return 'Paused';
        default:
            return 'Unknown';
    }
}

function getBackupScheduleStatusType(status?: BackupScheduleStatus | string) {
    switch (status) {
        case BackupScheduleStatus.SCHEDULED:
            return 'ok';
        case BackupScheduleStatus.PAUSED:
            return 'disabled';
        default:
            return 'disabled';
    }
}
