import ApiService from './ApiService';
import Deployments from '../types/Deployments';
import { API_DEPLOYMENT_V3 } from '../core/CcxEnv';
import DeploymentOptions from '../types/DeploymentOptions';

// constants
const API_URL = API_DEPLOYMENT_V3;

export default class DataStoresService extends ApiService {
    /**
     * Get list of Data Stores.
     */
    static async getDataStores(
        projectUuid?: string,
        deploymentOptions?: DeploymentOptions,
        paramString?: string
    ): Promise<any> {
        // @TODO: Use the projectUuid when the backend is ready
        // const response = await this.request('GET', `deployments/${projectUuid}`);
        const response = await this.request(
            'GET',
            `data-stores?${paramString}`
        );

        return {
            datastores: new Deployments(response.datastores, deploymentOptions),
            totalItems: response.total,
        };
    }

    protected static getApiUrl() {
        return API_URL;
    }
}
