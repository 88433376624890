import React, { ReactElement } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import AppLogoBrand from './AppLogoBrand';

interface Props extends CcxComponentProps {
    height?: number;
    width?: number;
    useExtended?: boolean;
}

function AppLogoBrandRedis({
    height = 28,
    width = 90,
    testId = 'AppLogoBrandRedis',
    useExtended = true,
}: Props): ReactElement {
    return (
        <AppLogoBrand
            logo={
                useExtended
                    ? 'vendor-redis-brand-logo'
                    : 'vendor-redis-brand-logo-short'
            }
            height={height}
            width={width}
            size=""
            testId={testId}
        />
    );
}

export default AppLogoBrandRedis;
