import { Tooltip } from 'antd';
import React, { ReactElement } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import styles from './VpcStatus.module.less';
import Vpc from '../../../types/Vpc';
import DotLabelStatus from '../../ccx/common/DotLabelStatus';

interface Props extends CcxComponentProps {
    vpc: Vpc;
    updating?: boolean;
}

function VpcStatus({
    vpc,
    testId = 'VpcStatus',
    updating = false,
}: Props): ReactElement {
    let StatusComponent = null;

    if (vpc?.isStatusOk()) {
        StatusComponent = (
            <DotLabelStatus
                type="ok"
                label={vpc?.getVpcStatus()}
                glow={updating}
                testId={`${testId}StatusComponent`}
            />
        );
    } else if (vpc?.isStatusError()) {
        StatusComponent = (
            <DotLabelStatus
                type="error"
                label={vpc?.getStatusMessage()}
                glow={updating}
                testId={`${testId}StatusComponent`}
            />
        );
    } else if (vpc?.isStatusWarning()) {
        StatusComponent = (
            <DotLabelStatus
                type="warning"
                label={vpc?.getStatusMessage()}
                glow={updating}
                testId={`${testId}StatusComponent`}
            />
        );
    } else {
        StatusComponent = (
            <DotLabelStatus
                type="normal"
                label={vpc?.getVpcStatus()}
                glow={updating}
                testId={`${testId}StatusComponent`}
            />
        );
    }

    if (vpc?.isStatusError() || vpc?.isStatusWarning()) {
        return (
            <div data-testid={testId}>
                <Tooltip
                    placement="bottom"
                    title="Our Support Team has been notified of an incident in regards to this VPC. No further action is required."
                >
                    {StatusComponent}{' '}
                </Tooltip>
            </div>
        );
    }

    return (
        <div data-testid={testId}>
            <Tooltip placement="bottom" title={vpc?.getStatusMessage()}>
                {StatusComponent}{' '}
            </Tooltip>
        </div>
    );
}

export default VpcStatus;
