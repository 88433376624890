import React, { useEffect, useState } from 'react';
import { Radio, Switch, Space } from 'antd';
import styles from './DeploymentDashboardOptions.module.less';
import AppLabeledComponent from '../../ccx/common/AppLabeledComponent';
import CcxComponentProps from '../../../core/CcxComponent';
import DeploymentsItem from '../../../types/DeploymentsItem';

interface DeploymentDashboardOptionsProps extends CcxComponentProps {
    value?: any;
    onChange?: Function;
    onSummaryChange?: Function;
    disabled?: boolean;
    deployment?: DeploymentsItem;
    hasLoadBalancers?: boolean;
}

const DeploymentDashboardOptions: React.FC<DeploymentDashboardOptionsProps> = ({
    value,
    onChange,
    onSummaryChange,
    disabled,
    deployment,
    hasLoadBalancers,
    testId = 'DeploymentDashboardOptions',
}) => {
    const [tabsOptions, setTabsOptions] = useState<any>();

    useEffect(() => {
        if (deployment?.isRedis() || !hasLoadBalancers) {
            setTabsOptions([
                { label: 'System', value: 'host' },
                { label: 'Database nodes', value: 'db' },
            ]);
        } else {
            setTabsOptions([
                { label: 'System', value: 'host' },
                { label: 'Database nodes', value: 'db' },
                { label: 'Load balancers', value: 'lb' },
            ]);
        }
    }, [deployment]);

    const handleTabOnChange = (e: any) => {
        onChange && onChange(e.target.value);
    };

    const handleOnSummaryChange = (value: boolean) => {
        onSummaryChange && onSummaryChange(value);
    };

    return (
        <Space data-testid={testId}>
            <AppLabeledComponent label="Chart set:" softText={false}>
                <Radio.Group
                    value={value}
                    onChange={handleTabOnChange}
                    optionType="button"
                    disabled={disabled}
                    className={styles.DeploymentDashboardOptionsChartSetRadio}
                >
                    {tabsOptions?.map(
                        ({ label, value: val }: any, index: any) => (
                            <Radio.Button key={val} value={val}>
                                <span>{label}</span>
                            </Radio.Button>
                        )
                    )}
                </Radio.Group>
            </AppLabeledComponent>
        </Space>
    );
};

export default DeploymentDashboardOptions;
