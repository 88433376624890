import InstanceVolumeType, {
    InstanceVolumeTypeClassProps,
} from './InstanceVolumeType';

export type InstanceVolumeTypesClassProps = {
    [provider: string]: InstanceVolumeTypeClassProps[];
};

export interface InstanceVolumeTypesInterface {
    [provider: string]: InstanceVolumeType[];
}

export default class InstanceVolumeTypes
    implements InstanceVolumeTypesInterface
{
    [provider: string]: InstanceVolumeType[];

    constructor(props: InstanceVolumeTypesClassProps) {
        for (const provider in props) {
            if (Object.prototype.hasOwnProperty.call(props, provider)) {
                this[provider] = (props[provider] || []).map(
                    (vt: InstanceVolumeTypeClassProps) =>
                        new InstanceVolumeType(vt)
                );
            }
        }
    }
}
