export type AdditionalFieldsClassProps = {
    new_user: string;
};

export default class AdditionalFields {
    public readonly newUser: boolean;

    constructor(props: AdditionalFieldsClassProps) {
        if (props) {
            this.newUser = props.new_user ? JSON.parse(props.new_user) : false;
        } else {
            this.newUser = false;
        }
    }
}
