import React from 'react';
import { Tabs } from 'antd';

export type WizardConfigurationStep = {
    step: string;
    title?: string | React.ReactElement;
    content: React.ReactElement;
    className?: string;
};
export type WizardConfigurationProps = {
    steps: WizardConfigurationStep[];
    activeStep?: string;
};
export default function WizardConfiguration({
    steps,
    activeStep,
}: WizardConfigurationProps) {
    return (
        <Tabs
            className="WizardContainer"
            activeKey={activeStep}
            destroyInactiveTabPane={true}
            renderTabBar={() => <div />}
            animated={false}
        >
            {steps.map(({ title = '', step, className, content }) => (
                <Tabs.TabPane tab={title} key={step} className={className}>
                    {content}
                </Tabs.TabPane>
            ))}
        </Tabs>
    );
}
