export type PrometheusPostgreSqlLockTablesStatsBackendApiProps = {
    created: number;
    pg_locks_count: number;
};

export default class PrometheusPostgreSqlLockTablesStats {
    readonly created: number;
    readonly pgLocksCount: number;

    constructor(props: PrometheusPostgreSqlLockTablesStatsBackendApiProps) {
        this.created = props.created;
        this.pgLocksCount = props.pg_locks_count;
    }
}
