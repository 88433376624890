export type PrometheusMySqlMemoryUtilizationStatsBackendApiProps = {
    created: number;
    innodb_mem_adaptive_hash: number;
    innodb_buffer_pool_size: number;
    query_cache_size: number;
    key_buffer_size: number;
    innodb_log_buffer_size: number;
};

export default class PrometheusMySqlMemoryUtilizationStats {
    readonly created: number;
    readonly innodbMemAdaptiveHash: number;
    readonly innodbBufferPoolSize: number;
    readonly queryCacheSize: number;
    readonly keyBufferSize: number;
    readonly innodbLogBufferSize: number;

    constructor(props: PrometheusMySqlMemoryUtilizationStatsBackendApiProps) {
        this.created = props.created;
        this.innodbMemAdaptiveHash = props.innodb_mem_adaptive_hash;
        this.innodbBufferPoolSize = props.innodb_buffer_pool_size;
        this.queryCacheSize = props.query_cache_size;
        this.keyBufferSize = props.key_buffer_size;
        this.innodbLogBufferSize = props.innodb_log_buffer_size;
    }
}
