export type PrometheusProxySqlDataSentHostgroupDistributionStatsBackendApiProps =
    {
        created: number;
        data_sent: number;
    };

export default class PrometheusProxySqlDataSentHostgroupDistributionStats {
    readonly created: number;
    readonly dataSent: number;

    constructor(
        props: PrometheusProxySqlDataSentHostgroupDistributionStatsBackendApiProps
    ) {
        this.created = props.created;
        this.dataSent = props.data_sent;
    }
}
