import { useEffect, useState } from 'react';
import VpcService from '../../services/VpcService';
import VpcPeering from '../../types/VpcPeering';

export default function useVpcPeers(
    cloud?: string,
    region?: string,
    vpcIds?: string[]
) {
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [vpcPeers, setVpcPeers] = useState<VpcPeering[] | undefined>(
        undefined
    );
    const ids = vpcIds?.join(',') || '';

    const load = async () => {
        if (cloud && region && vpcIds && vpcIds.length > 0) {
            try {
                setLoading(true);
                // @todo handler error and find a way to display error message
                const details = await Promise.all(
                    vpcIds.map(async (vpcId) => {
                        return await VpcService.getVpcPeer(vpcId);
                    })
                );
                setVpcPeers(details.flat());
                setError(null);
                setLoaded(true);
            } catch (e: any) {
                console.log('error', e);
                setError(e);
            } finally {
                setLoading(false);
            }
        }
    };

    const refresh = async () => {
        await load();
    };

    // get deployments for the given user
    useEffect(() => {
        const init = async () => {
            await load();
        };

        init();
    }, [cloud, region, ids]);

    return { vpcPeers, refresh, loading, loaded, updating, error };
}
