export type PrometheusPostgreSqlIdleSessionsInTransactionStatsBackendApiProps =
    {
        created: number;
        idle_in_transaction: number;
    };

export default class PrometheusPostgreSqlIdleSessionsInTransactionStats {
    readonly created: number;
    readonly idleInTransaction: number;

    constructor(
        props: PrometheusPostgreSqlIdleSessionsInTransactionStatsBackendApiProps
    ) {
        this.created = props.created;
        this.idleInTransaction = props.idle_in_transaction;
    }
}
