export type PrometheusProxySqlMemoryUtilizationStatsBackendApiProps = {
    created: number;
    sqlite3_memory_bytes: number;
    connpool_memory_bytes: number;
    mysql_backend_buffers_bytes: number;
    mysql_frontend_buffers_bytes: number;
    mysql_session_internal_bytes: number;
};

export default class PrometheusProxySqlMemoryUtilizationStats {
    readonly created: number;
    readonly sqlite3MemoryBytes: number;
    readonly connpoolMemoryBytes: number;
    readonly mysqlBackendBuffersBytes: number;
    readonly mysqlFrontendBuffersBytes: number;
    readonly mysqlSessionInternalBytes: number;

    constructor(
        props: PrometheusProxySqlMemoryUtilizationStatsBackendApiProps
    ) {
        this.created = props.created;
        this.sqlite3MemoryBytes = props.sqlite3_memory_bytes;
        this.connpoolMemoryBytes = props.connpool_memory_bytes;
        this.mysqlBackendBuffersBytes = props.mysql_backend_buffers_bytes;
        this.mysqlFrontendBuffersBytes = props.mysql_frontend_buffers_bytes;
        this.mysqlSessionInternalBytes = props.mysql_session_internal_bytes;
    }
}
