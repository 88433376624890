import { useEffect, useState } from 'react';
import BackupService from '../../services/BackupService';

export default function useBackupRestores(uuid: string | undefined) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [backupRestores, setBackupRestores] = useState<any | undefined>(
        undefined
    );

    const load = async () => {
        if (uuid) {
            setLoading(true);

            try {
                const details = await BackupService.getBackupRestores(uuid);

                setBackupRestores(details);
                setLoading(false);
                setError(null);
            } catch (e: any) {
                setLoading(false);
                setError(e);
            }

            setLoading(false);
        }
    };

    const refresh = () => {
        load();
    };

    useEffect(() => {
        refresh();
    }, [uuid]);

    return { backupRestores, refresh, loading, error };
}
